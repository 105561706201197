import React from 'react'
import { Grid, Stack, Skeleton } from '@mui/material'

export default function CertificateSkeletonComponent() {
  return (
    <Grid container justifyContent="center" alignItems="start" spacing="40px">
      <Grid item xs={11} md>
        <Skeleton variant="rectangular" height="435px" sx={{ borderRadius: '6px' }} />

        <Stack direction="row" alignItems="center" spacing="16px" sx={{ marginTop: '12px', marginBottom: '12px' }}>
          <Skeleton variant="circular" width="48px" height="48px"/>
          <Skeleton variant="text" width="200px" height="30px" />
        </Stack>

        <Skeleton variant="text" width="500px" height="30px" />
        <Skeleton variant="text" width="500px" height="30px" />
      </Grid>

      <Grid item xs={11} md>
        <Skeleton variant="rectangular" height="360px" />
        <Skeleton variant="text" width="400px" height="60px" sx={{ marginTop: '10px', marginBottom: '5px' }} />
        <Skeleton variant="text" width="200px" height="30px" />

        <Skeleton variant="text" width="200px" height="30px" />

        <Skeleton variant="rectangular" height="48px" sx={{ borderRadius: '8px', marginTop: '15px' }} />
      </Grid>
    </Grid>
  )
}