import React from 'react'
import { Box, Stack, Typography, LinearProgress } from '@mui/material'

interface DevlabProgressProps {
  allScore?: string
  progress?: number
  completed?: boolean
}

export default function DevlabProgress({ allScore, progress, completed }: DevlabProgressProps) {
  return (
    <Box
      sx={{
        marginBottom: '20px',
        paddingY: '40px',
        paddingX: '20px',
        backgroundColor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'
      }}
    >
      <Stack direction="row" alignItems="center" gap="5px" sx={{ marginBottom: '3px' }}>
        <Typography variant="body1" sx={{ color: 'text.primary' }}>ความคืบหน้าในการทำแบบทดสอบ</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>{`(${allScore} คะแนน)`}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap="10px">
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            value={progress ?? 0}
            variant="determinate"
            color={completed ? 'success' : 'primary' }
            sx={{
              height: '8px',
              borderRadius: 5,
              '& .MuiLinearProgress-bar': {
                borderRadius: 5
              }
            }}
          />
        </Box>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>{`${progress}%`}</Typography>
      </Stack>
      {(completed && progress && progress < 100) ?
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>**คะแนนอาจมีการเปลี่ยนแปลงหากโจทย์มีการเปลี่ยนแปลง</Typography>
        : <></>
      }
    </Box>
  )
}
