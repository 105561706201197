import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';

const buddhistEra = require('dayjs/plugin/buddhistEra');
dayjs.extend(buddhistEra);

import duration from 'dayjs/plugin/duration';
import { DurationUnitType } from 'dayjs/plugin/duration';
dayjs.extend(duration);

import toObject from 'dayjs/plugin/toObject';
dayjs.extend(toObject);

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fDateThai(date: Date | string) {
  const dateTime = dayjs(date);
  return `${dateTime.format('DD')} ${
    thaiMonthList[dateTime.month()]
  } ${dateTime.format('BBBB')}`;
}

type Duration =
  | 'AUTO'
  | 'SECOND'
  | 'MINUTE'
  | 'HOUR'
  | 'DAY'
  | 'MONTH'
  | 'YEAR';

type DurationType = {
  time: number;
  unit: string;
};

export function fTimeDuration(
  second: number,
  unit?: Duration,
): Object | string {
  const time = dayjs.duration(second, 'seconds');
  const unitTime = unit?.toLowerCase() ?? null;

  const duration = durationUnitList.reduce((list, item) => {
    const unit = item.unit.toLowerCase();
    return {
      ...list,
      [unit]: {
        time: time.get(unit as DurationUnitType),
        unit: item.label,
      },
    };
  }, {});

  if (unit !== null) {
    if (unit === 'AUTO') {
      return Object.keys(duration).reduce((result: string, key: string) => {
        const durationByUnit: DurationType =
          duration[key as keyof typeof duration];
        if (result === '' && durationByUnit.time > 0)
          return result + `${durationByUnit.time} ${durationByUnit.unit} `;
        else return result;
      }, '');
    } else {
      const durationByUnit: DurationType =
        duration[unitTime as keyof typeof duration];
      return `${durationByUnit.time} ${durationByUnit.unit}`;
    }
  }

  return duration;
}

export const thaiMonthList = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

export const shortMonthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const durationUnitList = [
  { unit: 'YEAR', label: 'ปี' },
  { unit: 'MONTH', label: 'เดือน' },
  { unit: 'DAY', label: 'วัน' },
  { unit: 'HOUR', label: 'ชม.' },
  { unit: 'MINUTE', label: 'นาที' },
  { unit: 'SECOND', label: 'วินาที' },
];
