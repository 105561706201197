import React, { useState, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Typography, Container, Stack, Box } from '@mui/material'
import { Waypoint } from 'react-waypoint'
import axiosInstance from '../../utils/axios'

import { ProjectDetailType, FilterParameterType } from '../../@types/projectGallery'
import HelmetMetaData from '../../components/Helmet'

import GalleryControls from './GalleryControl'
import GalleryHeaderSearch from './GalleryProjectList/GalleryHeaderSearch'
import GalleryProjectGroup from './GalleryProjectList/GalleryProjectGroup'

import queryString from 'query-string'

export default function ProjectGallery() {
  const location = useLocation()
  const [searchParamsRoute] = useSearchParams()

  const [queryParams, setQueryParams] = useState(location.search ? location.search.replace('?', '&') : '')

  const [page, setPage] = useState<number>(1)
  const [loadingStatus, setLoadingStatus] = useState<string>('loading')

  const [projectList, setProjectList] = useState<ProjectDetailType[]>([])
  const [projectCount, setProjectCount] = useState<number>(0)

  const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth)

  const text1: string = 'Explore the project\'s stronger developers'
  const text2: string = 'รวมผลงานสุดเจ๋งเเละไอเดียอย่างกับ Start Up มา Pitching งานของเหล่า Developer ที่ผ่านการร่ำเรียนเเละฝึกวิชาอย่างยาวนาน จากบทเรียนสุดเเสนพิเศษจากทางเรา'

  const [searchMode, setSearchMode] = useState<string>(() => {
    if (searchParamsRoute.get('search') !== null) return 'search'
    else if (searchParamsRoute.getAll('categories').length > 1 || searchParamsRoute.getAll('tags').length !== 0) return 'filter'
    return 'main'
  })

  const [filterParameter, setFilterParameter] = useState<FilterParameterType>({
    sortBy: searchParamsRoute.get('sortBy') !== null ? searchParamsRoute.get('sortBy') as string : '',
    sortOrder: searchParamsRoute.get('sortBy') !== null ? searchParamsRoute.get('sortOrder') as string : '',
    search: searchParamsRoute.get('search') !== null ? searchParamsRoute.get('search') as string : '',
    tags: searchParamsRoute.getAll('tags').length !== 0 ? searchParamsRoute.getAll('tags') as string[] : [],
    category: searchParamsRoute.get('categories') !== null ? searchParamsRoute.get('categories') as string : searchMode === 'main' ? 'all' : '',
    categories: searchParamsRoute.getAll('categories').length > 1 ? searchParamsRoute.getAll('categories') as string[] : []
  })

  const applySearch = (playload: FilterParameterType) => {
    setFilterParameter(playload)
    sendSearchParams(playload)
  }

  const sendSearchParams = async (searchParams: FilterParameterType) => {
    let gatherParams = {}

    if (searchParams.sortBy !== '' && searchParams.sortOrder !== '') gatherParams = { ...gatherParams, sortBy: searchParams.sortBy, sortOrder: searchParams.sortOrder }
    if (searchParams.search !== '') gatherParams = { ...gatherParams, search: searchParams.search }
    if (searchParams.tags.length !== 0) gatherParams = { ...gatherParams, tags: searchParams.tags }

    if (searchParams.category !== '' && searchParams.category !== 'all') gatherParams = { ...gatherParams, categories: searchParams.category }
    else if (searchParams.categories.length !== 0) gatherParams = { ...gatherParams, categories: searchParams.categories }


    // const totalParams = Object.keys(searchParams).reduce((prev, curr : string) => Array.isArray(searchParams[curr]), {})

    const params = queryString.stringify(gatherParams)
    await setQueryParams('&' + params)
    await window.history.pushState({}, '', `/devproject${params !== '' ? '?' : ''}` + params);

    await setLoadingStatus('loading')
    await setPage(1)
    await setProjectList([])
    await fetchProjectGalleryList(1, `&${params}`)

  }

  const fetchProjectGalleryList = async (pageGallery: number, params?: string) => {
    try {
      const response = await axiosInstance.get(`/projects/gallery?&page=${pageGallery}&take=16${params ? params : queryParams}`)
      const data = await response.data

      if (pageGallery === 1) await setProjectList(data.data)
      else {
        await setPage(pageGallery)
        await setProjectList([...projectList, ...data.data])
      }

      if (data.meta.hasNextPage) await setLoadingStatus('ready')
      else await setLoadingStatus('empty')

      await setProjectCount(data.meta?.itemCount)
    } catch (error) {
      setProjectList([])
    }
  }


  useEffect(() => {
    if (loadingStatus === 'loading') fetchProjectGalleryList(page)
  }, [])


  useEffect(() => {
    const getWidth = () => setWidthWindow(window.innerWidth)

    window.addEventListener('resize', getWidth)
    return () => window.removeEventListener('resize', getWidth)
  }, [])

  return (
    <>
      <HelmetMetaData />
      <Container sx={{ marginY: '50px', maxWidth: '1270px' }}>
        {searchMode === 'main' ? (
          <Stack direction="row" justifyContent="center">
            <Box sx={{ maxWidth: '748.8px', width: '100%' }}>
              <Typography variant="h2" sx={{ textAlign: 'center', wordBreak: 'keep-all' }}>{text1}</Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'regular', marginTop: '20px' }}>{text2}</Typography>
            </Box>
          </Stack>
        ) : null}

        <GalleryControls
          widthWindow={widthWindow}
          searchMode={searchMode}
          onChangeSearchMode={setSearchMode}
          searchParams={filterParameter}
          submitSearchParams={applySearch}
        />


        <GalleryHeaderSearch
          searchMode={searchMode}
          galleryParams={filterParameter}
          projectCount={projectCount}
        />

        <GalleryProjectGroup
          searchMode={searchMode}
          loadingStatus={loadingStatus}
          projectList={projectList}
        />

        <Waypoint
          onEnter={() => {
            if (loadingStatus === 'ready') {
              fetchProjectGalleryList(page + 1)
              setLoadingStatus('loading')
            }
          }}
        />

      </Container>
    </>
  )
}
