import React from 'react';
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles';

interface LineAreaChartType {
  xLabelList: string[],
  yCountList: number[],
  unit: string,
  color: string,
  config?: object
}

export default function LineAreaChart ({ xLabelList, yCountList, unit, color, config = {} }: LineAreaChartType){
  const theme = useTheme()
  const chartData = [
     { name: unit, data: yCountList }
  ]
  const chartOptions = {
    colors: [color],
    xaxis: {
      categories: xLabelList,
      labels: {
        hideOverlappingLabels: true,
        show: true,
        style: {
          colors: theme.palette.grey[600],
          fontSize: '12px',
        }
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      axisTicks: { show: false },
      labels: {
        show: true,
        style: {
          colors: theme.palette.grey[600],
          fontSize: '12px',
        }
      },
      axisBorder: { show: false }
    },
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      background: 'none'
    },
    grid: {
      show: true,
      strokeDashArray: 3,
      borderColor: theme.palette.grey[500_24],
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      dropShadow: {
          enabled: false,
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0.2,
      }
    },
    stroke: {
      width: 3,
    },
    theme: {
      mode: 'dark' as 'dark' | 'light'
    }
  };

  return (
    <Chart type="area" series={chartData} options={{...chartOptions, ...config}} height={300} width={'100%'}/>
  )
}
