import React, { useState, useMemo, useEffect } from 'react';
import {
  Grid,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { ButtonGroup, Button } from '@mui/material';
import { Select, SelectChangeEvent, MenuItem } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

import axiosInstance from '../../utils/axios';
import { SearchFilterType, FilterOptionsType } from '../../@types/courseSearch';

import Icon from '../Iconify';
import useAuth from '../../hooks/useAuth';
interface CourseFilterControlProps {
  filters: SearchFilterType;
  setFilters: Function;
  isBundle: boolean;
  disableClickFn?: boolean;
}

interface CourseFilterProps {
  label: string;
  disabled?: boolean;
  value: string;
  options: FilterOptionsType[];
  onSelect: Function;
}

enum CourseFilterType {
  CATEGORY = 'CATEGORY',
  GROUP = 'GROUP',
}

const CourseFilterInputContent = [
  {
    label: 'หมวดหมู่คอร์สเรียน',
    name: 'categories',
    type: CourseFilterType.CATEGORY,
  },
  {
    label: 'ประเภทคอร์สเรียน',
    name: 'group',
    type: CourseFilterType.GROUP,
  },
];

const CourseFilterButtonContent = {
  course: [
    {
      label: 'เรียงลำดับตาม',
      name: 'sortBy',
      options: [
        { text: 'ผู้เรียน', value: 'MostEnrolled', icon: 'ph:student-light' },
        { text: 'ล่าสุด', value: 'Newest', icon: 'carbon:recently-viewed' },
      ],
    },
    {
      label: 'การลงทะเบียน',
      name: 'enroll',
      options: [
        { text: 'ทั้งหมด', value: 'All', icon: 'fluent-mdl2:view-all-2' },
        {
          text: 'เคยเรียน',
          value: 'Enrolled',
          icon: 'fluent-emoji-high-contrast:ok-hand',
        },
        {
          text: 'ยังไม่เคย',
          value: 'NotEnrolled',
          icon: 'material-symbols:do-not-touch-outline',
        },
      ],
    },
  ],
  bundle: [
    {
      label: 'เรียงลำดับตาม',
      name: 'sortBy',
      options: [
        { text: 'ผู้เรียน', value: 'MostEnrolled', icon: 'ph:student-light' },
        { text: 'ล่าสุด', value: 'Newest', icon: 'carbon:recently-viewed' },
        { text: 'รีวิว', value: 'MostReview', icon: 'ph:star' },
      ],
    },
    {
      label: 'ความเป็นเจ้าของ',
      name: 'own',
      options: [
        { text: 'ทั้งหมด', value: 'All', icon: 'fluent-mdl2:view-all-2' },
        {
          text: 'มีแล้ว',
          value: 'Owned',
          icon: 'fluent-emoji-high-contrast:ok-hand',
        },
        {
          text: 'ไม่มี',
          value: 'NotOwned',
          icon: 'material-symbols:do-not-touch-outline',
        },
      ],
    },
  ],
};

const SortFilterStyle = styled(Select)({
  '& .MuiSelect-select': {
    padding: '15px',
  },
  '&.Mui-disabled fieldset': {
    opacity: 0.5,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
  width: '100%',
  height: '54px',
});

function CourseFilterInput({
  disabled = false,
  value,
  label,
  options,
  onSelect,
}: CourseFilterProps) {
  const handleSelectChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value);
  };

  return (
    <Grid item xs={12} sm={5.9} md={2.9}>
      <Typography
        sx={{
          ...(disabled ? { color: 'text.disabled' } : {}),
        }}
      >
        {label}
      </Typography>
      <SortFilterStyle
        value={value || 'All'}
        disabled={options.length === 0 || disabled}
        onChange={(e) => handleSelectChange(e as SelectChangeEvent)}
        sx={{
          color: value ? 'text.primary' : 'text.secondary',
        }}
      >
        <MenuItem value="All">{'แสดงทั้งหมด'}</MenuItem>
        {options.map((item: FilterOptionsType, index: number) => (
          <MenuItem key={`${label}-${index}`} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </SortFilterStyle>
    </Grid>
  );
}

function CourseFilterAutoComplete({
  disabled = false,
  label,
  value,
  options,
  onSelect,
}: CourseFilterProps) {
  const [selected, setSelected] = useState<string>(value || '');

  useEffect(() => {
    if (typeof value === 'string')
      setSelected(
        options.find((option: FilterOptionsType) => option.value === value)
          ?.text ?? '',
      );
  }, [value, options]);
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={5.9} md={2.9}>
      <Typography
        sx={{
          ...(disabled ? { color: 'text.disabled' } : {}),
        }}
      >
        {label}
      </Typography>
      <Autocomplete
        freeSolo
        disabled={disabled}
        sx={{
          '.MuiAutocomplete-inputRoot': {
            padding: '8px',
          },
          '.Mui-disabled.MuiAutocomplete-inputRoot': {
            fieldset: {
              border: `solid 1.25px ${alpha(
                theme.palette.text.disabled,
                0.25,
              )}`,
            },
          },
        }}
        disableClearable={value === ''}
        value={value}
        inputValue={selected}
        options={[{ text: 'แสดงทั้งหมด', value: 'All' }, ...options]}
        getOptionLabel={(option) => (option as FilterOptionsType).text || ''}
        renderInput={(param) => (
          <TextField
            {...param}
            placeholder="แสดงทั้งหมด"
            disabled={disabled}
            onChange={(event) =>
              setSelected(
                typeof event.target.value === 'string'
                  ? event.target.value
                  : '',
              )
            }
          />
        )}
        onChange={(_, option, reason) => {
          if (reason === 'clear') onSelect('All');
          else if (reason === 'selectOption') {
            if (option === null) onSelect('All');
            else onSelect((option as FilterOptionsType).value);
          }
        }}
      />
    </Grid>
  );
}

function CourseFilterButtonGroup({
  disabled = false,
  value,
  label,
  options,
  onSelect,
}: CourseFilterProps) {
  const selected = useMemo(() => value, [value]);

  return (
    <Grid item xs={12} sm={5.9} md={2.9}>
      <Typography
        sx={{
          ...(disabled ? { color: 'text.disabled' } : {}),
        }}
      >
        {label}
      </Typography>
      <ButtonGroup
        fullWidth
        disabled={disabled}
        sx={{
          minHeight: '47px',
          height: 'fit-content',
        }}
      >
        {options.map((item: FilterOptionsType, index: number) => (
          <Button
            key={`${label}-${index}`}
            disabled={disabled}
            sx={{ opacity: disabled ? 0.5 : 1 }}
            color={selected === item.value ? 'primary' : 'tertiary'}
            variant={selected === item.value ? 'contained' : 'outlined'}
            onClick={() => onSelect(item.value)}
          >
            <Stack direction="column" alignItems="center" sx={{ gap: '0px' }}>
              <Icon icon={item.icon ?? ''} sx={{ fontSize: '20px' }} />
              <Typography variant="body2">{item.text}</Typography>
            </Stack>
          </Button>
        ))}
      </ButtonGroup>
    </Grid>
  );
}

export default function CourseFilterControl({
  filters,
  setFilters,
  isBundle,
  disableClickFn = false,
}: CourseFilterControlProps) {
  const [categoryList, setCategoryList] = useState<FilterOptionsType[]>([]);
  const [groupList, setGroupList] = useState<FilterOptionsType[]>(
    filters.group ? [{ text: filters.group, value: filters.group }] : [],
  );

  const [loading, setLoading] = useState<boolean[]>([true, true]);
  const isLoading = loading.reduce(
    (result: boolean, item: boolean) => result || item,
    false,
  );
  const { isAuthenticated } = useAuth();

  const formatOptions = (data: any[], selector: string) => {
    return data.map((item: any) => ({
      text: item[selector],
      value: item[selector],
    }));
  };

  const getCategoryList = async () => {
    try {
      const response = await axiosInstance('/categories?take=200');
      const data = await response.data;
      setCategoryList(formatOptions(data.data, 'title'));
      setLoading((prev: boolean[]) => [false, prev[1]]);
    } catch {
      return;
    }
  };

  const getGroupList = async () => {
    try {
      const response = await axiosInstance('/courses/group-suggest');
      const data = await response.data;
      setGroupList(formatOptions(data, 'name'));
      setLoading((prev: boolean[]) => [prev[0], false]);
    } catch {
      return;
    }
  };

  useEffect(() => {
    getCategoryList();
    getGroupList();
  }, []);

  return (
    <Grid container rowGap="20px" sx={{ justifyContent: 'space-between' }}>
      {CourseFilterButtonContent[isBundle ? 'bundle' : 'course'].map(
        (item: any, index: number) => (
          <CourseFilterButtonGroup
            key={`filter-button-group-${index}`}
            label={item.label}
            disabled={
              (item.name === 'enroll' || item.name === 'own') &&
              !isAuthenticated
            }
            value={
              filters[item.name as keyof typeof filters] ||
              item.options[0].value
            }
            options={item.options}
            onSelect={(value: string) => {
              if (!disableClickFn)
                setFilters(
                  { ...filters, [item.name]: value },
                  'NOT_CHANGE_URL',
                );
            }}
          />
        ),
      )}

      <CourseFilterAutoComplete
        disabled={isLoading || isBundle}
        label={CourseFilterInputContent[0].label}
        value={filters.categories}
        options={categoryList}
        onSelect={(value: string) => {
          if (!disableClickFn) {
            setFilters({
              ...filters,
              categories: value !== 'All' ? value : '',
            });
          }
        }}
      />

      <CourseFilterInput
        label={CourseFilterInputContent[1].label}
        disabled={isLoading || isBundle}
        value={filters.group}
        options={groupList}
        onSelect={(value: string) => {
          if (!disableClickFn) {
            setFilters({
              ...filters,
              group: value !== 'All' ? value : '',
            });
          }
        }}
      />
    </Grid>
  );
}
