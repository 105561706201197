import React, { useState } from 'react';
import { Box, Stack, Avatar, Typography, Button } from '@mui/material';
import {
  profileUserType,
  profileStatusType,
  profileExperienceType,
  profileCoverImageType,
} from '../../../@types/profile';

import ProfileStatusEditor from './ProfileStatusEditor';
import ProfileExperienceEditor from './ProfileExperienceEditor';
import CoverImageEditor from './CoverImageEditor';
import PublicNameEditor from './PublicNameEditor';

interface ProfileCoverProps {
  userData: profileUserType;
  setUserData: Function;
  profileStatus: profileStatusType[];
  experience: profileExperienceType | null;
  coverImage: profileCoverImageType | null;
}

export default function ProfileCover({
  userData,
  setUserData,
  profileStatus,
  experience,
  coverImage,
}: ProfileCoverProps) {
  const [openNameModal, setOpenNameModal] = useState<boolean>(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          marginBottom: '20px',
          '@media (max-width: 700px)': {
            flexDirection: 'column',
            justifyContent: 'center',
          },
        }}
      >
        <Stack direction="column">
          <Avatar
            src={userData.imageUrl}
            alt="profile-image"
            sx={{ width: '150px', height: '150px', margin: '0 auto' }}
          />
          <Typography
            variant="h5"
            sx={{
              wordBreak: 'break-word',
              textAlign: 'center',
              width: '150px',
              '@media (max-width: 700px)': {
                width: 'auto',
              },
            }}
          >
            {userData.publicName}
          </Typography>
          <Button
            sx={{ marginTop: '8px' }}
            onClick={() => setOpenNameModal(true)}
          >
            แก้ไขชื่อ
          </Button>
        </Stack>
        <Box sx={{ marginTop: '30px', width: '100%' }}>
          <ProfileExperienceEditor experience={experience} />
          <ProfileStatusEditor profileStatus={profileStatus} />
        </Box>
      </Box>
      <CoverImageEditor coverImage={coverImage} />
      <PublicNameEditor
        setUserName={setUserData}
        open={openNameModal}
        onClose={() => setOpenNameModal(false)}
      />
    </Box>
  );
}
