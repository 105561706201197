import React from 'react'
import { Box, Stack, Button, Typography, Badge, Card } from '@mui/material'
import dayjs from 'dayjs'

import Image from '../../components/Image'
import Icon from '../../components/Iconify'
import { fDateThai } from '../../utils/formatTime' 
import { AnnouncementType } from '../../@types/notification'

interface AnnounceDataProps {
  announceData: AnnouncementType
  clickAnnounce: Function
}

export default function AnnounceItem({
  announceData,
  clickAnnounce
}: AnnounceDataProps) {
  return (
    <Button 
      onClick={() => clickAnnounce(announceData.id)}
      color='tertiary' 
      sx={{
        padding: 0, 
        width: '100%', 
        position: 'relative'
      }}
    >
      {
        !announceData.readAt &&
          <Badge 
            color="primary" 
            badgeContent=" " 
            variant="dot" 
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px'
            }}
          />
      }
      <Card 
        sx={{
          borderLeft: `5px solid`,
          borderColor: announceData.announceIconColor || 'primary.main', 
          display: 'flex', 
          padding: '10px', 
          alignItems: 'center', 
          gap: '10px',
          backgroundColor: announceData.readAt ? 'background.default': 'background.paper',
          color: announceData.readAt ? 'text.disabled' : 'text.primary',
          width: '100%',
          textAlign: 'left',
          wordBreak: 'break-word',
        }}
      >
        {(announceData.announceIcon && announceData.announceIcon !== '') ? (
          <Stack
            alignItems="center"
            sx={{
              width: '48px',
              height: '40px',
              padding: '5px',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: announceData.announceIconColor
            }}
          >
            <Icon 
              icon={announceData.announceIcon}
              sx={{ 
                color: announceData.announceIconColor,
                fontSize: '25px'
              }}
            />
          </Stack>
        ) : (
          <Image 
            src="/logos/borntodev-80x80.png" 
            alt="announce-logo" 
            sx={{width: '40px', height: '40px', minWidth: '40px', borderRadius: '8px'}}
          />
        )}
        <Box width={'100%'}>
          <Typography variant='subtitle2' sx={{...ellipsisTwo}}>
            {announceData.title}
          </Typography>
          <Box sx={{display:'flex', justifyContent:'space-between'}}>
            <Typography variant='caption' color='text.disabled'>{fDateThai(dayjs(announceData.announcedAt).toDate())}</Typography>
            <Typography variant='caption' color='text.disabled'>{`${dayjs(announceData.announcedAt).format('HH:mm:ss')} น.`}</Typography>
          </Box>
        </Box>
      </Card>
    </Button>
  )
}

const ellipsisTwo = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
};
