import React from 'react'
import Codemirror, { BasicSetupOptions } from '@uiw/react-codemirror'

// -> config code editor
// -> ref: https://uiwjs.github.io/react-codemirror/
// import { atomone } from '@uiw/codemirror-theme-atomone'
import { dracula } from '@uiw/codemirror-theme-dracula'
import { materialLight } from '@uiw/codemirror-theme-material'
import { langs } from '@uiw/codemirror-extensions-langs'
import { languageConfig } from './language.config'

interface CodeEditorProps {
  theme?: string
  code?: string | null
  language?: string
  onChangeCode?: Function
}

function CodeEditor({ theme = 'dark', code, onChangeCode, language = 'python' }: CodeEditorProps) {
  const options: BasicSetupOptions = {
    lineNumbers: true,
    autocompletion: true,
    syntaxHighlighting: true,
  }

  const handleChange = (value: string) => {
    if (onChangeCode) onChangeCode(value)
  }

  return (
    <Codemirror
      value={code ?? languageConfig[language !== '' ? language : 'python'].code}
      minHeight="200px"
      maxHeight="600px"
      basicSetup={options}
      theme={theme === 'dark' ? dracula : materialLight}
      extensions={[languageConfig[language !== '' ? language : 'python'].language, langs.javascript()]}
      onChange={handleChange}
    />
  )
}

export default CodeEditor
