import { createSlice } from '@reduxjs/toolkit';
import { BornBitsNotificationType } from '../../@types/bornbit';

type InitialSocketStateType = {
  connected: boolean;
  bornBitNotification: BornBitsNotificationType | null;
};

const initialSocketState: InitialSocketStateType = {
  connected: false,
  bornBitNotification: null,
};

const slice = createSlice({
  name: 'bornbit',
  initialState: initialSocketState,
  reducers: {
    setSocketConnected(state, action) {
      state.connected = action.payload;
    },
    setBornBitsNotification(state, action) {
      state.bornBitNotification = action.payload;
    },
  },
});

export default slice.reducer;

export const { setSocketConnected, setBornBitsNotification } = slice.actions;
