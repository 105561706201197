import { Avatar, Box, Button, Card, Chip, Grid, Icon, LinearProgress, Stack, Typography, alpha, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import axiosInstance from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import Iconify from "../Iconify";
import { fTSeparator } from "../../utils/formatNumber";
import { Link } from "react-router-dom";
import { BB_OPEN } from "../../config";

export default function CheckinStat({closeAll}: {closeAll: Function}) {
  const theme = useTheme()
  const { user } = useAuth()
  async function getBornbitStat() {
    const res = await axiosInstance.get('/bornbits/checkin/statistics')
  }

  useEffect(() => {
    getBornbitStat()
  }, [])

  if(!user || !BB_OPEN) return <></>

  return (
    <Box 
      sx={{
        padding: '20px', 
        marginX: '20px', 
        background: `linear-gradient(140deg, ${theme.palette.background.neutral} 0%,${theme.palette.background.paper} 100%)`,
        borderRadius: '8px',
        position: 'relative'
      }}
    >
      <Button 
        component={Link}
        to='/profile?target=achievement'
        onClick={() => closeAll()}
        sx={{
          position: 'absolute', 
          right: 20, 
          width: 'fit-content', 
          minWidth: 0,
          '&.MuiButtonBase-root': {padding: '2px'}
        }}
      >
        <Iconify icon='majesticons:award' />
        <Typography variant='caption'>
          (20)
        </Typography>
      </Button>
      <Box sx={{display: 'flex', gap: '10px'}}>
        <Avatar sx={{width: '50px', height: '50px'}} src={user.imageUrl} alt='user-image'/>
        <Box sx={{marginTop: '8px'}}>
          <Typography 
            variant='h4' 
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {user.publicName}
          </Typography>
          <Typography variant='caption' sx={{color: 'text.secondary', marginTop: '-10px'}}>
            ({user.level})
          </Typography>
          <Stack direction={'row'}>
            <Typography variant='body1' sx={{color: 'primary.main', marginTop: '2px'}}>
              <Iconify icon='mdi:alpha-b-box-outline'/>
            </Typography>
            <Typography variant='body1' sx={{color: 'primary.main'}}>
              {fTSeparator(1437)}
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box 
        sx={{
          minHeight: '40px',
          marginY: '10px',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word'
        }}
      >
        Bio 
      </Box>
      <Box>
        <LinearProgress variant="determinate" value={89} sx={{borderRadius: '8px'}}/>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Typography variant='subtitle2'>
            {user.level}
          </Typography>
          <Typography variant='caption' sx={{color: 'text.secondary'}}>
            ({fTSeparator(1437)}/{fTSeparator(4000)})
          </Typography>
        </Stack>
      </Box>
    </Box>
  )
}