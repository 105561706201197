import React from 'react'
import { Card, Button, Badge, Box, Typography } from '@mui/material'
import Image from '../Image'
import ReactMarkdown from 'react-markdown'
import { NotificationType } from '../../@types/notification'
import dayjs from 'dayjs'
import { fDateThai } from '../../utils/formatTime'
import './noti-item.css'

export default function NotiItem({notiData, clickNoti}: {notiData: NotificationType, clickNoti: Function}) {
  return (
    <Button 
      onClick={() => clickNoti(notiData.id, notiData.link)}
      color='tertiary' 
      sx={{padding: 0, width: '100%', position: 'relative'}}
    >
      {
        !notiData.readAt &&
          <Badge 
            color="primary" 
            badgeContent=" " 
            variant="dot" 
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px'
            }}
          />
      }
      <Card 
        sx={{
          borderLeft: `5px solid ${notiData.color}`, 
          display: 'flex', 
          padding: '10px', 
          alignItems: 'center', 
          gap: '10px',
          backgroundColor: notiData.readAt ? 'background.default': 'background.paper',
          color: notiData.readAt ? 'text.disabled': 'text.primary',
          width: '100%',
          textAlign: 'left'
        }}
      >
        <Image src={notiData.thumbnail} sx={{width: '40px', height: '40px', minWidth: '40px', borderRadius: '8px'}} />
        <Box width={'100%'}>
          <ReactMarkdown className='react-markdown-pd-0'>{notiData.message}</ReactMarkdown>
          <Box sx={{display:'flex', justifyContent:'space-between'}}>
            <Typography variant='caption' color='text.disabled'>{fDateThai(dayjs(notiData.createdAt).toDate())}</Typography>
            <Typography variant='caption' color='text.disabled'>{`${dayjs(notiData.createdAt).format('HH:mm:ss')} น.`}</Typography>
          </Box>
        </Box>
      </Card>
    </Button>
  )
}