import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Waypoint } from 'react-waypoint';

import {
  BundleCatalogType,
  courseCatalogCardType,
} from '../../@types/courseCatalog';
import SearchHeader from '../../components/main/SearchHeader';
import axios from '../../utils/axios';

import CatalogSearchFilter, { initialFilters } from './CatalogSearchFilter';
import ProductSearchResult from './ProductSearchResult';
import { ProductConfigType, productConfigList } from './productConfig';

export default function SearchSection({
  searchMode,
  setSearchMode,
  take = 16,
}: {
  searchMode: boolean;
  setSearchMode: (mode: boolean) => void;
  take?: number;
}) {
  const location = useLocation();

  const [searchLoading, setSearchLoading] = useState<boolean>(searchMode);

  const [page, setPage] = useState<number>(0);
  const [searchCount, setSearchCount] = useState<number>(0);
  const [lastPage, setLastPage] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState('');
  const [resetSearch, setResetSearch] = useState<boolean>(false);

  const [coursesSearch, setCoursesSearch] = useState<courseCatalogCardType[]>(
    [] as courseCatalogCardType[],
  );
  const [bundlesSearch, setBundlesSearch] = useState<BundleCatalogType[]>(
    [] as BundleCatalogType[],
  );

  const getSearchCourse = async (
    curPage: number,
    params?: string,
  ): Promise<void> => {
    try {
      setSearchLoading(true);
      if (getCurrentProduct() === 'bundle') {
        const response = await axios.get(
          `/bundles?${params || searchParams}&page=${curPage + 1}&take=${take}`,
        );
        const data = await response.data;
        setBundlesSearch(
          curPage === 0 ? data.data : [...bundlesSearch, ...data.data],
        );
        setLastPage(!data.meta.hasNextPage);
        setSearchCount(data.meta.itemCount);
      } else {
        const response = await axios.get(
          `/courses?${params || searchParams}&page=${curPage + 1}&take=${take}`,
        );
        const data = await response.data;
        setCoursesSearch(
          curPage === 0 ? data.data : [...coursesSearch, ...data.data],
        );
        setLastPage(!data.meta.hasNextPage);
        setSearchCount(data.meta.itemCount);
      }

      setPage(curPage + 1);
      setSearchLoading(false);
      setResetSearch(false);
    } catch (_) {
      return;
    }
  };

  function applySearchFilter(params?: string) {
    if (params) {
      setCoursesSearch([]);
      setBundlesSearch([]);
      setSearchParams(params);
      getSearchCourse(0, params);
      setSearchMode(true);
    } else if (typeof params === 'undefined') {
      setSearchMode(false);
    }
  }

  function getNextPage() {
    !searchLoading && !lastPage && getSearchCourse(page);
  }

  function getCurrentProduct() {
    return productConfigList.find((config) => config.path === location.pathname)
      ?.name as keyof ProductConfigType;
  }

  const handleClearSearch = () => {
    setResetSearch(true);
    window.history.replaceState(null, '', location.pathname);
    const param = new URLSearchParams(initialFilters);
    applySearchFilter(param.toString());
  };

  return (
    <>
      <CatalogSearchFilter
        loading={searchLoading}
        resetParams={resetSearch}
        type={getCurrentProduct()}
        applySearchFilter={applySearchFilter}
        defaultOpenController={searchMode}
      />
      {searchMode ? (
        <>
          <SearchHeader
            loading={searchLoading}
            search={searchParams}
            searchCount={searchCount}
            onClearSearch={handleClearSearch}
          />
          {searchLoading ||
          coursesSearch.length > 0 ||
          bundlesSearch.length > 0 ? (
            <>
              <ProductSearchResult
                type={getCurrentProduct()}
                courses={coursesSearch}
                bundles={bundlesSearch}
                loading={searchLoading}
              />
              <Waypoint onEnter={() => getNextPage()} />
            </>
          ) : (
            <Box sx={{ paddingY: '100px' }}>
              <Typography sx={{ textAlign: 'center' }}>
                {'- ไม่พบผลลัพธ์ที่คุณค้นหา -'}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
