import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';

import Image from '../../components/Image';
import EditorBodyStyle from '../../components/editor/EditorBody';
import { AnnouncementType } from '../../@types/notification';
import { fDateThai } from '../../utils/formatTime';
import axiosInstance from '../../utils/axios';
import RichViewer from '../../components/editor/RichViewer';
import LectureSkeleton from '../../components/skeleton/Lecture';

export default function NotificationPage() {
  const { noticeId } = useParams();
  const [loading, setLoading] = useState(true);
  const [announceData, setAnnounceData] = useState<AnnouncementType>();

  const getNotification = async (id: string) => {
    try {
      const response = await axiosInstance.get(
        `/notifications/announcements/${id}`,
      );
      const data = await response.data;
      setAnnounceData(data);
      setLoading(false);
    } catch {
      setLoading(false);
      window.location.replace('/error?code=announce');
    }
  };

  useEffect(() => {
    if (noticeId) getNotification(noticeId);
    else window.location.replace('/error?code=announce');
  }, []);

  return (
    <Container
      sx={{
        marginTop: '30px',
        maxWidth: '1062.38px',
        '@media (min-width: 2000px)': {
          maxWidth: '1280px',
        },
      }}
    >
      {loading ? (
        <LectureSkeleton />
      ) : (
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              paddingBottom: '37.65%',
            }}
          >
            <Image
              src={announceData?.cover}
              alt=""
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            />
          </Box>

          <Box
            sx={{
              paddingTop: '20px',
              paddingBottom: '40px',
              paddingX: '50px',
            }}
          >
            <Typography variant="h3" sx={{ wordBreak: 'break-word' }}>
              {announceData?.title}
            </Typography>
            <Typography sx={{ color: 'text.disabled' }}>
              {`${fDateThai(announceData?.announcedAt as string)} - ${dayjs(
                announceData?.announcedAt,
              ).format('HH:mm น.')}`}
            </Typography>

            <Box sx={{ marginTop: '20px' }}>
              <EditorBodyStyle read showText>
                <RichViewer
                  content={
                    announceData?.description
                      ? JSON.parse(announceData.description)
                      : ''
                  }
                />
              </EditorBodyStyle>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
}
