import React from 'react';
import { Box, Container, Grid, Typography } from "@mui/material";
import PlanTitle from './PlanTitle';
import Lottie from "lottie-react";
import PrivilegesLottie from "./PrivilegesLottie.json";

export default function SpecialPrivileges() {
  return (
    <>
      <Box sx={{backgroundColor: 'background.paper', padding: '20px 0px 100px 0px'}}>
        <Container>
            <Grid container alignItems={'center'}>
                <Grid md={4.4} xs={12} item>
                  <Typography sx={{fontSize: '48px'}}>
                    สิทธิพิเศษเฉพาะคุณที่มีให้สำหรับสมาชิก
                  </Typography>
                  <Typography variant='h2'>
                    <PlanTitle/>
                  </Typography>
                  <Typography sx={{fontSize: '28px'}}>
                    เรียน อัพสกิล ทดสอบความรู้ ได้แบบไร้กังวล เรื่องค่าใช้จ่าย
                  </Typography>
                  <Typography sx={{fontSize: '28px'}}>
                    เพราะเราได้รวมทุกสิ่ง ที่คุณต้องรู้ไว้ให้คุณแล้ว
                  </Typography>
                </Grid>
                <Grid md={1.6} xs={0} item>
                </Grid>
                <Grid md={6} xs={12} item>
                  <Lottie animationData={PrivilegesLottie} loop={true} style={{width: '80%', margin: '0 auto'}}/>
                </Grid>
            </Grid>
            <Box sx={{'@media (max-width: 899px)': {justifyContent: 'center', display: 'flex'}, marginTop: '10px'}}>
              <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    '@media (max-width: 899px)': {
                      flexDirection: 'column',
                      gap: '40px',
                      justifyContent: 'center',
                    },
                }}
                >
                {
                    privilegesDatas.map((each, index) => 
                      <Privilege key={`Privilege-${index}`} privilegeData={each} />
                    )
                }
              </Box>
            </Box>
        </Container>
      </Box>
      <Box sx={{padding: '20px 0px 100px 0px', textAlign: 'center', paddingY: '80px'}}>
        <Typography sx={{fontSize: '30px' }}>
          สิทธิพิเศษเฉพาะสมาชิก <PlanTitle sx={{fontWeight: 'bold'}}/>
        </Typography>
        <Typography sx={{fontSize: '20px' }}>
          เรียนแบบไลฟ์สดผ่านออนไลน์ หรือ
        </Typography>
        <Typography sx={{fontSize: '20px' }}>
          ในหลักสูตรพรีเมียมยอดฮิต ได้ทุกเดือน
        </Typography>
      </Box>
    </>
  )
}

interface PrivilegeProp {
  title1: string
  title2: string
  sub1: string
  sub2: string
}

function Privilege({privilegeData}: {privilegeData: PrivilegeProp}) {
  return (
    <Box>
      <Typography variant='h3' sx={{color: 'primary.main'}}>
        {privilegeData.title1}
      </Typography>
      <Typography variant='h3'>
        {privilegeData.title2}
      </Typography>
      <Typography >
        {privilegeData.sub1}
      </Typography>
      <Typography >
        {privilegeData.sub2}
      </Typography>
    </Box>
  )
}

const privilegesDatas = [
  {
    title1: 'Verified',
    title2: 'Certificate',
    sub1: 'เรียนจบ ทำแบบทดสอบผ่าน',
    sub2: 'รับ Certificate ไปเลยทันที'
  },
  {
    title1: 'Technical',
    title2: 'Support',
    sub1: 'รับการสนับสนุนจากทีมงานเทคนิค',
    sub2: 'ช่วยเหลือทุกปัญหาจากการเรียน'
  },
  {
    title1: 'NEW COURSE',
    title2: 'EVERY MONTH',
    sub1: 'หลักสูตรสอนสด และ ออนไลน์ที่อัพเดต',
    sub2: 'สดใหม่ทุกเดือน ให้คุณได้อัพสกิลแบบเต็ม ๆ'
  }
]