import React, { useEffect, useState } from "react";
import { useDispatch, RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { setOnSeek } from '../../../../redux/slices/lesson'
import { ByteArkPlayerContainer } from 'byteark-player-react'
import { Box } from '@mui/material';

interface BytearkPlayerType {
  url: string
  onProgress: Function
  onEnded: Function
  timestamp: number
  at?: string | (string | null)[]
}

export default function BytearkPlayer({url, onProgress, onEnded, timestamp, at}:BytearkPlayerType) {
  const [playerInstant, setplayerInstant] = useState<any>()
  const dispatch = useDispatch()
  const seekTimeStamp = useSelector((state: RootState)=> state.lesson.seekTimeStamp);
  const onSeek = useSelector((state: RootState)=> state.lesson.onSeek);
  const playerOptions = {
    autoplay: true,
    fluid: true,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: {
      src: url,
      videoId: url.split('/').at(-2),
      type: 'application/x-mpegURL',
    },
    plugins: {
      bytearkLighthouse: {
        projectId: window.__RUNTIME_CONFIG__.REACT_APP_BYTEARK_LIGHTHOUSE_PROJECT_ID
      }
    }
  }


  const onReady = (player: any) => {
    setplayerInstant(player)
    player.currentTime(at || timestamp)
    player.on('ended', onEnded)
    player.on('timeupdate', () => {
      if (player.currentTime() % 1 <= 0.25) {
        onProgress(player.currentTime())
      }
    })
  }

  useEffect(() => {
    if(playerInstant){
      playerInstant.currentTime(seekTimeStamp)
      dispatch(setOnSeek(false))
    }
  }, [onSeek])


  return (
    <Box sx={{}}>
      <ByteArkPlayerContainer
        {...playerOptions}
        onReady={onReady}
      />
    </Box>
  )
}

