import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { NAVBAR } from "../config";
import { Box, Button, Typography, Container, IconButton, Drawer, Divider, Switch } from "@mui/material";
import { styled } from '@mui/material/styles';
import Iconify from "./Iconify";
import useAuth from "../hooks/useAuth";
import axiosInstance from "../utils/axios";
import Image from "./Image";

import { useDispatch, RootState, useSelector } from '../redux/store';
import { setConsentOpen } from '../redux/slices/config';

export default function Consent() {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const [openConsent, setOpenConsent] = useState(false)
  useEffect(() => {
    if (window.localStorage.getItem('ConsentMarketingCookie') === null){
      setOpenConsent(true)
    } else {
      initConsentRequired()
    }
  }, [])

  function setOpenSetting(open: boolean) {
    dispatch(setConsentOpen(open))
  }

  function initConsentRequired() {
    if(getMarketingConsentValue()) {
      initGA()
    }
  }

  function onOpenSetting() {
    setOpenSetting(true)
    setOpenConsent(false)
  }

  function handleConsentAccepted() {
    initGA()
    setMarketingConsentValue('true')
    user && setEmailUaPConsentValue(true)
    setOpenConsent(false)
  }

  function handleConsentDeclined() {
    declineGA()
    setMarketingConsentValue('false')
    user && setEmailUaPConsentValue(false)
    setOpenConsent(false)
  }
  return (
    <>
    {
      openConsent &&
      <FooterStickyStyle>
        <Container
          sx={{
            display: 'flex',
            paddingY: '10px',
            alignItems: 'center',
            gap: '20px',
            justifyContent: 'space-between',
            '@media (max-width: 1200px)': {
              flexDirection: 'column',
            }
          }}
        >
          <Typography variant='caption' sx={{maxWidth: '700px', color: 'text.primary'}}>
            เราใช้คุกกี้เพื่อพัฒนาประสิทธิภาพ และประสบการณ์ที่ดีในการใช้เว็บไซต์ของคุณ คุณสามารถศึกษารายละเอียดได้ที่
            <a
              href={'https://www.borntodev.com/%e0%b8%99%e0%b9%82%e0%b8%a2%e0%b8%9a%e0%b8%b2%e0%b8%a2%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b9%80%e0%b8%9b%e0%b9%87%e0%b8%99%e0%b8%aa%e0%b9%88%e0%b8%a7%e0%b8%99%e0%b8%95%e0%b8%b1%e0%b8%a7/'}
              target='_blank'
              rel="noopener noreferrer"
              style={{textDecoration: 'none'}}
            >
              <Typography variant='caption' sx={{marginX: '5px', color: 'text.primary', ':hover': {color: 'primary.main'}}}>
              นโยบายความเป็นส่วนตัว
              </Typography>
            </a>
            และมีการสมัครรับอีเมลอัปเดตข่าวสารโดยสามารถจัดการความเป็นส่วนตัวเองของคุณได้เองโดยคลิกที่
            <Typography
              variant='caption'
              sx={{marginX: '5px', color: 'text.primary', cursor: 'pointer',':hover': {color: 'primary.main'}}}
              onClick={onOpenSetting}
            >
              ตั้งค่า
            </Typography>
          </Typography>
          <Box sx={{display: 'flex', gap: '10px', minWidth: '300px', justifyContent: 'end'}}>
            <Button variant='outlined' onClick={onOpenSetting}>ตั้งค่า</Button>
            <Button variant='outlined' onClick={handleConsentDeclined}>ไม่ยอมรับ</Button>
            <Button variant='contained' onClick={handleConsentAccepted} sx={{color: 'text.button'}}>ยอมรับทั้งหมด</Button>
            <IconButton aria-label="delete" onClick={() => setOpenConsent(false)}>
              <Iconify icon='ic:round-close'/>
            </IconButton>
          </Box>
        </Container>
      </FooterStickyStyle>
    }
    <ConsentSetting setOpen={setOpenSetting}/>
    </>
  )
}

function ConsentSetting({setOpen}: {setOpen: Function}) {
  const { user } = useAuth()
  const [UaPConsent, setUaPConsent] = useState(getEmailUaPConsentValue())
  const [MConsent, setMConsent] = useState(getMarketingConsentValue())
  const consentOpen = useSelector((state: RootState) => state.config.consentOpen)

  function getEmailUaPConsentValue() {
    return user?.consent.updateAndPromotion
  }

  function saveConsent() {
    setMarketingConsentValue(`${MConsent}`)
    setEmailUaPConsentValue(UaPConsent)
    setOpen(false)
  }

  function acceptAll() {
    setMarketingConsentValue(`true`)
    setEmailUaPConsentValue(true)
    setUaPConsent(true)
    setMConsent(true)
    setOpen(false)
  }

  useEffect(() => {
    setUaPConsent(getEmailUaPConsentValue())
  }, [user])

  return(
    <SideNavStyle
      anchor="left"
      open={consentOpen}
      onClose={() => setOpen(false)}
    >
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Image alt='logo' src="/logos/school-full-225x72.png" sx={{width: '130px'}}/>
        <IconButton aria-label="delete" onClick={() => setOpen(false)}>
          <Iconify icon='ic:round-close'/>
        </IconButton>
      </Box>
      <Divider sx={{marginY: '10px'}}/>
      <Typography variant='h5'>ตั้งค่าความเป็นส่วนตัว</Typography>
      <Typography variant='caption'>คุณสามารถเลือกการตั้งค่าคุกกี้โดยเปิด/ปิด คุกกี้ในแต่ละประเภทได้ตามความต้องการ ยกเว้น คุกกี้ที่จำเป็น</Typography>
      <Button variant='outlined' sx={{marginTop: '10px'}} onClick={() => acceptAll()}>ยอมรับทั้งหมด</Button>
      <Divider sx={{marginY: '10px'}}/>
      <Typography variant='subtitle2' sx={{marginTop: '10px'}}>คุกกี้ที่จำเป็น</Typography>
      <Switch defaultChecked disabled/>
      <Typography variant='caption'>
        ประเภทของคุกกี้มีความจำเป็นสำหรับการทำงานของเว็บไซต์ เพื่อให้คุณสามารถใช้ได้อย่างเป็นปกติ และเข้าชมเว็บไซต์ คุณไม่สามารถปิดการทำงานของคุกกี้นี้ในระบบเว็บไซต์ของเราได้
      </Typography>

      {/* <Typography variant='caption' sx={{marginTop: '5px'}}>รายละเอียดคุกกี้</Typography> */}

      <Typography variant='subtitle2' sx={{marginTop: '10px'}}>คุกกี้สำหรับการติดตามทางการตลาด</Typography>
      <Switch checked={MConsent} onChange={() => setMConsent(!MConsent)}/>

      <Typography variant='caption'>
        ประเภทของคุกกี้ที่มีความจำเป็นในการใช้งานเพื่อการวิเคราะห์ และ นำเสนอโปรโมชัน สินค้า รวมถึงหลักสูตรฟรี และ สิทธิพิเศษต่าง ๆ คุณสามารถเลือกปิดคุกกี้ประเภทนี้ได้โดยไม่ส่งผลต่อการทำงานหลัก เว้นแต่การนำเสนอโปรโมชันที่อาจไม่ตรงกับความต้องการ
      </Typography>

      {/* <Typography variant='caption' sx={{marginTop: '5px'}}>รายละเอียดคุกกี้</Typography> */}
      {
        user &&
        <>
          <Typography variant='subtitle2' sx={{marginTop: '10px'}}>สมัครรับอีเมลอัปเดตข่าวสาร</Typography>
          <Switch checked={UaPConsent} onChange={() => setUaPConsent(!UaPConsent)}/>

          <Typography variant='caption'>
            การสมัครรับอีเมลเพื่ออัปเดตข้อมูลข่าวสารรวมถึงโปรโมชั่นต่าง ๆ ภายใน borntoDev School และการเปลี่ยนแปลงเนื้อหาบทเรียนในหลักสูตรที่คุณเคยลงทะเบียนไว้
          </Typography>
        </>
      }


      <Button sx={{marginTop: '20px'}} variant='outlined' onClick={() => saveConsent()}>บันทึกการตั้งค่า</Button>
    </SideNavStyle>
  )
}

function initGA() {
  const gaKey = window.__RUNTIME_CONFIG__.REACT_APP_GA_KEY
  gaKey && ReactGA.initialize(gaKey);
}

function declineGA() {
  document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "_gat=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getMarketingConsentValue() {
  return window.localStorage.getItem('ConsentMarketingCookie') === 'true'
}

function setMarketingConsentValue(value: string) {
  window.localStorage.setItem('ConsentMarketingCookie', value)
}

function setEmailUaPConsentValue(value: boolean) {
  axiosInstance.put('/users/consents', {"updateAndPromotion": value})
}

const SideNavStyle = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper' : {
      maxWidth: NAVBAR.DASHBOARD_WIDTH,
      backgroundColor: theme.palette.background.default,
      backgroundImage: 'none',
      width: '100%',
      borderLeft: '0px',
      padding: '40px'
    },
    '*::-webkit-scrollbar': {
        width: '0.5em'
      },
      '*::-webkit-scrollbar-track': {
        WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'slategrey',
        borderRadius: '8px'
      }
  }));

const FooterStickyStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: '0',
  bottom: '0',
  backgroundColor: theme.palette.background.paper,
  width: '100vw',
  minHeight: '80px',
  zIndex: 1201,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'bottom 0.5s',
  boxShadow: '0px -6px 20px -5px rgba(0,0,0,0.34)'
}));
