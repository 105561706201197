import React, { useState } from 'react';
import { Container } from '@mui/material';
import HelmetMetaData from '../../components/Helmet';
import SearchSection from './SearchSection';

export default function SearchPage() {
  const [searchMode, setSearchMode] = useState<boolean>(true);

  return (
    <>
      <HelmetMetaData />
      <Container
        sx={{
          maxWidth: '1220px',
          padding: '0px !important',
          marginBottom: '20px',
        }}
      >
        <SearchSection searchMode={searchMode} setSearchMode={setSearchMode} />
      </Container>
    </>
  );
}
