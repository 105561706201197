import React from 'react';
import { createRef, useRef, useState, useEffect } from 'react';
import { Box, Typography, Container, Button, Skeleton } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import { CarouselDots } from '../../components/carousel';
import { NavLink as RouterLink } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import axiosInstance from '../../utils/axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

enum BannerType {
  BACKGROUND = 'BACKGROUND',
  COURSE = 'COURSE',
  CUSTOM = 'CUSTOM',
}

enum ButtonType {
  PRIMARY = 'PRIMARY',
  LIGHT = 'LIGHT',
  TEXT = 'TEXT',
}

type BannerDataType = {
  id: string;
  displayOrder: number;
  type: BannerType;
  detail: any;
};

type ButtonDataType = {
  buttonText: string;
  buttonUrl: string;
  buttonIconify: string;
  buttonType: ButtonType;
};

const widthCover = 1536;
const heightCover = 640;
const widthVideoRatio = 16;
const heightVideoRatio = 9;

export default function Cover() {
  const theme = useTheme();
  const [bannerData, setBannerData] = useState<BannerDataType[]>([]);
  const carouselRef = useRef<Slider>(null);
  const [loading, setLoading] = useState(true);
  const [play, setPlay] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(
    theme.direction === 'rtl' ? bannerData.length - 1 : 0,
  );

  const getBanner = async () => {
    try {
      const response = await axiosInstance('/banner');
      const data = await response.data;
      setBannerData(data);
      setLoading(false);
    } catch {
      return;
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  const settings = {
    speed: 800,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    useTransform: false,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
    ...CarouselDots({
      zIndex: 8,
      bottom: 24,
      margin: 'auto',
      position: 'absolute',
    }),
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateCoverHeight = () =>
    (windowWidth * heightCover * (windowWidth > 1200 ? 0.7 : 1)) / widthCover;

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {loading ? (
        <Skeleton
          variant="rounded"
          height={calculateCoverHeight()}
          width={'100%'}
        />
      ) : (
        <>
          <Slider ref={carouselRef} {...settings}>
            {bannerData.map((banner, index) => (
              <CoverItem
                key={`banner-${index}`}
                bannerData={banner}
                index={index}
                cur={currentIndex}
                windowWidth={windowWidth}
                setLoading={setLoading}
                play={play}
              />
            ))}
          </Slider>
          <Waypoint
            onEnter={() => setPlay(true)}
            onLeave={() => setPlay(false)}
          />
        </>
      )}
    </Box>
  );
}

function CoverItem({
  bannerData,
  index,
  cur,
  setLoading,
  play,
  windowWidth,
}: {
  bannerData: BannerDataType;
  index: number;
  cur: number;
  setLoading: Function;
  play: boolean;
  windowWidth: number;
}) {
  const ref = createRef<HTMLInputElement>();
  const theme = useTheme();

  const [pictureModa, setPictureMode] = useState<boolean>(false);
  const calculateCoverHeight = () =>
    (windowWidth * heightCover * (windowWidth > 1200 ? 0.7 : 1)) / widthCover;

  const videoHeight =
    calculateCoverHeight() >= (windowWidth * heightVideoRatio) / widthVideoRatio
      ? calculateCoverHeight()
      : (windowWidth * heightVideoRatio) / widthVideoRatio;
  const videoMarginToCenter =
    calculateCoverHeight() < videoHeight
      ? (calculateCoverHeight() - videoHeight) / 2
      : 0;

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        paddingBottom: `${calculateCoverHeight()}px`,
      }}
    >
      <Box
        sx={{
          background:
            bannerData.type === BannerType.BACKGROUND
              ? 'linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.1) 18%, rgba(0,0,0,0) 100%)'
              : alpha(theme.palette.background.default, 0.55),
          zIndex: '5',
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container sx={{ maxWidth: '1270px' }}>
          <Box sx={{ maxWidth: '725px' }}>
            {/* title */}
            <Typography
              sx={{
                fontSize:
                  bannerData.detail.titleFontSize ??
                  (bannerData.detail?.title?.length > 20 ? '64px' : '96px'),
                lineHeight: bannerData.detail.titleFontSize
                  ? bannerData.detail?.title?.length > 20
                    ? '64px'
                    : '60px'
                  : bannerData.detail?.title?.length > 20
                    ? '64px'
                    : '96px',
                '@media (max-width: 1200px)': {
                  fontSize: '48px',
                  lineHeight: '48px',
                },
                '@media (max-width: 600px)': {
                  fontSize: '20px',
                  lineHeight: '20px',
                },
              }}
            >
              {bannerData.detail?.title ?? bannerData.detail?.course?.title}
            </Typography>
            {/* subtitle */}
            <Typography
              sx={{
                fontSize: bannerData.detail.subtitleFontSize ?? 24,
                '@media (max-width: 1200px)': {
                  fontSize: '24px',
                  lineHeight: '24px',
                },
                '@media (max-width: 600px)': {
                  display: 'none',
                },
              }}
            >
              {bannerData.detail?.subtitle ??
                bannerData.detail?.course?.shortDescription}
            </Typography>
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px',
                '@media (max-width: 600px)': {
                  marginTop: '5px',
                },
              }}
            >
              {bannerData.type === BannerType.COURSE && (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    minHeight: '60px',
                    minWidth: '230px',
                    color: 'text.button',
                    '@media (max-width: 1200px)': {
                      minHeight: '0px',
                      minWidth: '0px',
                    },
                  }}
                  component={RouterLink}
                  to={`/course/${bannerData.detail?.course?.slug}`}
                >
                  <Iconify
                    icon={'dashicons:book'}
                    width="30px"
                    height="30px"
                    sx={{
                      '@media (max-width: 1200px)': {
                        width: '20px',
                        height: '20px',
                      },
                      '@media (max-width: 600px)': {
                        width: '15px',
                        height: '15px',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      marginLeft: '5px',
                      textDecoration: 'none',
                      fontSize: '20px',
                      '@media (max-width: 1200px)': {
                        fontSize: '15px',
                      },
                      '@media (max-width: 600px)': {
                        fontSize: '10px',
                      },
                    }}
                  >
                    ดูรายละเอียดคอร์ส
                  </Typography>
                </Button>
              )}

              {bannerData.type === BannerType.CUSTOM &&
                bannerData.detail &&
                bannerData.detail?.bannerCustomButton?.map(
                  (button: ButtonDataType, index: number) => (
                    <Button
                      key={`cover-button-${button.buttonText}-${index + 1}`}
                      href={button.buttonUrl}
                      sx={{
                        minHeight: '50px',
                        minWidth: '200px',
                        color:
                          button.buttonType === ButtonType.PRIMARY
                            ? 'text.button'
                            : 'text.primary',
                        '@media (max-width: 1200px)': {
                          minHeight: '0px',
                          minWidth: '0px',
                        },
                        '@media (max-width: 600px)': {
                          display: index > 0 ? 'none' : 'flex',
                        },
                      }}
                      variant={
                        button.buttonType === ButtonType.PRIMARY
                          ? 'contained'
                          : button.buttonType === ButtonType.LIGHT
                            ? 'outlined'
                            : 'text'
                      }
                      color={
                        button.buttonType === ButtonType.PRIMARY
                          ? 'primary'
                          : 'inherit'
                      }
                      startIcon={
                        <Iconify
                          icon={button.buttonIconify}
                          sx={{ width: '25px', height: '25px' }}
                        />
                      }
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          marginLeft: '5px',
                          textDecoration: 'none',
                          '@media (max-width: 1200px)': {
                            fontSize: '16px',
                          },
                          '@media (max-width: 600px)': {
                            fontSize: '10px',
                          },
                        }}
                      >
                        {button.buttonText}
                      </Typography>
                    </Button>
                  ),
                )}
            </Box>
          </Box>
        </Container>
      </Box>
      {(bannerData.type !== BannerType.COURSE &&
        bannerData.detail?.mediaType === 'IMAGE') ||
      (pictureModa && bannerData.type === BannerType.COURSE) ||
      bannerData.detail?.course?.previewVideo === null ? (
        <Image
          src={
            bannerData.detail?.mediaURL ??
            bannerData.detail?.course?.images.cover
          }
          disabledEffect
          visibleByDefault
          alt="cover"
          sx={{ width: '100%', height: '100%', position: 'absolute' }}
        />
      ) : (
        <ReactPlayer
          url={
            bannerData.detail?.mediaURL ??
            bannerData.detail?.course?.previewVideo
          }
          controls={false}
          width={
            calculateCoverHeight() >=
            (windowWidth * heightVideoRatio) / widthVideoRatio
              ? (calculateCoverHeight() * widthVideoRatio) / heightVideoRatio
              : windowWidth
          }
          height={
            calculateCoverHeight() >=
            (windowWidth * heightVideoRatio) / widthVideoRatio
              ? calculateCoverHeight()
              : (windowWidth * heightVideoRatio) / widthVideoRatio
          }
          style={{
            position: 'absolute',
            top: `${videoMarginToCenter}px`,
          }}
          // Old style

          // width={coverHeight >= width * 9 / 16 ? coverHeight * 16 / 9 : width}
          // height={coverHeight >= width * 9 / 16 ? coverHeight : width * 9 / 16}
          // style={{
          //   position: 'absolute',
          //   top: '0',
          //   marginLeft: coverHeight >= width * 9 / 16 ? `-${(coverHeight * 16 / 9 - width) / 2}px` : '0px',
          //   marginTop: width * 9 / 16 >= coverHeight ? `-${(width * 9 / 16 - coverHeight) / 2}px` : '0px'
          // }}
          loop={true}
          onBufferEnd={() => setLoading(false)}
          playing={index === cur && play}
          muted={true}
          onError={() => setPictureMode(true)}
        />
      )}
    </Box>
  );
}
