import React, { useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Button } from '@mui/material';
import { BoxProps, StepIconProps, StepConnector } from '@mui/material';
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled, useTheme, alpha } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { lessonType, quizAttempts, allQuizsResultType, allUserAttemptType } from '../../../@types/lesson';
import CircleScoreChart from '../../../components/charts/CircleScoreChart';
import AveragePointScoreChart from '../../../components/charts/AveragePointScoreChart';
import ResultAlert from '../../../components/ResultAlert';

export default function QuizResult({quizData, lastSubmit, setPage}:
  {quizData: lessonType, lastSubmit: boolean, setPage: React.Dispatch<React.SetStateAction<string>>})
  {
  const theme = useTheme()
  const { courseSlug } = useParams()
  const attemptData = quizData.quizData.attemptData.quizAttempts
  const initialAttempt = lastSubmit ? attemptData.at(-1) : attemptData.find((attempt) => attempt.active) || attemptData.at(-1)
  const [activeAttempt, setActiveAttempt] = useState(initialAttempt || attemptData[0])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '@media (max-width: 699px)': {
            display: 'block',
          }
        }}
      >
        <Typography variant="h5" sx={{marginBottom: '10px'}}>{quizData.title}</Typography>
        <Box sx={{display: 'flex', gap: '10px', justifyContent: 'center'}}>
          <Button variant="contained" sx={{borderRadius: '8px', minWidth: 'auto', whiteSpace: 'nowrap', maxHeight: '36px'}} disabled={quizData.quizData.attemptData.remainAttempt <= 0} onClick={() => setPage('description')}>ทำอีกครั้ง<Iconify icon="bi:arrow-repeat" sx={{width: '20px', height: '20px', marginLeft: '5px'}}/></Button>
          {
            quizData.nextLesson !== null &&
            <Button color='secondary' variant="contained" sx={{borderRadius: '8px', minWidth: 'auto', whiteSpace: 'nowrap', maxHeight: '36px'}}  to={`/course/${courseSlug}/${quizData.nextLesson.id}`} component={RouterLink} >
              บทเรียนถัดไป<Iconify icon="akar-icons:arrow-right" sx={{width: '20px', height: '20px', marginLeft: '5px'}}/>
            </Button>
          }
        </Box>
      </Box>

      <QuizResultAlert
        passed={activeAttempt.passed}
        remainAttempt={quizData.quizData.attemptData.remainAttempt}
        maxAttempt={quizData.quizData.attemptData.maxAttempt}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '@media (max-width: 599px)': {
            flexDirection: 'column',
          },
        }}>
        <CircleScoreChart
          passed={activeAttempt.passed}
          score={activeAttempt.sumScore}
          maxScore={activeAttempt.maxScore}
        />
        <QuizAllUserChart
          allUserAttempt={quizData.quizData.allUserAttempt}
          activeAttempt={activeAttempt}/>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          '@media (max-width: 599px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box sx={{width: '100%', maxWidth: '490px'}}>
          <Typography
            variant="subtitle1"
            sx={{color: theme.palette.text.secondary, marginY: '15px', textAlign: 'center'}}
          >
            เฉลยจำนวณข้อถูกผิด
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <QuizAnswerCheck activeAttempt={activeAttempt}/>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{color: theme.palette.text.secondary, marginY: '15px', textAlign: 'center'}}
          >
            ตอนนี้ท่านได้ทำแบบทดสอบไปแล้ว
          </Typography>
          <AllQuizStepper allQuizsResult={quizData.quizData.allQuizsResult} quizId={quizData.quizData.quiz.id}/>
        </Box>
        <AttemptSelector activeAttemptId={activeAttempt.id} setActiveAttempt={setActiveAttempt} attemptData={attemptData}/>
      </Box>
    </Box>
  )
}

function AttemptSelector ({activeAttemptId, setActiveAttempt, attemptData}:
  {activeAttemptId: string, setActiveAttempt: Function, attemptData: quizAttempts[]}) {
  const theme = useTheme()
  return (
    <Box sx={{maxWidth: '356px', width: '100%', margin: '10px auto'}}>
      <Typography sx={{textAlign: 'center', color:  theme.palette.text.secondary}} variant="subtitle1">
        ประวัติการทำแบบทดสอบนี้
      </Typography>
      { attemptData.map((attempt) => (
        <Attempt
          key={attempt.id}
          attempt={attempt}
          activeAttemptId={activeAttemptId}
          setActiveAttempt={setActiveAttempt}
        />
       ))
      }
      <Box sx={{textAlign: 'center'}}>
        <Typography sx={{color: theme.palette.text.secondary}} variant="caption">
          หมายเหตุ : นำครั้งที่มากที่สุดมาคำนวณ
        </Typography>
      </Box>
    </Box>
  )
}

function Attempt({attempt, activeAttemptId, setActiveAttempt}:
  {attempt: quizAttempts, activeAttemptId: string, setActiveAttempt: Function}) {
  return (
    <Button sx={{width: '100%'}} onClick={() => setActiveAttempt(attempt)}>
      <Typography
        sx={{
          textAlign: 'center',
          color: attempt.id === activeAttemptId ? 'primary.main' : 'text.secondary'
        }}
        variant="subtitle1"
      >
        ครั้งที่ {attempt.attempt} : {attempt.sumScore}/{attempt.maxScore} คะแนน
        <br/>
        เมื่อวันที่ {dayjs(attempt.createdAt).format('DD/MM/YYYY')}
      </Typography>
    </Button>
  )
}

function AllQuizStepper ({allQuizsResult, quizId}:
  {allQuizsResult: allQuizsResultType[], quizId: string}) {
  const theme = useTheme()
  return (
    <>
      <Stepper sx={{flexWrap: 'wrap', justifyContent: 'center'}} alternativeLabel connector={<QuizConnector/>}>
        {allQuizsResult.map((quizResult, index) => (
          <Step key={`quiz-${index+1}`} sx={{maxWidth: '50px'}} completed={quizResult.status === 'PASSED'} active={quizResult.status === 'FAILED'}>
            <StepLabel StepIconComponent={QuizStepper}><Typography variant='body2' sx={{color:  theme.palette.text.secondary}}>Quiz {index+1}</Typography></StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  )
}

function QuizStepper(props: StepIconProps) {
  const theme = useTheme()
  const { active, completed } = props;
  const icon = completed ? 'akar-icons:check' : active ? 'gridicons:cross' : 'ci:dot-03-m'
  const color = completed ? theme.palette.success.main : active ? theme.palette.error.main : theme.palette.grey[400]
  return (
    <Iconify sx={{color: color, marginTop: '5px'}} icon={icon}></Iconify>
  );
}

const QuizConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.error.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderRadius: 1,
    maxWidth: '6.5px',
    margin: '0 auto'
  }
}));

function QuizAnswerCheck ({activeAttempt}: {activeAttempt: quizAttempts}) {
  return (
    <Box sx={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
      {activeAttempt.results.map((result, index) =>
        <ResultAnswerStyle key={index} passed={result}>
          <Typography variant="subtitle1">{index+1}</Typography>
        </ResultAnswerStyle>)}
    </Box>
  )
}

interface ResultAnswerStyleProps extends BoxProps {
  passed?: boolean
}

const ResultAnswerStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'passed' ,
})<ResultAnswerStyleProps>(({ passed, theme }) => {
  return {
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 100,
  backgroundColor: passed !== null ? passed ? theme.palette.success.main : theme.palette.error.main : theme.palette.grey[900],
  color: theme.palette.common.white,
  boxShadow: `0px 4px 10px 0px ${alpha(passed !== null ? passed ? theme.palette.success.main : theme.palette.error.main : theme.palette.grey[900], 0.5)}`
}});

function QuizAllUserChart ({allUserAttempt, activeAttempt}:
  {allUserAttempt: allUserAttemptType[], activeAttempt: quizAttempts}) {

  const activeAttemptData =  allUserAttempt.find((quiz) => quiz.quizId === activeAttempt.quizId)
  if (activeAttemptData === undefined) {
    return <>notfound</>
  }

  return (
    <Box sx={{textAlign: 'center'}}>
      <AveragePointScoreChart
        averageScore={activeAttemptData.averageScore}
        score={activeAttempt.sumScore}
        counts={activeAttemptData.counts}
        passed={activeAttempt.passed}
        label={'คะแนนเฉลี่ยของแบบทดสอบนี้'}
      />
    </Box>
  )
}

function QuizResultAlert({passed, remainAttempt, maxAttempt}:
  {passed: boolean, remainAttempt: number, maxAttempt: number}) {
  const alertText = {
    passed: {
      title: 'ขอแสดงความยินดี ! คุณได้คะแนนมากกว่าเกณฑ์ค่าเฉลี่ย !!',
      description: 'ผลการทดสอบของคุณอยู่ในเกณฑ์ผ่าน และ ยอดเยี่ยม เราขอเป็นกำลังใจให้สู้ต่อไป !'
    },
    failed: {
      title: `ขอแสดงความเสียใจ คุณได้คะแนนต่ำกว่าเกณฑ์ ทำได้อีก ${remainAttempt}/${maxAttempt} ครั้ง`,
      description: 'ผลการทดสอบของคุณอยู่ในเกณฑ์ไม่ผ่าน เราขอเป็นกำลังใจให้คุณพยายามต่อไป !'
    }
  }
  return(
    <ResultAlert
      severity={passed ? "success" : "error"}
      title={alertText[passed ? "passed" : "failed"].title}
      description={alertText[passed ? "passed" : "failed"].description}
    />
  )
}
