import React, { useState, useMemo, useEffect } from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import axiosInstance from '../../utils/axios';
import Icon from '../Iconify';
import { ProductType } from '../../pages/main/productConfig';

type courseSuggestionType = {
  title: string;
  type: string;
};

interface CourseAutocompleteProps {
  data: ProductType;
  value: string;
  onChange: Function;
  onSearch: Function;
}

const emojiMap = [
  {
    emoji: '📑',
    type: 'course',
    name: 'Course',
  },
  {
    emoji: '🧑‍🏫',
    type: 'instructor',
    name: 'Instructor',
  },
  {
    emoji: '📦',
    type: 'bundle',
    name: 'Bundle',
  },
];

const AnswerKeywordStyle = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& label.Mui-focused': {
    color: theme.palette.text.secondary,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '2px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    '& fieldset': {
      borderColor: theme.palette.background.default,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.background.default,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.background.default,
    },
  },
}));

export default function CourseAutocomplete({
  data,
  value,
  onChange,
  onSearch,
}: CourseAutocompleteProps) {
  const [courseSuggestionList, setCourseSuggestionList] = useState<
    courseSuggestionType[]
  >([]);
  const searchText = useMemo(() => value, [value]);
  const [selectOption, setSelectOption] = useState<boolean>(false);

  const getSearchSuggestion = async (searchText: string) => {
    try {
      const params = new URLSearchParams({ take: '5', search: searchText });
      const response = await axiosInstance.get(
        `${data.searchApiPath}?${params.toString()}`,
      );
      setCourseSuggestionList(response.data);
    } catch {
      return;
    }
  };

  const resetSearch = () => {
    onSearch({ search: '' });
  };

  useEffect(() => {
    getSearchSuggestion(value);
    setSelectOption(false);
  }, [value]);

  return (
    <Autocomplete
      freeSolo
      value={searchText}
      inputValue={value}
      options={courseSuggestionList}
      groupBy={(option) => {
        const groupLesson = emojiMap.find((map) => map.type === option.type);
        return `${groupLesson?.emoji} ${groupLesson?.name}`;
      }}
      // getOptionLabel option provide object | string but declare string so no solution for this
      getOptionLabel={(option) => (option as courseSuggestionType).title || ''}
      onChange={(_, option, reason) => {
        if (reason === 'clear') resetSearch();
        else {
          if (option === null) onSearch({ search: '' });
          else
            onSearch({
              search: typeof option === 'string' ? option : option.title,
            });
        }
      }}
      onHighlightChange={(_, option) => {
        if (option !== null) setSelectOption(true);
        else setSelectOption(false);
      }}
      renderInput={(params) => (
        <AnswerKeywordStyle
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="charm:search" />
              </InputAdornment>
            ),
          }}
          onKeyDown={(event) => {
            if (!selectOption && event.keyCode === 13)
              onSearch({ search: value });
          }}
          onChange={(event) =>
            onChange(
              typeof event.target.value === 'string' ? event.target.value : '',
            )
          }
          placeholder={data.searchPlaceholder}
        />
      )}
    />
  );
}
