import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Description from './Description';
import Lessons from './Lessons';
import Review from './Review';
import StartCourse from '../../../components/course/StartCourse';
import Properties from './Properties';
import Instructors from './Instructors';
import BundleSuggest from './BundleSuggest';
import { courseDataType } from '../../..//@types/courseData';
import { OverviewReviewType } from '../../../@types/reviewCourse';
import CourseDetailFooter from './CourseDetailFooter';
import { Waypoint } from 'react-waypoint';
import FooterSticky from '../../../components/FooterSticky';
import Icon from '../../../components/Iconify';

function ChangeLogButton({ courseSlug }: { courseSlug: string }) {
  return (
    <Button
      href={`/course/${courseSlug}/changelog`}
      variant="outlined"
      sx={{ marginTop: '40px' }}
    >
      <Icon
        icon="solar:history-bold"
        sx={{ fontSize: '20px', marginRight: '5px' }}
      />
      <Typography>ประวัติการอัปเดต</Typography>
    </Button>
  );
}

export default function Detail({
  courseData,
  overviewData,
}: {
  courseData: courseDataType;
  overviewData: OverviewReviewType;
}) {
  const [show, setShow] = useState(false);
  return (
    <Box
      sx={{
        padding: '60px',
        '@media (max-width: 899px)': {
          padding: '60px 20px',
        },
      }}
    >
      <Grid
        container
        sx={{
          marginTop: '0',
          justifyContent: 'space-between',
          '@media (max-width: 899px)': {
            flexFlow: 'column-reverse',
          },
        }}
      >
        <Grid item xs={12} md={6.78}>
          <Description description={courseData.description} />

          <Lessons sections={courseData.sections} />
          <FooterSticky
            show={show}
            setShow={setShow}
            child={<CourseDetailFooter courseData={courseData} />}
          />
          <ChangeLogButton courseSlug={courseData.slug} />
          <Review courseId={courseData.id} overviewData={overviewData} />
        </Grid>
        <Grid item xs={12} md={4.57} sx={{ marginBottom: '60px' }}>
          <StartCourse
            slug={courseData.slug}
            userLearningStatus={courseData.userLearningStatus}
            courseStatus={courseData.status}
            wcProduct={courseData.wcProduct}
          />
          <Waypoint onEnter={() => setShow(false)} />
          {!courseData.userLearningStatus.enrollable &&
            !courseData.userLearningStatus.enrolled && (
              <BundleSuggest
                slug={courseData.slug}
                coursePrice={courseData?.wcProduct?.price ?? 0}
              />
            )}
          <Properties
            features={courseData.features}
            categories={courseData.categories}
            recommendedSoftwares={courseData.recommendedSoftwares}
            tags={courseData.tags}
          />
          {courseData.instructors.length > 0 && (
            <Instructors instructors={courseData.instructors} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
