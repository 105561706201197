import { createSlice } from '@reduxjs/toolkit';
import { CommentDetailType } from '../../@types/comments';

interface CommentQuestionQueqe {
  id: string;
  status: string;
}
interface CommentState {
  lastCommentUpdated: CommentDetailType | null;
  commentQuestionQueqes: CommentQuestionQueqe[];
}

const initialState: CommentState = {
  lastCommentUpdated: null,
  commentQuestionQueqes: [],
};

const slice = createSlice({
  name: 'comment',
  initialState: initialState,
  reducers: {
    setLastCommentUpdated(state, action) {
      state.lastCommentUpdated = action.payload;
    },
    addCommentQuestionQueqe(state, action) {
      state.commentQuestionQueqes = [
        ...state.commentQuestionQueqes,
        action.payload,
      ];
    },
    updateCommentQuestionQueqe(state, action) {
      state.commentQuestionQueqes = state.commentQuestionQueqes.map((each) =>
        each.id === action.payload.id ? action.payload : each,
      );
    },
    removeCommentQuestionQueqeById(state, action) {
      state.commentQuestionQueqes = state.commentQuestionQueqes.filter(
        (each) => each.id !== action.payload,
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLastCommentUpdated,
  addCommentQuestionQueqe,
  updateCommentQuestionQueqe,
  removeCommentQuestionQueqeById,
} = slice.actions;
