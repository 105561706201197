import React from 'react';
import { Box, Stack, Grid, Skeleton } from '@mui/material';

export default function ProfileStatistic() {
  return (
    <Box>
      <Skeleton
        variant="rounded"
        width="200px"
        height="30px"
        sx={{
          marginTop: '30px',
          marginBottom: '20px',
          backgroundColor: 'grey.600',
        }}
      />

      <Grid
        container
        columnGap="10px"
        rowGap="20px"
        sx={{ marginTop: '10px', marginBottom: '40px' }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            padding: '23px',
            backgroundColor: 'background.paper',
            borderRadius: '16px',
            boxShadow: '0px 16px 32px -4px #0000003D',
          }}
        >
          <Skeleton variant="rounded" width="140px" height="25px" />
          <Skeleton
            variant="rounded"
            width="160px"
            sx={{ marginTop: '5px', marginBottom: '15px' }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            sx={{ backgroundColor: 'grey.600', height: '300px' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3.8}
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: '16px',
            paddingBottom: '40px',
            boxShadow: '0px 16px 32px -4px #0000003D',
          }}
        >
          <Skeleton
            variant="rounded"
            width="117px"
            height="25px"
            sx={{
              margin: '23px',
              marginBottom: '30px',
            }}
          />
          <Stack direction="column" alignItems="center">
            <Skeleton
              variant="circular"
              width="200px"
              height="200px"
              sx={{ backgroundColor: 'grey.600' }}
            />
            <Skeleton
              variant="rounded"
              width="80%"
              height="50px"
              sx={{ marginTop: '20px' }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
