import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CourseBundleMetaType, CourseLessonPreview } from '../../../@types/bundle';
import CardArrowSelector from '../../../components/CardArrowSelector';
import Iconify from '../../../components/Iconify';
import { alpha, useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

export default function PreviewLessons({courses}: {courses: CourseBundleMetaType[]}) {
  const isLessonPreviewExisted = () => courses.reduce((lessonPCount, course) => lessonPCount + course.previewLessons.length, 0) > 0
  return (
    isLessonPreviewExisted() ?
    <Box>
      <Typography variant='h4' sx={{marginTop: '20px'}}>
        ตัวอย่างบทเรียนในแพ็กเกจนี้
      </Typography>
      { courses.map((course: CourseBundleMetaType) => course.previewLessons.length > 0 && <PreviewLesson course={course}/> ) }
    </Box>
    :
    <></>
  )
}

function PreviewLesson({course}: {course: CourseBundleMetaType}) {
  return (
    <Box>
      <Typography variant='h5' sx={{margin: '20px 0px 10px 30px'}}>
        {course.title}
      </Typography>
      <CardArrowSelector
        cards={<CoursePreviewCards lessons={course.previewLessons} courseSlug={course.slug}/>}
        cardWidth={175}
        sx={{gap: '5px'}}
      />
    </Box>
  )
}

function CoursePreviewCards({lessons, courseSlug}: {lessons: CourseLessonPreview[], courseSlug: string}) {
  const theme = useTheme();
  return (
    <>
      {lessons.map((lessonData) => (
        <Button
          key={lessonData.title}
          component={Link}
          to={`/course/${courseSlug}/${lessonData.id}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            position: 'relative',
            minWidth: '170px',
            maxWidth: '170px',
            textAlign: 'left',
            color: 'text.primary',
            padding: '5px',
            alignItems: 'flex-start'
          }}
        >
          <Box sx={{width: '100%', wordBreak: 'break-word'}}>
            <Box
              sx={{
                width: '100%',
                height: '90px',
                backgroundImage: !lessonData.videoUrl ? `url(${lessonData.coverImageUrl})` :
                  `linear-gradient(to bottom,${alpha(theme.palette.background.default, .55)},${alpha(theme.palette.background.default, .55)}),url(${lessonData.coverImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '5px',
              }}
            >
              {
                lessonData.videoUrl && <VideoThumbnail videoUrl={lessonData.videoUrl}/>
              }
            </Box>
            {lessonData.title}
          </Box>
        </Button>
      ))}
    </>
  )
}

function VideoThumbnail({videoUrl}: {videoUrl: string}){
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '5px',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: alpha(theme.palette.background.default, 0.2),
        }}
      >
        <Iconify icon='material-symbols:play-circle-outline-rounded' sx={{width: '50px', height: '50px'}}/>
      </Box>
      <ReactPlayer
        url={videoUrl}
        controls={false}
        width='160px'
        height='90px'
        light={true}
        playing={false}
        playIcon={<></>}
      />
    </Box>
  )
}
