import React, { useState, useEffect, useCallback } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from './Iconify';
import dayjs from 'dayjs';

interface CountdownProps {
  limitTime: dayjs.Dayjs;
  show?: boolean;
  countdown?: boolean;
  status?: string;
  submitQuiz: Function;
}
interface TimeComponentProps {
  time: number;
}

type TimeType = {
  hour: string;
  minute: string;
  second: string;
};

const StyleTimeBox = styled(Box)(({ theme }) => ({
  padding: '10px',
  position: 'relative',
  display: 'inline-flex',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[600]}`,
  backgroundColor: theme.palette.background.default,
}));

function TimeComponent({time} :TimeComponentProps) {
  const [timer, setTimer] = useState<TimeType>({
    hour: '00',
    minute: '00',
    second: '00',
  });

  const handleTimer = (time: number) => {
    const result = new Date(time * 1000).toISOString();
    setTimer({
      hour: result.slice(11, 13),
      minute: result.slice(14, 16),
      second: result.slice(17, 19),
    });
  };

  useEffect(() => {
    handleTimer(time);
  }, [time]);

  return (
    <Stack direction="row">
      {Object.entries(timer).map((item: any, index: number) => (
        <Stack
          key={`time-${index}`}
          direction="row"
          justifyContent="center"
          sx={{
            color:
              parseInt(timer.hour) === 0 &&
              parseInt(timer.minute) === 0 &&
              parseInt(timer.second) <= 10
                ? 'error.main'
                : 'text.primary',
          }}
        >
          <Stack direction="column">
            <Typography
              variant="caption"
              sx={{
                color: 'text.primary',
                textAlign: 'center',
                marginRight: index !== 2 ? '14px' : '0px',
              }}
            >
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="caption"
                sx={{ width: '20px', textAlign: 'center' }}
              >
                {item[1]}
              </Typography>
              {index < 2 && (
                <Typography sx={{fontSize:'10px'}}>
                  :
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default function Countdown({ limitTime, show = true,countdown,status,submitQuiz }: CountdownProps) {
  const [countdownTime, setCountdownTime] = useState<number>(limitTime.diff(dayjs(),'second') ?? 0);
  
  const handleEndCountdown = useCallback(() => {
    if(countdown && status === 'PROGRESS'){
      submitQuiz()
    }
    setCountdownTime(0);
  }, [countdown, status, submitQuiz]);
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdownTime((prevCountdownTime) => {
        if (prevCountdownTime > 1) {
          return prevCountdownTime - 1;
        } else {
          clearInterval(timer);
          handleEndCountdown();
          return 0;
        }
      });
    }, 1000);
  
    return () => clearInterval(timer);
  }, [handleEndCountdown]);

  if (show) {
    return (
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        sx={{ gap: '10px', marginBottom: '20px' }}
      >
        <StyleTimeBox>
        <Iconify icon='emojione-monotone:stopwatch' height={20} width={20}/>
          <Stack
            direction="row"
            alignItems="end"
            sx={{ paddingX: '10px', gap: '8px' }}
          >
            <TimeComponent time={countdownTime} />
          </Stack>
        </StyleTimeBox>
      </Stack>
    );
  }

  return null;
}
