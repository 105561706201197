import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Box } from '@mui/material';
import { DeltaStatic } from 'quill';
import { styled } from '@mui/material/styles';

const Embed = Quill.import('blots/embed');

type IframeSourceType = {
  url: string;
  width: number;
  height: number;
};

class IframeEmbededBlot extends Embed {
  static blotName = 'iframeembeded';
  static tagName = 'DIV';
  static className = 'iframeembeded';

  static create(data: any) {
    const node = super.create(data);
    node.setAttribute('data-value', data);

    const { url, width, height } = this.convertObjectString(data);
    const paddingBottom = this.calculatePadding(width, height);

    const iframeEmbedWrapper = document.createElement('div');
    iframeEmbedWrapper.style.cssText = `width:100%; height:0px; position:relative; padding-bottom:${paddingBottom}%;`;

    const iframeEmbeded = document.createElement('iframe');
    iframeEmbeded.src = `${url}#view=Fit`;
    iframeEmbeded.allowFullscreen = true;
    iframeEmbeded.width = '100%';
    iframeEmbeded.height = '100%';
    iframeEmbeded.style.cssText =
      'width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;';
    iframeEmbedWrapper.appendChild(iframeEmbeded);
    node.appendChild(iframeEmbedWrapper);
    return node;
  }

  static calculatePadding(width?: number, height?: number): string {
    if (width && height) return String((height * 100) / width);
    else return '56.25';
  }

  static convertObjectString(value: string): IframeSourceType {
    try {
      return JSON.parse(value);
    } catch {
      return { url: value, width: 16, height: 9 };
    }
  }

  static value(domNode: any) {
    return domNode.getAttribute('data-value');
  }
}

class FileEmbededBlot extends Embed {
  static blotName = 'fileembeded';
  static tagName = 'DIV';
  static className = 'fileembeded';

  static create(data: string) {
    const node = super.create(data);
    node.setAttribute('data-value', data);
    node.innerHTML = `<a href="${data}" target="_blank">${data}</a>`;
    return node;
  }

  static value(domNode: any) {
    return domNode.getAttribute('data-value');
  }
}

Quill.register(IframeEmbededBlot);
Quill.register(FileEmbededBlot);

export default function RichViewer({
  content,
  sx,
}: {
  content: string | DeltaStatic;
  sx?: object;
}) {
  const modules = {
    syntax: true,
  };
  return (
    <Box sx={{ ...sx }}>
      <RichViewerStyle>
        <ReactQuill
          value={content}
          readOnly={true}
          theme={'bubble'}
          modules={modules}
        />
      </RichViewerStyle>
    </Box>
  );
}

const RichViewerStyle = styled(Box)(({ theme }) => ({
  '& .ql-editor': {
    padding: 0,
    overflowY: 'hidden',
  },
  '& .ql-container.ql-bubble': {
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
  },
  '.ql-bubble .ql-editor pre': {
    padding: '16px',
    borderRadius: '10px',
    fontSize: '1rem',
  },
  p: {
    fontSize: '1rem',
  },
  code: {
    color: 'black',
  },
  a: {
    color: theme.palette.primary.main,
  },
  'a:visited': {
    color: theme.palette.primary.dark,
  },
  'a:hover': {
    color: theme.palette.primary.light,
  },
}));
