import {
  instructorType,
  categoryType,
  tagType,
  courseProgressType,
  courseImagesType,
  CourseStatusType,
  BundleStatusType,
} from './courseData';

export type videoBannerType = {
  id: string;
  title: string;
  slug: string;
  shortDescription: string;
  coverImage: string;
  previewVideo: string;
  instructors: instructorType[];
  categories: categoryType[];
  tags: tagType[];
};

export type courseCatalogType = {
  title: string;
  description: string;
  filter: courseFilterType;
  courses: courseCatalogCardType[];
};

export type courseFilterType = {
  categories: string[];
  tags: string[];
  enroll: string;
};

export type courseCatalogCardType = {
  id: string;
  title: string;
  slug: string;
  shortDescription: string;
  rating: number;
  categories: categoryType[];
  images: courseImagesType;
  previewVideo: string;
  durationText: string;
  instructors: instructorType[];
  courseProgress: courseProgressType;
  recommendedPlan: recommendedPlanType;
  wcProduct?: WcProductDataType;
  status: CourseStatusType;
  studentCount: number;
  completable: boolean;
  lessonAmount: number;
  lessonCompletedAmount: number;
};

export type recommendedPlanType = {
  id: string;
  title: string;
  description: string;
  prices: {
    monthly: priceplan;
    yearly: priceplan;
  };
};

export type priceplan = {
  regular: number;
  sale: number;
  taxIncluded: boolean;
};

export enum WcProductType {
  SINGLE = 'SINGLE',
  BUNDLE = 'BUNDLE',
}

export type WcProductDataType = {
  id: number;
  image: string;
  name: string;
  price: number;
  regularPrice: number;
  salePrice: number;
  salePageUrl: string;
  slug: string;
  type: WcProductType;
  url: string;
  status: string;
  shortDescription: string;
};

export type BundleCatalogType = {
  id: string;
  title: string;
  duration: number;
  slug: string;
  shortDescription: string;
  images: courseImagesType;
  previewVideo: string;
  status: BundleStatusType.PUBLISHED;
  wcProduct: WcProductDataType;
  owned: false;
  studentCount: number;
  instructors: BundleInstructorType[];
  review: ReviewBundleDataType;
};

type ReviewBundleDataType = {
  average: number;
  count: number;
};

export type BundleInstructorType = {
  id: string;
  publicName: string;
  imageUrl: string;
};
