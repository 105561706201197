import React, { useState } from 'react';
import { courseContinueType } from '../../../@types/courseContinue';
import { Typography, CircularProgress, Button, Box } from '@mui/material';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import axios from '../../../utils/axios';
import { fTSeparator } from '../../../utils/formatNumber';
import { WcProductDataType } from '../../../@types/courseCatalog';
import { FREE_PLAN } from './config';
import Iconify from '../../Iconify';

export function DisabledSection({
  label,
  short = false,
}: {
  label: string;
  short?: boolean;
}) {
  return (
    <Button
      disabled={true}
      color="primary"
      variant="contained"
      sx={short ? footerStyle : defaultStyle}
    >
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
    </Button>
  );
}

export function LearnSection({
  enrolled,
  slug,
  courseStatus,
  short = true,
}: {
  enrolled: boolean;
  slug: string;
  courseStatus: string;
  short?: boolean;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const getContinueLesson = async (
    slug: string,
  ): Promise<courseContinueType | undefined> => {
    try {
      const response = await axios.get(`/courses/${slug}/lessons/continue`);
      return response.data;
    } catch (error) {
      return;
    }
  };

  const postEnrollLesson = async (slug: string) => {
    try {
      await axios.post(`/courses/${slug}/enroll`);
    } catch (error) {
      return;
    }
  };

  const continueLesson = async (slug: string) => {
    if (!loading) {
      setLoading(true);
      const res = await getContinueLesson(slug);
      navigate(`/course/${slug}/${res?.id}`);
    }
  };

  const enrollCourse = async (slug: string) => {
    if (!loading) {
      setLoading(true);
      await postEnrollLesson(slug);
      if (courseStatus === 'PREREGISTER') window.location.reload();
      const res = await getContinueLesson(slug);
      navigate(`/course/${slug}/${res?.id}`);
    }
  };

  return (
    <>
      {loading ? (
        <Button
          disabled={true}
          color="primary"
          variant="contained"
          sx={short ? footerStyle : defaultStyle}
        >
          <CircularProgress
            sx={{ width: '25px !important', height: '25px !important' }}
          />
        </Button>
      ) : courseStatus === 'PREREGISTER' ? (
        <>
          {enrolled ? (
            <Button
              disabled
              color="primary"
              variant="contained"
              sx={short ? footerStyle : defaultStyle}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                {short ? 'ลงทะเบียนแล้ว' : 'ลงทะเบียนล่วงหน้าแล้ว'}
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={() => enrollCourse(slug)}
              color="primary"
              variant="contained"
              sx={short ? footerStyle : defaultStyle}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Iconify
                  icon="mdi:clock-outline"
                  sx={{ width: '25px', height: '25px' }}
                />
                {short ? 'ลงทะเบียน' : 'ลงทะเบียนล่วงหน้า'}
              </Typography>
            </Button>
          )}
        </>
      ) : (
        <>
          {enrolled ? (
            <Button
              onClick={() => continueLesson(slug)}
              color="primary"
              variant="contained"
              sx={short ? footerStyle : defaultStyle}
            >
              <Typography sx={{ fontWeight: 'bold' }}>เข้าเรียนต่อ</Typography>
            </Button>
          ) : (
            <Button
              onClick={() => enrollCourse(slug)}
              color="primary"
              variant="contained"
              sx={short ? footerStyle : defaultStyle}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                {short ? 'เริ่มเรียน' : 'เริ่มเรียนหลักสูตรนี้'}
              </Typography>
            </Button>
          )}
        </>
      )}
    </>
  );
}

export function LoginSection({
  recommendedPlan,
  short = false,
}: {
  recommendedPlan: string;
  short?: boolean;
}) {
  const quote =
    recommendedPlan !== FREE_PLAN || short
      ? 'เข้าสู่ระบบ'
      : 'เข้าสู่ระบบเพื่อเรียนฟรีได้แล้ววันนี้ !';
  return (
    <>
      <Button
        component={RouterLink}
        to="/login"
        color="primary"
        variant="contained"
        sx={short ? footerStyle : defaultStyle}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{quote}</Typography>
      </Button>
    </>
  );
}

export function PlanSaleSection({
  isOpenAccess,
  short = false,
}: {
  isOpenAccess: boolean;
  short?: boolean;
}) {
  const quote = short
    ? 'ลงทะเบียน'
    : isOpenAccess
      ? 'เข้าสู่ระบบเพื่อเรียนฟรีได้แล้ววันนี้ !'
      : 'ลงทะเบียนตอนนี้';
  return (
    <>
      {/* open access will in this case only not logged in otherwise enroll section*/}
      <Button
        component={RouterLink}
        to={
          isOpenAccess
            ? `/login?redirectUrl=${window.location.href}`
            : '/subscription'
        }
        color="primary"
        variant="contained"
        sx={short ? footerStyle : defaultStyle}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{quote}</Typography>
      </Button>
    </>
  );
}

export function NotLoggedInPreRegisterSection({
  isPremium,
  short = false,
}: {
  isPremium: boolean;
  short?: boolean;
}) {
  return (
    <a
      href={`/login?redirectUrl=${window.location.href}`}
      target={'_self'}
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <Button
        color="primary"
        variant="contained"
        sx={short ? footerStyle : defaultStyle}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Iconify
            icon="mdi:clock-outline"
            sx={{ width: '25px', height: '25px' }}
          />
          {short ? 'ลงทะเบียน' : 'ลงทะเบียนล่วงหน้า'}
        </Typography>
      </Button>
    </a>
  );
}

export function ProductSaleSection({
  wcProduct,
  isNotLoggedIn,
  short = false,
}: {
  wcProduct: WcProductDataType;
  isNotLoggedIn: boolean;
  short?: boolean;
}) {
  const isDiscount = wcProduct.regularPrice > wcProduct.price;
  return (
    <a
      href={
        isNotLoggedIn
          ? `/login?redirectUrl=${window.location.href}`
          : wcProduct.url
      }
      target={isNotLoggedIn ? '_self' : '_blank'}
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <Button
        color="primary"
        variant="contained"
        sx={short ? footerStyle : defaultStyle}
      >
        {short ? (
          <>
            <Iconify
              icon="clarity:shopping-cart-solid"
              width={20}
              height={20}
            />
            ลงทะเบียน
          </>
        ) : isDiscount ? (
          <Typography sx={{ fontWeight: 'bold' }}>
            ลงทะเบียนหลักสูตรนี้ - {fTSeparator(wcProduct.price)}฿ ( ปกติ
            <Box
              component="span"
              sx={{ textDecoration: 'line-through', marginLeft: '5px' }}
            >
              {fTSeparator(wcProduct.regularPrice)}
            </Box>
            ฿ )
          </Typography>
        ) : (
          <Typography sx={{ fontWeight: 'bold' }}>
            ลงทะเบียนหลักสูตรนี้ - {fTSeparator(wcProduct.price)}฿
          </Typography>
        )}
      </Button>
    </a>
  );
}

const defaultStyle = {
  width: '100%',
  padding: '10px',
  marginTop: '25px',
};

const footerStyle = {
  width: '200px',
  height: '70px',
  borderRadius: '8px',
  fontSize: '20px',
  '@media (max-width: 1200px)': {
    fontSize: '14px',
    width: '150px',
    height: 'auto',
  },
};
