import React, { useState, useEffect } from 'react';
import { Divider } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useSelector, RootState } from '../../redux/store';
import { CommentContainer } from './CommentContainer';
import { AnswerContainer } from './AnswerContainer';
import SelectedComment from './SelectedComment';
import useAuth from '../../hooks/useAuth';

interface CommentsProps {
  isAnswerLesson: boolean;
  lessonId: string;
}

export default function Comments({ isAnswerLesson, lessonId }: CommentsProps) {
  const { isAuthenticated } = useAuth();
  const [commentMode, setCommentMode] = useState<'COMMENT' | 'ANSWER'>(
    isAnswerLesson ? 'ANSWER' : 'COMMENT',
  );
  const [queryParameters] = useSearchParams();

  const commentId = queryParameters.get('commentId');
  const answerId = queryParameters.get('answerId');

  const CourseData = useSelector(
    (state: RootState) => state.lesson.courseLessons,
  );

  const id = commentId || answerId;
  const selectedCommmentType = answerId ? 'ANSWER' : 'COMMENT';

  useEffect(() => {
    if (commentId) {
      setCommentMode('COMMENT');
    } else if (answerId && isAnswerLesson) {
      setCommentMode('ANSWER');
    }
  }, [commentId, answerId, isAnswerLesson]);

  return (
    <>
      <Divider sx={{ marginTop: '40px' }} />

      {id && selectedCommmentType === commentMode && (
        <SelectedComment
          id={id}
          type={selectedCommmentType}
          courseId={CourseData.id}
          lessonId={lessonId}
        />
      )}

      {isAnswerLesson && isAuthenticated ? (
        <AnswerContainer courseId={CourseData.id} lessonId={lessonId} />
      ) : (
        <CommentContainer
          courseId={CourseData.id}
          lessonId={lessonId}
          enrolled={CourseData.enrolled}
        />
      )}
    </>
  );
}
