import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from '../../../components/Image';
import { NavLink as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { nextLessonType } from '../../../@types/lesson';
import { useSelector, RootState } from '../../../redux/store';

export default function NextLesson({nextLesson, setShow}: {nextLesson: nextLessonType, setShow: Function}) {
  const theme = useTheme()
  const { courseSlug } = useParams()

  const courseData = useSelector((state: RootState) => state.lesson.courseLessons)

  function goNextLesson() {
    window.scrollTo(0, 0)
    setShow(false)
  }

  return (
          <Box
            sx={{
              maxWidth: '1220px',
              width: '100%',
              padding: '14px',
          }}>
            <Box sx={{
              display: 'none',
              '@media (max-width: 699px)': {
                display: 'block',
              },
            }}>
              <Box sx={{marginLeft: '25px'}}>
                  <Typography sx={{color: theme.palette.primary.light}} variant="caption">ตอนถัดไป</Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '700px',
                      '@media (max-width: 1200px)': {
                        maxWidth: '450px',
                      },
                      width: '100%'
                    }}
                    variant="h5"
                  >
                    {nextLesson.title}
                  </Typography>
                </Box>
                <Box sx={{textAlign: 'center', marginTop: '5px'}}>
                  <Button
                    color="primary"
                    variant="contained"
                    to={`/course/${courseSlug}/${nextLesson.id}`}
                    component={RouterLink}
                    onClick={() => goNextLesson()}
                    sx={{width: '200px'}}>
                    <Typography sx={{color: 'text.button'}} variant="h5">
                      บทถัดไป
                    </Typography>
                  </Button>
                </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '@media (max-width: 699px)': {
                display: 'none',
              },
            }}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Image
                  disabledEffect
                  visibleByDefault
                  alt="next-lesson-image"
                  effect="blur"
                  src={nextLesson.coverImageUrl || courseData.images.cover}
                  sx={{ width: '168px', height: '95px', borderRadius: '8px'}}
                />
                <Box sx={{marginLeft: '25px'}}>
                  <Typography sx={{color: theme.palette.primary.light}} variant="caption">ตอนถัดไป</Typography>
                  <Typography
                    sx={{
                      color: 'text.primary',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '700px',
                      '@media (max-width: 1200px)': {
                        maxWidth: '450px',
                      },
                      width: '100%'
                    }}
                    variant="h5"
                  >
                    {nextLesson.title}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  to={`/course/${courseSlug}/${nextLesson.id}`}
                  component={RouterLink}
                  onClick={() => goNextLesson()}
                  sx={{width: '200px', height: '70px'}}>
                  <Typography sx={{color:  'text.button'}} variant="h5">
                    บทถัดไป
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
  )
}
