import React from 'react';
import { Typography, Box } from '@mui/material';
import Iconify from '../Iconify';
import { fTSeparator } from '../../utils/formatNumber';

import {
  WcProductDataType,
  BundleCatalogType,
} from '../../@types/courseCatalog';
import { RatingInCard } from '../../components/Rating';
import BundleInstructors from './BundleInstructors';

type BundleCardContentProps = {
  bundle: BundleCatalogType;
};

type BundleSaleDetailDataType = {
  rating: number;
  durationText: string;
  wcProduct: WcProductDataType;
  owned: boolean;
  studentCount: number;
};

const ellipsisTwo = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
};

function BundleCardContent({ bundle }: BundleCardContentProps) {
  return (
    <>
      <Box>
        <Typography
          variant="subtitle2"
          sx={{
            maxHeight: '44px',
            minHeight: '44px',
            color: 'text.primary',
            ...ellipsisTwo,
            textDecoration: 'none',
            ':hover': {
              color: 'primary.main',
            },
          }}
        >
          {bundle.title}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="caption"
          sx={{
            maxHeight: '36px',
            minHeight: '36px',
            color: 'text.secondary',
            ...ellipsisTwo,
          }}
        >
          {bundle.shortDescription}
        </Typography>
      </Box>
      <BundleInstructors instructors={bundle.instructors} />
      <BundleSaleDetail
        bundleSaleDetailData={{
          rating: bundle.review.average,
          durationText:
            bundle.duration / 3600 > 1
              ? `${Math.round(bundle.duration / 3600)} ชั่วโมง`
              : `${Math.round(bundle.duration / 3600)} นาที`,
          wcProduct: bundle.wcProduct,
          owned: bundle.owned,
          studentCount: bundle.studentCount,
        }}
      />
    </>
  );
}

function BundleSaleDetail({
  bundleSaleDetailData,
}: {
  bundleSaleDetailData: BundleSaleDetailDataType;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
      }}
    >
      <Box>
        <RatingInCard
          rate={bundleSaleDetailData.rating}
          studentCount={bundleSaleDetailData.studentCount}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Iconify
            icon="akar-icons:clock"
            width="20"
            height="20"
            sx={{ color: 'grey.600' }}
          />
          <Typography variant="body2" sx={{ color: 'grey.600' }}>
            {bundleSaleDetailData.durationText}
          </Typography>
        </Box>
      </Box>
      <Box>
        {!bundleSaleDetailData.owned ? (
          bundleSaleDetailData.wcProduct.price <
          bundleSaleDetailData.wcProduct.regularPrice ? (
            <Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    textDecoration: 'line-through',
                    textDecorationColor: 'text.secondary',
                  }}
                >
                  {fTSeparator(bundleSaleDetailData.wcProduct.regularPrice)}.-
                </Typography>
              </Box>
              <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                {fTSeparator(bundleSaleDetailData.wcProduct.price)}.-
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ color: 'text.primary', marginTop: '20px' }}
              >
                {fTSeparator(bundleSaleDetailData.wcProduct.price)}.-
              </Typography>
            </Box>
          )
        ) : (
          <Typography
            variant="subtitle1"
            sx={{ color: 'text.secondary', marginTop: '20px' }}
          >
            เป็นเจ้าของแล้ว
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default BundleCardContent;
