import React from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { Link as RouteLink, useLocation } from 'react-router-dom'

import Image from '../../components/Image';
import getObjParams from '../../utils/getObjParams'
import Iconify from '../../components/Iconify';

const config = {
  general: {
    headerText: 'Something went wrong',
    subHeaderText: '',
    descriptionText: ''
  },
  device: {
    headerText: 'คุณถูกตัดจากการเชื่อมต่อ',
    subHeaderText: 'เนื่องจากบัญชีนี้มีการใช้งานเกิน 2 อุปกรณ์',
    descriptionText: 'หากการใช้งานผ่านอุปกรณ์นี้ กรุณาเข้าสู่ระบบใหม่',
  },
  announce: {
    headerText: 'เกิดข้อผิดพลาดในการเข้าถึงประกาศ',
    subHeaderText: 'เนื่องจากประกาศหมดอายุ หรือถูกลบแล้ว',
    descriptionText: '',
  }
}

const configKey = Object.keys(config)

export default function ErrorPage() {
  const location = useLocation()
  const qParams = getObjParams(location.search)
  const code = configKey.includes(qParams.code || '') ? qParams.code : 'general'
  const message = (config[code as keyof typeof config])
  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Stack direction="column" justifyContent="center" alignItems="center" sx={{ textAlign: 'center', height: '100%' }}>
        <Box sx={{ marginBottom: '40px' }}>
          <Iconify 
            icon='pajamas:warning-solid'
            sx={{
              color: 'warning.main',
              width: '70px',
              height: '70px'
            }}
          />
          <Typography variant="h4">{message.headerText}</Typography>
          <Typography variant="h4">{message.subHeaderText}</Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>{message.descriptionText}</Typography>
        </Box>

        <Divider sx={{ width: '100%' }} />
        <Image visibleByDefault alt='logo' src={`${window.__RUNTIME_CONFIG__.PUBLIC_URL}logos/school-full-225x72.png`} sx={{ width: '300px', margin: '15px 0px' }} />
        <Button component={RouteLink} to="/" variant="contained" sx={{ marginY: '30px', width: 'fit-content', borderRadius: '16px', padding: '5px 30px' }}>
          <Typography variant="button" sx={{color: 'text.button'}}>GO HOME</Typography>
        </Button>
      </Stack>
    </Box>
  )
}
