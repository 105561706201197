import { ReactNode } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import {
  LinkProps,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemButtonProps,
} from '@mui/material';
// config
import { ICON } from '../../../config';

// ----------------------------------------------------------------------

type IProps = LinkProps & ListItemButtonProps;

interface ListItemStyleProps extends IProps {
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
}));

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});

export const LessonIconStyle = styled(ListItemIcon)({
  minWidth: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '10px',
});

export const SectionTime = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  backgroundColor: alpha(theme.palette.primary.main, 0.75),
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  width: 'fit-content',
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}))


interface LessonItemStyleProps extends ButtonProps {
  isCurrentLesson?: boolean;
}

export const LessonItemStyle = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'isCurrentLesson',
})<LessonItemStyleProps>(({ isCurrentLesson, theme }) => ({

}))
