import { ColorSchema } from '../../theme/palette';

export const productConfig = {
  course: {
    name: 'course',
    icon: 'clarity:book-solid',
    word: 'หลักสูตร',
    path: '/search',
    link: '/search',
    color: 'tertiary' as ColorSchema,
    searchPlaceholder: 'ค้นหาด้วยชื่อหลักสูตร หรือผู้สอน',
    searchApiPath: '/courses/course-suggest',
  },
  bundle: {
    name: 'bundle',
    icon: 'clarity:bundle-solid',
    word: 'แพ็กเกจ',
    path: '/search/bundle',
    link: '/search/bundle',
    color: 'tertiary' as ColorSchema,
    searchPlaceholder: 'ค้นหาด้วยชื่อแพ็กเกจ',
    searchApiPath: '/bundles/bundle-suggest',
  },
};

export type ProductConfigType = {
  course: ProductType;
  bundle: ProductType;
};

export type ProductType = {
  name: string;
  icon: string;
  word: string;
  path: string;
  link: string;
  color: ColorSchema;
  searchPlaceholder: string;
  searchApiPath: string;
};

export const productConfigList = [
  {
    name: productConfig.course.name,
    path: productConfig.course.path,
  },
  {
    name: productConfig.bundle.name,
    path: productConfig.bundle.path,
  },
];
