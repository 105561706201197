import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

interface PieChartType {
  dataList: number[];
  labelList: string[];
}

export default function PieChart({ dataList, labelList }: PieChartType) {
  const theme = useTheme();
  const chartData = dataList;
  const chartOptions = {
    labels: labelList,
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      position: 'bottom' as 'left' | 'right' | 'bottom' | 'top',
      labels: {
        colors: 'common.white',
        useSeriesColors: false,
      },
    },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  };
  return (
    <Chart
      type="pie"
      series={chartData}
      options={chartOptions}
      width={'100%'}
    />
  );
}
