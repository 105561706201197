import React from 'react';
import { Box, Grid, Button } from '@mui/material';
import { BundleFormType, BundleMetaType } from '../../../@types/bundle';
import Iconify from '../../../components/Iconify';

import CoursePath from './CoursePath';
import Features from './Features';
import PropertiesList from './PropertiesList';
import SaleSection from './SaleSection';
import PreviewLessons from './PreviewLessons';
import Description from './Description';
import BundleReview from '../BundleReview';

export default function Detail({
  bundleData,
  bundleMeta,
}: {
  bundleData: BundleFormType;
  bundleMeta: BundleMetaType;
}) {
  return (
    <Box
      sx={{
        padding: '60px',
        '@media (max-width: 899px)': {
          padding: '60px 20px',
        },
      }}
    >
      <Grid
        container
        sx={{
          marginTop: '0',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12} md={3.57}>
          <SaleSection
            wcProduct={bundleData.wcProduct}
            owned={bundleData.owned}
          />
          <a
            href={bundleData.wcProduct.data.salePageUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{ width: '100%', marginTop: '5px' }}>
              <Iconify
                icon="material-symbols:menu"
                sx={{ marginRight: '5px' }}
              />
              ดูรายละเอียดฉบับเต็มของแพ็กเกจนี้
            </Button>
          </a>
          <CoursePath
            courses={bundleMeta.courses}
            lastUpdated={bundleMeta.lastUpdated}
          />
        </Grid>
        <Grid item xs={12} md={7.78}>
          <Features bundleMeta={bundleMeta} />
          <Description description={bundleData.description} />
          <PreviewLessons courses={bundleMeta.courses} />
          <PropertiesList
            propertiesList={bundleData.aboutList}
            title="คุณจะได้เรียนรู้เกี่ยวกับ"
            icon={
              <Iconify
                sx={{ color: 'primary.main', marginTop: '5px' }}
                icon={'material-symbols:check'}
              />
            }
          />
          <PropertiesList
            propertiesList={bundleData.targetList}
            title="คอร์สนี้เหมาะสำหรับคนที่"
            icon={<>•</>}
          />
          <BundleReview
            bundleSlug={bundleData.slug}
            rateReview={bundleMeta.review.average}
            reviewCount={bundleMeta.review.count}
            courseCount={bundleMeta.courses.length}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
