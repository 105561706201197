import React from 'react'
import { Container, Box, Stack, Typography } from '@mui/material'

import { fTSeparator } from '../../../utils/formatNumber'

interface SystemStatisticsProps {
  studentCount?: number
  courseCount?: number
  reviews?: number
}

enum StatisticType {
  STUDENT = 'STUDENT',
  COURSE = 'COURSE',
  REVIEW = 'REVIEW'
}

const systemStatisticContent = [
  {
    fontSize: '42px',
    alignment: 'start',
    title: 'เรียนเข้มข้นจริง',
    description_1: 'เน้นปฏิบัติ พาทำโจทย์ รวมถึงมีการประเมินวัดผล',
    description_2: 'ทุกหลักสูตรเน้นใช้กับอุตสาหกรรมจริง'
  },
  { 
    name: StatisticType.STUDENT,
    fontSize: '28px',
    description_1: 'คือจำนวนผู้เรียนทั้งหมด',
    description_2: 'ที่ไว้วางใจ และ เข้าเรียนกับเรา',
  },
  { 
    name: StatisticType.COURSE,
    fontSize: '28px',
    description_1: 'คือจำนวนหลักสูตรทั้งหมด',
    description_2: 'ที่ผู้เรียนสามารถเข้าถึงได้',
  },
  { 
    name: StatisticType.REVIEW,
    fontSize: '28px',
    description_1: 'คือคะแนนการรีวิวจริง', 
    description_2: 'จากผู้เรียนที่เข้าเรียนกับเรา' 
  },
]

export default function SystemStatistics({
  studentCount = 0,
  courseCount = 0,
  reviews = 0
}: SystemStatisticsProps) {
  return (
    <Box 
      sx={{
        backgroundColor: 'primary.main',
        color: 'common.black',
        padding: '50px 0px',
        textAlign: 'center',
        '@media (max-width: 899px)': {
          padding: '50px 0px',
        }
      }}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{
            '@media (max-width: 899px)': {
              flexDirection: 'column',
              gap: '20px'
            }
          }}
        >
          {systemStatisticContent.map((item, index: number) => {
            const statNum = item.name === StatisticType.STUDENT 
              ? studentCount 
              : item.name === StatisticType.COURSE
              ? courseCount
              : reviews
            return (
              <Box
                key={`system-statistic-${index}`}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{ gap: '10px' }}
                >
                  <Typography
                    sx={{ 
                      ...(item.name ? { display: 'none' } : {}),
                      fontSize: item.fontSize
                    }}
                  >
                    {'🔥'}
                  </Typography>
                  <Box>
                    {item.title && (
                      <Typography
                        sx={{ 
                          fontSize: item.fontSize,
                          textAlign: item.alignment ?? 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {item.title}
                      </Typography>
                    )}
                    {item.name && (
                      <Typography
                        sx={{
                          fontSize: item.fontSize,
                          fontWeight: 'bold'
                        }}
                      >
                        {`${statNum ? fTSeparator(statNum) : '-'}`}
                      </Typography>
                    )}
                    <Typography
                      sx={{ 
                        ...(item.alignment ? { textAlign: item.alignment } : {})
                      }}
                    >
                      {item.description_1}
                    </Typography>
                    <Typography
                      sx={{ 
                        ...(item.alignment ? { textAlign: item.alignment } : {})
                      }}
                    >
                      {item.description_2}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
          )})}
        </Stack>
      </Container>
    </Box>
  )
}