import React from "react";
import { Modal, Stack, Typography, Button } from '@mui/material'

import Icon from '../../../components/Iconify'

interface ModalDeleteCommentProps {
  openModal: boolean,
  setOpenModal: Function,
  commentId: string,
  typeComment?: string,
  deleteCommentFunction: Function
}

export default function ModalDeleteComment({ openModal, setOpenModal, commentId, typeComment, deleteCommentFunction }: ModalDeleteCommentProps) {
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '141px',
          width: '343px',
          backgroundColor: 'background.paper',
          borderRadius: '16px',
          borderColor: 'transparent',
          boxShadow: 24,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ marginBottom: '10px' }}>
          <Icon icon="dashicons:warning" sx={{ width: '40px', height: '40px', marginRight: '8px', color: 'error.main' }} />
          <Typography variant="body1">คุณต้องการลบความคิดเห็นนี้</Typography>
        </Stack>
        <Stack direction="row" spacing={1.5}>
          <Button
            sx={{
              backgroundColor: 'grey.500',
              color: 'common.white',
              ':hover': {
                backgroundColor: 'grey.700'
              }
            }}
            onClick={() => setOpenModal(false)}
          >
            ยกเลิก
          </Button>
          <Button
            sx={{
              backgroundColor: 'primary.main',
              color: 'common.white',
              ':hover': {
                backgroundColor: 'primary.dark'
              }
            }}
            onClick={() => deleteCommentFunction(commentId, typeComment)}
          >
            ยืนยัน
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}