import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LineAreaChart from '../../components/charts/LineAreaChart';
import PieChart from '../../components/charts/PieChart';
import ProfileStatistic from '../../components/skeleton/ProfileStatistic';
import axios from '../../utils/axios';
import { shortMonthList } from '../../utils/formatTime';
import { chartDataType } from '../../@types/chart';
import {
  categoriesInterestDataType,
  learningDataType,
} from '../../@types/profile';

export default function StatisticData() {
  const [loading, setLoading] = useState(true);
  const [categoriesInterestData, setCategoriesInterestData] =
    useState<categoriesInterestDataType>();
  const [learningData, setLearningData] = useState<learningDataType>();
  const theme = useTheme();

  const getProfileData = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await axios.get('/progress/lesson-completion-statistic');
      setLearningData(response.data);
      const catResponse = await axios.get('/categories/interested');
      setCategoriesInterestData(catResponse.data);
      setLoading(false);
    } catch (error) {
      // navigate('/login', {replace: true})
    }
  };

  const getCatInterestStatisticData = (): chartDataType => {
    return categoriesInterestData?.categories.reduce(
      (prev: chartDataType, curr) => ({
        labelList: [...prev.labelList, curr.title],
        dataList: [...prev.dataList, curr.count],
      }),
      { labelList: [], dataList: [] } as chartDataType,
    ) as chartDataType;
  };

  const getLearningStatisticData = (): chartDataType => {
    return learningData?.completedLessonEachMonth.reduce(
      (prev: chartDataType, curr) => ({
        labelList: [...prev.labelList, shortMonthList[curr.month - 1]],
        dataList: [...prev.dataList, curr.count],
      }),
      { labelList: [], dataList: [] } as chartDataType,
    ) as chartDataType;
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <Box sx={{ marginTop: '20px' }}>
      {loading ? (
        <ProfileStatistic />
      ) : (
        <>
          <Typography variant="h4">สถิติการใช้งาน</Typography>
          <Grid
            container
            columnGap="10px"
            rowGap="20px"
            sx={{ marginTop: '10px', marginBottom: '40px' }}
          >
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                padding: '23px',
                backgroundColor: 'background.paper',
                borderRadius: '16px',
                boxShadow: '0px 16px 32px -4px #0000003D',
              }}
            >
              <Typography variant="h6">จำนวนบทเรียน</Typography>
              <Typography variant="body2" color="grey.500">
                {`(${learningData?.compareWithLastYearPercentage || '-'}%) than
                last year`}
              </Typography>
              <LineAreaChart
                xLabelList={getLearningStatisticData().labelList}
                yCountList={getLearningStatisticData().dataList}
                unit="บทเรียน"
                color={theme.palette.primary.main}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3.8}
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '16px',
                boxShadow: '0px 16px 32px -4px #0000003D',
              }}
            >
              <Typography
                variant="h6"
                sx={{ padding: '23px', marginBottom: '20px' }}
              >
                หมวดหมู่ที่สนใจ
              </Typography>
              <Box sx={{ paddingBottom: '23px' }}>
                {getCatInterestStatisticData().dataList.length ? (
                  <PieChart
                    dataList={getCatInterestStatisticData().dataList}
                    labelList={getCatInterestStatisticData().labelList}
                  />
                ) : (
                  <Typography sx={{ textAlign: 'center' }}>
                    ยังไม่มีข้อมูล
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
