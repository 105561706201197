import { createSlice } from '@reduxjs/toolkit';
import { ProfileDataType, ProfileDevlabStatType } from '../../@types/profile';
import { ProjectGalleryType } from '../../@types/projectGallery';

type InitialStateType = {
  loading: boolean;
  profileLoading: boolean;
  profileData: ProfileDataType | null;
  devlabStatistic: ProfileDevlabStatType | null;
  userProject: ProjectGalleryType | null;
};

const initialState: InitialStateType = {
  loading: true,
  profileLoading: true,
  profileData: null,
  devlabStatistic: null,
  userProject: null,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileLoading(state, actions) {
      state.profileLoading = actions.payload;
    },
    setLoading(state, actions) {
      state.loading = actions.payload;
    },
    setProfileData(state, actions) {
      state.profileData = actions.payload;
    },
    setDevlabStatistic(state, actions) {
      state.devlabStatistic = actions.payload;
    },
    setUserProfile(state, actions) {
      state.userProject = actions.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setProfileLoading,
  setLoading,
  setProfileData,
  setDevlabStatistic,
  setUserProfile,
} = slice.actions;
