import { useEffect } from 'react';
import { dispatch } from '../../redux/store';
import { setActive } from '../../redux/slices/device';
import { setAuthSocketConnected } from '../../redux/slices/socket';
import io, { Socket } from 'socket.io-client';
import useAuth from '../../hooks/useAuth';

function DeviceLimitSocketController() {
  const { isAuthenticated } = useAuth();

  const authSocket: Socket = io(
    (window.__RUNTIME_CONFIG__.REACT_APP_HOST_SOCKET_KEY || '') + 'socket-auth',
    {
      path: window.__RUNTIME_CONFIG__.REACT_APP_HOST_SOCKET_PATH_PREFIX || '',
      withCredentials: true,
    },
  );

  useEffect(() => {
    if (authSocket) {
      authSocket.on('update_status', (data: any) => {
        dispatch(setActive(data.active));
      });
      authSocket.on('logout', (data: any) => {
        window.location.href = '/error?code=device';
      });

      dispatch(setAuthSocketConnected(true));
    }
  }, [authSocket, dispatch]);

  const disconnectSocket = () => {
    if (authSocket) {
      authSocket.disconnect();
      dispatch(setAuthSocketConnected(false));
    }
  };

  useEffect(() => {
    !isAuthenticated && disconnectSocket();
    return () => {
      disconnectSocket();
    };
  }, [isAuthenticated]);

  return null;
}

export default DeviceLimitSocketController;
