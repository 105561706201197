import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { HEADER, NAVBAR } from '../../../config';
import { Box, Typography, Badge, Container } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Image from '../../../components/Image';


export default function HeadNav() {

  return (
    <HeadNavStyle>
      <Container sx={{padding: '20px', height: '100%'}}>
        {/* <Notifications /> */}
        <Box
          sx={{
            maxWidth:'1062.38px',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-between',
            '@media (min-width: 1200px)': {
              maxWidth:'1280px'
            },
          }}>
          <Box sx={{display: 'flex', gap: '20px'}}>
            <Box component={RouterLink} to={'/'}>
              <Image visibleByDefault alt='logo' src="/logos/school-full-225x72.png" sx={{width: '175px'}}/>
            </Box>
            <RedirectMenu />
          </Box>
        </Box>
      </Container>
    </HeadNavStyle>
  )
}

function RedirectMenu(){
  const theme = useTheme()
  const {courseSlug} = useParams()
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
      {menuList.map((menu, index) =>
        menu.external
        ?
        <a key={`menu-${index}`} style={{textDecoration: 'none'}} href={menu.link}>
          <Box
            sx={{display: 'flex',gap: '5px', color: theme.palette.text.primary, ':hover': {color: 'primary.main'}}}>
            <Iconify icon={menu.icon} sx={{width: '24px', height: '24px'}} />
            <Typography variant="body1">{menu.title}</Typography>
          </Box>
        </a>
        :
        <Box
          key={`menu-${index}`}
          component={RouterLink}
          to={menu.title === 'ข้อมูลหลักสูตร' || menu.title === 'บทเรียน' ? `/course/${courseSlug}` : menu.link}
          sx={{
            display: 'flex',
            gap: '5px',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            ':hover': {color: 'primary.main'}
          }}
        >
          <Iconify icon={menu.icon} sx={{width: '24px', height: '24px'}} />
          <Typography>{menu.title}</Typography>
        </Box>)
      }
    </Box>
  )
}

function Notifications() {
  return (
    <Box sx={{position: 'relative', height: '0px'}}>
      <Box sx={{position: 'absolute', right: '0', top: '12px'}}>
        <Badge badgeContent={8} color='error'>
          <Iconify icon='eva:bell-fill' sx={{width: '24px', height: '24px'}} />
        </Badge>
      </Box>
    </Box>

  )
}

const HeadNavStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
  '@media (max-width: 1200px)': {
    width: '100%',
    display: 'none'
  },
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  boxShadow: '0px 2px 20px -5px rgba(0,0,0,0.30)'
}))

const menuList = [
  {
    icon: 'fluent:document-16-filled',
    title: 'ข้อมูลหลักสูตร',
    link: '/course',
    external: false
  },
  {
    icon: 'jam:messages-f',
    title: 'คอมมูนิตี้',
    link: 'https://www.facebook.com/groups/2425184314214694/',
    external: true
  },
  {
    icon: 'bxs:folder',
    title: 'โปรเจกต์ทั้งหมด',
    link: '/devproject?sortBy=submittedAt&sortOrder=desc',
    external: false
  }
]
