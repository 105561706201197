import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../Iconify';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import axios from '../../utils/axios';
import { themeList } from '../../theme';
import { ThemeContext } from '../../theme';
import { setConsentOpen, setBornbitOpen } from '../../redux/slices/config';
import Notification from '../notification';
import { useDispatch } from 'react-redux';
import { BB_OPEN } from '../../config';

export default function UserCard() {
  const { user, isAuthenticated, isInitialized } = useAuth();
  return (
    <Box>
      {!isInitialized ? (
        <></>
      ) : isAuthenticated ? (
        <ProfileCard image={user?.imageUrl} name={`${user?.publicName}`} />
      ) : (
        <Button
          color="primary"
          component={RouterLink}
          to={'/login'}
          variant="contained"
          sx={{ width: '100%', color: 'text.button' }}
        >
          <Iconify
            icon="ic:twotone-login"
            sx={{ width: '24px', height: '24px', marginRight: '10px' }}
          />
          <Typography variant="body1">เข้าสู่ระบบ</Typography>
        </Button>
      )}
    </Box>
  );
}

function ProfileCard({ image, name }: { image: string; name: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box component={RouterLink} to={'/profile'}>
          <Avatar src={image} alt="user-image" />
        </Box>
        <Box sx={{ ml: 2, maxWidth: '140px' }}>
          <RouterLink to="/profile" style={{ textDecoration: 'none' }}>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.primary',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ':hover': { color: 'primary.main' },
              }}
            >
              {name}
            </Typography>
          </RouterLink>
          <UserMenu />
        </Box>
      </Box>
      <Notification />
    </Box>
  );
}

function UserMenu() {
  const theme = useTheme();
  const { setNewPalette } = useContext(ThemeContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function userLogout() {
    await localStorage.removeItem('bb-check-in');
    await axios.post('/auth/logout');
    navigate('/');
    logout();
  }

  function changeMode(themeColor: string) {
    const newTheme = themeList.includes(themeColor) ? themeColor : 'dark';
    window.localStorage.setItem('theme-mode', newTheme);

    setNewPalette({ palette: { mode: newTheme }, typography: {} });
    handleClose();
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function openPrivacySetting() {
    dispatch(setConsentOpen(true));
    handleClose();
  }

  function openBornbitSidebar() {
    dispatch(setBornbitOpen(true));
    handleClose();
  }

  const menuConfig = {
    BORNBITS: {
      onClick: openBornbitSidebar,
      styles: { color: 'primary.main' },
      icon: 'mdi:alpha-b-box-outline',
      label: 'Bornbits',
    },
    THEME: {
      onClick: () =>
        changeMode(theme.palette.mode === 'dark' ? 'light' : 'dark'),
      styles: {},
      icon: 'jam:refresh-reverse',
      label: 'สลับธีม',
    },
    TRANSFER: {
      onClick: handleClose,
      styles: {},
      icon: 'mdi:file-transfer-outline',
      label: 'โอนย้ายข้อมูล',
      link: '/event/transfer',
    },
    SUBSCRIPTION: {
      onClick: handleClose,
      styles: { color: 'info.light' },
      icon: 'icon-park-solid:vip-one',
      label: 'Borntodev+',
      link: '/subscription',
    },
    DEVICES: {
      onClick: handleClose,
      styles: {},
      icon: 'ri:device-fill',
      label: 'อุปกรณ์ที่เชื่อมต่อ',
      link: '/setting/devices',
    },
    PRIVACY: {
      onClick: openPrivacySetting,
      styles: {},
      icon: 'fluent:person-lock-16-filled',
      label: 'ความเป็นส่วนตัว',
    },
    LOGOUT: {
      onClick: userLogout,
      styles: { ':hover': { color: 'error.main' } },
      icon: 'material-symbols:logout-rounded',
      label: 'ออกจากระบบ',
    },
  };

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          color: 'text.secondary',
        }}
        onClick={handleClick}
      >
        <Iconify
          icon="icon-park-solid:all-application"
          width={15}
          height={15}
          sx={{ transform: 'rotate(45deg)' }}
        />
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          เพิ่มเติม
        </Typography>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={RouterLink}
          to="/subscription"
          onClick={() => handleClose()}
          sx={{ color: 'info.light' }}
        >
          <Iconify
            icon="icon-park-solid:vip-one"
            width={20}
            height={20}
            sx={{ marginRight: '5px' }}
          />
          borntoDev+
        </MenuItem>
        <MenuItem
          onClick={() =>
            changeMode(theme.palette.mode === 'dark' ? 'light' : 'dark')
          }
        >
          <Iconify
            icon="jam:refresh-reverse"
            width={20}
            height={20}
            sx={{ marginRight: '5px' }}
          />
          สลับธีม
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/event/transfer"
          onClick={() => handleClose()}
        >
          <Iconify
            icon="mdi:file-transfer-outline"
            width={20}
            height={20}
            sx={{ marginRight: '5px' }}
          />
          โอนย้ายข้อมูล
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/setting/devices"
          onClick={() => handleClose()}
        >
          <Iconify
            icon="ri:device-fill"
            width={20}
            height={20}
            sx={{ marginRight: '5px' }}
          />
          อุปกรณ์ที่เชื่อมต่อ
        </MenuItem>
        <MenuItem onClick={() => openPrivacySetting()}>
          <Iconify
            icon="fluent:person-lock-16-filled"
            width={20}
            height={20}
            sx={{ marginRight: '5px' }}
          />
          ความเป็นส่วนตัว
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => userLogout()}
          sx={{ ':hover': { color: 'error.main' } }}
        >
          <Iconify
            icon="material-symbols:logout-rounded"
            width={20}
            height={20}
            sx={{ marginRight: '5px' }}
          />
          ออกจากระบบ
        </MenuItem>
      </Menu>
    </>
  );
}

interface UserMenuItemType {
  onClick: Function;
  styles: object;
  icon: string;
  label: string;
  link?: string;
}
