import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ProfileDetailProps } from '../profile/ProfileDetailTooltip';
import { LeaderboardUserType } from './type';

export const ContentCardStyle = styled(Card)(({ theme }) => ({
  backgroundColor: 'background.paper',
  color: 'text.primary',
  padding: '38px',
  borderRadius: '8px',
  maxWidth: '1062.38px',
  '@media (min-width: 2000px)': {
    maxWidth:'1280px'
  },
}));

export function getProfileDetailData(data: LeaderboardUserType) : ProfileDetailProps {
  const dataConverted = {
    id: data.id,
    imageUrl: data.image,
    publicName: data.publicName,
    anonymous: false,
    role: 'STUDENT',
    experience: data.experience,
    target: data.target
  } as ProfileDetailProps
  return dataConverted
}