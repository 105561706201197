import React, { useState, useMemo } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import Icon from '../../components/Iconify';

interface ShareButtonListProps {
  certId: string;
}

interface ShareButtonIconProps {
  icon: string;
  color?: string;
  onClick?: () => void;
}

const shareIconList = {
  ['clipboard']: {
    icon: 'entypo:link',
    color: '',
  },
  ['facebook']: {
    icon: 'ri:facebook-fill',
    color: '#1877F2',
  },
  ['instagram']: {
    icon: 'uil:instagram-alt',
    color: '#E02D69',
  },
  ['linkedin']: {
    icon: 'logos:linkedin-icon',
    color: '',
  },
  ['x']: {
    icon: 'pajamas:twitter',
    color: '',
  },
  ['email']: {
    icon: 'flat-color-icons:google',
    color: '',
  },
};

function ShareButtonIcon({ icon, color, onClick }: ShareButtonIconProps) {
  return (
    <Icon
      icon={icon}
      sx={{
        minWidth: '24px',
        minHeight: '24px',
        cursor: 'pointer',
        ...(color ? { color: color } : {}),
      }}
      onClick={onClick}
    />
  );
}

export default function ShareButtonList({ certId }: ShareButtonListProps) {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const shareURL = useMemo<string>(
    () => `${window.location.origin}/certificate/event/${certId}`,
    [certId],
  );

  const copyClipBoard = (copyText: string) => {
    navigator.clipboard.writeText(copyText).then(() => {
      setShowToolTip(true);
      setTimeout(() => {
        setShowToolTip(false);
      }, 1000);
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ flexWrap: 'wrap', gap: '15px' }}
    >
      <Tooltip
        arrow
        open={showToolTip}
        onClose={() => setShowToolTip(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="bottom"
        title="Copy link to clipboard"
      >
        <Box>
          <ShareButtonIcon
            icon={shareIconList.clipboard.icon}
            onClick={() => copyClipBoard(shareURL)}
          />
        </Box>
      </Tooltip>

      <FacebookShareButton url={shareURL}>
        <ShareButtonIcon
          icon={shareIconList.facebook.icon}
          color={shareIconList.facebook.color}
        />
      </FacebookShareButton>
      {/* <InstapaperShareButton url={shareURL}>
        <ShareButtonIcon
          icon={shareIconList.instagram.icon}
          color={shareIconList.instagram.color}
        />
      </InstapaperShareButton> */}
      <LinkedinShareButton url={shareURL}>
        <ShareButtonIcon icon={shareIconList.linkedin.icon} />
      </LinkedinShareButton>
      <TwitterShareButton url={shareURL}>
        <ShareButtonIcon icon={shareIconList.x.icon} />
      </TwitterShareButton>
      {/* <EmailShareButton url={shareURL}>
        <ShareButtonIcon icon={shareIconList.email.icon} />
      </EmailShareButton> */}
    </Stack>
  );
}
