import React, {useCallback, useEffect, useState} from 'react';
import { Box, Typography, Button } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles'
import { profileCoverImageType } from '../../../@types/profile';

import EditorHeader from './EditorHeader';
import Image from '../../../components/Image';
import { UploadSingleFile } from '../../../components/upload';

import { CustomFile } from '../../../components/upload';
import Iconify from '../../../components/Iconify';

import axiosInstance from '../../../utils/axios';

interface ProfileCoverProps {
  coverImage: profileCoverImageType | null
}

export default function CoverImageEditor({coverImage}: ProfileCoverProps) {
  const theme = useTheme()

  const [image, setImage] = useState<CustomFile | null | string>(null)
  const [initStatus, setInitStatus] = useState(true)

  const handleWorkImagesDrop = useCallback(
    (acceptedFiles: any, preview: any) => {
      setImage(acceptedFiles[0])
      setInitStatus(false)
    },
    [setImage]
  );

  const handleRemoveImage = () => {
    if (image) {
      setImage(null);
      setInitStatus(false)
    }
  }

  useEffect(() => {
    coverImage && setImage(coverImage.url)
  }, [])

  async function onSave() {
    const form = new FormData()
    image && form.append('image', image)

    await axiosInstance.put('/users/profile/cover-image', form)
    setInitStatus(true)
  }

  return (
    <Box>
      <EditorHeader title={'รูปปก'} onSubmit={onSave} disabled={initStatus}/>
      <Button onClick={handleRemoveImage} startIcon={<Iconify icon={'ic:round-disabled-by-default'}/>} sx={{marginY: '10px'}}>กลับสู่รูป Default</Button>
      <UploadSingleFile
        accept={{ 'image/*': ['.jpeg', '.jpg', '.png', '.webp'] }}
        file={image}
        error={false}
        maxSize={2097152}
        onDrop={handleWorkImagesDrop}
        component={
          <Box sx={{position: 'relative'}}>
            <Box sx={{position: 'absolute', background: alpha(theme.palette.background.default, 0.5), width: '100%', height: '100%', padding: '10px'}}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  borderStyle: 'dashed',
                  border: `dashed 3px ${theme.palette.text.primary}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Iconify sx={{width: '50px', height: '50px'}} icon={'material-symbols:drive-folder-upload'}/>
                <Typography variant='h4'>ลากหรือคลิกเพื่อเปลี่ยนรูปปก</Typography>
              </Box>
            </Box>
            <Image
              src={image ? typeof image !== 'string' ? URL.createObjectURL(image) : image : '/images/orange-bg.png'}
              sx={{
                width: '100%',
                height: '300px',
              }}
            />
          </Box>
        }
    />
      <Button sx={{width: '100%'}}>

      </Button>
    </Box>
  )
}
