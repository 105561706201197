export const initialProjectDetail = {
  id: '',
  userId: '',
  projectId: '',
  name: '',
  description: '',
  youtubeLink: '',
  worksLink: '',
  coverImage: '',
  worksImages: [''],
  favorite: 0,
  view: 0,
  isFavorite: 0,
  isSave: 0,
  checkedAt: new Date(),
  user: {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    publicName: '',
    bio: '',
    role: '',
    imageUrl: ''
  },
  project: {
    course: {
      id: '',
      title: '',
      slug: ''
    }
  },
  anonymous: false
}

export const initialGitParams = {
  username: '',
  repository: ''
}
