import { langs } from '@uiw/codemirror-extensions-langs'

export const languageConfig: any = {
  javascript: {
    code: '// This is a source code.',
    language: langs.javascript()
  },
  python: {
    code: '# This is a source code.',
    language: langs.python()
  },
  java: {
    code: '// This is a source code.',
    language: langs.java()
  },
  cpp: {
    code: '// This is a source code.',
    language: langs.cpp()
  },
  csharp: {
    code: '// This is a source code.',
    language: langs.csharp(),
  },
  c: {
    code: '// This is a source code.',
    language: langs.c()
  },
  go: {
    code: '// This is a source code.',
    language: langs.go()
  },
  kotlin: {
    code: '// This is a source code.',
    language: langs.kotlin()
  },
  ruby: {
    code: '# This is a source code.',
    language: langs.ruby()
  },
  swift: {
    code: '# This is a source code.',
    language: langs.swift()
  }
}
