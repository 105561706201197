import React, { useState, useEffect } from 'react'
import { Box, Stack, Typography, Button, Divider, CircularProgress } from '@mui/material'
import ReactQuill from 'react-quill'

import { CommentDetailType, CustomFile, ImageCommentType } from '../../@types/comments'
import EditorBodyStyle from '../editor/EditorBody'
import ImageEditor from './ImageEditor'

interface CommentEditorProps {
  error: boolean
  onError: Function
  data: CommentDetailType
  typeEditor: string
  open: boolean
  submitButton: string
  onCancel: Function
  onSubmit: Function
}

const toolbarOptions = [
  [{ 'header': [1, 2, 3, false] }, {'align': [false, 'center', 'right']}],
  ['bold', 'italic', 'underline', 'code-block'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }], ['clean']
];

const formats = [
  'bold',
  'align',
  'italic',
  'size',
  'strike',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'code-block',
];

function CommentEditor({ error, onError, data, typeEditor, open, submitButton, onCancel, onSubmit }: CommentEditorProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [contentText, setContentText] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [imagesPath, setImagePath] = useState<ImageCommentType[]>(typeEditor === 'edit' ? data.commentImages || [] : [])
  const [images, setImages] = useState<CustomFile[]>([])
  const [imageOrder, setImageOrder] = useState<number[]>([])

  const handleText = (text: string): string => {
    try {
      return JSON.parse(text)
    } catch {
      return text
    }
  }

  const onReset = () => {
    setContent('')
    setImagePath([])
    setImages([])
    setImageOrder([])
  }

  const handleSubmission = async () => {
    setLoading(true)
    const form = new FormData()

    let submissionData: any = {
      text: content,
      images: images,
      imagesOrder: JSON.stringify(imageOrder),
      imagePath: JSON.stringify(imagesPath),
    }

    if (typeEditor === 'reply') {
      submissionData = await {...submissionData, replyFrom: data.id}
    }

    await Object.keys(submissionData).forEach((key: string) => {
      if (Array.isArray(submissionData[key])) {
        if (submissionData[key].length) {
          submissionData[key].forEach((value: any) => {
            if (key === 'images') form.append(`${key}[]`, value)
            else form.append(key, JSON.stringify(value))
          })
        }
      } else {
        form.append(key, submissionData[key])
      }
    })

    await onSubmit(form)
    await setLoading(false)
  }

  useEffect(() => {
    if (typeEditor === 'edit') {
      setContent(data.text)
      setImagePath(data.commentImages)
    }
    return
  }, [open])

  return (
    <Box>
      <EditorBodyStyle>
        <ReactQuill
          formats={formats}
          placeholder={typeEditor === 'reply' ? 'ตอบกลับความคิดเห็น....' : 'แสดงความคิดเห็น....'}
          value={handleText(content)}
          readOnly={false}
          modules={{toolbar: toolbarOptions, syntax: true}}
          theme={"snow"}
          style={{borderRadius: '10px'}}
          onChange={(content: string, _1, _2, editor: ReactQuill.UnprivilegedEditor) => {
            if (content !== '') onError(false)
            setContent(JSON.stringify(editor.getContents()))
            setContentText(editor.getText())
          }}
        />
      </EditorBodyStyle>

      {error && (
        <Typography
          variant="caption"
          sx={{ marginTop: '10px', color: 'error.main' }}
        >
          กรุณาใส่ข้อความก่อนเเสดงความคิดเห็น
        </Typography>
      )}

      <ImageEditor
        files={images}
        fileOrder={imageOrder}
        uploaded={imagesPath}
        onReturnValue={(type: string, value: CustomFile[] | number[] | ImageCommentType[]) => {
          if (type === 'files') setImages(value as CustomFile[])
          else if (type === 'fileOrder') setImageOrder(value as number[])
          else if (type === 'uploaded') setImagePath(value as ImageCommentType[])
        }}
      />

      <Divider sx={{ marginY: '20px' }} />

      {loading && (
        <Stack direction="row" justifyContent="end">
          <Stack direction="row" alignItems="center" gap="5px">
            <CircularProgress color="inherit" size="10px"/>
            <Typography variant="caption">กำลังส่งข้อมูล</Typography>
          </Stack>
        </Stack>
      )}

      <Stack direction="row" justifyContent="end" sx={{ marginTop: '10px', gap: '5px' }}>
        <Button
          variant="outlined"
          color="inherit"
          sx={{ width: '80px', borderRadius: '5px' }}
          onClick={() => {
            onCancel()
            onReset()
            onError()
          }}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={contentText.trim() === '' || loading}
          sx={{ width: '100px', borderRadius: '5px' }}
          onClick={() => !loading && handleSubmission()}
        >
          {submitButton}
        </Button>
      </Stack>
    </Box>
  )
}

export default CommentEditor
