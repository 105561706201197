import React from 'react';
import QRCode from 'qrcode.react';

import './certificateStyle.css';
import { OfflineCertificate } from '../../../@types/certificate';

interface CertificateProps {
  certificate?: OfflineCertificate;
}

const dateFormat = (
  startDate?: Date | string | null,
  endDate?: Date | string | null,
): string => {
  const startDateFormatted = new Date(startDate ? startDate : '');
  const endDateFormatted = new Date(endDate ? endDate : '');
  const month: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  return `( ${
    month[startDateFormatted.getMonth()]
  } ${startDateFormatted.getDate()}, ${startDateFormatted.getFullYear()} ${
    endDate
      ? ` - ${
          month[endDateFormatted.getMonth()]
        } ${endDateFormatted.getDate()}, ${endDateFormatted.getFullYear()}`
      : ''
  } )`;
};

const prePath = '';

export default function CertificatePreview({ certificate }: CertificateProps) {
  const logo = '/certificate_images/logo.png';
  const config = certificateConfigs['NORMAL'];

  return (
    <div
      style={{
        backgroundColor: config.backgroundColor,
        maxHeight: config.maxHeight,
        color: config.textColor,
        padding: config.padding,
      }}
      className="certificate-wrap"
      id="section-to-print"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div>
          {/* title section */}
          <div className="cert-title-section">
            <img className="logo" src={prePath + logo} alt="logo" />
            {config.titleComponent}
          </div>
          {/* middle section */}
          <div className="cert-coursedetail-section">
            <div
              className="coursedetail-description1"
              style={{ color: config.textColor }}
            >
              This is to certify that
            </div>
            <div className="coursedetail-name">
              <p>{certificate?.recipientName}</p>
            </div>
            <div className="coursedetail-description2">
              <span>and satisfied The requirements by passing The</span>
            </div>
            <div className="coursedetail-coursename">
              <p className="course-name">{certificate?.course.title}</p>
              <p className="course-date">
                {dateFormat(
                  certificate?.course.fromDate,
                  certificate?.course.toDate,
                )}
              </p>
            </div>
          </div>
        </div>
        {/* lower section */}
        <div className="cert-approval-section">
          {/* ceo signature */}
          {certificate?.course.instructor.signatureBlack ? (
            <div className="signature-1">
              <img
                className="signature"
                src={certificate?.course.instructor.signatureBlack || ''}
                alt="signature1"
              />
              <div
                style={{
                  lineHeight: '16px',
                  color: config.textColor,
                }}
              >
                <p style={{ margin: '0' }}>
                  {certificate?.course.instructor?.publicName}
                </p>
                <p style={{ margin: '0', fontWeight: 'normal' }}>
                  ผู้ดำเนินการสอนในหลักสูตรดังกล่าว
                </p>
                <p style={{ margin: '0', fontWeight: 'normal' }}>
                  {'ภายใต้การดูแลของบริษัท บอร์นทูเดฟ จำกัด'}
                </p>
              </div>
            </div>
          ) : (
            <div className="signature-1">
              <img
                className="signature"
                src={prePath + config.ceoSignature}
                alt="signature1"
              />
              <div
                style={{
                  lineHeight: '16px',
                  color: config.textColor,
                }}
              >
                <p style={{ margin: '0' }}>KITTIKORN PRASERTSAK</p>
                <p style={{ margin: '0' }}>MANAGING DIRECTOR</p>
                <p style={{ margin: '0' }}>borntoDev Co., Ltd.</p>
              </div>
            </div>
          )}
          {/* qr, supporter section */}
          <div className="verification-section">
            <div style={{ bottom: config.qrMargin }}>
              {certificate?.sponsorLogo && (
                <div style={{ color: config.textColor, marginBottom: '40px' }}>
                  <p>Supported / Co-created with</p>
                  <img
                    src={certificate?.sponsorLogo}
                    alt="sponsor"
                    style={{
                      maxWidth: '200px',
                      maxHeight: '60px',
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  gap: '10px',
                }}
              >
                <div>
                  <p style={{ fontWeight: 'normal', color: config.textColor }}>
                    Valid Certificate ID
                  </p>
                  <p style={{ color: '#ffbe01', marginBottom: '5px' }}>
                    <b>{certificate?.id}</b>
                  </p>
                </div>
                <div className="qr-img">
                  <QRCode
                    value={`${window.location.origin}/certificate/event/${certificate?.id}`}
                    level="H"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cert-footer">
          <div className="footer-logo">
            <img
              alt=""
              src={prePath + '/certificate_images/footer_logo.png'}
              style={{ width: '100px', marginLeft: '1rem' }}
            />
          </div>
          <div className="footer-tagline">
            <p style={{ margin: '0' }}>
              เรียนรู้ไอที “อัพสกิลเขียนโปรแกรม” จากตัวจริง ปั้นให้คุณเป็น
              คนสายไอทีระดับมืออาชีพ
            </p>
          </div>
        </div>
      </div>

      <div
        className="bg-bit_topRight"
        style={{
          backgroundImage: `url(${prePath + config.imageDecoration})`,
          top: '-34px',
          right: '-34px',
        }}
      ></div>
      <div
        className="bg-bit_bottomLeft"
        style={{
          backgroundImage: `url(${prePath + config.imageDecoration})`,
          bottom: '-40px',
          left: '-40px',
        }}
      ></div>
      {config.specialImage}
    </div>
  );
}

const openTitle = (
  <div className="cert-title" style={{ color: 'inherit' }}>
    <h4
      className="header-title"
      style={{
        textTransform: 'uppercase',
        fontSize: '30px',
        margin: 0,
      }}
    >
      CERTIFICATE OF ACHIEVEMENT
    </h4>
  </div>
);

const certificateConfigs = {
  NORMAL: {
    backgroundColor: 'white',
    maxHeight: '600px',
    padding: '48px 50px 80px 60px',
    qrMargin: '0px',
    textColor: '#484848',
    ceoSignature: '/certificate_images/signature1.png',
    imageDecoration: '/certificate_images/bit.png',
    specialImage: null,
    titleComponent: openTitle,
  },
};
