import React, { useEffect, useState } from 'react';
import { courseDataType } from '../../../@types/courseData';
import axios from '../../../utils/axios';
import ResultAlert from '../../../components/ResultAlert'
import { Box, Typography, Button } from '@mui/material';
import { useParams, Link } from 'react-router-dom'

export default function PreviewAlert() {
  const {courseSlug} = useParams()
  const [courseData, setCourseData] = useState<courseDataType>()
  useEffect(() => {
    init()
  }, [])
  async function init() {
    const response = await axios.get(`/courses/${courseSlug}`)
    setCourseData(response.data)
  }

  function getChapter() {
    return courseData ? courseData.sections.reduce((prev, curr) => prev+curr.lessons.length, 0) : 0
  }

  return (
    <Box sx={{marginBottom: '20px'}}>
      {courseData &&
        <ResultAlert
            severity='success'
            content={
            <Box>
              <Typography variant="subtitle2">ตอนนี้คุณกำลังทดลองเรียนฟรี ในหลักสูตร {courseData.title}</Typography>
              <Box>
              <Typography variant="body2">หากต้องการเรียนแบบจุใจ จัดเต็มกว่า {getChapter()} ตอน {courseData.durationText ? `(ความยาว ${courseData.durationText})` : ''} สามารถลงทะเบียนหลักสูตรนี้ได้เลย </Typography>
              <Button
                color='success'
                component={Link}
                to={`/course/${courseSlug}`}
                sx={{
                  float: 'right',
                  color: 'success.darker',
                  backgroundColor: 'success.light',
                  ':hover': {
                    color: 'success.darker',
                    backgroundColor: 'success.main',
                  }
                }}
                variant='contained'>
                  [ลงทะเบียน]
                </Button>
              </Box>
            </Box>
            }
        />
      }
    </Box>
  )
}
