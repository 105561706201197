import { Box, Button, ButtonGroup, MenuItem, Select, Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import axios from '../../utils/axios';
import { LeaderboardCategoryType } from './type';

export function LeaderboardFilter({applyFilter}: {applyFilter: Function}) {
  const [selectedCategory, setSelectedCategory] = useState('ALL')
  const [selectedDuration, setSelectedDuration] = useState('ALL')

  useEffect(() => {
    applyFilter(selectedCategory, selectedDuration)
  }, [selectedCategory, selectedDuration])

  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: '20px', flexDirection: 'column'}}>
      <CategoriesFilter
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <DurationFilter
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
      />
    </Box>
  )
}

interface DurationFilterProps {
  selectedDuration: string
  setSelectedDuration: Function
}

function DurationFilter({selectedDuration, setSelectedDuration}: DurationFilterProps) {
  const durationFilters = ['ALL', 'DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']
  return (
    <Box>
      <Box 
        sx={{
          display: 'flex', 
          gap: '10px', 
          alignItems: 'center', 
          justifyContent: 'center',
          '@media (min-width: 500px)': {
            display: 'none',
          },
        }}
      >
        <Typography>Duration: </Typography>
        <Select value={selectedDuration} onChange={(e) => setSelectedDuration(e.target.value)} sx={{width: '150px'}}>
          <MenuItem value={'ALL'}>ALL</MenuItem>
          {
            durationFilters.map((duration) => 
              <MenuItem key={'leaderboard-duration-'+duration} value={duration}>{duration}</MenuItem>
            )
          }
        </Select>
      </Box>
    
      <ButtonGroup 
        color="inherit"
        sx={{
          '@media (max-width: 500px)': {
            display: 'none',
          },
        }}
      >
        {
          durationFilters.map((duration) => (
            <Button
              variant={selectedDuration === duration ? 'contained' : 'outlined'}
              key={'leaderboard-duration-'+duration} 
              onClick={() => setSelectedDuration(duration)}
              color={'primary'}
            >
              {duration}
            </Button>
          ))
        }
      </ButtonGroup>
    </Box>
  )
}

interface CategoriesFilterProps {
  selectedCategory: string
  setSelectedCategory: Function
}

function CategoriesFilter({selectedCategory, setSelectedCategory}: CategoriesFilterProps) {
  const [categories, setCategories] = useState<LeaderboardCategoryType[]>([] as LeaderboardCategoryType[])
  const getLeaderboardCategories = async () => {
    try {
      const response = await axios.get(`/bornbits/leaderboard/categories`)
      const data = await response.data
      setCategories(data || [])
    } catch (error) {
    }
  };

  useEffect(() => {
    getLeaderboardCategories()
  }, [])
  return (
    <Box sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
      <Typography variant='subtitle1'>Category: </Typography>
      <Select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} sx={{width: '150px'}}>
        <MenuItem value={'ALL'}>ALL</MenuItem>
        {
          categories.map((category) => 
            <MenuItem key={'leaderboard-category-'+category.id} value={category.title}>{category.title}</MenuItem>
          )
        }
      </Select>
    </Box>
  )
}