import React, { useEffect } from 'react';
import { Box, Container, Button, Typography, keyframes } from '@mui/material';
import Image from '../components/Image';
import { useSearchParams } from 'react-router-dom';
import { HOST_API } from '../config';
import useAuth from '../hooks/useAuth';
import Iconify from '../components/Iconify';

export default function LoginPage() {
  const { isAuthenticated, isInitialized } = useAuth();
  const [searchParams] = useSearchParams();
  const disabled =
    searchParams.get('success') === 'true' &&
    !(isInitialized && !isAuthenticated);

  const login = () => {
    setRedirectUrl();
    window.location.href = `${HOST_API}auth/borntodev/login`;
  };

  useEffect((): any => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'auto');
  }, []);

  const setRedirectUrl = () => {
    const redirectUrl = getRedirectByParams();
    if (redirectUrl) window.localStorage.setItem('redirectUrl', redirectUrl);
  };

  const getRedirectByParams = () => {
    const redirectUrlParam = searchParams.get('redirectUrl');
    const eventParam = searchParams.get('event');
    if (redirectUrlParam !== null) return redirectUrlParam;
    else if (eventParam === 'transfer') return '/event/transfer';
    else return '/profile?target=continueLearning';
  };

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = window.localStorage.getItem('redirectUrl');
      window.localStorage.removeItem('redirectUrl');
      window.location.replace(
        redirectUrl || '/profile?target=continueLearning',
      );
      document.body.style.overflow = 'auto';
    }
  }, [isAuthenticated]);

  return <StyledLoginPage login={login} disabled={disabled} />;
}

function StyledLoginPage({
  login,
  disabled,
}: {
  login: Function;
  disabled: boolean;
}) {
  const bgImgLocation = 'images/login-bg.jpeg';
  const upDown = keyframes`
  50%{
    transform: translateY(30px);`;
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom,rgba(0, 0, 0, 0.5),rgba(0, 0, 0)),url(${bgImgLocation})`,
        backgroundSize: 'cover',
        overflow: 'hidden',
      }}
    >
      <Container
        sx={{
          maxWidth: '983px !important',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            background: 'linear-gradient(135deg, #FFE16A 0%, #B78103 100%)',
            width: '325px',
            height: '325px',
            borderRadius: '100%',
            top: '-50px',
            left: '-150px',
            animation: `${upDown} 5s infinite ease`,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            background: 'linear-gradient(135deg, #FFE16A 0%, #B78103 100%)',
            width: '134px',
            height: '134px',
            borderRadius: '100%',
            bottom: '-30px',
            left: '75px',
            animation: `${upDown} 6s infinite ease`,
          }}
        />
        <Box sx={{ position: 'relative', width: '100%' }}>
          <Box
            sx={{
              position: 'absolute',
              background: 'linear-gradient(135deg, #74CAFF 0%, #0C53B7 100%)',
              width: '207px',
              height: '207px',
              borderRadius: '100%',
              top: '-120px',
              right: '-80px',
              animation: `${upDown} 7s infinite ease`,
            }}
          />
          <Box
            sx={{
              maxWidth: '824px',
              maxHeight: '264px',
              position: 'relative',
              margin: 'auto',
            }}
          >
            <Box
              sx={{
                padding: `${(264 / 824) * 100}% 0px 0px 100%`,
                height: '100%',
              }}
            />
            <Image
              visibleByDefault
              alt="logo"
              src="/logos/school-full-824x624.png"
              sx={{
                top: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                animation: `${upDown} 8s infinite ease`,
              }}
            />
          </Box>

          <Box sx={{ margin: '50px auto', maxWidth: '436px' }}>
            <Button
              variant="contained"
              disabled={disabled}
              sx={{
                width: '100%',
                paddingY: '15px',
                background: disabled
                  ? 'auto'
                  : 'linear-gradient(147.39deg, #FFCC2F 9.49%, #F99E0C 98.97%)',
                boxShadow: '0px 8px 16px rgba(255, 193, 7, 0.24)',
                borderRadius: '8px',
              }}
              onClick={() => login()}
            >
              <Typography variant="h4">
                {disabled ? (
                  <>
                    <Iconify icon="eos-icons:bubble-loading" />{' '}
                  </>
                ) : (
                  <></>
                )}
                เข้าสู่ระบบด้วย borntoDev
              </Typography>
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <a
              style={{ textDecoration: 'none' }}
              href="https://www.borntodev.com/my-account/"
            >
              <Typography
                sx={{
                  color: 'text.primary',
                  textAlign: 'center',
                  ':hover': { color: 'primary.main' },
                }}
                variant="h4"
              >
                สมัครสมาชิก borntoDev
              </Typography>
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
