import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Box, Button, Stack, Grid } from '@mui/material'
import { styled, useTheme } from '@mui/system'
import axios from 'axios'

import Icon from './Iconify'
import { getLinkIcon } from '../pages/projectDetail/projectConfig'

interface PropsRepoCard {
  userName: string,
  repoName: string,
  workLink: string
}

type GitDataDetail = {
  name: string,
  description: string,
  language: string,
  stargazers_count: Number,
  forks: Number,
  html_url: string
}

const intialData = {
  name: '',
  description: '',
  language: '',
  stargazers_count: 0,
  forks: 0,
  html_url: ''
}

const RepoBox = styled(Stack)(({ theme }) => ({
  maxWidth: '500px',
  width: '100%',
  padding: '20px',
  border: '2px solid',
  borderColor: theme.palette.background.neutral,
  borderRadius: '10px',
  gap: '10px',
  cursor: 'pointer',
  transition: '.2s',
  ':hover': {
    borderColor: theme.palette.grey[500_48]
  }
}))

const FlexRowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.secondary
}))

function RepoCard({ userName, repoName, workLink }: PropsRepoCard) {
  const [gitDetail, setGitDetail] = useState<GitDataDetail>(intialData)
  const [fetchError, setFetchError] = useState<Boolean>(true)
  const [languageColor, setLanguageColor] = useState<string>('')

  const fetchGitData = async () => {
    if (!workLink.includes('github.com')) return
    try {
      const link = `https://api.github.com/repos/${userName}/${repoName}`
      const response = await axios.get(link)
      const data = await response.data
      await setGitDetail(data)
      await fetchLanguageColor(data.language)
      setFetchError(false)
    } catch (error) {
    }
  }

  const fetchLanguageColor = async (language: string) => {
    try {
      const response = await axios.get('https://raw.githubusercontent.com/ozh/github-colors/master/colors.json')
      const data = await response.data

      Object.keys(data).forEach(async (key: string, index: number) => {
        if (key.toLowerCase() === language.toLowerCase()) await setLanguageColor(data[key]?.color || '#DBDE5B')

      })
    } catch (error) {
    }
  }

  useEffect(() => {
    fetchGitData()
  }, [userName])

  if (!fetchError) {
    return (
      <Button disableRipple={true} href={gitDetail.html_url} sx={{ ':hover': { backgroundColor: 'transparent' } }}>
        <RepoBox direction="column">
          <FlexRowBox>
            <Icon icon="ant-design:book-filled" sx={{ marginRight: '10px', width: '20px', height: '20px', color: 'text.primary' }} />
            <a
              href={gitDetail.html_url}
              target="_blank"
              rel='noopener noreferrer'
              style={{
                textDecoration: 'none'
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: 'primary.main', cursor: 'pointer', ':hover': { textDecorationLine: 'underline' } }}
              >
                {gitDetail?.name}
              </Typography>
            </a>
          </FlexRowBox>

          <Typography variant="body2" sx={{ color: 'text.primary' }}>{gitDetail?.description}</Typography>

          <Stack direction="row" spacing={3} sx={{flexWrap: 'wrap'}}>
            <FlexRowBox>
              <Box sx={{ backgroundColor: languageColor, marginRight: '10px', width: '20px', height: '20px', borderRadius: '100%' }} />
              <Typography variant="body2">{gitDetail?.language}</Typography>
            </FlexRowBox>
            <FlexRowBox>
              <Icon icon="ic:baseline-grade" sx={{ width: '20px', height: '20px', marginRight: '10px' }} />
              <Typography variant="body2">{gitDetail?.stargazers_count.toString()}</Typography>
            </FlexRowBox>
            <FlexRowBox>
              <Icon icon="ic:twotone-device-hub" sx={{ width: '20px', height: '20px', marginRight: '10px' }} />
              <Typography variant="body2">{gitDetail?.forks.toString()}</Typography>
            </FlexRowBox>
          </Stack>
        </RepoBox>
      </Button>
    )
  }

  return  (
    <>
      <Typography sx={{marginBottom: '5px'}}>ลิงก์ผลงาน:</Typography>
      <a href={workLink.includes('https://') ? workLink : `https://${workLink}`} style={{textDecoration: 'none'}} target='_blank' rel='noreferrer'>
        <Button variant='outlined' sx={{borderRadius: '8px'}}>
          <Icon icon={getLinkIcon(workLink)} sx={{ width: '30px', height: '30px', marginRight: '5px'}}></Icon>
          <Typography variant='h5'>Project</Typography>
        </Button>
      </a>
    </>
  );
}

export default RepoCard
