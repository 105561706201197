import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import html2canvas from 'html2canvas';
import {
  Typography,
  Container,
  Box,
  Stack,
  Grid,
  Avatar,
  Button,
  Skeleton,
  Tooltip,
} from '@mui/material';
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import axiosInstance from '../../utils/axios';
import Icon from '../../components/Iconify';
import { CertificateType } from '../../@types/certificate';
import { OverviewReviewType } from '../../@types/reviewCourse';
import { courseDataType } from '../../@types/courseData';

import CertificateSkeleton from '../../components/skeleton/CertificateSkeleton';
import CertificateCard from './CertificateCard';
import Image from '../../components/Image';
import ReviewRateStar from '../../components/ReviewRateStar';
import HelmetMetaData from '../../components/Helmet';
import GeneralLink from '../../components/link/General';
import TextLink from '../../components/link/Text';

export default function CertificateComponent() {
  const { certificateId } = useParams();
  const navigate = useNavigate();
  const [certificateData, setCertificateData] = useState<CertificateType>();
  const [courseDetailData, setCourseDetailData] = useState<courseDataType>();
  const [courseOverviewRating, setCourseOverviewRating] =
    useState<OverviewReviewType>();

  const certRef = useRef<HTMLElement>(null);
  const [certImage, setCertImage] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(true);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);

  const convertToPng = () => {
    if (certRef.current !== null) {
      html2canvas(certRef.current.children[0] as HTMLElement, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      }).then((canvased) => {
        const image = canvased.toDataURL('png');
        setCertImage(image);
      });
    }
  };

  const printDocument = () => {
    const certElement = document.createElement('a');
    certElement.setAttribute(
      'download',
      `borntodev-academy_${certificateData?.course.title}_certificate.png`,
    );
    certElement.setAttribute('href', certImage);
    certElement.click();
  };

  const copyClipBoard = (copyText: string) => {
    navigator.clipboard.writeText(copyText).then(() => {
      setShowToolTip(true);
      setTimeout(() => {
        setShowToolTip(false);
      }, 1000);
    });
  };

  useEffect(() => {
    if (certificateData !== undefined) convertToPng();
  }, [certificateData, loading]);

  const fetchCertificate = async () => {
    try {
      const response = await axiosInstance(`/certificate/${certificateId}`);
      const data = await response.data;
      await setCertificateData(data);
      return data;
    } catch {
      navigate('/', { replace: true });
    }
  };

  const fetchCourseDetail = async (courseSlug?: string) => {
    try {
      const response = await axiosInstance(`/courses/${courseSlug}`);
      const data = await response.data;
      await setCourseDetailData(data);
    } catch (error) {
      return;
    }
  };

  const fetchOverviewRating = async (courseId?: string) => {
    try {
      const response = await axiosInstance(
        `/courses/${courseId}/review/summary`,
      );
      const data = await response.data;
      await setCourseOverviewRating(data);
    } catch (error) {
      return;
    }
  };

  const initialData = async () => {
    if (certificateData) {
      await Promise.all([
        fetchCourseDetail(certificateData?.course.slug),
        fetchOverviewRating(certificateData?.course.id),
      ]);
      await setLoading(false);
    } else {
      await fetchCertificate();
    }
  };

  useEffect(() => {
    initialData();
  }, [certificateData]);

  return (
    <>
      <HelmetMetaData />
      <Container
        sx={{
          marginY: '58px',
          maxWidth: '1287px',
          '&.MuiContainer-root': { paddingRight: '0px', paddingLeft: '0px' },
        }}
      >
        {!loading ? (
          <Grid container justifyContent="center" spacing="40">
            <Grid item xs={11} md>
              <Stack
                direction="column"
                sx={{
                  boxShadow: '0px 2px 10px',
                  color: 'grey.600',
                  borderRadius: '6px',
                }}
              >
                <Box
                  sx={{
                    height: '25px',
                    width: '100%',
                    backgroundColor: '#36383a',
                    borderStartEndRadius: '6px',
                    borderStartStartRadius: '6px',
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.5}
                    sx={{ height: '100%', marginLeft: '10px' }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'error.main',
                        width: '10px',
                        height: '10px',
                        borderRadius: '100%',
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: 'primary.main',
                        width: '10px',
                        height: '10px',
                        borderRadius: '100%',
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '10px',
                        height: '10px',
                        borderRadius: '100%',
                      }}
                    />
                  </Stack>
                </Box>
                <Box
                  sx={{ width: 0, height: 0, overflow: 'hidden' }}
                  ref={certRef}
                >
                  <CertificateCard certificate={certificateData} />
                </Box>

                {certImage ? (
                  <Image
                    alt="cert-image"
                    src={certImage}
                    sx={{
                      borderEndEndRadius: '6px',
                      borderEndStartRadius: '6px',
                    }}
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    height="420px"
                    sx={{
                      borderEndEndRadius: '6px',
                      borderEndStartRadius: '6px',
                    }}
                  />
                )}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ marginTop: '12px', marginBottom: '12px' }}
              >
                <GeneralLink
                  link={
                    certificateData?.userId
                      ? `/profile/${certificateData?.userId}`
                      : ''
                  }
                >
                  <Avatar
                    alt={'owner-image'}
                    src={certificateData?.user.imageUrl}
                    sx={{
                      width: '48px',
                      height: '48px',
                      marginRight: '16px',
                    }}
                  />
                </GeneralLink>
                <TextLink
                  link={
                    certificateData?.userId
                      ? `/profile/${certificateData?.userId}`
                      : ''
                  }
                  text={`${certificateData?.user.publicName}`}
                  typographyVariant="subtitle1"
                />
              </Stack>

              <Grid container spacing="10px">
                <Grid container item alignItems="center" spacing="10px">
                  <Grid xs={12} item>
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{ width: '100%', borderColor: 'grey.600' }}
                      onClick={() => printDocument()}
                    >
                      <Typography variant="body1">ดาวน์โหลด</Typography>
                      <Icon
                        icon="bxs:download"
                        sx={{
                          marginLeft: '5px',
                          width: '15px',
                          height: '15px',
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item spacing="10px">
                  <Grid xs={5} item>
                    <Typography variant="body1">
                      แชร์ไปให้เพื่อนของคุณตอนนี้
                    </Typography>
                  </Grid>
                  <Grid xs={6} item>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ flexWrap: 'wrap', gap: '24px' }}
                    >
                      <Tooltip
                        arrow
                        open={showToolTip}
                        onClose={() => setShowToolTip(false)}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="bottom"
                        title="Copy link to clipboard"
                      >
                        <Box>
                          <Icon
                            icon="entypo:link"
                            sx={{
                              minWidth: '24px',
                              minHeight: '24px',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              copyClipBoard(
                                `${window.location.origin}/certificate/${certificateId}`,
                              )
                            }
                          />
                        </Box>
                      </Tooltip>

                      <FacebookShareButton
                        url={`${window.location.origin}/certificate/${certificateId}`}
                      >
                        <Icon
                          icon="ri:facebook-fill"
                          sx={{
                            minWidth: '24px',
                            minHeight: '24px',
                            color: '#1877F2',
                            cursor: 'pointer',
                          }}
                        />
                      </FacebookShareButton>
                      {/* <InstapaperShareButton
                        url={`${window.location.origin}/certificate/${certificateId}`}
                      >
                        <Icon
                          icon="uil:instagram-alt"
                          sx={{
                            minWidth: '24px',
                            minHeight: '24px',
                            color: '#E02D69',
                            cursor: 'pointer',
                          }}
                        />
                      </InstapaperShareButton> */}
                      <LinkedinShareButton
                        url={`${window.location.origin}/certificate/${certificateId}`}
                      >
                        <Icon
                          icon="logos:linkedin-icon"
                          sx={{
                            minWidth: '24px',
                            minHeight: '24px',
                            cursor: 'pointer',
                          }}
                        />
                      </LinkedinShareButton>
                      <TwitterShareButton
                        url={`${window.location.origin}/certificate/${certificateId}`}
                      >
                        <Icon
                          icon="pajamas:twitter"
                          sx={{
                            minWidth: '24px',
                            minHeight: '24px',
                            cursor: 'pointer',
                          }}
                        />
                      </TwitterShareButton>
                      {/* <EmailShareButton
                        url={`${window.location.origin}/certificate/${certificateId}`}
                      >
                        <Icon
                          icon="flat-color-icons:google"
                          sx={{
                            minWidth: '24px',
                            minHeight: '24px',
                            cursor: 'pointer',
                          }}
                        />
                      </EmailShareButton> */}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={11} md>
              {courseDetailData?.previewVideo ? (
                <ReactPlayer
                  url={courseDetailData?.previewVideo}
                  controls={true}
                  width="100%"
                  style={{ backgroundColor: 'black' }}
                  playing={true}
                />
              ) : (
                <Box sx={{ paddingTop: '56.25%', position: 'relative' }}>
                  <Image
                    src={courseDetailData?.images?.cover}
                    alt="Course-Image"
                    sx={{
                      borderRadius: '10px',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Box>
              )}

              <Typography
                variant="h4"
                sx={{ marginTop: '13px', marginBottom: '10px' }}
              >
                {courseDetailData?.title}
              </Typography>
              <Typography variant="subtitle1">
                {courseDetailData?.shortDescription}
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                sx={{ marginTop: '32px', marginBottom: '20px' }}
              >
                {Array(5)
                  .fill(0)
                  .map((_, index: number) => (
                    <ReviewRateStar
                      key={`review-icon-${index}`}
                      width="24px"
                      height="24px"
                      display={
                        courseDetailData?.rating
                          ? courseDetailData.rating - index
                          : 0
                      }
                    />
                  ))}

                <Typography
                  variant="body1"
                  sx={{ marginRight: '20px', marginLeft: '14px' }}
                >
                  {courseOverviewRating?.avg}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginRight: '9px' }}
                >{`(${courseOverviewRating?.count} รีวิว)`}</Typography>
                <Typography variant="body1">{`${courseOverviewRating?.student} ผู้เรียน`}</Typography>
              </Stack>

              <Button
                href={`/course/${certificateData?.course.slug}`}
                variant="contained"
                color="primary"
                sx={{
                  marginTop: '15px',
                  width: '100%',
                  height: '48px',
                  color: 'common.black',
                  borderRadius: '8px',
                }}
              >
                <Typography variant="h6">ดูรายละเอียดคอร์ส</Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CertificateSkeleton />
        )}
      </Container>
    </>
  );
}
