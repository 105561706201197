import React from 'react';
import { Box, Typography } from '@mui/material';
import { DeltaStatic } from 'quill';

import RichViewer from '../../../components/editor/RichViewer';

interface BundleDescriptionProps {
  description: string | DeltaStatic;
}

export default function BundleDescription({
  description,
}: BundleDescriptionProps) {
  if (JSON.stringify(description).length > 30) {
    return (
      <Box>
        <Typography variant="h4" sx={{ marginY: '20px' }}>
          เกี่ยวกับ
        </Typography>
        <RichViewer content={description} />
      </Box>
    );
  } else return null;
}
