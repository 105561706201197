import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Stack } from '@mui/material';
import { Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';

import Icon from '../../components/Iconify';
import Image from '../../components/Image';
import HelmetMetaData from '../../components/Helmet';
import CertificateEventSkeleton from '../../components/skeleton/CertificateEventSkeleton';
import { OfflineCertificate } from '../../@types/certificate';
import axiosInstance from '../../utils/axios';

import ShareButtonList from './ShareButtonList';
import CertificateEventCard from './CertificateEventCard';

export default function CertificateEvent() {
  const { certificateEventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const certRef = useRef<HTMLElement>(null);
  const [certImage, setCertImage] = useState<string>('');
  const [certificateData, setCertificateData] = useState<OfflineCertificate>();

  const convertToPng = () => {
    if (certRef.current !== null) {
      html2canvas(certRef.current.children[0] as HTMLElement, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      }).then((canvased) => {
        const image = canvased.toDataURL('png');
        setCertImage(image);
      });
    }
  };

  const printDocument = () => {
    const certElement = document.createElement('a');
    certElement.setAttribute(
      'download',
      `borntodev-academy_${certificateData?.course.title}_certificate.png`,
    );
    certElement.setAttribute('href', certImage);
    certElement.click();
  };

  const getOfflineCertificate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/offline-certificate/${certificateEventId}`,
      );
      const data = await response.data;
      setCertificateData(data);
      setLoading(false);
    } catch {
      setLoading(false);
      navigate('/404');
    }
  };

  useEffect(() => {
    getOfflineCertificate();
  }, []);

  useEffect(() => {
    if (certificateData) convertToPng();
  }, [certificateData]);

  return (
    <>
      <HelmetMetaData />
      <Container
        sx={{
          marginY: '58px',
          maxWidth: '1287px',
          '&.MuiContainer-root': { paddingRight: '0px', paddingLeft: '0px' },
        }}
      >
        {loading ? (
          <CertificateEventSkeleton />
        ) : (
          <>
            <Box sx={{ width: 0, height: 0, overflow: 'hidden' }} ref={certRef}>
              <CertificateEventCard certificate={certificateData} />
            </Box>

            <Stack direction="row" justifyContent="center">
              {certImage && (
                <Image
                  alt="cert-image"
                  src={certImage}
                  sx={{
                    width: '80%',
                  }}
                />
              )}
            </Stack>

            <Box sx={{ paddingX: '20px' }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={printDocument}
                sx={{
                  width: '100%',
                  marginTop: '30px',
                  borderColor: 'grey.600',
                }}
              >
                <Typography variant="body1">ดาวน์โหลด</Typography>
                <Icon
                  icon="bxs:download"
                  sx={{
                    marginLeft: '5px',
                    width: '15px',
                    height: '15px',
                  }}
                />
              </Button>

              <Stack direction="row" sx={{ marginTop: '20px', gap: '30px' }}>
                <Typography>แชร์ไปให้เพื่อนของคุณตอนนี้</Typography>
                <ShareButtonList certId={certificateEventId ?? ''} />
              </Stack>
            </Box>
          </>
        )}
      </Container>
    </>
  );
}
