import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";

export function TopRankSectionSkeleton () {
  return (
    <Grid container sx={{marginY: '50px'}}>
      <Grid item xs={0} md={12/5} />
      <Grid item xs={12/3} md={12/5} sx={{ marginTop: '50px', paddingX: '10px' }} >
        <TopRankSkeleton/>
      </Grid>
      <Grid item xs={12/3} md={12/5} sx={{ marginTop: '30px', paddingX: '10px' }} >
        <TopRankSkeleton/>
      </Grid>
      <Grid item xs={12/3} md={12/5} sx={{ marginTop: '50px', paddingX: '10px' }} >
        <TopRankSkeleton/>
      </Grid>
      <Grid item
       xs={0} md={12/5} />
    </Grid>
  )
}

export function UserRankSectionSkeleton () {
  return (
    <>
      <Skeleton sx={{width: '100%', height: '100px',}}/>
      <Skeleton sx={{width: '100%', height: '100px',}}/>
      <Skeleton sx={{width: '100%', height: '100px',}}/>
      <Skeleton sx={{width: '100%', height: '100px',}}/>
      <Skeleton sx={{width: '100%', height: '100px',}}/>
    </>
  )
}

function TopRankSkeleton() {
  return (
    <Box>
          <Box sx={{width: '70%', margin: '0 auto', position: 'relative'}}>
            <Box
              sx={{
                borderRadius: '50%',
                paddingTop: '90%',
                overflow: 'hidden',
                position: 'relative'
              }}
            >
              <Skeleton
                variant='circular'
                sx={{
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  zIndex: -1,
                  width: '100%',
                  height: '100%'
                }}
              />
            </Box>
          </Box>
          <Skeleton sx={{width: '100%', height: '30px'}}/>
          <Skeleton sx={{width: '100%', height: '30px'}}/>
          <Skeleton sx={{width: '100%', height: '30px'}}/>
        </Box>
  )
}