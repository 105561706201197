import { useEffect } from 'react';
import io, { Socket } from 'socket.io-client';

import useAuth from '../../hooks/useAuth';
import { dispatch } from '../../redux/store';
import {
  setSocketConnected,
  setBornBitsNotification,
} from '../../redux/slices/bornbit';

export default function BornBitSocketController() {
  const { isAuthenticated } = useAuth();
  const bornBitSocket: Socket = io(
    (window.__RUNTIME_CONFIG__.REACT_APP_HOST_SOCKET_KEY || '') + 'event',
    {
      path: window.__RUNTIME_CONFIG__.REACT_APP_HOST_SOCKET_PATH_PREFIX || '',
      withCredentials: true,
    },
  );

  const disconnectSocket = () => {
    if (bornBitSocket) {
      bornBitSocket.disconnect();
      dispatch(setSocketConnected(false));
    }
  };

  useEffect(() => {
    if (bornBitSocket) {
      bornBitSocket.on('bb-notification', (data: any) => {
        dispatch(setBornBitsNotification(data));
      });

      dispatch(setSocketConnected(true));
    }
  }, [bornBitSocket, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) disconnectSocket();
    return () => disconnectSocket();
  }, [isAuthenticated]);

  return null;
}
