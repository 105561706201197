import React from 'react';
import { Box, Container, Typography } from "@mui/material";
import PlanTitle, { SplitColorText } from './PlanTitle';

export default function BusinessSection() {
  return (
    <Box sx={{backgroundColor: 'common.black', paddingY: '80px'}}>
      <Container sx={{
          '@media (max-width: 899px)': {justifyContent: 'center', display: 'flex'}
        }}
      >
        <Box sx={{
            display: 'flex', 
            justifyContent: 'space-around',
            '@media (max-width: 899px)': {
              flexDirection: 'column',
              gap: '40px',
              justifyContent: 'center',
            }
          }}
        >
          <Box>
            <Typography sx={{fontSize: '30px'}}>
              🏢 ตอบโจทย์ทุกความรู้สายสายไอที
            </Typography>
            <Typography sx={{fontSize: '30px'}}>
              กับทักษะที่
              <Box sx={{color: 'primary.main', fontWeight: 'bold'}} component='span'> เหมาะกับทุกองค์กร</Box>
            </Typography>
            <Typography sx={{fontSize: '30px'}}>
              ใน <PlanTitle sx={{fontWeight: 'bold'}}/>
            </Typography>
            <Typography>
              เราออกแบบหลักสูตรมาให้ตอบโจทย์กับทุกอุตสาหกรรมที่ต้องการ
            </Typography>
            <Typography>
              คนสายไอทีไปทำงานจริง จนถึง ระบบหลังบ้านสำหรับองค์กรที่ทำให้
            </Typography>
            <Typography>
              บริษัทสามารถติดตามผลการเรียน และ การพัฒนาทักษะพนักงาน
            </Typography>
            <Typography>
              ของคนทั้งองค์กรได้ในเว็บเดียว
            </Typography>
          </Box>
          <Box>
            <Typography variant='h3' sx={{marginBottom: '10px'}}>
              <SplitColorText
                leftText='borntoDev+'
                rightText=' for Business'
                leftColor='primary.main'
                rightColor='text.primary'
              />
            </Typography>
            <Typography>
              ระบบติดตามการเรียน และ การจัดการสิทธิ์เข้าถึง
            </Typography>
            <Typography>
              หลักสูตรต่าง ๆ สำหรับคนในองค์กร
            </Typography>
            <Typography variant='h3' sx={{margin: '20px 0px 10px 0px'}}>
              <SplitColorText
                leftText='Expert'
                rightText=' Support'
                leftColor='primary.main'
                rightColor='text.primary'
              />
            </Typography>
            <Typography>
              คำแนะนำในการเรียนรู้ และ พัฒนาทักษะของ
            </Typography>
            <Typography>
              คนในองค์กรจากผู้เชี่ยวชาญตัวจริง
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
