import React, { useMemo } from 'react';
import { Container, Box, Stack, Typography, Button, Chip } from '@mui/material';

import Icon from '../../components/Iconify';
import { fTSeparator } from '../../utils/formatNumber';

interface SearchHeaderProps {
  loading: boolean;
  search: string;
  searchCount: number;
  onClearSearch: Function;
}

enum SearchType {
  GENERAL = 'GENERAL',
  FILTERED = 'FILTERED',
  MY_COURSE = 'MY_COURSE',
  MY_BUNDLE = 'MY_BUNDLE',
  ALL = 'ALL',
}

const SearchHeaderContent = {
  title: 'ค้นหารายการ',
  subTitle: 'มีรายการที่ตรงกัน',
};

export default function SearchHeader({
  loading,
  search,
  searchCount,
  onClearSearch,
}: SearchHeaderProps) {
  const searchText = useMemo<string>(() => {
    const text: string[] = [];
    const searchParams = new URLSearchParams(search);

    if (searchParams.get('search'))
      text.push(searchParams.get('search') as string);
    if (searchParams.get('categories'))
      text.push(searchParams.get('categories') as string);
    if (searchParams.get('group'))
      text.push(searchParams.get('group') as string);
    if (searchParams.get('plan')) text.push(searchParams.get('plan') as string);

    return text.length > 1 ? text.join(' , ') : text.join('');
  }, [search]);

  const searchSubText = useMemo<string[]>(() => {
    const text: string[] = [];
    const searchParams = new URLSearchParams(search);

    const sortFilter = searchParams.get('sortBy');
    const enrollFilter = searchParams.get('enroll');
    const ownFilter = searchParams.get('own');

    if (sortFilter) {
      if (sortFilter === 'MostEnrolled') text.push('ผู้เรียนมากที่สุด');
      else if (sortFilter === 'Newest') text.push('ใหม่ล่าสุด');
      else if (sortFilter === 'MostReview') text.push('รีวิวมากที่สุด');
    }

    if (enrollFilter) {
      if (enrollFilter === 'Enrolled') text.push('เคยเรียนแล้ว');
      else if (enrollFilter === 'NotEnrolled') text.push('ยังไม่เคยเรียน');
    }

    if (ownFilter) {
      if (ownFilter === 'Owned') text.push('เป็นเจ้าของ');
      else if (ownFilter === 'NotOwned') text.push('ไม่ได้เป็นเจ้าของ');
    }

    return text;
  }, [search]);

  const searchType = useMemo<SearchType>(() => {
    const searchParams = new URLSearchParams(search);

    const isSearchText = Boolean(searchParams.get('search'));
    const isSearchCategory = Boolean(searchParams.get('categories'));
    const isSearchOwn = Boolean(
      searchParams.get('own') && searchParams.get('own') !== 'All',
    );
    const isSearchEnroll = Boolean(
      searchParams.get('enroll') && searchParams.get('enroll') !== 'All',
    );
    const isSorted = Boolean(
      searchParams.get('sortBy') &&
        searchParams.get('sortBy') !== 'MostEnrolled',
    );
    const isMyBundle = Boolean(
      searchParams.get('own') && searchParams.get('own') === 'Owned',
    );
    const isMyCourse = Boolean(
      searchParams.get('enroll') && searchParams.get('enroll') === 'Enrolled',
    );

    if (isMyCourse) return SearchType.MY_COURSE;
    else if (isMyBundle) return SearchType.MY_BUNDLE;
    else if (isSearchText || isSearchCategory) return SearchType.GENERAL;
    else if (isSearchEnroll || isSearchOwn || isSorted)
      return SearchType.FILTERED;
    else return SearchType.ALL;
  }, [search]);

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap-reverse"
          columnGap="30px"
          rowGap="5px"
        >
          <Typography variant="h3">
            <Box component="span" sx={{ marginRight: '5px' }}>
              {SearchHeaderContent.title}
            </Box>
            <Box
              component="span"
              sx={{
                color:
                  searchType === SearchType.MY_COURSE ||
                  searchType === SearchType.MY_BUNDLE
                    ? 'primary.main'
                    : 'text.primary',
              }}
            >
              {searchType === SearchType.FILTERED
                ? ''
                : searchType === SearchType.MY_COURSE
                  ? 'คอร์สของฉัน'
                  : searchType === SearchType.MY_BUNDLE
                    ? 'แพ็กเกจของฉัน'
                    : ''}
            </Box>
            {[SearchType.MY_COURSE, SearchType.MY_BUNDLE].includes(
              searchType as SearchType,
            ) &&
              searchText && (
                <Box component="span" sx={{ color: 'primary.main' }}>
                  {' , '}
                </Box>
              )}
            {searchText && (
              <Box component="span" sx={{ color: 'primary.main' }}>
                {searchText}
              </Box>
            )}
          </Typography>

          {searchType !== SearchType.ALL && (
            <Button
              variant="outlined"
              color="tertiary"
              onClick={() => onClearSearch()}
              sx={{
                whiteSpace: 'nowrap',
                height: '40px',
                minWidth: '90px',
                gap: '5px',
              }}
            >
              <Icon
                icon="icon-park-outline:clear"
                sx={{ minWidth: '20px', minHeight: '20px' }}
              />
              <Typography variant="subtitle2">ล้างการค้นหา</Typography>
            </Button>
          )}
        </Stack>
        <Box sx={{ height: '70px' }}>
          {loading ? (
            <Stack
              direction="row"
              alignItems="center"
              sx={{ gap: '5px', marginTop: '10px' }}
            >
              <Icon icon="svg-spinners:bars-scale-fade" />
              <Typography>{'กำลังค้นหา'}</Typography>
            </Stack>
          ) : (
            <>
              {searchSubText.length > 0 && (
                <Stack
                  direction="row"
                  sx={{
                    marginTop: '10px',
                    gap: '4px',
                  }}
                >
                  {searchSubText.map((item: string, index: number) => (
                    <Chip
                      key={`subtitle${index}`}
                      variant="outlined"
                      label={item}
                    />
                  ))}
                </Stack>
              )}

              <Typography
                sx={{
                  marginTop: '10px',
                  color: 'text.disabled',
                }}
              >
                {`${SearchHeaderContent.subTitle} ${fTSeparator(
                  searchCount,
                )} รายการ`}
              </Typography>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
}
