import React from 'react'
import { Typography, Grid, Stack, Avatar, Box } from '@mui/material'

import ProjectGallerySkeleton from '../../../components/skeleton/projectGallery'
import Icon from '../../../components/Iconify'
import ProjectCard from '../../../components/ProjectCard'

import { ProjectDetailType, PropsProjectGroup } from '../../../@types/projectGallery'
import TextLink from '../../../components/link/Text'
import GeneralLink from '../../../components/link/General'
import { ProfileDetail, ProfileDetailTooltip } from '../../profile/ProfileDetailTooltip'


function GalleryProjectGroup({ projectList, searchMode, loadingStatus }: PropsProjectGroup) {
  if (projectList?.length > 0 || loadingStatus === 'loading') {
    return (
      <Box>
        <Grid justifyContent="flex-start" container sx={{ marginTop: searchMode !== 'main' ? '38px' : null, gap: '26px 10px' }} >
          {[...projectList]?.map((item: ProjectDetailType, index: Number) => (
            <Grid item xs={11.91} sm={5.8} md={3.8} lg={2.91} key={`project-card-${index}`} justifyContent="center" sx={{ display: 'grid' }}>
              <ProjectGalleryCard projectData={item}/>
            </Grid>
          ))}

          {loadingStatus === 'loading' ? Array(16).fill(0).map((_, index: number) => (
            <ProjectGallerySkeleton key={`skeleton-project-card-${index}`} index={index} />
          )) : null}
        </Grid>

      </Box>
    )
  } else {
    return (
      <Stack justifyContent="center" sx={{ height: '300px' }}>
        <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'regular', color: 'text.secondary' }}>{`- ไม่พบโปรเจกต์ที่คุณค้นหา -`}</Typography>
      </Stack>
    )
  }
}

export function ProjectGalleryCard({projectData, path}: {projectData: ProjectDetailType, path?: string}) {
  return (
    <Box sx={{ width: '250px' }}>
      <ProjectCard
        topicName={projectData.name}
        backgroundImage={projectData.coverImage}
        routerPathName={path || projectData.id}
      />

      <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '10px' }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <GeneralLink link={projectData?.userId && projectData?.user?.role === 'STUDENT'  ? `/profile/${projectData?.userId}` : ''}>
          {
            projectData?.anonymous ?
            <Avatar src={`${projectData.user?.imageUrl}`} alt="Peter" sx={{ width: '24px', height: '24px', marginRight: '15px' }} />
            :
            <>
            {
              projectData?.user?.role === 'STUDENT' ?
              <ProfileDetailTooltip
                title={ProfileDetail(projectData?.user)}
              placement="top-start">
              <Box>
                <Avatar src={`${projectData.user?.imageUrl}`} alt="Peter" sx={{ width: '24px', height: '24px', marginRight: '15px' }} />
              </Box>
            </ProfileDetailTooltip>
            :
            <Avatar src={`${projectData.user?.imageUrl}`} alt="Peter" sx={{ width: '24px', height: '24px', marginRight: '15px' }} />
            }
            </>
          }
          </GeneralLink>
          {
            projectData?.anonymous ?
            <TextLink
            link={projectData?.userId ? `/profile/${projectData?.userId}` : ''}
            text={projectData.user.publicName}
            typographyVariant='body2'
            sx={{
              fontWeight: 'bold',
              maxWidth: '145px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          />
          :
          <>
          {
            projectData?.user?.role === 'STUDENT' ?
            <ProfileDetailTooltip
              title={ProfileDetail(projectData?.user)}
              placement="top-start"
            >
              <Box>
                <TextLink
                  link={projectData?.userId && projectData?.user?.role === 'STUDENT' ? `/profile/${projectData?.userId}` : ''}
                  text={projectData.user.publicName}
                  typographyVariant='body2'
                  sx={{
                    fontWeight: 'bold',
                    maxWidth: '145px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                />
              </Box>
            </ProfileDetailTooltip>
            :
            <TextLink
              link={projectData?.userId && projectData?.user?.role === 'STUDENT' ? `/profile/${projectData?.userId}` : ''}
              text={projectData.user.publicName}
              typographyVariant='body2'
              sx={{
                fontWeight: 'bold',
                maxWidth: '145px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            />
          }
          </>
          }
        </Stack>

        <Stack direction="row" spacing={1}>
          {
            projectData.favorite > 0 &&
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Icon icon="heroicons-solid:thumb-up" sx={{ width: '19px', height: '19px', paddingRight: '3px' }} />
                <Typography variant="body2">{projectData.favorite}</Typography>
              </Stack>
          }
          {
            projectData.view > 0 &&
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Icon icon="bi:eye-fill" sx={{ width: '19px', height: '19px', paddingRight: '3px' }} />
                <Typography variant="body2">{projectData.view}</Typography>
              </Stack>
          }
        </Stack>
      </Stack>
    </Box>
  )
}

export default GalleryProjectGroup
