import React, { useState } from 'react'
import { Box, Stack, Typography, Modal, Button, CircularProgress, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { SelectChangeEvent } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ReactQuill from 'react-quill'
import { DeltaStatic } from 'quill'

import useAuth from '../../hooks/useAuth'
import Icon from '../../components/Iconify'
import EditorBodyStyle from '../../components/editor/EditorBody'
import axiosInstance from '../../utils/axios'
import { CustomFile } from '../../@types/comments'

import { NameInputField } from './CommentCard'
import ImageEditor from './ImageEditor'
import { useSelector } from '../../redux/store'

import { addCommentQuestionQueqe } from '../../redux/slices/comment';
import { dispatch } from '../../redux/store';

interface CommentControlProps {
  courseId: string,
  lessonId: string,
  spacificType?: keyof typeof commentTypeOptions;
  spacificComment?: string,
  onNewComment: Function,
}

const questionCategories = {
  LEARNING: {
    title: 'เนื้อหาในบทเรียน',
    description: '(เช่น มีข้อสงสัย หรือต้องการคำอธิบายเพิ่มเติมเกี่ยวกับหัวข้อใดในบทเรียนนี้)',
    autoAnswer: true
  },
  PROGRAMMING: {
    title: 'การเขียนโปรแกรม',
    description: '(เช่น การเขียนโค้ด การแก้บั๊ก หรือการพัฒนาโปรแกรมต่าง ๆ เพื่อให้ได้ผลลัพธ์ตามที่ต้องการ)',
    autoAnswer: true
  },
  SYSTEM_ISSUE: {
    title: 'ปัญหาการใช้งาน',
    description: '(เช่น ปัญหาในการเข้าถึงหน้าเว็บ ปัญหาการจบบทเรียน หรือปัญหาเทคนิคอื่น ๆ)',
    autoAnswer: false
  },
  LESSON_ISSUE: {
    title: 'ปัญหาเกี่ยวกับบทเรียน',
    description: '(เช่น ข้อผิดพลาดในเนื้อหาบทเรียน หรือ ลิงก์มีปัญหา)',
    autoAnswer: false
  },
}

const commentTypeOptions = {
  'COMMENT': {
    typesRadio: 
      <>
        <FormControlLabel value={'TALK'} control={<Radio />} label={'พูดคุย'} />
        <FormControlLabel value={'QUESTION'} control={<Radio />} label={'คำถาม'} />
      </>,
    text: 'แสดงความคิดเห็น',
  },
  'ANSWER': {
    typesRadio: <></>,
    text: 'ส่งการบ้าน',
  }
}

const AiAnswerList = ['PROGRAMMING', 'LEARNING']

export default function CommentControl({ onNewComment, courseId, lessonId, spacificType = 'COMMENT', spacificComment }: CommentControlProps) {
  const theme = useTheme()
  const { user } = useAuth()
  const { courseLessons } = useSelector((state) => state.lesson)

  const [contentText, setContentText] = useState<string>('')
  const [commentContent, setCommnetConetent] = useState<string | DeltaStatic>('')
  const [fileImage, setFileImage] = useState<CustomFile[]>([])
  const [fileImageOrder, setFileImageOrder] = useState<number[]>([])
  const [reset, setReset] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [inputError, setInputError] = useState<boolean>(false)

  const [commentType, setCommentType] = useState<"TALK" | "QUESTION" | "CLOSED" | "ANSWER">(spacificType === 'COMMENT' ? 'TALK' : spacificType as "TALK" | "QUESTION" | "CLOSED" | "ANSWER")
  const [questionCategory, setQuestionCategory] = useState('LEARNING' as keyof typeof questionCategories)

  const onSubmitComment = async () => {
    setLoading(true)
    try {
      const form = new FormData()

      const specificData = 
        spacificComment ? { rereplyFrom: spacificComment } 
        : commentType === 'QUESTION' ? { questionCategory: questionCategory} 
        : {}

      const submisstionData: any = {
        text: JSON.stringify(commentContent),
        type: commentType,
        images: fileImage,
        imagesOrder: JSON.stringify(fileImageOrder),
        ...specificData
      }

      Object.keys(submisstionData).forEach((key,) => {
        if (Array.isArray(submisstionData[key])) {
          submisstionData[key].forEach((value: any) => {
            if (key === 'images') form.append(`${key}[]`, value)
          })
        }
        else form.append(key, submisstionData[key])
      })

      const res = await axiosInstance.post(`/comments?courseId=${courseId}&lessonId=${lessonId}`, form)
      onNewComment()
      if (AiAnswerList.includes(submisstionData.questionCategory)) onCreateAiQuestion(res.data.id)
      await setCommnetConetent('')
      await setCommentType("TALK")
      await setFileImage([])
      await setFileImageOrder([])
      await setReset(true)
    } catch {
    }
    await setLoading(false)
  }

  function onCreateAiQuestion(id: string){
    dispatch(addCommentQuestionQueqe({
      id: id,
      status: 'WAIT'
    }));
  }

  function isEnrolled() {
    return courseLessons.enrolled
  }

  const toolbarOptions = [
    [{ 'header': [1, 2, 3, false] }, {'align': [false, 'center', 'right']}],
    ['bold', 'italic', 'underline', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }], ['clean']
  ];

  const formats = [
    'bold',
    'align',
    'italic',
    'size',
    'strike',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'code-block',
  ];

  return (
    <Box sx={{ backgroundColor: 'background.default', borderRadius: '16px', padding: '24px' }}>
      <Typography variant="h5" sx={{ marginBottom: '10px' }}>{commentTypeOptions[spacificType].text}</Typography>

      <Stack direction="row" alignItems="center" sx={{ gap: '5px', marginBottom: '10px' }}>
        <RadioGroup
          row
          value={commentType}
          onChange={(event: SelectChangeEvent) => setCommentType(event.target.value as "TALK" | "QUESTION" | "ANSWER")}
        >
          {commentTypeOptions[spacificType].typesRadio}
        </RadioGroup>
      </Stack>

      {
        commentType === 'QUESTION' && 
        <Box sx={{marginBottom: '24px'}}>
          <Stack direction="row" alignItems="center" sx={{ gap: '5px' }}>
            <RadioGroup
              row
              value={questionCategory}
              onChange={(event: SelectChangeEvent) => setQuestionCategory(event.target.value as keyof typeof questionCategories)}
            >
              {Object.keys(questionCategories).map((each, index) => {
                const catData = questionCategories[each as keyof typeof questionCategories]
                return (
                  <FormControlLabel 
                    key={`q-cat-${index}`} 
                    value={each} control={<Radio />} 
                    label={
                      <>
                        {catData.title}
                        {catData.autoAnswer && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                              padding: '2px 5px',
                              backgroundColor: 'success.darker',
                              borderRadius: '6px',
                              gap: '3px'
                            }}
                          >
                            <Icon icon="fluent:comment-checkmark-16-regular" />
                            <Typography variant="caption">
                              {'ตอบแบบรวดเร็วโดย AI'}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    } 
                  />
              )})}
            </RadioGroup>
          </Stack>
          <Typography variant='caption' sx={{color: 'text.secondary'}}>{questionCategories[questionCategory].description}</Typography>
          { questionCategories[questionCategory].autoAnswer ? <AutoAnswerManual/> : <></> }
        </Box>
      }
      <Stack direction="column">
        <EditorBodyStyle>
          <ReactQuill
            formats={formats}
            placeholder={`${commentTypeOptions[spacificType].text}....`}
            value={commentContent}
            readOnly={!isEnrolled()}
            modules={{toolbar: toolbarOptions, syntax: true}}
            theme={"snow"}
            style={{borderRadius: '10px'}}
            onChange={(content: string, _1, _2, editor: ReactQuill.UnprivilegedEditor) => {
              if (content !== '') setInputError(false)
              setCommnetConetent(editor.getContents())
              setContentText(editor.getText())
            }}
          />
        </EditorBodyStyle>

        {inputError && (
          <Typography variant="caption" sx={{ marginTop: '10px', color: 'error.main' }}>กรุณาใส่ข้อความก่อนแสดงความคิดเห็น</Typography>
        )}

        <Box sx={{ marginTop: '20px' }}>
          <ImageEditor
            label="อัปโหลดรูปภาพ"
            files={fileImage}
            fileOrder={fileImageOrder}
            reset={reset}
            onReturnValue={(type: string, value: CustomFile[] | number[]) => {
              if (type === 'files') setFileImage(value as CustomFile[])
              else if (type === 'fileOrder') setFileImageOrder(value as number[])
            }}
          />
        </Box>

        <NameInputField
          value={user?.publicName || ''}
          disabled={true}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: '10px' }}>
          {loading && (
            <Stack direction="row" alignItems="center" gap="5px">
              <CircularProgress color="inherit" size="10px"/>
              <Typography variant="caption">กำลังส่งข้อมูล</Typography>
            </Stack>
          )}
          <Button
            variant='contained'
            sx={{
              height: '36px',
              borderRadius: '8px',
              color: 'text.button'
            }}
            onClick={() => {
              if (contentText.trim() === '' && fileImage.length === 0 && loading) {
                setOpenAlert(true)
                setInputError(true)
              }
              else onSubmitComment()
            }}
            disabled={!isEnrolled() || contentText.trim() === '' || loading}
          >
            <Typography variant="button">{commentTypeOptions[spacificType].text}</Typography>
          </Button>
        </Box>
      </Stack>

      <Modal
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: '141px',
            width: '343px',
            padding: '4px',
            borderRadius: '16px',
            borderColor: 'transparent',
            boxShadow: 24,
            backgroundColor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ marginBottom: '20px' }}>
            <Stack justifyContent="center" alignItems="center" sx={{ width: '40px', height: '40px', marginRight: '12px', borderRadius: '12px', backgroundColor: `${theme.palette.warning.main}16` }}>
              <Icon icon="eva:alert-triangle-fill" sx={{ height: '24px', width: '24px', color: 'primary.main' }} />
            </Stack>
            <Typography variant="body1">กรุณาใส่ข้อความก่อนแสดงความคิดเห็น</Typography>
          </Stack>
          <Button
            sx={{
              backgroundColor: 'warning.main',
              color: 'common.white',
              height: '36px',
              width: '260px',
              ':hover': {
                backgroundColor: 'warning.dark'
              }
            }}
            onClick={() => setOpenAlert(false)}
          >
            รับทราบ
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

function AutoAnswerManual() {
  return (
    <Box sx={{backgroundColor: 'background.neutral', padding: '20px 15px', borderRadius: '10px', marginTop: '20px'}}>
      <Typography variant='h4' sx={{marginBottom: '5px'}}>การตอบกลับด้วยน้องเดฟ (AI)</Typography>
      <Typography sx={{fontSize: '14px'}}>✅ ผู้เรียนจะต้องอธิบายบริบทที่กำลังจะทำ และ ปัญหาที่เราเจอให้ชัดเจน เช่น แนบข้อความของ Error Message</Typography>
      <Typography sx={{fontSize: '14px'}}>✅ ถ้าหากมี Code / คำสั่งที่ได้เขียน / สิ่งที่เราได้ทำไว้ก่อนแล้ว ให้แนบไว้ในข้อความ (ไม่ใช่การแนบรูปภาพ) เพื่อให้ AI สามารถช่วยเหลือได้อย่างถูกต้อง</Typography>
      <Typography sx={{fontSize: '14px'}}>❤️ หากมีข้อสงสัยเพิ่มเติม หรือ สามารถแก้ไขได้ สามารถกดขอความช่วยเหลือจากเจ้าหน้าที่แทนได้</Typography>
    </Box>
  )
}