import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import CourseDetailSkeleton from '../../components/skeleton/CourseDetail';
import HelmetMetaData from '../../components/Helmet';
import axios from '../../utils/axios';
import { LessonParamsType } from '../../@types/params';
import { courseDataType } from '../../@types/courseData';
import { OverviewReviewType } from '../../@types/reviewCourse';

import Detail from './Detail';
import Cover from './Cover';
import RelatedCourses from './RelatedCourses';
import { initialCourseData, initialOverviewData } from './initialState';

export default function CourseDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { courseSlug } = useParams<LessonParamsType>();

  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] =
    useState<courseDataType>(initialCourseData);
  const [overviewData, setOverviewData] =
    useState<OverviewReviewType>(initialOverviewData);

  useEffect(() => {
    initData(courseSlug);
  }, []);

  async function initData(courseSlug?: string) {
    try {
      setLoading(true);
      const response = await axios.get(`/courses/${courseSlug}`);
      const responseReview = await axios(
        `/courses/${response.data.id}/review/summary`,
      );
      setCourseData(response.data);
      setOverviewData(responseReview.data);
      setLoading(false);
    } catch (error) {
      navigate('/');
    }
  }

  return (
    <CourseDetailRootStyle>
      {loading ? (
        <CourseDetailSkeleton />
      ) : (
        <>
          <HelmetMetaData
            title={courseData.title}
            image={courseData.images.cover}
            description={courseData.shortDescription}
            currentUrl={location.pathname}
          />
          <Cover
            courseImage={courseData.images.cover}
            previewVideo={courseData?.previewVideo}
            coverImage={courseData.images.cover}
            title={courseData.title}
            shortDescription={courseData.shortDescription}
            dateCourse={courseData.updatedAt}
            instructors={courseData.instructors}
            overviewData={overviewData}
          />
          <Box
            sx={{
              backgroundColor: 'background.paper',
              borderRadius: '0px 0px 16px 16px',
            }}
          >
            <Detail courseData={courseData} overviewData={overviewData} />
            <RelatedCourses
              categories={courseData.categories}
              courseId={courseData.id}
            />
          </Box>
        </>
      )}
    </CourseDetailRootStyle>
  );
}

const CourseDetailRootStyle = styled(Box)({
  maxWidth: '1270px',
  margin: 'auto',
});
