import React, { useMemo } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { fTSeparator } from '../../utils/formatNumber';
import { LeaderboardUserType } from './type';
import Userranks from './userrank';
import { ContentCardStyle, getProfileDetailData } from './utils';
import { ProfileDetail, ProfileDetailTooltip } from '../profile/ProfileDetailTooltip';
import { Link } from 'react-router-dom';
import { ellipsisTwo } from '../../utils/styles';

export default function TopRank({toprankData}: {toprankData: LeaderboardUserType[]}){
  const toprankDatas = [toprankData[1], toprankData[0], toprankData[2]]
  return (
    <>
    <Grid container sx={{marginTop: '50px'}} justifyContent={'center'}>
      { toprankDatas.map((each, index) => 
        each ?
        <TopRankComponent rankData={each} key={`toprank-${index}`}/> :
        <Grid key={`toprank-${index}`} item xs={index >= 1 && index <= 3 ? 12/3 : 0} md={12/5} />
      )}
    </Grid>

    {
      toprankData.length > 3 &&
      <ContentCardStyle sx={{margin: '30px auto 0px auto', padding: '0px', maxWidth: '900px'}}>
        <Userranks userrankData={[toprankData[3], toprankData[4]]} top/>
      </ContentCardStyle>
    }
    </>
    
  )
}

function TopRankComponent({rankData}: {rankData: LeaderboardUserType}) {
  const theme = useTheme()
  const topThree = rankData.rank <= 3 
  const color = useMemo(
    () => topThree ? theme.palette.primary.main : theme.palette.secondary.main, []
  )
  return (
    <Grid
      item
      xs={12/3}
      md={12/5}
      sx={{
        marginTop: rankData.rank === 1 ? '0px' : topThree ? '50px': '0px',
        paddingX: '10px'
      }}
    >
      {
        <Box>
          <Box sx={{width: '70%', margin: '0 auto', position: 'relative'}}>
            {
              rankData.rank === 1 &&
              <Box 
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Iconify icon='foundation:crown' sx={{color: color, width: '50px', height: '50px'}}/>
              </Box>
            }
            <Box
              sx={{
                borderRadius: '50%',
                border: `solid 5px ${color}`,
                paddingTop: '90%',
                overflow: 'hidden',
                position: 'relative'
              }}
            >
              <Image
                src={rankData.image}
                sx={{
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  zIndex: -1
                }}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: '-10px',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                backgroundColor: color,
                color: 'primary.contrastText',
                width: '30px',
                height: '30px',
                borderRadius: '8px',
                textAlign: 'center'
              }}
            >
              <Typography
                sx={{
                  color: 'primary.contrastText',
                }}
                variant='subtitle2'
                fontSize={'20px'}
              >
                {rankData.rank}
              </Typography>
            </Box>
          </Box>
          <ProfileDetailTooltip
            title={ProfileDetail(getProfileDetailData(rankData))}
            placement="top-start"
          >
            <Box 
              component={rankData.role === 'ADMIN' || rankData.role === 'INSTRUCTOR' ? Box : Link} 
              to={`/profile/${rankData.id}`}
              target='_blank'
              sx={{
                color: 'text.primary',
                textDecoration: 'none',
                ':hover': {
                  color: 'primary.main'
                }
              }}
            >
              <Typography
                variant='subtitle2'
                fontSize={'20px'}
                sx={{
                  textAlign: 'center',
                  marginTop: '15px',
                  width: '100%',
                  wordBreak: 'break-word',
                  ...ellipsisTwo
                }}
              >
                {rankData.publicName}
              </Typography>
            </Box>
          </ProfileDetailTooltip>
          <Typography
            variant='body2'
            sx={{
              textAlign: 'center',
              color: color,
            }}
          >
            {rankData.level}
          </Typography>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Iconify sx={{color: color, width: '25px', height: '25px'}} icon='mdi:alpha-b-box-outline'/>
            <Typography
              variant='body1'
              sx={{
                textAlign: 'center',
              }}
            >
              {fTSeparator(rankData.exp)}
            </Typography>
          </Box>
        </Box>
      }
    </Grid>
  )
}