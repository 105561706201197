import React from 'react';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { BundleInstructorType } from '../../@types/courseCatalog';
import { useTheme } from '@mui/material/styles';

export default function BundleInstructors({
  instructors,
}: {
  instructors: BundleInstructorType[];
}) {
  const theme = useTheme();
  const instructorsLength = instructors.length;
  return (
    <Box sx={{ marginY: '10px', display: 'flex', gap: '10px' }}>
      {Array(instructorsLength > 5 ? 4 : instructorsLength)
        .fill(0)
        .map((_, index: number) => (
          <Instructor
            key={instructors[index].id}
            instructor={instructors[index]}
          />
        ))}
      {instructorsLength > 5 && (
        <Tooltip
          title={
            <>
              {instructors.map((instructor, index) =>
                index >= 4 && (
                  <Box key={instructor.id}>
                    <Typography variant="caption">
                      • {instructor.publicName}
                    </Typography>
                  </Box>
                ),
              )}
            </>
          }
        >
          <Box
            sx={{
              width: 32,
              height: 32,
              borderRadius: '50%',
              border: `1px solid ${theme.palette.text.secondary}`,
              borderStyle: 'dashed',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            +{instructorsLength - 4}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

function Instructor({ instructor }: { instructor: BundleInstructorType }) {
  return (
    <Tooltip title={instructor.publicName}>
      <Avatar
        alt={instructor.publicName}
        src={instructor.imageUrl}
        sx={{
          width: 32,
          height: 32,
        }}
      />
    </Tooltip>
  );
}
