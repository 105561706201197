import { CommentType, CommentDetailType, CommentMetaType } from '../../@types/comments'

export const InitialCommentBody: CommentDetailType = {
  id: '',
  userId: '',
  isBot: false,
  text: '',
  type: 'TALK',
  status: '',
  isRead: false,
  isCorrect: false,
  isPending: false,
  createdAt: new Date(),
  user: {
    username: '',
    publicName: '',
    imageUrl: '',
    bio: '',
    role: 'STUDENT',
    experience: {
      position: '',
    },
    target: {
      value: '',
    }
  },
  firstChild: null,
  child: 0,
  commentImages: [],
}

const InitialMetaData: CommentMetaType = {
  hasNextPage: false,
  hasPreviousPage: false,
  itemCount: 0,
  page: 1,
  pageCount: 1
} 

export const InitialComment: CommentType = {
  data: [InitialCommentBody],
  meta: InitialMetaData
}
