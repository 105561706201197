import React, { useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Stack, Grid, Typography, Chip, Collapse, Divider } from '@mui/material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

import { sectionType, lessonType } from '../../../@types/courseLesson'
import ResultAlert from '../../../components/ResultAlert'
import Icon from '../../../components/Iconify'

interface IncompleteCourseSummaryProps {
  courseTitle: string
  percentProgress: number
  sectionData: sectionType[]
}

interface SectionItemProps {
  data: sectionType
  sectionIndex: number
}

interface SectionProps {
  title: string
  index: number
  open: boolean
  setOpen: Function
}

interface LessonProps {
  data: lessonType
}

const LESSON_ICONS: {[key: string]: string} = {
  VIDEO: 'ci:play-circle-outline',
  CHECKED: 'akar-icons:check',
  CAUTION: 'eva:alert-triangle-outline',
  DEVLAB: 'fluent:window-dev-edit-16-filled',
  TEXT: 'gg:loadbar-doc',
  QUIZ: 'ic:round-quiz',
  LOCKED: 'material-symbols:lock'
}

function SectionItem({ data, sectionIndex }: SectionItemProps) {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      <Section 
        title={data.title}
        index={sectionIndex} 
        open={open} 
        setOpen={setOpen}
      />
      <Collapse in={open}>
        {data.lessons.map((lesson: lessonType, lessonIndex: number) => (
          <Lesson 
            key={`lesson-${sectionIndex}-${lessonIndex}`}
            data={lesson}
          />
        ))}
      </Collapse>
    </>
  )
}

function Section({ title, index, open, setOpen }: SectionProps) {
  return (
    <Grid container sx={{alignItems: 'center', marginTop: '20px', cursor: 'pointer'}} onClick={() => setOpen(!open)}>
      <Grid item xs={2} sm={1.15}>
        <Typography sx={{fontSize: '36px', color: 'text.secondary', lineHeight: '1'}}>
          {index.toString().padStart(2, '0')}
        </Typography>
      </Grid>
      <Grid item xs={8.5} sm={9.35}>
        <Typography variant="h5">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={1.5} sx={{justifyContent: 'flex-end', display: 'flex'}}>
        <Box>
          <Icon
            icon={open ? 'dashicons:arrow-up-alt2' : 'dashicons:arrow-down-alt2'}
            sx={{ width: 24, height: 24 }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

function Lesson({ data }: LessonProps) {
  const navigate = useNavigate()
  const { courseSlug } = useParams()
  return (
    <Grid container sx={{alignItems: 'end', marginTop: '10px'}}>
      <Grid item xs={1.15} sm={1.15} />
      <Grid item xs={7} md={8.5} xl={8.85}>
        <Stack 
          direction="row" 
          alignItems="center"
          onClick={() => navigate(`/course/${courseSlug}/${data.id}`)}
          sx={{ 
            gap: '5px',
            cursor: 'pointer',
            alignItems: 'top',
            ':hover': { color: 'primary.main' } 
          }}
        >
          
          <Icon icon={LESSON_ICONS[data.type]} sx={{ fontSize: '20px', minWidth: '20px', marginTop: data.isEndQuestion ? '6px' : '2px' }} />
          <Stack
            direction="row"
            sx={{ 
              gap: '5px',
              alignItems: 'center',
              flexWrap: 'wrap'
            }} 
          >
            <Typography variant="body1">
              {data.title}             
            </Typography>

            {data.isEndQuestion && (
              <Chip
                variant="outlined"
                label="มีการบ้านประจำบทเรียน"
                sx={{width: 'fit-content'}}
              />
            )}

          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default function IncompleteCourseSummary({ 
  courseTitle, 
  percentProgress, 
  sectionData 
}: IncompleteCourseSummaryProps) {
  const [lessonCount, setLessonCount] = useState<number>(0)

  const data = useMemo(() => {
    const IncompleteSection = sectionData.flatMap((sectionItem: sectionType) => {
      if (sectionItem.lessons.some((lessonItem: lessonType) => !lessonItem.completed)) {
        const IncompleteLesson = sectionItem.lessons.flatMap((lessonItem: lessonType) => {
          if (!lessonItem.completed) {
            setLessonCount((prev: number) => prev + 1)
            return lessonItem
          } else return []
        })
        return {...sectionItem, lessons: IncompleteLesson}
      } else return []
    })
    return IncompleteSection
  }, [sectionData])


  return (
    <Box
      sx= {{
        padding: '40px 70px',
        '@media (max-width:1200px)': {
          padding: '40px 20px',
        }
      }}
    >
      <ResultAlert 
        title="ผู้เรียนทำการเรียนเนื้อหาภายในคอร์สเรียนไม่สมบรูณ์" 
        description="การรับใบ Certificate ประจำคอร์สเรียน ผู้เรียนจำเป็นต้องทำการศึกษาเนื้อหาภายในคอร์สเรียนในครบถ้วนก่อน" 
        severity="warning"
      />

      <Box sx={{ marginBottom: '20px', marginTop: '10px' }}>
        <Typography variant="h3">สรุปผลการเรียนปัจจุบัน</Typography>
        <Typography color="primary">{`คอร์สเรียน ${courseTitle}`}</Typography>
      </Box>

      <Box>
        <Stack direction="row" alignItems="center" sx={{ gap: '5px' }}>
          <Typography>ความคืบหน้าของคอร์สเรียน</Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>{`( ${percentProgress}% )`}</Typography>
        </Stack>
        <LinearProgress 
          variant="buffer" 
          value={percentProgress} 
          sx={{ 
            height: '8px',
            borderRadius: '5px',
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: '5px',
            }
          }}
        />
      </Box>

      <Box sx={{ marginTop: '40px' }}>
        <Box>
          <Typography variant="h4">บทเรียนที่ยังไม่ผ่าน</Typography>
          <Typography>{`มีบทเรียนที่ยังไม่ผ่านอีก ${lessonCount} บทเรียน`}</Typography>
        </Box>
        
        <Divider sx={{ marginY: '20px' }} />
        {data.map((item: sectionType, index: number) => (
          <SectionItem 
            key={`section-${index}`}
            data={item}
            sectionIndex={index + 1}
          />
        ))}
      </Box>
    </Box>
  )
}