import React from 'react';
import { userLearningStatusType } from '../../../@types/courseData';
import { Button } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { fTSeparator } from '../../../utils/formatNumber';
import { WcProductDataType } from '../../../@types/courseCatalog';
import {
  LearnSectionLabel,
  FreeSectionLabel,
  SubscriptionSectionLabel,
  PremiumSaleSectionLabel,
} from './Label';
import {
  LearnSection,
  PlanSaleSection,
  ProductSaleSection,
  DisabledSection,
  NotLoggedInPreRegisterSection,
} from './Action';
import { FREE_PLAN } from './config';

type StartCourseProps = {
  userLearningStatus: userLearningStatusType;
  slug: string;
  courseStatus: 'PUBLISHED' | 'SOON' | 'EXCLUSIVE' | 'PREREGISTER';
  wcProduct: WcProductDataType;
  hideLabel?: boolean;
  variant?: 'detail' | 'footer' | 'lesson';
};

export default function StartCourse({
  userLearningStatus,
  slug,
  courseStatus,
  wcProduct,
  hideLabel = false,
  variant = 'detail',
}: StartCourseProps) {
  const { user } = useAuth();

  //login included value
  const isOwned = userLearningStatus.enrolled || userLearningStatus.enrollable;
  const isExclusiveOwned = userLearningStatus.enrolled;

  const isNotLoggedIn = !user;
  const isPremium = !userLearningStatus.recommendedPlan;
  const isOpenAccess = userLearningStatus.recommendedPlan?.title === FREE_PLAN;
  const isCourseOpenSell = wcProduct?.status === 'publish';
  const getSubscriptionPlanYear = () => {
    const yearVariation = userLearningStatus.recommendedPlan.variations.find(
      (each: any) =>
        each.billingInterval === 1 && each.billingPeriod === 'YEAR',
    );
    return yearVariation;
  };

  const ActionSection = (short = false) =>
    //Action
    courseStatus === 'SOON' ||
    (courseStatus === 'EXCLUSIVE' && !isExclusiveOwned) ? (
      <DisabledSection label={'เร็ว ๆ นี้'} short={short} />
    ) : isOwned ? (
      <LearnSection
        enrolled={userLearningStatus.enrolled}
        slug={slug}
        courseStatus={courseStatus}
        short={short}
      />
    ) : courseStatus === 'PREREGISTER' && !user && !isPremium ? (
      <NotLoggedInPreRegisterSection short={short} isPremium={isPremium} />
    ) : isCourseOpenSell ? (
      <ProductSaleSection
        wcProduct={wcProduct}
        isNotLoggedIn={isNotLoggedIn}
        short={short}
      />
    ) : !isPremium ? (
      <PlanSaleSection isOpenAccess={isOpenAccess} short={short} />
    ) : (
      <DisabledSection label={'ยุติการขายแล้ว'} short={short} />
    );

  if (variant === 'footer') {
    return ActionSection(true);
  }

  return (
    <>
      {
        //Label
        hideLabel ||
        courseStatus === 'SOON' ||
        (courseStatus === 'EXCLUSIVE' && !isExclusiveOwned) ? (
          <></>
        ) : isOpenAccess ? (
          <FreeSectionLabel />
        ) : isOwned && user ? (
          <LearnSectionLabel
            basicPlan={user.subscription.plan.basicPlan}
            plan={user.subscription.plan.title}
          />
        ) : isCourseOpenSell ? (
          <PremiumSaleSectionLabel wcProduct={wcProduct} />
        ) : !isPremium ? (
          <SubscriptionSectionLabel
            recommendedPlan={userLearningStatus.recommendedPlan.title}
            yearPrice={getSubscriptionPlanYear()?.price}
          />
        ) : (
          <></>
        )
      }
      {ActionSection()}
      {
        //Plus Plan Seperate Sell
        !isPremium &&
          !isOpenAccess &&
          isCourseOpenSell &&
          !isOwned &&
          courseStatus !== 'SOON' &&
          courseStatus !== 'EXCLUSIVE' && (
            <a
              href={'/subscription'}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button
                sx={{
                  color: 'text.primary.main',
                  marginTop: '10px',
                  width: '100%',
                }}
              >
                หรือ ลงทะเบียนผ่าน borntoDev Plus เพียง{' '}
                {fTSeparator(getSubscriptionPlanYear()?.price || 0)}฿ ต่อปี
              </Button>
            </a>
          )
      }
    </>
  );
}
