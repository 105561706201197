import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Pagination,
  PaginationItem,
  PaginationItemProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  quizDataType,
  quizAnswerType,
  questionType,
  questionAttemptType,
} from '../../../@types/quiz';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Iconify from '../../../components/Iconify';
import ShadowButton from '../../../components/ShadowButton';
import axiosInstance from '../../../utils/axios';

import Question from './Question';
import QuizAnswerTotal from './QuizAnswerTotal';
import Countdown from '../../../components/Countdown';
import dayjs from 'dayjs';

export default function QuizQuestions({
  questions,
  limitTime,
  status,
  submitQuiz,
}: {
  questions: quizDataType;
  limitTime: dayjs.Dayjs;
  status?: string;
  submitQuiz: Function;
}) {
  const { courseSlug, lessonId } = useParams();
  const navigate = useNavigate();
  const [questionAttemptList, setQuestionAttemptList] = useState<
    questionAttemptType[]
  >([]);
  const [answers, setAnswers] = useState<quizAnswerType[]>(
    handleInitialAnswer(),
  );
  const [currentAnswer, setCurrentAnswer] = useState<any>({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [qPage, setQPage] = useState('sheet');
  const [loading, setLoading] = useState<boolean>(false)

  const updateQuizProgress = async () => {
    try {
      if (!_.isEmpty(currentAnswer)) {
        const response = await axiosInstance.put(
          `quiz/${questions.quiz.id}/questions/${currentAnswer.questionId}/submit`,
          {
            quizAttemptId: questions.quizAttemptId,
            questionAttemptId:
              questionAttemptList.find(
                (item: questionAttemptType) =>
                  item.questionId === currentAnswer.questionId,
              )?.questionAttemptId ?? '',
            questionAnswer: {
              ...currentAnswer,
            },
          },
        );
        const data = await response.data;
        await setQuestionAttemptList((prev: questionAttemptType[]) => {
          if (
            prev.some(
              (item: questionAttemptType) => item.questionAttemptId === data.id,
            )
          )
            return prev;
          else
            return [
              ...prev,
              {
                questionId: currentAnswer.questionId,
                questionAttemptId: data.id,
                answers: currentAnswer.answers,
              },
            ];
        });

        setCurrentAnswer({});
      }
    } catch {
      navigate(`/course/${courseSlug}/${lessonId}?error=new_version`);
    }

    setLoading(false)
  };

  const handleChangePage = async (page: number) => {
    setLoading(true)
    await updateQuizProgress();
    await setCurrentQuestion(page);
  };

  function handleInitialAnswer() {
    return questions.quiz.questions.reduce(
      (prev: quizAnswerType[], curr: questionType) => {
        const continuousAnswer: any = questions.allQuestionAttempt.find(
          (attemp: any) => attemp.questionId === curr.id,
        );

        return [
          ...prev,
          {
            questionId: curr.id,
            answers: _.isEmpty(continuousAnswer)
              ? curr.answerType === 'KEYWORD'
                ? [{ text: '' }]
                : []
              : continuousAnswer.answer,
          },
        ];
      },
      [] as quizAnswerType[],
    );
  }

  // -> Test fill answer data in testtest course.
  useEffect(() => {
    if (questions.quiz.questions.length > 0) {
      handleInitialAnswer();

      if (questions.allQuestionAttempt.length > 0) {
        setCurrentQuestion(
          answers.findIndex(
            (item: any) =>
              item.questionId === questions.allQuestionAttempt[0].questionId,
          ),
        );
      }
    }
  }, [questions]);

  useEffect(() => {
    if (questions.allQuestionAttempt.length > 0) {
      setQuestionAttemptList(questions.allQuestionAttempt);
    }
  }, [questions]);

  if (answers.length > 0) {
    return (
      <Box>
        {qPage === 'sheet' ? (
          <QuizSheets
            title={questions.quiz.title}
            questions={questions.quiz.questions}
            answers={answers}
            setAnswers={setAnswers}
            setCurrentAnswer={setCurrentAnswer}
            setQPage={setQPage}
            currentQuestion={currentQuestion}
            handleChangePage={handleChangePage}
            updateProgress={updateQuizProgress}
            loading={loading}
            limitTime={limitTime}
            countdown={questions.quiz.countdown}
            status={status}
            submitQuiz={() => submitQuiz(questions.quizAttemptId)}
          />
        ) : (
          <QuizAnswerTotal
            questions={questions}
            answers={answers}
            setQPage={setQPage}
            submitQuiz={() => submitQuiz(questions.quizAttemptId)}
          />
        )}
      </Box>
    );
  }

  return null;
}

function QuizSheets({
  title,
  questions,
  answers,
  setAnswers,
  setCurrentAnswer,
  setQPage,
  currentQuestion,
  handleChangePage,
  updateProgress,
  loading,
  limitTime,
  countdown,
  status,
  submitQuiz
}: {
  title: string;
  questions: questionType[];
  answers: quizAnswerType[];
  setAnswers: React.Dispatch<React.SetStateAction<quizAnswerType[]>>;
  setCurrentAnswer: Function;
  setQPage: React.Dispatch<React.SetStateAction<string>>;
  currentQuestion: number;
  handleChangePage: Function;
  updateProgress: Function;
  loading: boolean;
  limitTime: dayjs.Dayjs;
  countdown: boolean;
  status?: string;
  submitQuiz: Function;
}) {
  function chooseAnswer(choiceId: string, questionId: string) {
    const currentQuestion = questions.find(
      (question) => question.id === questionId,
    );
    const otherQuestionAnswers = answers.filter(
      (answer) => answer.questionId !== questionId,
    );

    if (
      currentQuestion !== undefined &&
      currentQuestion.answerType === 'MULTIPLE'
    ) {
      const currentQuestionAnswer = answers.find(
        (answer) => answer.questionId === questionId,
      )?.answers;
      if (currentQuestionAnswer !== undefined) {
        const choosedAnswers = currentQuestionAnswer.find(
          (answer) => answer.choiceId === choiceId,
        )
          ? [
              ...currentQuestionAnswer.filter(
                (answer) => answer.choiceId !== choiceId,
              ),
            ]
          : [...currentQuestionAnswer, { choiceId: choiceId }];
        setAnswers([
          ...otherQuestionAnswers,
          { questionId: questionId, answers: choosedAnswers },
        ]);
        setCurrentAnswer({ questionId: questionId, answers: choosedAnswers });
      }
    } else {
      setAnswers([
        ...otherQuestionAnswers,
        { questionId: questionId, answers: [{ choiceId: choiceId }] },
      ]);
      setCurrentAnswer({
        questionId: questionId,
        answers: [{ choiceId: choiceId }],
      });
    }
  }

  function writeAnswer(keyword: string, questionId: string) {
    const otherQuestionAnswers = answers.filter(
      (answer) => answer.questionId !== questionId,
    );
    setAnswers([
      ...otherQuestionAnswers,
      { questionId: questionId, answers: [{ text: keyword }] },
    ]);
    setCurrentAnswer({ questionId: questionId, answers: [{ text: keyword }] });
  }

  const qLength = questions.length;
  const isAnswerNotCompleted =
    answers.reduce(
      (prev, curr) =>
        curr.answers.length === 0 || curr.answers[0].text === ''
          ? prev + 1
          : prev,
      0,
    ) > 0;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentQuestion]);

  function checkAnswer() {
    updateProgress();
    if (!isAnswerNotCompleted) {
      setQPage('total');
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',

          '@media (max-width: 699px)': {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Box>
          <Typography variant="h5">{title}</Typography>
        </Box>
        {countdown ?
          <Countdown limitTime={limitTime} status={status} countdown={countdown} submitQuiz={submitQuiz} />
          : <></>
        }
      </Box>
      <Question
        question={questions[currentQuestion]}
        answer={
          answers.find(
            (answer) => answer.questionId === questions[currentQuestion].id,
          ) || answers[0]
        }
        chooseAnswer={chooseAnswer}
        writeAnswer={writeAnswer}
        index={currentQuestion}
      />
      <QuizController
        currentQuestion={currentQuestion}
        changeCurrentQuestion={handleChangePage}
        updateProgress={updateProgress}
        qLength={qLength}
        isAnswerNotCompleted={isAnswerNotCompleted}
        checkAnswer={checkAnswer}
        sx={{ marginY: '20px', textAlign: 'center' }}
        loading={loading}
      />
      <QuestionSelector
        currentQuestion={currentQuestion}
        changeCurrentQuestion={handleChangePage}
        answers={answers}
        questions={questions}
        loading={loading}
      />
    </>
  );
}

function QuizController({
  currentQuestion,
  changeCurrentQuestion,
  qLength,
  isAnswerNotCompleted,
  checkAnswer,
  sx,
  loading
}: {
  currentQuestion: number;
  changeCurrentQuestion: Function;
  updateProgress: Function;
  qLength: number;
  isAnswerNotCompleted: boolean;
  checkAnswer: Function;
  sx?: object;
  loading: boolean
}) {
  return (
    <Box sx={sx || { marginBottom: '10px', textAlign: 'end' }}>
      <ShadowButton
        disabled={currentQuestion === 0 || loading}
        variant="contained"
        color="tertiary"
        onClick={() => changeCurrentQuestion(currentQuestion - 1)}
        sx={{ marginRight: '30px' }}
      >
        <Iconify
          icon="eva:arrow-ios-back-fill"
          sx={{
            height: 20,
            width: 20,
            mr: 1,
            textShadow: '2px 2px 4px #000000',
          }}
        />
        Back
      </ShadowButton>
      {currentQuestion + 1 < qLength && (
        <ShadowButton
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => changeCurrentQuestion(currentQuestion + 1)}
        >
          Next
          <Iconify
            icon="eva:arrow-ios-forward-fill"
            sx={{ height: 20, width: 20, ml: 1 }}
          />
        </ShadowButton>
      )}
      {currentQuestion + 1 === qLength && (
        <ShadowButton
          variant="contained"
          color="secondary"
          disabled={isAnswerNotCompleted || loading}
          onClick={() => checkAnswer()}
        >
          เสร็จสิ้น
          <Iconify
            icon="ic:baseline-send"
            sx={{ height: 20, width: 20, ml: 1 }}
          />
        </ShadowButton>
      )}
    </Box>
  );
}

function QuestionSelector({
  currentQuestion,
  changeCurrentQuestion,
  answers,
  questions,
  loading
}: {
  currentQuestion: number;
  changeCurrentQuestion: Function;
  answers: quizAnswerType[];
  questions: questionType[];
  loading: boolean
}) {
  const qLength = questions.length;

  return (
    <Box
      sx={{
        marginTop: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Pagination
        disabled={loading}
        count={qLength}
        onChange={(e, value) => {
          changeCurrentQuestion(value - 1);
          window.scrollTo(0, 0);
        }}
        page={currentQuestion + 1}
        showFirstButton
        showLastButton
        renderItem={(item) => {
          const questionId =
            questions?.[item.page !== null ? item.page - 1 : 0]?.id;
          const answer = answers.find(
            (each) => each?.questionId === questionId,
          );
          const isAnswerExist =
            answer !== undefined &&
            item.type === 'page' &&
            !(answer.answers.length === 0 || answer.answers[0].text === '');
          const isCurrentQuestion =
            item.type === 'page' && item.page === currentQuestion + 1;
          return (
            <QuestionPaginationItemStyle
              isCurrentQuestion={isCurrentQuestion}
              isAnswerExist={isAnswerExist}
              {...item}
            />
          );
        }}
      />
    </Box>
  );
}

interface QuestionPaginationItemProps extends PaginationItemProps {
  isAnswerExist?: boolean;
  isCurrentQuestion?: boolean;
}

const QuestionPaginationItemStyle = styled(PaginationItem, {
  shouldForwardProp: (prop) =>
    prop !== 'isAnswerExist' && prop !== 'isCurrentQuestion',
})<QuestionPaginationItemProps>(
  ({ isAnswerExist, isCurrentQuestion, theme }) => {
    return {
      borderRadius: 100,
      maxWidth: 40,
      maxHeight: 40,
      minWidth: 40,
      minHeight: 40,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: isCurrentQuestion
        ? `${theme.palette.primary.main} !important`
        : isAnswerExist
        ? `${theme.palette.primary.light} !important`
        : theme.palette.background.default,
      border: `1px solid ${
        isCurrentQuestion || isAnswerExist
          ? theme.palette.primary.main
          : theme.palette.background.paper
      }`,
      color:
        isCurrentQuestion || isAnswerExist
          ? theme.palette.primary.contrastText
          : theme.palette.text.secondary,
    };
  },
);
