import React from 'react';
import { Box } from '@mui/material';
import Image from '../../../components/Image';

export default function LectureCover({ url }: { url?: string }) {
  return (
    <Box sx={{ position: 'relative', width: '100%', paddingBottom: '37.65%' }}>
      <Image
        disabledEffect
        visibleByDefault
        alt="cover"
        src={url}
        sx={{ width: '100%', height: '100%', position: 'absolute' }}
      />
    </Box>
  );
}
