import { useMemo, useEffect } from 'react'
import dayjs from 'dayjs'

import axiosInstance from '../../utils/axios'
import useAuth from '../../hooks/useAuth'
import { BornBitType } from '../../@types/bornbit'

import { dispatch } from '../../redux/store'
import { setBornBitsNotification } from '../../redux/slices/bornbit'

export default function EventBornBit() {
  const { isAuthenticated } = useAuth()
  const isDailyCheckIn = useMemo<boolean>(() => {
    const checkIn = localStorage.getItem('bb-check-in')
    if (checkIn !== null) {
      return dayjs().isSame(dayjs(JSON.parse(checkIn)), 'day')
    } else return false
  }, [])

  const checkInBornBit = async () => {
    try {
      const response = await axiosInstance.post('/bornbits/checkin')
      const data = await response.data
      
      if (data && data.success) {
        localStorage.setItem('bb-check-in', JSON.stringify(dayjs()))
        data.isCheckinCreated && (
          dispatch(setBornBitsNotification({
            id: 'bb-daily-check-in',
            type: BornBitType.NOTIFICATION,
            message: 'ทำการ Daily Check-in แล้ว'
          }))
        )
      }
    } catch {}
  }

  useEffect(() => {
    if (isAuthenticated && !isDailyCheckIn) {
      checkInBornBit()
    }
  }, [isAuthenticated, isDailyCheckIn])

  return null
}