import React from 'react';
import { Box, Typography } from '@mui/material';
import { profileSkillType } from '../../../@types/profile';

export default function Skills({skills}: {skills: profileSkillType[]}) {
  if (skills.length === 0) return <></>
  return (
    <Box>
      <Typography variant='h4'>สกิลหลักที่มี</Typography>
      <Box sx={{display: 'flex', gap: '10px'}}>
        {
          skills.map((skill, index) => <Skill key={`skill-${index+1}`} text={skill.name}/>)
        }
      </Box>
    </Box>
  )
}

export function Skill({text}: {text: string}) {
  return(
    <Box sx={{backgroundColor: 'error.main', padding: '0px 5px 5px 5px', borderRadius: '5px', height: '29px'}}>
      <Typography variant='caption' sx={{color: 'common.white'}}>
        {text}
      </Typography>
    </Box>
  )
}
