import React, { useState, useEffect } from 'react';
import { Container, Card, Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import LectureSkeleton from '../../components/skeleton/Lecture';
import HelmetMetaData from '../../components/Helmet';
import Image from '../../components/Image';
import axios from '../../utils/axios';
import {
  getCourseLessons,
  setLessonTimeStamp,
} from '../../redux/slices/lesson';
import { useDispatch, useSelector } from '../../redux/store';

import Quiz from './Quiz';
import Lecture from './Lecture';
import Devlab from './Devlab';
import CourseNoPermission from './CourseNoPermission';
import CourseError from './CourseError';
import { initialLesson } from './initialState';
import { lessonType } from '../../@types/lesson';
import useAuth from '../../hooks/useAuth';
import ResultAlert from '../../components/ResultAlert';

const ContentCardStyle = styled(Card)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0),
  backgroundImage: 'none',
  color: theme.palette.text.primary,
  padding: '38px',
  maxWidth: '1062.38px',
  '@media (min-width: 2000px)': {
    maxWidth: '1280px',
  },
}));

export default function Lesson() {
  const dispatch = useDispatch();
  const { courseSlug, lessonId } = useParams();
  const [lesson, setLesson] = useState<lessonType>(initialLesson);
  const [loading, setLoading] = useState(true);
  const [accessible, setAccessible] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const { active } = useSelector((state) => state.device);

  const { isInitialized, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const getLesson = async (sf: string) => {
    try {
      const response = await axios.get(
        `/courses/${courseSlug}/lessons/${lessonId}`,
        {
          params: {
            sf,
          },
        },
      );
      // @ts-ignore redux type error
      dispatch(getCourseLessons(courseSlug || ''));
      setLesson({ ...response.data });
      setAccessible(true);
      setLoading(false);
    } catch (error: any) {
      if (error.statusCode !== 403 && error.statusCode !== 503) {
        navigate('/404', { replace: true });
      } else {
        setErrorCode(error.statusCode);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(setLessonTimeStamp(0));
  }, []);

  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      getLesson('un');
    } else if (active !== null) {
      if (active) {
        getLesson('au');
      } else {
        navigate('/setting/devices', { replace: true });
      }
    }
  }, [active, isInitialized]);

  const { courseLessons } = useSelector((state) => state.lesson);
  const location = useLocation();
  const isHomework = lesson.isEndQuestion;

  const scrollToComment = () => {
    const element = document.getElementById('homework-title');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <>
      <HelmetMetaData
        title={courseLessons.title}
        currentUrl={location.pathname}
      />
      {lesson.type === 'QUIZ' && (
        <Image
          src="/logos/school-full-824x624.png"
          sx={{ width: '348px', margin: '30px auto' }}
        />
      )}
      <Container
        sx={{
          marginTop: '30px',
          maxWidth: '1062.38px',
          '@media (min-width: 2000px)': {
            maxWidth: '1280px',
          },
        }}
      >
        {isHomework && (
          <ResultAlert
            severity={'info'}
            sx={{ marginY: '20px' }}
            content={
              <Box sx={{ display: 'flex', gap: '5px' }}>
                บทเรียนนี้มีการบ้าน ผู้เรียนจะต้องทำการ
                <Box
                  onClick={scrollToComment}
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {'"ส่งการบ้าน"'}
                </Box>
                และรอเจ้าหน้าที่ตรวจสอบเพื่อจบบทเรียน
              </Box>
            }
          />
        )}
        {!accessible && !loading ? (
          errorCode === 503 ? (
            <CourseError />
          ) : (
            <CourseNoPermission title={'เนื้อหาบทเรียนถูกล็อค'} />
          )
        ) : (
          <ContentCardStyle
            sx={{
              margin: 'auto',
              padding: '0px',
              ...(lesson.type === 'DEVLAB' ? { boxShadow: 'none' } : {}),
            }}
          >
            {loading ? (
              <LectureSkeleton />
            ) : lesson.type === 'QUIZ' ? (
              <Box
                sx={{ padding: '38px', backgroundColor: 'background.paper' }}
              >
                <Quiz lesson={lesson} />
              </Box>
            ) : lesson.type === 'DEVLAB' ? (
              <Box>
                <Devlab lesson={lesson} />
              </Box>
            ) : (
              <>
                <Lecture lesson={lesson} />
              </>
            )}
          </ContentCardStyle>
        )}
      </Container>
    </>
  );
}
