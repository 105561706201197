import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axios';
import { courseCatalogCardType } from '../../@types/courseCatalog';
import { Waypoint } from 'react-waypoint';
import ProductSearchResult from './ProductSearchResult';
import Iconify from '../../components/Iconify';
import { Link } from 'react-router-dom';

export default function CatalogAllCourse() {
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<boolean>(false);
  const [coursesSearch, setCoursesSearch] = useState<courseCatalogCardType[]>(
    [] as courseCatalogCardType[],
  );
  const take = 16;

  const getSearchCourse = async (curPage: number): Promise<void> => {
    try {
      setSearchLoading(true);

      const response = await axiosInstance.get(
        `/catalog/courses?&page=${curPage + 1}&take=${take}`,
      );
      const data = await response.data;
      setCoursesSearch(
        curPage === 0 ? data.data : [...coursesSearch, ...data.data],
      );
      setLastPage(!data.meta.hasNextPage);
      setPage(curPage + 1);
      setSearchLoading(false);
    } catch (error) {
      return;
    }
  };

  function getNextPage() {
    !searchLoading && !lastPage && getSearchCourse(page);
  }

  useEffect(() => {
    getNextPage();
  }, []);

  return (
    <Box>
      <Box sx={{ marginY: '20px' }}>
        <Typography sx={{ marginX: '30px' }} variant="h3">
          หลักสูตรทั้งหมด
        </Typography>
        <Box component={Link} to="/search">
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginX: '30px',
              color: 'text.secondary',
              textDecoration: 'underline',
            }}
            variant="body1"
          >
            <Iconify icon={'ic:round-search'} />
            ค้นหาหลักสูตร
          </Typography>
        </Box>
      </Box>
      <ProductSearchResult
        type={'course'}
        courses={coursesSearch}
        bundles={[]}
        loading={searchLoading}
      />
      <Waypoint onEnter={() => getNextPage()} />
    </Box>
  );
}
