import { Pagination } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PaginationStyled = styled(Pagination)(({ theme }) => ({
  '&.MuiPagination-root button': {
    color: theme.palette.text.primary,
    ':hover': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.warning.lighter
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.warning.lighter,
      color: theme.palette.warning.main,
      ':hover': {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light,
      }
    }
  },
  '&.MuiPagination-root div': {
    color: theme.palette.grey[800],
    '&.MuiPaginationItem-ellipsis': {
      color: theme.palette.text.primary
    }
  },
}))