import React, { useMemo, useState, createContext } from "react";
import { ReactNode } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import typography from "./typography";
import palette from "./palette";

export const ThemeContext = createContext({ setNewPalette: (value: { palette: any, typography: any }) => {} })
export const themeList = ['light', 'dark']

const mode = window.localStorage.getItem('theme-mode') || ''
const selectedMode = themeList.includes(mode) ? mode as string : 'dark'

type Props = {
  children: ReactNode;
};

export default function Theme({ children }: Props) {
  const [options, setOptions] = useState({
    palette: palette[selectedMode as 'light' | 'dark'],
    typography: typography
  })

  const customPalette = useMemo(() => ({
    setNewPalette: (value: { palette: any, typography: any }) => {
      const newPalette: { palette: any, typography: any } = (Object.keys(value.palette).find((item: string) => item === 'mode')) ? { ...value, palette: { ...palette[value.palette.mode as "light" | "dark"]} } : { ...value }
      setOptions((prevState: any) => ({ palette: { ...prevState.palette, ...newPalette.palette }, typography: { ...prevState.typography, ...newPalette.typography } }))
    }
  }), [])

  const customTheme = useMemo(() => (
    createTheme({
      palette: options.palette,
      typography: options.typography
    })
  ), [options])

  return (
    <ThemeContext.Provider value={customPalette}>
      <ThemeProvider theme={customTheme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
