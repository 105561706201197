import React from "react";
import { Box } from '@mui/material'

import { lessonType } from "../../../@types/lesson"
import CodingDetail from "./DevlabDetail";

interface DevlabProps {
  lesson: lessonType
}

function Devlab({ lesson }: DevlabProps) {
  return (
    <Box sx={{ color: 'text.secondary'}}>
      <CodingDetail detail={lesson} />
    </Box>
  )
}

export default Devlab