import React, { useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { Box, Grid, Typography } from '@mui/material';
import CardWithCover from '../../../components/CardWithCover'
import { BundleCatalogType } from '../../../@types/courseCatalog'
import BundleCardContent from '../../../components/bundle/BundleCardContent'
import { useParams } from 'react-router-dom';

export default function RelatedCourses() {
  const [bundles, setBundles] = useState<BundleCatalogType[]>([] as BundleCatalogType[])
  const { bundleSlug } = useParams()
  useEffect(() => {
    getSearchCourse()
  }, [])
  const getSearchCourse = async (): Promise<void> => {
    try {
      const response = await axios.get(`/catalog/bundle/${bundleSlug}/recommended-bundle`)
      setBundles(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      {bundles.length ?
        <Box sx={{paddingY: '50px'}}>
          <Typography sx={{margin: '0px 0px 20px 20px'}} variant="h3">แพ็กเกจอื่น ๆ ที่น่าสนใจ</Typography>
          <BundleSearchResult bundles={bundles}/>
        </Box>
        : <></>
      }
    </>
  )
}

function BundleSearchResult({bundles}: {bundles: BundleCatalogType[]}) {
  return (
      <Box sx={{padding: '10px'}}>
        <Grid container sx={{rowGap: '20px'}}>
          {bundles.map((bundleData)=>
          <Grid key={bundleData.id} item xs={12} sm={6} md={4} xl={3} sx={{justifyContent: 'center', display: 'flex'}}>
            <Box sx={{width: '290px',}}>
              <CardWithCover
                key={bundleData.title}
                cover={bundleData.images.cover}
                label={
                  <Typography
                    variant='body1'
                    sx={{
                      background: 'linear-gradient(162deg, rgba(251,193,87,1) 0%, rgba(227,67,11,1) 100%);',
                      padding: '5px 10px',
                      color: 'common.white',
                      borderRadius: '5px',
                    }}
                  >
                    BUNDLE
                  </Typography>
                }
                link={`/bundle/${bundleData.slug}`}
                child={<BundleCardContent bundle={bundleData}/>}
              />
            </Box>
          </Grid>
        )}
        </Grid>
      </Box>
  )
}
