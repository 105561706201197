import React from 'react';

import { courseCatalogCardType } from '../../@types/courseCatalog';
import CardWithCover from '../CardWithCover';

import CourseCardContent from './CourseCardContent';
import CourseCardButton from './CourseCardButton';

export function CourseCard({
  courseData,
}: {
  courseData: courseCatalogCardType;
}) {
  return (
    <CardWithCover
      key={courseData.title}
      cover={courseData.images.card}
      instructors={courseData.instructors}
      link={`/course/${courseData.slug}`}
      child={<CourseCardContent courseData={courseData} />}
      specialChild={<CourseCardButton courseData={courseData} />}
    />
  );
}
