import React, { useState, useEffect, useRef } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import Icon from './Iconify'

interface ToastAlertProps {
  title?: string | null
  timeCount?: number
  startIcon?: React.ReactNode | null
  endIcon?: React.ReactNode | null
  open: boolean
  handleClose: Function
}

const TIME_COUNT = 4

export default function ToastAlert({
  title = '',
  timeCount = TIME_COUNT,
  startIcon,
  endIcon,
  open,
  handleClose
}: ToastAlertProps) {
  const [show, setShow] = useState<boolean>(false)
  const [time, setTime] = useState<number>(timeCount)
  const intervalRef = useRef<number | null>(null)
  const toastRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (open) {
      setMargin()
      startInterval()
    }
  }, [open])

  useEffect(() => {
    if (time <= 0) stopInterval()
  }, [time])

  function setMargin() {
    setTimeout(() => setShow(true), 100)
  }

  function startInterval() {
    if (intervalRef.current !== null) return
    intervalRef.current = window.setInterval(() => {
      setTime((prevTime: number) => prevTime - 1)
    }, 1000)
  }

  function stopInterval() {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current)
      intervalRef.current = null
      setTimeout(handleClose, 500)
      setShow(false)
      setTime(timeCount)
    }
  }

  if (open) {
    return (
      <Box
        ref={toastRef}
        sx={{
          opacity: show ? 1 : 0,
          marginBottom: show ? '10px' : `-${toastRef.current?.clientHeight ?? 40}px`,
          transition: 'all .5s ease-in-out',
        }}
      >
        <Box
          onClick={() => stopInterval()}
          sx={{
            gap: '10px',
            padding: '8px 10px',
            backgroundColor: 'background.paper',
            width: 'fit-content',
            maxWidth: '500px',
            borderRadius: '20px',
            boxShadow: '0px 5px 15px -1px rgba(0,0,0, .3)',
            cursor: 'pointer'
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              gap: '10px',
            }}
          >
            {startIcon !== null 
              ? startIcon 
                ?? (
                  <Icon 
                    icon="ic:round-check"
                    sx={{
                      width: '25px',
                      height: '25px',
                      padding: '3px',
                      backgroundColor: 'secondary.main',
                      color: 'common.white',
                      borderRadius: '100%',
                    }}
                  />
                )
              : null
            }

            <Typography
              sx={{
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>

            {(endIcon !== null && endIcon) && endIcon}
          </Stack>
        </Box>
      </Box>
    )
  }

  return null
}