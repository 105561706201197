import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { ProjectDetailType } from '../../../@types/projectGallery'
import axios from '../../../utils/axios';
import { ProjectGalleryCard } from  '../../projectGallery/GalleryProjectList/GalleryProjectGroup'

import ProjectGallerySkeleton from '../../../components/skeleton/projectGallery'
import { Waypoint } from 'react-waypoint';

export default function ProjectsProfileList({profileId}: {profileId: string}){
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState(false)
  const [projects, setProjects] = useState<ProjectDetailType[]>([])

  const take = 16

  useEffect(() => {
    getSearchCourse(0)
  }, [])

  const getSearchCourse = async (curPage: number, params?: string, ): Promise<void> => {
    try {
      setLoading(true)
      const response = await axios.get(`/projects/gallery?sortBy=userFeatured&userId=${profileId}&page=${curPage + 1}&take=${take}`)
      setProjects(curPage === 0 ? response.data.data : [...projects, ...response.data.data])
      setLastPage(!response.data.meta.hasNextPage)

      setPage(curPage + 1)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  function getNextPage() {
    (!loading && !lastPage) && getSearchCourse(page)
  }

  return (
    <Box>
      <Grid justifyContent="flex-start" container sx={{ gap: '26px 10px' }} >
        { projects.map((item, index) =>
          <Grid item xs={11.91} sm={5.8} md={3.8} lg={2.91} key={`project-f3-card-${index+1}`} justifyContent="center" sx={{ display: 'grid' }}>
            <ProjectGalleryCard projectData={item} path={`/devproject/${item.id}`}/>
          </Grid>
        )}
        {
          loading && Array(16).fill(0).map((_, index: number) => (
            <ProjectGallerySkeleton key={`skeleton-project-card-${index}`} index={index} />
          ))
        }
      </Grid>
      <Waypoint onEnter={() => getNextPage()}/>
    </Box>
  )
}
