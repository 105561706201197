import React from 'react';
import { Typography, Stack, Box } from '@mui/material';
import ReviewRateStar from './ReviewRateStar';
import { MIN_STU_SHOWN } from '../config';
import Iconify from './Iconify';
import { fTSeparator } from '../utils/formatNumber';

export default function Rating({ rate }: { rate: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Stack direction="row" justifyContent="start" alignItems="start">
        {Array(5)
          .fill(0)
          .map((_, index: number) => (
            <ReviewRateStar
              key={`review-icon-${index}`}
              width="15px"
              height="15px"
              display={rate - index}
            />
          ))}
      </Stack>
      <Typography
        variant="body2"
        sx={{ color: 'primary.main', marginTop: '6px' }}
      >
        {rate}
      </Typography>
    </Box>
  );
}

export function RatingInCard({
  rate,
  studentCount,
}: {
  rate: number;
  studentCount: number;
}) {
  return rate ? (
    <Rating rate={rate} />
  ) : studentCount >= MIN_STU_SHOWN ? (
    <Box
      sx={{
        color: 'text.primary',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <Iconify
        icon="eva:people-fill"
        sx={{
          width: '15px',
          height: '15px',
          color: 'text.primary',
        }}
      />
      <Typography>{studentCount} ท่าน</Typography>
    </Box>
  ) : (
    <></>
  );
}

export function RatingInCover({
  rate,
  reviewCount,
  studentCount,
}: {
  rate: number;
  reviewCount: number;
  studentCount: number;
}) {
  return rate > 0 ? (
    <>
      <Stack direction="row">
        {Array(5)
          .fill(0)
          .map((_, index: number) => (
            <ReviewRateStar
              key={`review-icon-${index}`}
              width="15px"
              height="15px"
              display={rate - index}
            />
          ))}
      </Stack>
      {`${rate} (${fTSeparator(reviewCount)} รีวิว)`}
      {studentCount >= 20 ? (
        ` จากผู้เรียน ${fTSeparator(studentCount)} ท่าน`
      ) : (
        <></>
      )}
    </>
  ) : studentCount >= 20 ? (
    <Stack
      direction="row"
      alignItems={'center'}
      sx={{ color: 'text.secondary' }}
      gap={'5px'}
    >
      <Iconify
        icon="eva:people-fill"
        sx={{
          width: '15px',
          height: '15px',
        }}
      />
      <Typography>ผู้เรียน {studentCount} ท่าน</Typography>
    </Stack>
  ) : (
    <></>
  );
}
