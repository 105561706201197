import React, { Dispatch, SetStateAction, useRef } from 'react';
import {
  ListItemText,
  Collapse,
  Box,
  Typography,
  Grid,
  Button,
  Tooltip,
  Stack,
} from '@mui/material';
import Iconify from '../../../components/Iconify';
import { useTheme } from '@mui/material/styles';
import { sectionType } from '../../..//@types/courseLesson';
import { Link, useParams } from 'react-router-dom';
import { LessonParamsType } from '../../../@types/params';

import { fTimeDuration } from '../../../utils/formatTime';

interface PreviewLessonType {
  title: string;
  id: string;
  sectionTitle: string;
  duration: number;
}

export default function Lessons({ sections }: { sections: sectionType[] }) {
  const { courseSlug } = useParams<LessonParamsType>();
  const previewRef = useRef<HTMLAnchorElement | null>(null);

  const previewLessonList = sections.reduce((prev, section) => {
    const previewLessons = section.lessons.reduce((prevv, lesson) => {
      return lesson.isPreview
        ? [
            ...prevv,
            {
              title: lesson.title,
              id: lesson.id,
              sectionTitle: section.title,
              duration: lesson.duration,
            },
          ]
        : prevv;
    }, [] as PreviewLessonType[]);
    return [...prev, ...previewLessons];
  }, [] as PreviewLessonType[]);

  return (
    <>
      <Box ref={previewRef}>
        {previewLessonList.length ? (
          <>
            <Box
              sx={{
                display: 'flex',
                margin: '30px 0px 10px 0px',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Iconify
                icon="tdesign:play-demo"
                sx={{ width: '30px', height: '30px' }}
              />
              <Typography variant="h4">ตัวอย่างบทเรียนทั้งหมด</Typography>
            </Box>
            {previewLessonList.map((lesson) => (
              <PreviewLesson
                key={`preview-lesson-${lesson.id}`}
                lesson={lesson}
                courseSlug={courseSlug}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={{ margin: '30px 0px 20px 0px' }}>
        <Typography variant="h4">เนื้อหาในคอร์ส</Typography>
      </Box>
      {sections.map((section) => (
        <SectionItem key={section.id} section={section} />
      ))}
    </>
  );
}

function SectionItem({ section }: { section: sectionType }) {
  const [open, setOpen] = React.useState(section.displayOrder === 1);
  return (
    <>
      <ListItemText>
        <Section
          title={section.title}
          displayOrder={section.displayOrder}
          setOpen={setOpen}
          open={open}
        />
      </ListItemText>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {section.lessons.map((lesson) => (
          <Lesson
            key={lesson.id}
            title={lesson.title}
            duration={lesson.duration}
            id={lesson.id}
            isPreview={lesson.isPreview}
          />
        ))}
      </Collapse>
    </>
  );
}

function Section({
  title,
  displayOrder,
  setOpen,
  open,
}: {
  title: string;
  displayOrder: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}) {
  return (
    <Grid
      container
      sx={{ alignItems: 'center', marginTop: '20px', cursor: 'pointer' }}
      onClick={() => setOpen((prev: boolean) => !prev)}
    >
      <Grid item xs={2} sm={1.15}>
        <Typography
          sx={{ fontSize: '36px', color: 'text.secondary', lineHeight: '1' }}
        >
          {displayOrder.toString().padStart(2, '0')}
        </Typography>
      </Grid>
      <Grid item xs={8.5} sm={9.35}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item xs={1.5} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Box>
          <Iconify
            icon={
              open ? 'dashicons:arrow-up-alt2' : 'dashicons:arrow-down-alt2'
            }
            sx={{ width: 24, height: 24 }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function Lesson({
  title,
  duration,
  id,
  isPreview,
}: {
  title: string;
  duration: number;
  id: string;
  isPreview: boolean;
}) {
  const theme = useTheme();
  const { courseSlug } = useParams<LessonParamsType>();
  return (
    <Grid container sx={{ alignItems: 'end', marginTop: '10px' }}>
      <Grid item xs={1.15} sm={1.15}></Grid>
      <Grid item xs={7} md={8.5} xl={8.85}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid
        item
        xs={3.85}
        md={2.3}
        xl={2}
        sx={{ justifyContent: 'flex-end', display: 'flex' }}
      >
        {isPreview ? (
          <Tooltip title="พรีวิว" placement="left" arrow>
            <Button
              variant="contained"
              href={`/course/${courseSlug}/${id}`}
              sx={{
                padding: '0px 8px',
                borderRadius: '50px',
                color: 'text.button',
                gap: '3px',
                alignItems: 'center',
                minWidth: 'fit-content !important',
                whiteSpace: 'nowrap',
              }}
            >
              <Box sx={{ marginTop: '2px' }}>
                {duration
                  ? (fTimeDuration(duration, 'AUTO') as string)
                  : 'พรีวิว'}
              </Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '17px', height: '17px' }}
              >
                <Iconify
                  icon={duration ? 'material-symbols:play-circle' : 'mdi:paper'}
                  sx={{ width: '100%', height: '100%' }}
                />
              </Stack>
            </Button>
          </Tooltip>
        ) : duration ? (
          <Typography
            variant="body2"
            sx={{
              border: `${theme.palette.text.secondary} 1px solid`,
              padding: '2px 8px',
              borderRadius: '50px',
              whiteSpace: 'nowrap',
            }}
          >
            {Math.floor(duration / 60)} นาที
          </Typography>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}

function PreviewLesson({
  lesson,
  courseSlug = '',
}: {
  lesson: PreviewLessonType;
  courseSlug?: string;
}) {
  const link = `/course/${courseSlug}/${lesson.id}`;
  return (
    <>
      <Button
        href={link}
        sx={{
          gap: '8px',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginY: '5px',
          width: '100%',
        }}
      >
        <Box>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            section: {lesson.sectionTitle}
          </Typography>
          <Typography variant="body1">{lesson.title}</Typography>
        </Box>
        <PreviewButton duration={lesson.duration} />
      </Button>
    </>
  );
}

function PreviewButton({
  duration,
  link,
}: {
  duration?: number;
  link?: string;
}) {
  return (
    <Button
      variant="contained"
      component={link ? Link : Button}
      to={link}
      sx={{
        padding: '0px 8px',
        borderRadius: '50px',
        color: 'text.button',
        gap: '3px',
        alignItems: 'center',
        height: '25px',
        width: 'fit-content',
        minWidth: 'fit-content',
        whiteSpace: 'nowrap',
      }}
    >
      <Box sx={{ marginTop: '2px' }}>
        {duration ? (fTimeDuration(duration, 'AUTO') as string) : 'พรีวิว'}
      </Box>
      <Stack
        direction="row"
        justifyItems="center"
        alignItems="center"
        sx={{ width: '17px', height: '17px' }}
      >
        <Iconify
          icon={duration ? 'material-symbols:play-circle' : 'mdi:paper'}
          sx={{ width: '100%', height: '100%' }}
        />
      </Stack>
    </Button>
  );
}
