import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axios";
import CheckinCalendar from "./CheckinCalendar";
import dayjs, { Dayjs } from "dayjs";
import MonthSelector from "./MonthSelector";
import { Box, Grid, Typography } from "@mui/material";

export interface CheckinLogItemType {
  continue: number;
  createdAt: string;
  date: string;
  total: number;
  userId: string;
}

export interface CheckinLogCalendarItemType extends CheckinLogItemType {
  day: number;
}

export default function CheckinLog() {
  const now = dayjs()
  const [checkinLogs, setCheckinLogs] = useState<number[]>([])
  const [prevIndexMonth, setPrevIndexMonth] = useState(0)
  const [loading, setLoading] = useState(true)
  const selectedDate = now.subtract(prevIndexMonth, 'month').date(1)

  async function getCheckinLog() {
    try {
      setLoading(true)
      const res = await axiosInstance.get(`/bornbits/checkin/histories?month=${selectedDate.year()}-${selectedDate.month()+1}`)
      const data = res.data.data.map((each: CheckinLogItemType) => {
        const date = dayjs(each.date)
        return date.date()
      })
      setCheckinLogs(data)
      setLoading(false)
    } catch { setLoading(false) }
  }

  useEffect(() => {
    getCheckinLog()
  }, [prevIndexMonth])
  return (
    <Box sx={{paddingX: '20px', height: '400px', marginTop: '20px'}}>
      <MonthSelector
        date={selectedDate}
        shift={(index: number) => setPrevIndexMonth(prevIndexMonth + index)}
        shiftMonth={prevIndexMonth * -1}
        maxLeft={5}
        maxRight={0}
        disabled={loading}
      />
      <CheckinCalendar 
        amount={selectedDate.daysInMonth()} 
        checkinLogs={checkinLogs} 
        startIndex={selectedDate.day()} 
        shiftMonth={prevIndexMonth * -1}
        loading={loading}
      />
      <Grid container>
        <Grid item xs={5}>
          <Typography variant='caption'>
            เช็คอินทั้งหมด 56 วัน
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant='caption'>
            เช็คอินต่อเนื่อง 30 วัน
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Typography variant='caption'>
          เริ่มเรียนวันแรก 20 พ.ย. 63  
        </Typography>
      </Box>
      <Typography variant='caption' sx={{color: 'text.secondary'}}>
        *หมายเหตุ: การเช็คอินจะเริ่มนับหลังจากวันที่ xx xx xx เป็นต้นไป
      </Typography>
    </Box>
  )
}
