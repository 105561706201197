import { createSlice } from '@reduxjs/toolkit';

type SocketState = {
  authSocketConnected: boolean;
}

const initialState: SocketState = {
  authSocketConnected: false,
};

const slice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setAuthSocketConnected(state, action) {
      state.authSocketConnected = action.payload
    },
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
  setAuthSocketConnected
} = slice.actions;

