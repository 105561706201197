import React from 'react';
import { Box, Typography } from '@mui/material';
import { profileCommunicationLanguagesType } from '../../../@types/profile';

export default function ContactLanguages({communicationLanguages}: {communicationLanguages: profileCommunicationLanguagesType[]}) {
  if (communicationLanguages.length === 0) return <></>

  function getLangText() {
    return communicationLanguages.reduce((acc, cur, index) => acc + (index > 0 ? ', ': '') + cur.name, '')
  }

  return (
    <Box sx={{marginY: '20px'}}>
      <Typography variant='h4'>
        ภาษาหลักในการสื่อสาร
      </Typography>
      <Typography variant='body1'>
        {getLangText()}
      </Typography>
    </Box>
  )
}
