import React, { useState, useEffect } from 'react';
import { Box, Grid, useTheme, Switch, Typography, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { ProjectDetailType } from '../../../@types/projectGallery'
import EditorHeader from '../profileEditor/EditorHeader';
import axiosInstance from '../../../utils/axios';

import { ProjectGalleryCard } from  '../../projectGallery/GalleryProjectList/GalleryProjectGroup'
import ProjectGallerySkeleton from '../../../components/skeleton/projectGallery'
import { Waypoint } from 'react-waypoint';

import { alpha } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';

export default function ProjectsEditor({profileId}: {profileId: string}) {
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState(false)
  const [projects, setProjects] = useState<ProjectDetailType[]>([])

  const take = 16

  useEffect(() => {
    getSearchCourse(0)
  }, [])

  const getSearchCourse = async (curPage: number, params?: string, ): Promise<void> => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`/projects/gallery?sortBy=userFeatured&userId=${profileId}&page=${curPage + 1}&take=${take}`)
      setProjects(curPage === 0 ? response.data.data : [...projects, ...response.data.data])
      setLastPage(!response.data.meta.hasNextPage)
      setPage(curPage + 1)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  function getNextPage() {
    (!loading && !lastPage) && getSearchCourse(page)
  }

  return (
    <>
      <EditorHeader title='สถานะการเผยแพร่โปรเจค'/>
      <Box>
        <Grid justifyContent="flex-start" container sx={{ gap: '26px 10px' }} >
          { projects.map((item, index) =>
            <Grid item xs={11.91} sm={5.8} md={3.8} lg={2.91} key={`project-f3-card-${index+1}`} justifyContent="center" sx={{ display: 'grid' }}>
              <Box sx={{position: 'relative'}}>
                <ProjectEditor projectData={item}/>
                <ProjectGalleryCard projectData={item} path={`/devproject/${item.id}`}/>
              </Box>
            </Grid>
          )}
          {
            loading && Array(16).fill(0).map((_, index: number) => (
              <ProjectGallerySkeleton key={`skeleton-project-card-${index}`} index={index} />
            ))
          }
        </Grid>
        <Waypoint onEnter={() => getNextPage()}/>
      </Box>
    </>
  )
}

function ProjectEditor({projectData}: {projectData: ProjectDetailType}) {
  const theme = useTheme()
  const [featured, setFeatured] = useState(projectData.isUserProfileFeatured)
  const [anonymous, setAnonymous] = useState(projectData.anonymous)

  return (
    <Box sx={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, backgroundColor: alpha(theme.palette.background.default, 0.8), zIndex: 1}}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        { featured !== undefined && <FeaturedProjectEditor projectId={projectData.id} featured={featured} setFeatured={setFeatured}/> }
        <AnonymousProjectEditor projectId={projectData.id} anonymous={anonymous} setAnonymous={setAnonymous}/>
      </Box>
    </Box>
  )
}

function FeaturedProjectEditor({projectId, featured, setFeatured}: {projectId: string, featured: boolean, setFeatured: Function}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const locale = {
    on: 'ตั้งเป็นโปรเจกต์แนะนำ',
    off: 'ยกเลิกการแนะนำ'
  }

  async function submit() {
    try {
      setLoading(true)
      await swapFeatured()
      setLoading(false)
      setError('')
      setFeatured(!featured)
    } catch(error: any) {
      setLoading(false)
      setError(error.message[0])
    }
  }

  async function swapFeatured() {
    axiosInstance.put(`/projects/${projectId}/profile-featured`, {isFeatured: !featured} )
  }

  return (
    <Box>
      <Tooltip title={featured ? locale.off : locale.on} placement='top' arrow>
        <IconButton onClick={submit} disabled={loading}>
          {
            loading ? <CircularProgress size={15} sx={{marginLeft: '10px'}} color={'inherit'}/>
            : <Iconify sx={{width: '50px', height: '50px', color: featured ? 'primary.main': 'text.secondary'}} icon={featured ? 'solar:star-circle-bold-duotone' : 'solar:star-circle-bold'}/>
          }
        </IconButton>
      </Tooltip>
      {
        error &&
        <Typography variant='caption' sx={{color: 'error.main'}}>
          {error}
        </Typography>
      }
    </Box>
  )
}

function AnonymousProjectEditor({projectId, anonymous, setAnonymous}: {projectId: string, anonymous: boolean, setAnonymous: Function}) {
  const [isInitial, setIsInitital] = useState(true)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  async function submit() {
    try {
      setLoading(true)
      setSuccess(false)
      await saveChanges()
      setSuccess(true)
      setLoading(false)
      setIsInitital(true)
      setError('')
    } catch(error: any) {
      setLoading(false)
      setError(error.message[0])
    }
  }

  async function saveChanges() {
    axiosInstance.put(`/projects/${projectId}/anonymous`, {anonymous: anonymous} )
  }

  function onSwitchChange() {
    setAnonymous(!anonymous);
    setIsInitital(false)
    setSuccess(false)
  }

  return (

        <Box sx={{width: '190px'}}>
          <Typography variant='subtitle1'>การเผยแพร่แบบไม่ระบุตัวตน</Typography>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Iconify icon={'material-symbols:public'} sx={{width: '30px', height: '30px', color: !anonymous ? 'primary.main' : 'text.secondary'}}/>
              <Switch checked={anonymous} onChange={onSwitchChange} color={'primary'}/>
              <Iconify icon={'mdi:anonymous-circle'} sx={{width: '30px', height: '30px', color: anonymous ? 'primary.main' : 'text.secondary'}}/>
            </Box>
            <Button
              disabled={isInitial}
              onClick={submit}
              variant='contained'
              color='primary'
              sx={{
                color: 'text.button',
                padding: '0px',
                borderRadius: '8px'
              }}
            >
              {
                loading
                ? <CircularProgress size={15} sx={{marginLeft: '10px'}} color={'inherit'}/>
                : (success) ?
                  <Iconify icon='ep:success-filled'/>
                  :
                  'บันทึก'
              }
            </Button>
          </Box>
          {
            error ?
            <Typography variant='caption' sx={{color: 'error.main'}}>
              {error}
            </Typography>
            :
            !isInitial && <>
              {
                anonymous &&
                <Typography variant='caption' sx={{color: 'primary.main'}}>
                  ไม่
                </Typography>
              }

              <Typography variant='caption' sx={{color: 'text.secondary'}}>
                แสดงชื่อของคุณในโปรเจกต์
              </Typography>
            </>
          }
        </Box>

  )
}
