import React, { useState, useEffect, useRef } from 'react'
import { Typography, Grid, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { CategoryAndTagsType, PropsCategoryButton } from '../../../@types/projectGallery'

const CategoryButton = styled(Button)(({ theme }) => ({
  padding: '10px',
  flexGrow: 0,
  borderRadius: '8px',
  color: theme.palette.text.primary,
  '&:hover &.Mui-focusVisible': {
    backgroundColor: 'rgba(145, 158, 171, 0.2)',
  },
  '&.MuiButton-outlined': {
    borderColor: theme.palette.grey[700],
    '&.Mui-disabled': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.action.active,
      borderColor: theme.palette.action.active,
    },
  }
}))


export default function CategoryButtonComponent({ searchMode, setSearchMode, widthWindow, categoryList, categorySelected, submitCategorySelected }: PropsCategoryButton) {
  const categoryBar = useRef<HTMLDivElement>(null)
  const [elementWidth, setElementWidth] = useState<string>('100%')

  const setMaxWidth = (): string => {
    let result: string = '100%'

    if (categoryBar.current) {
      const width = (categoryBar.current.clientWidth - 50) / 4
      result = `${width}px`
    }

    return result
  }

  useEffect(() => {
    setElementWidth(setMaxWidth())
  }, [])

  return (
    <Grid ref={categoryBar} container justifyContent="center" alignItems="end" sx={{ padding: widthWindow >= 1200 ? '0px 80px' : '', gap: '15px' }}>
      <Grid item xs={12} lg="auto">
        <CategoryButton
          variant="outlined"
          color="inherit"
          sx={{ width: window.innerWidth >= 1200 ? 'auto' : '100%' }}
          disabled={categorySelected.category === 'all' && searchMode === 'main'}
          onClick={() => {
            submitCategorySelected({ ...categorySelected, category: 'all', search: '', categories: [], tags: [] })
            setSearchMode('main')
          }}
        >
          <Typography variant='body1'>all</Typography>
        </CategoryButton>
      </Grid>

      {categoryList?.map((categoryItem: CategoryAndTagsType, index) => index <= 2 ? (
        <Grid item xs={12} lg="auto" key={`category-button-${index}`}>
          <CategoryButton
            variant="outlined"
            color="inherit"
            sx={{ width: '100%', maxWidth: widthWindow >= 1200 ? elementWidth : '100%' }}
            disabled={categorySelected.category === categoryItem.title && searchMode === 'main'}
            onClick={() => {
              submitCategorySelected({ ...categorySelected, category: categoryItem.title, search: '', categories: [], tags: [] })
              setSearchMode('main')
            }}
          >
            <Box 
              sx={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'center',
                '@media (min-width: 1200px)': {
                  maxWidth: '200px', 
                },
              }}
            >
              <Typography variant='body1' sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {categoryItem.title}
              </Typography>
              <Box sx={{width: 'max-content', textAlign: 'end'}}>
                {`(${categoryItem.count})`}
              </Box>
            </Box>

          </CategoryButton>
        </Grid>
      ) : null)}
    </Grid>
  )
}