import React from 'react';
import { Box } from '@mui/material';
import Cover from './Cover'
import Detail from './Detail'
import Form from '../Form'
import { DeltaStatic } from 'quill';

export default function ProjectSubmission ({projectId, description, remainAttempt, coverImage}: {projectId: string, description: DeltaStatic| string, remainAttempt: number, coverImage: string}) {
  return (
    <>
      <Cover url={coverImage}/>
      <Box
        sx={{
          padding: '40px 70px',
          '@media (max-width:1200px)': {
            padding: '40px 20px',
          }
        }}>
        <Detail description={description}/>
        <Form projectId={projectId} remainAttempt={remainAttempt}/>
      </Box>
    </>
  )
}


