import React, { useEffect, useState } from 'react';

import { SubscriptionPlanType, VariationsType } from '../../@types/plan';
import { setPlusAnnounce } from '../../redux/slices/notification';
import { useDispatch } from '../../redux/store';
import axiosInstance from '../../utils/axios'
;
import PromoteBanner from './content/PromoteBanner';
import SystemStatistics from './content/SystemStatistics';
import SpecialPrivileges from './content/SpecialPrivileges';
import PlansCourses from './content/PlansCourses';
import BusinessSection from './content/BusinessSection';
import Contact from './content/Contact';

import PlanSelector from './PlanSelector';

type PlanPriceType = {
  monthly: number
  yearly: number
}

export default function SubscriptionPlanPage() {
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlanType[]>([])
  const [recommendedPlan, setRecommendedPlan] = useState<SubscriptionPlanType>()
  const [planPrice, setPlanPrice] = useState<PlanPriceType>({ monthly: 0, yearly: 0 })
  const [onholdLink, setOnholdLink] = useState()
  const [schoolStudentCount, setSchoolStudentCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const getSubscriptionPlan = async () => {
    try {
      setLoading(true)
      const res = await axiosInstance.get(`/subscriptions/plans`)
      setSubscriptionPlans(res.data.plans)

      const rcmPlan = res.data.plans.find((plan: SubscriptionPlanType) => plan.isRecommendedPlan === true)
      if (rcmPlan) {
        setRecommendedPlan(rcmPlan)
        setPlanPrice({
          yearly: rcmPlan.variations.find((item: VariationsType) => item.billingPeriod === 'YEAR').price,
          monthly: rcmPlan.variations.find((item: VariationsType) => item.billingPeriod === 'MONTH').price
        })
      }
      
      setOnholdLink(res.data.onholdSubscriptionUrl)
      setSchoolStudentCount(res.data.schoolStudentCount)

      const plusPlan = res.data.plans.find((plan: SubscriptionPlanType) => !plan.basicPlan && plan.userCurrentPlan)
      if (plusPlan) {
        const announceData = {
          planTitle: plusPlan.title,
          nextPaymentDate: res.data.nextPaymentDate
        }
        dispatch(setPlusAnnounce(announceData))
      }

      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubscriptionPlan()
  }, [])

  return (
    <>
      <PromoteBanner 
        monthlyPrice={planPrice.monthly}
        yearlyPrice={planPrice.yearly}
      />
      <SystemStatistics 
        studentCount={schoolStudentCount}
        courseCount={recommendedPlan?.courseCount}
        reviews={recommendedPlan?.avgReview}
      />
      <SpecialPrivileges/>
      <PlansCourses 
        courseCount={recommendedPlan?.courseCount || 0}
      />
      <BusinessSection/>
      <PlanSelector 
        loading={loading} 
        onholdLink={onholdLink} 
        subscriptionPlans={subscriptionPlans}
      />
      <Contact/>
    </>
  )
}