import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  consentOpen: false,
  bornbitOpen: false
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConsentOpen(state, action) {
      state.consentOpen = action.payload
    },
    setBornbitOpen(state, action) {
      state.bornbitOpen = action.payload
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
  setConsentOpen,
  setBornbitOpen
} = slice.actions;

