import React from 'react'
import { Modal, Box, Stack, Typography, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Icon from '../../../components/Iconify'

interface ModalReplyCommentProps {
  openModal: boolean
  onCloseModal: Function
}

export default function ModalReplyComment({ openModal, onCloseModal }: ModalReplyCommentProps) {
  const theme = useTheme()

  return (
    <Modal
      open={openModal}
      onClose={() => onCloseModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: '141px',
          width: '343px',
          padding: '4px',
          borderRadius: '16px',
          borderColor: 'transparent',
          boxShadow: 24,
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ marginBottom: '20px' }}>
          <Stack justifyContent="center" alignItems="center" sx={{ width: '40px', height: '40px', marginRight: '12px', borderRadius: '12px', backgroundColor: `${theme.palette.warning.main}16` }}>
            <Icon icon="eva:alert-triangle-fill" sx={{ height: '24px', width: '24px', color: 'primary.main' }} />
          </Stack>
          <Typography variant="body1">กรุณาใส่ข้อความก่อนแสดงความคิดเห็น</Typography>
        </Stack>
        <Button
          sx={{
            backgroundColor: 'warning.main',
            color: 'common.white',
            height: '36px',
            width: '260px',
            ':hover': {
              backgroundColor: 'warning.dark'
            }
          }}
          onClick={() => onCloseModal()}
        >
          รับทราบ
        </Button>
      </Box>
    </Modal>
  )
}