import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, CircularProgress } from '@mui/material';
import Iconify from '../../../components/Iconify';

interface EditorHeaderProps {
  title: string
  onSubmit?: Function
  loading?: boolean
  disabled?: boolean
  success?: boolean
}

export default function EditorHeader({title, onSubmit, disabled}: EditorHeaderProps) {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  async function submit() {
    if(onSubmit) {
      try {
        setLoading(true)
        setSuccess(false)
        await onSubmit()
        setSuccess(true)
        setLoading(false)
        setError('')
      } catch(error: any) {
        setLoading(false)
        setError(error.message[0])
      }
    }
  }

  useEffect(() => {
    !disabled && setSuccess(false)
  }, [disabled])

  return (
    <Box>
      <Box sx={{display: 'flex', gap: '10px', alignItems: 'end', flexWrap: 'wrap'}}>
        <Typography variant='h4'>
          <Iconify icon='icon-park-solid:spanner' />
          {title}
        </Typography>
          {
            onSubmit &&
            <Button onClick={() => submit()} variant='outlined' disabled={disabled || loading}>
              บันทึก {loading && <CircularProgress size={15} sx={{marginLeft: '10px'}} color={'inherit'}/>}
            </Button>
          }
          {
            success &&
            <Typography variant='body2' sx={{color: 'success.main'}}>
              <Iconify icon='ep:success-filled'/>
              บันทึกแล้ว
            </Typography>
          }

      </Box>
      <Typography color={'error'}>{error}</Typography>
    </Box>
  )
}
