import React from 'react';
import { Box, Stack, Skeleton } from '@mui/material';

export default function CertificateEventCard() {
  return (
    <Box>
      <Stack direction="row" justifyContent="center">
        <Skeleton
          variant="rectangular"
          width="80%"
          height="600px"
          sx={{ borderRadius: '6px' }}
        />
      </Stack>

      <Stack direction="column" sx={{ marginTop: '30px', gap: '8px' }}>
        <Skeleton variant="rectangular" height="45px" />
        <Skeleton variant="rectangular" height="40px" width="50%" />
      </Stack>
    </Box>
  );
}
