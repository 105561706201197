import React from 'react'
import { Box, Typography, Grid, Skeleton } from '@mui/material'

export default function CourseDetailSkeleton() {
  return (
    <Box>
      <Skeleton variant="rectangular" width={'100%'} height={'550px'} sx={{backgroundColor: 'grey.400'}}/>
      <Box sx={{backgroundColor: 'background.paper', borderRadius: '0px 0px 16px 16px'}}>
        <Box sx={{padding: '60px'}}>
          <Grid container sx={{marginTop: '0', justifyContent: 'space-between'}}>
            <Grid item xs={6.78}>
              <Typography variant="h4">
                <Skeleton sx={{backgroundColor: 'grey.400'}} width={'50%'}/>
              </Typography>
              <Box sx={{ marginTop: '30px' }}></Box>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'80%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'90%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'90%'}/>
              <Box sx={{ marginTop: '30px' }}></Box>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'90%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'80%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'70%'}/>
            </Grid>
            <Grid item xs={4.57}>
              <Typography variant="h4">
                <Skeleton sx={{backgroundColor: 'grey.400'}} width={'10%'}/>
              </Typography>
              <Typography variant="h3">
                <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
              </Typography>
              <Skeleton sx={{backgroundColor: 'grey.400'}} width={'60%'}/>
              <Box sx={{ marginTop: '10px' }}></Box>
              <Typography variant="h2">
                <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
              </Typography>
            </Grid>
          </Grid>
      </Box>
      </Box>
    </Box>
  )
}
