import React, { useEffect } from 'react';
import { Button, Modal, Card, Stack, Typography } from '@mui/material';
import { useSelector } from '../../redux/store';
import Iconify from '../Iconify';

export default function SessionTimeoutNoti() {
  const { sessionTimeoutNoti } = useSelector((state) => state.notification);
  const [countdownTime, setCountdownTime] = React.useState(1000);

  useEffect(() => {
    if (sessionTimeoutNoti) {
      const timer = setInterval(() => {
        setCountdownTime((prevCountdownTime) => {
          if (prevCountdownTime > 1) {
            return prevCountdownTime - 1;
          } else {
            clearInterval(timer);
            goLogin();
            return 0;
          }
        });
      }, 1000);
    } else {
      setCountdownTime(5);
    }
  }, [sessionTimeoutNoti]);

  const goLogin = () => {
    window.location.href = `/login?redirectUrl=${window.location.href}`;
  };

  return (
    <Modal open={sessionTimeoutNoti}>
      <Card
        sx={{
          borderRadius: '20px',
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          padding: '80px 30px',
        }}
      >
        <Stack direction="column" alignItems={'center'}>
          <Iconify icon="tabler:robot-off" width={80} height={80} />
          <Typography variant="h4">Session หมดอายุ</Typography>
          <Typography variant="body1">
            คุณจะถูกพาไปที่หน้าลงชื่อเข้าใช้ใน ({countdownTime})
          </Typography>
          <Button
            variant="contained"
            sx={{ marginTop: '20px' }}
            onClick={goLogin}
          >
            ไปที่หน้าลงชื่อเข้าใช้
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
}
