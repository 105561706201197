import React from 'react';
import { Grid, Button } from '@mui/material';
import ContactLinks from './ContactLinks';
import ContactLanguages from './ContactLanguages';
import DevlabStat from './DevlabStat';
import Bio from './Bio';
import Target from './Target';
import Skills from './Skills';
import Cover from './ProfileCover';

import { ProfileDataType } from '../../../@types/profile';
import ProfileSkeleton from '../../../components/skeleton/Profile';
import Iconify from '../../../components/Iconify';

interface ProfileDetailProps {
  profileData: ProfileDataType;
  isSelf: boolean;
  swapToEdit: Function;
}

export default function ProfileDetail({
  profileData,
  isSelf,
  swapToEdit,
}: ProfileDetailProps) {
  const EditButton = isSelf ? (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => swapToEdit()}
      sx={{ marginBottom: '10px', color: 'text.button' }}
    >
      <Iconify icon="icon-park-solid:spanner" />
      แก้ไขข้อมูลส่วนตัว
    </Button>
  ) : null;

  return (
    <>
      {profileData ? (
        <>
          <Cover
            userData={profileData.user}
            profileStatus={profileData.profileStatus}
            experience={profileData.experience}
            coverImage={profileData.coverImage}
          />
          <Grid
            container
            gap={0.1}
            sx={{
              marginBottom: '10px',
              paddingX: '20px',

              '@media (max-width: 899px)': {
                flexFlow: 'column-reverse',
                paddingX: '15px',
              },
            }}
          >
            <Grid item xs={12} md={2.6}>
              <ContactLinks contacts={profileData.contacts} />
              <ContactLanguages
                communicationLanguages={profileData.communicationLanguages}
              />
              <DevlabStat />
            </Grid>
            <Grid item xs={12} md={9.3}>
              <Bio bio={profileData.user.bio} editButton={EditButton} />
              <Target target={profileData.target} />
              <Skills skills={profileData.skills} />
            </Grid>
          </Grid>
        </>
      ) : (
        <ProfileSkeleton />
      )}
    </>
  );
}
