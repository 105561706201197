import axios from 'axios';
// config
import { HOST_API } from '../config';
import { store } from '../redux/store';
import { setSessionTimeoutNoti } from '../redux/slices/notification';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  withCredentials: true,
});

const excluteAuthApi = ['users/me'];

const isInExcluteAuthApi = (url: string) => {
  const path = url.replace(HOST_API, '');
  return excluteAuthApi.find((excludePath) => path.includes(excludePath));
};

const handleError = (error: any) => {
  if (
    error?.response?.data?.statusCode === 401 &&
    !isInExcluteAuthApi(error?.response?.request?.responseURL)
  ) {
    store.dispatch(setSessionTimeoutNoti(true));
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);
    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    );
  },
);

export default axiosInstance;
