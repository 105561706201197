import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { ProjectSubmissionType } from '../../../@types/projectSubmission'
import CircleScoreChart from '../../../components/charts/CircleScoreChart';
import AveragePointScoreChart from '../../../components/charts/AveragePointScoreChart';
import CertificateButton from '../../../components/course/CertificateButton'
import ResultAlert from '../../../components/ResultAlert';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export default function CourseSummary({ projectSubmissionData, courseLessons }: { projectSubmissionData: ProjectSubmissionType, courseLessons: any }) {
  const alertText = {
    "APPROVED": {
      severity: "success",
      title: 'ขอแสดงความยินดี ! คุณเรียนจบหลักสูตรของเราเรียบร้อย',
      description: 'นี่คือก้าวแรกของความสำเร็จของคุณ อย่าลืมพัฒนาทักษะต่อไป เพื่อให้คุณนั้นแข็งแกร่งยิ่งขึ้น',
    },
    "INCOMPLETE": {
      severity: "warning",
      title: 'คอร์สเรียนนี้ยังไม่จบ',
      description: 'การเดินทางของเรายังคงดำเนินต่อไป เรายังคงทำงานอย่างหนักเพื่อให้คุณได้ความรู้อย่างต่อเนื่อง ในระหว่างนี้ หากคุณต้องการฝึกฝนทักษะเพิ่มเติม สามารถเข้าไปที่',
    },
  }
  const courseData = useSelector((state: RootState) => state.lesson.courseLessons)
  const textCondition = courseData.completable === true ? 'APPROVED':"INCOMPLETE"
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '@media (max-width: 699px)': {
            display: 'block',
          }
        }}
      >
        <Typography variant="h5">สรุปผลการเรียนทั้งหมด</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'end',
            '@media (max-width: 499px)': {
              flexDirection: 'column',
            }
          }}
        >
          {courseData.completable === true?
            <CertificateButton
            completedCourse={projectSubmissionData.submissionData?.status === 'APPROVED' || projectSubmissionData.courseProgress.projectStatus === "NONE"}
            certificateId={projectSubmissionData.courseProgress.certificateId}
            courseId={courseLessons.id}
            courseName={courseLessons.title} />
          :<></>
          }
        </Box>
      </Box>
            <ResultAlert
              severity={alertText[textCondition].severity as "success" | "info" | "error"}
              content={
                <>
                  <Typography variant="subtitle2">{alertText[textCondition].title}</Typography>
                  <Typography variant="body2" sx={{display:'inline'}}>{alertText[textCondition].description}</Typography>
                  {textCondition === 'INCOMPLETE' && <Typography variant="body2" sx={{display:'inline'}}><a href="https://www.borntodev.com/devlab/" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}> Devlab</a> ได้เลย ✌️</Typography>}
                </>
              }
            />
      {projectSubmissionData.courseProgress.quizAmount > 0 && (
        <Stack direction="row" justifyContent="center">
          <CircleScoreChart
            passed={true}
            score={projectSubmissionData.courseProgress.quizSumScore}
            maxScore={projectSubmissionData.courseProgress.quizMaxScore}
          />
          {
            projectSubmissionData.courseUsersScoreSummary !== null &&
            projectSubmissionData.courseUsersScoreSummary?.averageScore ?
              <AveragePointScoreChart
                averageScore={projectSubmissionData.courseUsersScoreSummary.averageScore}
                score={projectSubmissionData.courseProgress.quizSumScore}
                counts={projectSubmissionData.courseUsersScoreSummary.counts}
                passed={true}
                label={'คะแนนเฉลี่ยของผู้เรียนทั้งหมด'}
              />
            :
            <></>
          }
        </Stack>
      )}
    </Box>
  )
}
