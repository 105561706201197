import React, { useMemo } from 'react';
import QRCode from 'qrcode.react';

import './certificateStyle.css';
import { CertificateType, categoryType } from '../../../@types/certificate';

interface CertificateProps {
  certificate?: CertificateType;
}

const dateFormat = (date?: Date): string => {
  const dateFormated = new Date(date ? date : '');
  const month: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${
    month[dateFormated.getMonth()]
  } ${dateFormated.getDate()}, ${dateFormated.getFullYear()}`;
};

const prePath = '';

export default function CertificatePreview({ certificate }: CertificateProps) {
  const handleType = () => {
    if (certificate?.course.group.name === 'Open Access') {
      return 'OPEN';
    } else if (
      certificate?.course.categories.some(
        (category: categoryType) => category.title === 'Bootcamp',
      )
    ) {
      return 'BOOTCAMP';
    } else {
      return 'PREMIUM';
    }
  };

  const signature = useMemo<string | null>(() => {
    const instructor = certificate?.course.instructors[0];

    if (handleType() === 'OPEN') {
      return instructor?.signatureBlack ?? null;
    } else {
      return instructor?.signatureWhite ?? null;
    }
  }, [certificate]);

  const logo = '/certificate_images/logo.png';
  const type = handleType();
  const config = certificateConfigs[type];

  return (
    <div
      style={{
        backgroundColor: config.backgroundColor,
        maxHeight: config.maxHeight,
        color: config.textColor,
        padding: config.padding,
      }}
      className="certificate-wrap"
      id="section-to-print"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div>
          {/* title section */}
          <div className="cert-title-section">
            <img className="logo" src={prePath + logo} alt="logo" />
            {config.titleComponent}
          </div>
          {/* middle section */}
          <div className="cert-coursedetail-section">
            <div
              className="coursedetail-description1"
              style={{ color: config.textColor }}
            >
              This is to certify that
            </div>
            <div className="coursedetail-name">
              <p>{`${certificate?.user.firstName} ${certificate?.user.lastName}`}</p>
            </div>
            <div className="coursedetail-description2">
              <span>
                successfully completed and received a passing grade in
              </span>
            </div>
            <div className="coursedetail-coursename">
              <p>{certificate?.course.title}</p>
            </div>
          </div>
        </div>
        {/* lower section */}
        <div className="cert-approval-section">
          {/* ceo signature */}
          <div className="signature-1">
            <img
              className="signature"
              src={prePath + config.ceoSignature}
              alt="signature1"
            />
            <div
              style={{
                lineHeight: '16px',
                color: config.textColor,
              }}
            >
              <p style={{ margin: '0' }}>KITTIKORN PRASERTSAK</p>
              <p style={{ margin: '0' }}>MANAGING DIRECTOR</p>
              <p style={{ margin: '0' }}>borntoDev Co., Ltd.</p>
            </div>
          </div>
          {/* instructor signature */}
          {signature !== null && (
            <div className="signature-2">
              <img
                className="signature"
                src={signature}
                alt="signature2"
                style={{
                  WebkitFilter: 'invert(100%)',
                  filter: 'invert(100%)',
                }}
              />
              <div
                style={{
                  lineHeight: '16px',
                  maxWidth: '222px',
                  color: config.textColor,
                }}
              >
                <p
                  style={{ margin: '0' }}
                >{`${certificate?.course?.instructors?.[0]?.firstName?.toUpperCase()} ${certificate?.course?.instructors?.[0]?.lastName?.toUpperCase()}`}</p>
                <p style={{ margin: '0' }}>INSTRUCTOR</p>
              </div>
            </div>
          )}
          {/* qr, supporter section */}
          <div className="verification-section">
            <div style={{ bottom: config.qrMargin }}>
              {certificate?.sponsorLogo ? (
                <div style={{ color: config.textColor, marginBottom: '40px' }}>
                  <p>Supported / Co-created with</p>
                  <img
                    src={certificate?.sponsorLogo}
                    alt="sponsor"
                    style={{
                      maxWidth: '200px',
                      maxHeight: '60px',
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="date-data" style={{ color: config.textColor }}>
                <p>VERIFIED CERTIFICATE</p>
                <p>{`Issue ${dateFormat(certificate?.createdAt)}`}</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
                <div>
                  <p style={{ color: config.textColor }}>
                    Valid Certificate ID
                  </p>
                  <p style={{ color: '#ffbe01', marginBottom: '5px' }}>
                    <b>{certificate?.id}</b>
                  </p>
                </div>
                <div className="qr-img">
                  <QRCode
                    value={`${window.location.origin}/certificate/${certificate?.id}`}
                    level="H"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {type === 'OPEN' ? (
          <div className="cert-footer">
            <div className="footer-logo">
              <img
                src={prePath + '/certificate_images/footer_logo.png'}
                alt=""
                style={{ width: '100px', marginLeft: '1rem' }}
              />
            </div>
            <div className="footer-tagline">
              <p style={{ margin: '0' }}>
                เรียนรู้ไอที “อัพสกิลเขียนโปรแกรม” จากตัวจริง ปั้นให้คุณเป็น
                คนสายไอทีระดับมืออาชีพ
              </p>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div
        className="bg-bit_topRight"
        style={{
          backgroundImage: `url(${prePath + config.imageDecoration})`,
          top: '-34px',
          right: '-34px',
        }}
      ></div>
      <div
        className="bg-bit_bottomLeft"
        style={{
          backgroundImage: `url(${prePath + config.imageDecoration})`,
          bottom: '-40px',
          left: '-40px',
        }}
      ></div>
      {config.specialImage}
    </div>
  );
}

const openTitle = (
  <div className="cert-title" style={{ color: 'inherit' }}>
    <h4
      className="header-title"
      style={{
        textTransform: 'uppercase',
        fontSize: '30px',
        margin: 0,
      }}
    >
      CERTIFICATE OF ACHIEVEMENT
    </h4>
    <h4
      className="header-subtitle"
      style={{
        textTransform: 'uppercase',
        fontSize: '24px',
        margin: 0,
      }}
    >
      OPEN ACCESS ACADEMY
    </h4>
  </div>
);

const bootcampTitle = (
  <div
    className="cert-title"
    style={{ color: 'inherit', lineHeight: '2.6rem' }}
  >
    <h4
      className="header-title"
      style={{
        textTransform: 'uppercase',
        fontSize: '42px',
        margin: 0,
      }}
    >
      PROFESSIONAL
    </h4>

    <h4
      className="header-subtitle"
      style={{
        textTransform: 'uppercase',
        fontSize: '42px',
        margin: 0,
      }}
    >
      CERTIFICATE
    </h4>
  </div>
);

const premiumTitle = (
  <div
    className="cert-title"
    style={{ color: 'inherit', lineHeight: '2.6rem' }}
  >
    <h4
      className="header-title"
      style={{
        textTransform: 'uppercase',
        fontSize: '42px',
        margin: 0,
      }}
    >
      VERIFY CERTIFICATE
    </h4>
    <h4
      className="header-subtitle"
      style={{
        textTransform: 'uppercase',
        fontSize: '33px',
        margin: 0,
      }}
    >
      OF ACHIVEMENT
    </h4>
  </div>
);

const certificateConfigs = {
  OPEN: {
    backgroundColor: 'white',
    maxHeight: '620px',
    padding: '60px 50px 80px 60px',
    qrMargin: '0px',
    textColor: '#484848',
    ceoSignature: '/certificate_images/signature1.png',
    imageDecoration: '/certificate_images/bit.png',
    specialImage: <></>,
    titleComponent: openTitle,
  },
  BOOTCAMP: {
    backgroundColor: '#070A0E',
    maxHeight: '600px',
    padding: '60px',
    qrMargin: '27px',
    textColor: '#FFFEFE',
    ceoSignature: '/certificate_images/signature-1-white.png',
    imageDecoration: '/certificate_images/bit-white.png',
    specialImage: (
      <div
        className="bg-laptop"
        style={{
          backgroundImage: `url('${prePath}/certificate_images/laptop.png')`,
        }}
      />
    ),
    titleComponent: bootcampTitle,
  },
  PREMIUM: {
    backgroundColor: '#00082C',
    maxHeight: '600px',
    padding: '60px',
    qrMargin: '27px',
    textColor: '#FFFEFE',
    ceoSignature: '/certificate_images/signature-1-white.png',
    imageDecoration: '/certificate_images/bit-white.png',
    specialImage: (
      <div
        className="bg-star"
        style={{
          backgroundImage: `url('${prePath}/certificate_images/star.png')`,
        }}
      />
    ),
    titleComponent: premiumTitle,
  },
};
