import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Box, Button, Select, MenuItem, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams, useLocation, Link as RouterLink } from 'react-router-dom';
import { ConfirmationDialog } from '../../../components/Dialog';
import axios from '../../../utils/axios';
import Iconify from '../../../components/Iconify';
import ReactQuill from 'react-quill';
import { noteType } from '../../..//@types/notes';
//redux
import { useSelector } from 'react-redux';
import { useDispatch, RootState } from '../../../redux/store';
import { setSeekTimeStamp, setOnSeek } from '../../../redux/slices/lesson';

import { LessonParamsType } from '../../..//@types/params';
import { Waypoint } from 'react-waypoint';
import NoteSkeleton from '../../../components/skeleton/Note';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

import { DeltaStatic, Sources } from 'quill';

export default function Notes({ courseId }: { courseId: string }) {
  const { lessonId } = useParams<LessonParamsType>();
  const [notes, setNotes] = useState<noteType[]>([] as noteType[]);
  const [noteParams, setNoteParams] = useState('');
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getNoteList(params?: string, reset?: boolean): Promise<void> {
    try {
      setLoading(true);
      const nextPage = params || reset ? 1 : page + 1;
      const fetchParams = `?take=30&page=${nextPage}${
        params ? params : noteParams
      }`;
      const response = await axios.get(
        `/courses/${courseId}/notes${fetchParams}`,
      );
      setPage(nextPage);
      setLastPage(!response.data.meta.hasNextPage);
      setNotes(
        nextPage === 1 ? response.data.data : [...notes, ...response.data.data],
      );
      setLoading(false);
    } catch (error) {
      return;
    }
  }

  return (
    <Box sx={{ gap: '10px' }}>
      <NoteCreator
        lessonId={lessonId}
        courseId={courseId}
        getNoteList={getNoteList}
      />
      <NoteSortFilter
        lessonId={lessonId}
        setNoteParams={setNoteParams}
        getNoteList={getNoteList}
      />
      {notes.map((note) => (
        <Note
          key={note?.id}
          note={note}
          courseId={courseId}
          getNoteList={getNoteList}
        />
      ))}
      {loading ? (
        <Box sx={{ marginTop: '10px' }}>
          <NoteSkeleton />
          <NoteSkeleton />
          <NoteSkeleton />
          <NoteSkeleton />
        </Box>
      ) : (
        <Waypoint onEnter={() => !lastPage && getNoteList()} />
      )}
    </Box>
  );
}

function NoteCreator({
  lessonId = '',
  courseId,
  getNoteList,
}: {
  lessonId?: string;
  courseId: string;
  getNoteList: (params?: string, reset?: boolean) => Promise<void>;
}) {
  const lessonTimeStamp = useSelector(
    (state: RootState) => state.lesson.lessonTimeStamp,
  );
  const [showEditor, setShowEditor] = useState(false);
  const [noteContent, setNoteContent] = useState<string | DeltaStatic>('');
  const [noteTimestamp, setNoteTimestamp] = useState(0);
  const location = useLocation();

  const checkSummaryPage = (): boolean => {
    const path = location.pathname.split('/').slice(-1);
    return path.length > 0 ? path[0] === 'summary' : false;
  };

  async function createNote() {
    const payload = {
      lessonId: lessonId,
      content: noteContent,
      timestamp: noteTimestamp,
    };
    try {
      await axios.post(`/courses/${courseId}/notes`, payload);
      setNoteContent('');
      await getNoteList(undefined, true);
      setShowEditor(false);
    } catch (err) {
      return;
    }
  }

  return (
    <Box sx={{ marginY: '10px' }}>
      {showEditor ? (
        <>
          <NoteEditor
            noteContent={noteContent}
            setNoteContent={setNoteContent}
          />
          <Box sx={{ textAlign: 'right', marginTop: '10px' }}>
            <Button onClick={() => setShowEditor(false)}>ยกเลิก</Button>
            <Button
              sx={{ marginLeft: '10px' }}
              variant="contained"
              disabled={checkSummaryPage()}
              onClick={() => createNote()}
            >
              บันทึก
            </Button>
          </Box>
        </>
      ) : (
        <Button
          variant="outlined"
          onClick={() => {
            setNoteTimestamp(lessonTimeStamp);
            setShowEditor(true);
          }}
          color="tertiary"
          sx={{
            width: '100%',
            display: 'flex',
            paddingY: '10px',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="caption">
              เพิ่มโน้ตของคุณที่ {getFormattedTime(lessonTimeStamp)}
            </Typography>
          </Box>
          <Iconify icon={'charm:plus'} width={15} height={15} />
        </Button>
      )}
    </Box>
  );
}

function NoteEditor({
  noteContent,
  setNoteContent,
}: {
  noteContent: string | DeltaStatic;
  setNoteContent: Dispatch<SetStateAction<string | DeltaStatic>>;
}) {
  const toolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ];

  const formats = [
    'bold',
    'italic',
    'size',
    'strike',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'code-block',
  ];

  function handleChange(
    content: string,
    delta: DeltaStatic,
    source: Sources,
    editor: ReactQuill.UnprivilegedEditor,
  ) {
    setNoteContent(editor.getContents());
  }

  return (
    <NoteEditorStyle>
      <ReactQuill
        formats={formats}
        placeholder="จดโน้ตของคุณที่นี่...."
        value={noteContent}
        onChange={handleChange}
        readOnly={false}
        modules={{ toolbar: toolbarOptions, syntax: true }}
        theme={'snow'}
        style={{ borderRadius: '10px' }}
      />
    </NoteEditorStyle>
  );
}

function NoteSortFilter({
  lessonId,
  setNoteParams,
  getNoteList,
}: {
  lessonId?: string;
  setNoteParams: Dispatch<SetStateAction<string>>;
  getNoteList: (params?: string, reset?: boolean) => Promise<void>;
}) {
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('lesson');

  useEffect(() => {
    const sortBy = `&sortBy=${sort === 'lesson' ? 'lesson' : 'createdAt'}`;
    const sortOrder = `&sortOrder=${sort === 'lesson' ? 'asc' : sort}`;
    const lessonId = `${filter === 'all' ? '' : `&lessonId=${filter}`}`;
    const params = `${sortBy}${sortOrder}${lessonId}`;
    setNoteParams(params);
    getNoteList(params);
  }, [filter, sort]);

  return (
    <Box>
      <SortFilterStyle
        value={filter}
        onChange={(event) =>
          setFilter(
            typeof event.target.value === 'string' ? event.target.value : '',
          )
        }
      >
        <MenuItem value="all">แสดงโน้ตทุกบท</MenuItem>
        <MenuItem value={lessonId}>แสดงเฉพาะบทนี้</MenuItem>
      </SortFilterStyle>
      <SortFilterStyle
        value={sort}
        onChange={(event) =>
          setSort(
            typeof event.target.value === 'string' ? event.target.value : '',
          )
        }
        sx={{ marginLeft: '20px' }}
      >
        <MenuItem value="desc">เรียงตามใหม่สุด</MenuItem>
        <MenuItem value="asc">เรียงตามเก่าสุด</MenuItem>
        <MenuItem value="lesson">เรียงตามบทเรียน</MenuItem>
      </SortFilterStyle>
    </Box>
  );
}

function Note({
  note,
  courseId,
  getNoteList,
}: {
  note: noteType;
  courseId: string;
  getNoteList: (params?: string, reset?: boolean) => Promise<void>;
}) {
  const { courseSlug } = useParams();
  const [noteContent, setNoteContent] = useState(note.content);
  const [noteBeforeEdit, setNoteBeforeEdit] = useState<string | DeltaStatic>(
    '',
  );
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [action, setAction] = useState('edit');

  async function editNote() {
    const payload = {
      content: noteContent,
    };
    try {
      await axios.patch(`/courses/${courseId}/notes/${note.id}`, payload);
      await getNoteList(undefined, true);
      setEditMode(false);
      setOpenDialog(false);
    } catch (err) {
      return;
    }
  }

  async function deleteNote() {
    try {
      await axios.delete(`/courses/${courseId}/notes/${note.id}`);
      await getNoteList(undefined, true);
      setOpenDialog(false);
    } catch (err) {
      return;
    }
  }

  function openConfirmDialog(mode: string) {
    setAction(mode);
    setDialogText(
      mode === 'edit' ? 'ต้องการแก้ไข Note ของคุณ?' : 'ต้องการลบ Note ของคุณ?',
    );
    setOpenDialog(true);
  }

  function handleConfirm() {
    action === 'edit' ? editNote() : deleteNote();
  }

  const dispatch = useDispatch();
  const { lessonId } = useParams();

  function peekTo(time: number) {
    dispatch(setSeekTimeStamp(time));
    dispatch(setOnSeek(true));
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: '0' }}>
        <Grid item xs={1.6}>
          <Box
            component={RouterLink}
            to={`/course/${courseSlug}/${note.lessonDetail.lessonId}?at=${note.timestamp}`}
            onClick={(e: any) => {
              if (note.lessonDetail.lessonId === lessonId) {
                e.preventDefault();
                peekTo(note.timestamp);
              }
            }}
            sx={{ textDecoration: 'none' }}
          >
            <TimestampStyle sx={{ color: 'text.button' }}>
              {getFormattedTime(note.timestamp)}
            </TimestampStyle>
          </Box>
        </Grid>
        <Grid item xs={10.4}>
          <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
            <Grid item xs={10}>
              <Box
                component={RouterLink}
                to={`/course/${courseSlug}/${note.lessonDetail.lessonId}?at=${note.timestamp}`}
                onClick={(e: any) => {
                  if (note.lessonDetail.lessonId === lessonId) {
                    e.preventDefault();
                    peekTo(note.timestamp);
                  }
                }}
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'text.primary',
                    ':hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  {note.lessonDetail.lessonTitle}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <Iconify
                onClick={() => {
                  setNoteBeforeEdit(noteContent);
                  setEditMode(true);
                }}
                icon={'bxs:edit-alt'}
                width={15}
                height={15}
                sx={{ cursor: 'pointer', ':hover': { color: 'primary.main' } }}
              />
              <Iconify
                sx={{ cursor: 'pointer', ':hover': { color: 'primary.main' } }}
                onClick={() => openConfirmDialog('delete')}
                icon={'bxs:trash-alt'}
                width={15}
                height={15}
              />
            </Grid>
          </Grid>
          <Typography variant="caption">
            {editMode ? (
              <>
                <NoteEditor
                  noteContent={noteContent}
                  setNoteContent={setNoteContent}
                />
                <Box sx={{ textAlign: 'right', marginTop: '10px' }}>
                  <Button
                    onClick={() => {
                      setNoteContent(noteBeforeEdit);
                      setEditMode(false);
                    }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    sx={{ marginLeft: '10px' }}
                    variant="contained"
                    onClick={() => openConfirmDialog('edit')}
                  >
                    บันทึก
                  </Button>
                </Box>
              </>
            ) : (
              <NoteViewerStyle>
                <ReactQuill
                  value={noteContent}
                  readOnly={true}
                  theme={'bubble'}
                  modules={{ syntax: true }}
                />
              </NoteViewerStyle>
            )}
          </Typography>
        </Grid>
      </Grid>
      <ConfirmationDialog
        text={dialogText}
        open={openDialog}
        handleCancel={() => setOpenDialog(false)}
        handleConfirm={handleConfirm}
      />
    </>
  );
}

const SortFilterStyle = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': { padding: '15px' },
  color: theme.palette.text.secondary,
  fontSize: theme.typography.caption.fontSize,
}));

const TimestampStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  fontSize: '10px',
  padding: '3px 2px 2px',
  borderRadius: '15px',
  textAlign: 'center',
}));

const NoteViewerStyle = styled(Box)(({ theme }) => ({
  '& .ql-editor': {
    padding: 0,
    overflowY: 'hidden',
  },
  '& .ql-container.ql-bubble': {
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
}));

const NoteEditorStyle = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  backgroundColor: theme.palette.background.paper,

  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-toolbar.ql-snow': {
    borderColor: 'transparent',
    borderBottom: `solid 1px ${theme.palette.grey[500_32]}`,
  },
  '& .ql-snow.ql-toolbar .ql-formats': {
    margin: 0,
  },
  '& .ql-snow.ql-toolbar .ql-stroke': {
    fill: 'none',
    stroke: theme.palette.text.primary,
  },
  '& .ql-snow.ql-toolbar .ql-fill': {
    fill: theme.palette.text.primary,
    stroke: 'none',
  },
  '& .ql-snow.ql-toolbar .ql-picker': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  '& .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar button:hover .ql-fill':
    {
      fill: theme.palette.primary.main,
    },
  '& .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke':
    {
      stroke: theme.palette.primary.main,
    },
  '& .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke':
    {
      stroke: theme.palette.primary.main,
    },
  '& .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover':
    {
      color: theme.palette.primary.main,
    },
  '& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label': {
    borderColor: 'transparent',
  },
  '& .ql-snow.ql-toolbar .ql-picker-item:hover': {
    color: theme.palette.primary.main,
  },
  '& .ql-toolbar .ql-picker-options': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  '& .ql-editor': {
    minHeight: 200,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.secondary,
    },
    '& pre.ql-syntax': {
      padding: theme.spacing(2),
      borderRadius: '10px',
    },
    '& code': {
      color: theme.palette.grey[700],
    },
  },
}));

function getFormattedTime(time: number) {
  return `${Math.floor(time / 60)}:${Math.floor(time % 60)
    .toString()
    .padStart(2, '0')}`;
}
