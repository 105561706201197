import React, { useState, useEffect, forwardRef  } from 'react';
import { Box, Typography, TextField, Autocomplete, Button, IconButton } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import ChangePreview from './ChangePreview';
import Iconify from '../../../components/Iconify';
import _ from 'lodash'
import { ReactSortable } from 'react-sortablejs';
import { styled } from '@mui/material/styles';
import EditorHeader from './EditorHeader';

import { profileCommunicationLanguagesType } from '../../../@types/profile';

interface profileLangEditorType {
  id: number
  code: string
  name: string
}

const SortContainerStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  marginY: '10px',
  flexWrap: 'wrap'
}))

const CustomComponent = forwardRef<unknown, any>((props, ref) => {
  return ref && <SortContainerStyle ref={ref}>{props.children}</SortContainerStyle>;
});

function profileLangEditorFormat(profileLangs: profileCommunicationLanguagesType[]) {
  return profileLangs.map((each, index) => {
    return {
      id: index + 1,
      code: each.code,
      name: each.name
    } as profileLangEditorType
  })
}

export default function LangsEditor({langs}: {langs: profileCommunicationLanguagesType[]}) {
  const [initStatus, setInitStatus] = useState(true)
  const [langsData, setLangsData] = useState<profileLangEditorType[]>(profileLangEditorFormat(langs))


  function onAddLang(name: string, code: string) {
    setLangsData([ ...langsData,
      {
        id: langsData.length + 1,
        name: name,
        code: code
      }
    ])
    setInitStatus(false)
  }

  function onRemove(name: string) {
    //filter
    const filtered = langsData.filter((each) => each.name !== name).sort((a, b) => a.id - b.id)
    //reorder
    const reordered = filtered.map((each, index) => {return {...each, id: index+1}})
    setLangsData(reordered)
    setInitStatus(false)
  }

  function reorderedLangs(data: profileLangEditorType[]) {
    const reorderedLangs = data.map((each, index) => ({...each, id: index+1}))
    if(!_.isEqual(reorderedLangs, langsData)){
      setInitStatus(false)
      setLangsData(reorderedLangs)
    }
  }

  async function onSave() {
    await axiosInstance.put('/users/profile/communication-languages', {communicationLanguages: langsData})
    setInitStatus(true)
  }

  return (
    <Box sx={{width: '95%'}}>
      <EditorHeader title={'ภาษาหลักในการสื่อสาร'} onSubmit={onSave} disabled={initStatus}/>
      <LangSearchAdder onAddLang={onAddLang} langsData={langsData}/>
      <Box sx={{marginTop: '10px'}}>
        <ReactSortable tag={CustomComponent} list={langsData} setList={reorderedLangs}>
          {
            langsData.map((lang, index) =>
              <Box key={`langEditor-${index+1}`} sx={{position: 'relative', cursor: 'grab'}}>
                <IconButton sx={{position: 'absolute', top: -15, right: -15}} onClick={() => onRemove(lang.name)}>
                  <Iconify icon={'mdi:close-circle'} sx={{color: 'grey.400'}}/>
                </IconButton>
                <LangButton text={lang.name}/>
              </Box>
            )
          }
        </ReactSortable>
      </Box>

      <ChangePreview>
        <ContactLanguages communicationLanguages={langsData}/>
      </ChangePreview>
    </Box>
  )
}

interface langSuggestionType {
  code: string
  name: string
}

function LangSearchAdder({onAddLang, langsData}: {onAddLang: Function, langsData: profileLangEditorType[]}) {
  const [search, setSearch] = useState('')
  const [lang, setLang] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [langSuggestionList, setLangSuggestionList] = useState<langSuggestionType[]>([] as langSuggestionType[])

  useEffect(() => {
    getSearchSuggestion()
  }, [search])



  async function getSearchSuggestion() {
    const response = await axiosInstance.get(`/users/profile/communication-languages?take=5&search=${search}`)
    setLangSuggestionList(response.data)
  }

  function isDuplicated() {
    return langsData.find((each) => each.name === lang) !== undefined
  }

  function onSubmit() {
    const selectedLangData = langSuggestionList.find((each) => each.name === lang)
    if (selectedLangData) {
      onAddLang(selectedLangData.name, selectedLangData.code)
      setSearch('')
      setLang('')
      setRefresh(refresh + 1)
    }

  }

  return (
    <>
      <Autocomplete
        freeSolo
        key={refresh}
        options={langSuggestionList}
        getOptionLabel={(option: any) => option.name || search}
        onChange={(event: any, option: any) => {
          setLang(typeof option === 'string' ? option : option.name)}
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(event) => setSearch(typeof event.target.value === 'string' ? event.target.value : '')}
            placeholder={'Lang'}
            sx={{marginY: '10px'}}
          />
        )}
      />
      {
        lang &&
        <Box sx={{display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px'}}>
          <LangButton text={lang}/>
          <Button variant='outlined' sx={{padding: '5px'}} onClick={onSubmit} disabled={isDuplicated()}>+ เพิ่ม</Button>
        </Box>
      }
    </>
  )
}

function LangButton({text}: {text: string}) {
  return <Button variant='outlined' sx={{borderRadius: '50px', cursor: 'grab'}}>{text}</Button>
}

function ContactLanguages({communicationLanguages}: {communicationLanguages: profileLangEditorType[]}) {
  function getLangText() {
    return communicationLanguages.reduce((acc, cur, index) => acc + (index > 0 ? ', ': '') + cur.name, '')
  }
  return (
      <Typography variant='body1'>
        {getLangText()}
      </Typography>

  )
}
