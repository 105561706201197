import React from 'react';
import { Box, Typography } from '@mui/material';
import {DeltaStatic} from 'quill'
import RichViewer from '../../../components/editor/RichViewer'

export default function Content({title, content}: {title: string, content: string | DeltaStatic}) {
  return (
    <Box>
      <Typography variant="h4" sx={{marginBottom: '38px'}}>{title}</Typography>
      <RichViewer content={content}/>
    </Box>
  )
}
