import React from 'react'
import { TextField, Stack} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import Icon from '../../../components/Iconify'
import { FilterParameterType } from '../../../@types/projectGallery'

interface PropsGalleryTextField {
  searchMode: string,
  setSearchMode: Function,
  textSearch: FilterParameterType,
  submitSearchParams: Function
}

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    'fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  padding: '0px 40px',
  width: '748.8px',
  height: '69.7px',
  borderRadius: '10px',
  background: theme.palette.grey[500_16],
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'center',
}))

function GalleryTextField({ searchMode, setSearchMode, textSearch, submitSearchParams }: PropsGalleryTextField) {
  const theme = useTheme()

  const handleSearchSubmit = (event: any) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      submitSearchParams({ ...textSearch, search: event.target.value, tags: [], category: '', categories: [] })
      setSearchMode('search')
    } else if (event.key === 'Enter' && event.target.value === '' && searchMode !== 'main') {
      submitSearchParams({ ...textSearch, search: ''})
      setSearchMode('main')
    }
  }

  return (
    <Stack direction="row" justifyContent="center">
        <CssTextField
          autoComplete="off"
          placeholder="ค้นหาด้วยชื่อ หัวข้อ หรือผู้ใช้งาน"
          InputProps={{
            startAdornment: (
              <Icon icon="akar-icons:search" sx={{ marginRight: '10px', color: theme.palette.grey[500] }} />
            )
          }}
          onKeyDown={(event) => handleSearchSubmit(event)}
        />
    </Stack>
  )
}

export default GalleryTextField