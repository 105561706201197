import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

import Icon from '../Iconify';
import axiosInstance from '../../utils/axios';
import { fTSeparator } from '../../utils/formatNumber';
import {
  courseCatalogCardType,
  WcProductDataType,
} from '../../@types/courseCatalog';
import { CourseStatusType, ProjectStatusType } from '../../@types/courseData';

type CourseSaleDetailDataType = {
  plan?: string;
  wcProduct?: WcProductDataType;
  courseStatus: CourseStatusType;
};

interface CourseProgressButtonProps {
  progress: number;
  certificateId?: string;
  slug: string;
  projectStatus: ProjectStatusType;
  completable: boolean;
  lessonAmount: number;
  lessonCompletedAmount: number;
}

function CourseProgressButton({
  progress,
  certificateId,
  slug,
  projectStatus,
  completable,
  lessonAmount,
  lessonCompletedAmount,
}: CourseProgressButtonProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const getContinueLesson = async (
    slug: string,
  ): Promise<string | undefined> => {
    try {
      const response = await axiosInstance.get(
        `/courses/${slug}/lessons/continue`,
      );
      return `/course/${slug}/${response.data?.id}`;
    } catch {
      return `/course/${slug}`;
    }
  };

  const continueLesson = async (slug: string) => {
    if (!loading) {
      setLoading(true);

      const path = await getContinueLesson(slug);
      navigate(path || '/');
    }
  };

  return (
    <>
      {loading ? (
        <Button
          disabled={true}
          color={'warning'}
          variant="outlined"
          sx={{ borderRadius: '20px', width: '92.97px', padding: '8.5px' }}
        >
          <CircularProgress
            sx={{
              width: '15px !important',
              height: '15px !important',
            }}
          />
        </Button>
      ) : certificateId ? (
        <Button
          disableElevation
          disableRipple
          href={`/certificate/${certificateId}`}
          sx={{
            color: 'text.primary',
            '&.MuiButtonBase-root:hover': {
              color: 'success.main',
              backgroundColor: 'transparent',
            },
            display: 'flex',
            alignItems: 'center',
            transition: 'color 200ms linear',
          }}
        >
          <Icon
            icon="tabler:certificate"
            sx={{
              width: '28px',
              height: '28px',
            }}
          />
        </Button>
      ) : progress === 100 && completable ? (
        <Button
          color="success"
          variant="outlined"
          href={`/course/${slug}/summary`}
          sx={{
            borderRadius: '20px',
            bottom: '5px',
          }}
        >
          <Typography variant="body2">
            {projectStatus === ProjectStatusType.NONE ? 'Summary' : 'Project'}
          </Typography>
        </Button>
      ) : lessonAmount !== lessonCompletedAmount || completable ? (
        <Button
          color="warning"
          variant="outlined"
          onClick={(e) => {
            continueLesson(slug);
            e.preventDefault();
          }}
          sx={{
            borderRadius: '20px',
            bottom: '5px',
          }}
        >
          <Typography variant="body2">Continue</Typography>
        </Button>
      ) : (
        <Button
          color="success"
          variant="outlined"
          sx={{ borderRadius: '20px' }}
          href={`/course/${slug}/summary`}
        >
          <Typography variant="body2">View Course</Typography>
        </Button>
      )}
    </>
  );
}

interface CourseSaleButtonProps {
  slug: string;
  CourseSaleDetailData: CourseSaleDetailDataType;
  enrolled: boolean;
}

function CourseSaleButton({
  slug,
  CourseSaleDetailData,
  enrolled,
}: CourseSaleButtonProps) {
  const isPreRegistered: boolean =
    CourseSaleDetailData.courseStatus === CourseStatusType.PREREGISTER &&
    enrolled;

  const navigate = useNavigate();

  return (
    <>
      {isPreRegistered ? (
        <Button
          color="tertiary"
          variant="outlined"
          href={`/course/${slug}`}
          sx={{
            borderRadius: '20px',
            bottom: '8px',
          }}
        >
          <Typography variant="body2">{'ลงทะเบียนแล้ว'}</Typography>
        </Button>
      ) : CourseSaleDetailData.courseStatus === CourseStatusType.SOON ||
        CourseSaleDetailData.courseStatus === CourseStatusType.EXCLUSIVE ? (
        <Button
          color="info"
          variant="outlined"
          href={`/course/${slug}`}
          sx={{
            borderRadius: '20px',
            bottom: '8px',
          }}
        >
          <Typography variant="body2">
            {CourseSaleDetailData.courseStatus}
          </Typography>
        </Button>
      ) : CourseSaleDetailData.plan ? (
        <Button
          color="primary"
          variant="contained"
          href={`/course/${slug}`}
          sx={{
            borderRadius: '20px',
            color: 'text.button',
            right: '0px',
            bottom: '8px',
          }}
        >
          <Typography variant="body2">{CourseSaleDetailData.plan}</Typography>
        </Button>
      ) : CourseSaleDetailData.wcProduct ? (
        CourseSaleDetailData.wcProduct.status === 'publish' ? (
          CourseSaleDetailData.wcProduct.price <
          CourseSaleDetailData.wcProduct.regularPrice ? (
            <Box
              onClick={() => navigate(`/course/${slug}`)}
              sx={{
                position: 'inherit',
                right: '0px',
                bottom: '0px',
                cursor: 'pointer',
              }}
            >
              <Box sx={{ textAlign: 'right' }}>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    textDecoration: 'line-through',
                    textDecorationColor: 'text.secondary',
                  }}
                >
                  {`${fTSeparator(
                    CourseSaleDetailData.wcProduct.regularPrice,
                  )}.-`}
                </Typography>
              </Box>
              <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                {`${fTSeparator(CourseSaleDetailData.wcProduct.price)}.-`}
              </Typography>
            </Box>
          ) : (
            <Box
              onClick={() => navigate(`/course/${slug}`)}
              sx={{
                position: 'inherit',
                right: '0px',
                bottom: '8px',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'text.primary',
                  cursor: 'pointer',
                }}
              >
                {`${fTSeparator(CourseSaleDetailData.wcProduct.price)}.-`}
              </Typography>
            </Box>
          )
        ) : (
          <Box
            onClick={() => navigate(`/course/${slug}`)}
            sx={{
              width: '100px',
              position: 'inherit',
              right: '0px',
              bottom: '8px',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: 'grey.600',
                cursor: 'pointer',
              }}
            >
              ยุติการขายแล้ว
            </Typography>
          </Box>
        )
      ) : null}
    </>
  );
}

interface CourseCardButtonProps {
  courseData: courseCatalogCardType;
}

export default function CourseCardButton({
  courseData,
}: CourseCardButtonProps) {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        right: 16,
        bottom: 10,
      }}
    >
      {courseData.courseProgress !== null &&
      courseData.status !== CourseStatusType.SOON &&
      courseData.status !== CourseStatusType.PREREGISTER ? (
        <CourseProgressButton
          progress={courseData.courseProgress.percentComplete}
          certificateId={courseData.courseProgress.certificateId}
          slug={courseData.slug}
          projectStatus={courseData.courseProgress.projectStatus}
          completable={courseData.completable}
          lessonAmount={courseData.courseProgress.lessonAmount}
          lessonCompletedAmount={
            courseData.courseProgress.lessonCompletedAmount
          }
        />
      ) : (
        <CourseSaleButton
          slug={courseData.slug}
          enrolled={courseData.courseProgress !== null}
          CourseSaleDetailData={{
            plan: courseData.recommendedPlan?.title,
            wcProduct: courseData.wcProduct,
            courseStatus: courseData.status,
          }}
        />
      )}
    </Box>
  );
}
