import { lessonType } from "../../@types/lesson"

export const initialVideo = {
  duration: 0,
  id: '',
  lessonId: '',
  url: '',
}

export const initialPrevLesson = {
  content: '',
  coverImageUrl: '',
  displayOrder: 0,
  duration: 0,
  id: '',
  title: '',
  type: '',
}

export const initialNextLesson = {
  content: '',
  coverImageUrl: '',
  displayOrder: 0,
  duration: 0,
  id: '',
  title: '',
  type: '',
  video: initialVideo
}

export const initialQuizData = {
  quiz: {
    id: '',
    lessonId: '',
    title: '',
    description: '',
    maxScore: 0,
    percentPassing: 0,
    questionAmount: 0,
    countdown: false,
  },
  attemptData: {
    maxAttempt: 0,
    remainAttempt: 0,
    submitted: false,
    passed: false,
    quizAttempts: []
  },
    allUserAttempt: [],
    allQuizsResult: []
}

export const initialVideoProgress = {
  ended: false,
  timestamp: 0
}

export const initialLesson = {
  completed: false,
  content: '',
  coverImageUrl: '',
  displayOrder: 0,
  duration: 0,
  sectionId: '',
  id: '',
  nextLesson: initialNextLesson,
  prevLesson: initialPrevLesson,
  quizData: initialQuizData,
  quizPassed: false,
  title: '',
  type: '',
  video: initialVideo,
  videoProgress: initialVideoProgress,
  isEndQuestion: false,
} as lessonType
