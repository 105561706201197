import React from 'react';
import { Box, Typography } from '@mui/material';
import { profileTargetType } from '../../../@types/profile';
import dayjs from 'dayjs';

export default function Target({target}: {target: profileTargetType | null}) {
  if (!target) return <></>
  return (
    <Box sx={{marginY: '30px'}}>
      <Typography variant='h4'>
        🎯  เป้าหมายหลัก / สิ่งที่กำลังตามหา
      </Typography>
      <Typography variant='h6'>
        “{target.value}” - เมื่อวันที่ {dayjs(target.updatedAt).format('DD/MM/YYYY')}
      </Typography>
    </Box>
  )
}
