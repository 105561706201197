import React from 'react'
import { Box, Stack, Grid, Skeleton, Divider } from '@mui/material'

export default function DevlabQuestionSkeleton() {
  return (
    <Box 
      sx={{ 
        borderRadius: '8px', 
        backgroundColor: 'background.paper', 
        marginBottom: '20px', 
        border: '1px solid', 
        borderColor: 'grey.800' 
      }}
    >
      <Stack 
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ 
          backgroundColor: '#00000060', 
          borderStartStartRadius: '8px', 
          borderStartEndRadius: '8px', 
          paddingX: '20px', 
          paddingY: '40px' 
        }}
      >
        <Box>
          <Skeleton height="60px" width="250px" />
          <Stack direction="row" alignItems="center" gap="10px">
            <Skeleton width="60px" />
            <Skeleton width="100px" />
          </Stack>
        </Box>
        <Stack direction="row" alignItems="center" gap="5px">
          <Stack direction="column" alignItems="end" sx={{ marginTop: '-20px' }}>
            <Skeleton width="60px" />
            <Skeleton width="100px" height="30px" />
          </Stack>
          <Skeleton variant="circular" width="60px" height="60px" />
        </Stack>
      </Stack>

      <Box sx={{ marginTop: '20px', padding: '20px' }}>
        {Array(3).fill(0).map((_, index: number) => (
          <Box key={`descript-skeleton-${index}`}>
            <Skeleton width="100px" height="40px" />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Divider sx={{ marginY: '20px' }} />
          </Box>
        ))}

        <Box>
          <Grid container columns={{ xs: 2 }} columnSpacing="5px">
            <Grid item xs={1}>
              <Skeleton width="100px" />
              <Skeleton height="150px" sx={{ marginTop: '-25px' }} />
            </Grid>
            <Grid item xs={1}>
              <Skeleton width="100px" />
              <Skeleton height="150px" sx={{ marginTop: '-25px' }} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}