import React, { useState, useEffect, useRef } from 'react'
import { Typography, Box, Stack } from '@mui/material'

import axiosInstance from '../../utils/axios'
import { CommentType, CommentDetailType } from '../../@types/comments'
import CommentLoading from '../skeleton/CommentLoading'

import CommentCard from './CommentCard'
import CommentControl from './CommentControl'
import { InitialComment } from './initialComment'
import CourseNoPermission from '../../pages/lesson/CourseNoPermission'

import { PaginationStyled } from './PaginationStyled'

interface CommentProps {
  courseId: string,
  lessonId: string,
  enrolled?: boolean
}

function CommentContainer({ courseId, lessonId, enrolled }: CommentProps) {
  const topicComment = useRef<HTMLElement | null>(null)

  const [autoScrolling, setAutoScrolling] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [comment, setComment] = useState<CommentType>(InitialComment)
  const [pageComment, setPageComment] = useState<number>(1)
  const [triggerFetchComment, setTriggerFetchComment] = useState<boolean>(true)

  const fetchComment = async () => {
    try {
      if (autoScrolling) scrollTop()
      const response = await axiosInstance.get(`/comments/?page=${pageComment}&take=5&courseId=${courseId}&lessonId=${lessonId}`)
      const data = await response.data
      await setComment(data)
    } catch {
      return
    }
  }

  const scrollTop = () => topicComment.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })

  const onNewComment = () => {
    if(pageComment === 1) {
      setTriggerFetchComment(!triggerFetchComment)
    } else {
      setPageComment(1)
    }
    scrollTop()
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await fetchComment();
      setLoading(false);
    };
    fetchData()

    return () => setAutoScrolling(false)
  }, [pageComment, triggerFetchComment])

  return (
    <Box>
      <Stack direction="row" alignItems="flex-start" spacing={0.5} sx={{ marginTop: '40px', marginBottom: '40px' }}>
        <Typography ref={topicComment} variant="h4" sx={{ color: 'text.primary' }}>แสดงความคิดเห็นของคุณ</Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>{ comment?.meta.itemCount ? `(${comment?.meta.itemCount})` : null}</Typography>
      </Stack>

      {loading ? (
        <CommentLoading number={5} />
      ) : (
        <>
          {comment.data.map((comment: CommentDetailType) => (
            <CommentCard
              key={`comment-card-${comment.id}`}
              courseId={courseId}
              lessonId={lessonId}
              commentData={comment}
              childCommentCount={comment.child}
              triggerstate={triggerFetchComment}
              triggerFetchFunction={setTriggerFetchComment}
            />
          ))}
        </>
      )}

      {comment?.meta.pageCount !== undefined && comment?.meta.pageCount > 1 ? (
        <Box sx={{ marginBottom: '48px', display: 'flex', justifyContent: 'end' }}>
          <PaginationStyled
            count={comment?.meta.pageCount}
            page={pageComment}
            onChange={(_, value: number) => {
              setPageComment(value)
              setAutoScrolling(true)
            }}
          />
        </Box>
      ) : null}

      {
        enrolled ?
        <CommentControl
          courseId={courseId}
          lessonId={lessonId}
          onNewComment={onNewComment}
        />
        :
        <Box sx={{backgroundColor: 'background.default', paddingY: '1px', borderRadius: '8px'}}>
          <CourseNoPermission title='การแสดงความคิดเห็น เฉพาะผู้ลงทะเบียนแล้วเท่านั้น'/>
        </Box>
      }
    </Box>
  )
}

export { CommentContainer }