import React from 'react';
import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { fTSeparator } from '../../utils/formatNumber';
import { LeaderboardUserType } from './type';
import { ProfileDetail, ProfileDetailTooltip } from '../profile/ProfileDetailTooltip';
import { getProfileDetailData } from './utils';
import { ellipsisTwo } from '../../utils/styles';
import { Link } from 'react-router-dom';

export default function Userranks({userrankData, top = false}: {userrankData: LeaderboardUserType[], top?: boolean}) {
  return (
    <Stack sx={{padding: '20px 10px', gap: '10px'}}>
      { userrankData.map((each, index) => 
        <ProfileDetailTooltip
          key={`userrankrank-tooltip-${index}`}
          title={ProfileDetail(getProfileDetailData(each))}
          placement="top-start"
        >
          <Box>
            <UserrankComponent rankData={each} key={`userrankrank-${index}`} top={top}/>
          </Box>
        </ProfileDetailTooltip>
      ) }
    </Stack>
  )
}

export function UserrankComponent({rankData, top = false}: {rankData: LeaderboardUserType, top?: boolean}) {
  const theme = useTheme()
  const themeMode = theme.palette.mode
  const color = top ? 'primary' : 'secondary'
  const selfBg = color + (themeMode === 'dark' ? '.dark' : '.light')
  const selfText = color + (themeMode === 'dark' ? '.light' : '.dark')
  const otherText = color + '.main'

  return (
    <Button
      color={color}
      component={rankData.role === 'ADMIN' || rankData.role === 'INSTRUCTOR' ? Box : Link} 
      to={`/profile/${rankData.id}`}
      target='_blank'
      sx={{
        width: '100%', 
        backgroundColor: rankData.self ? selfBg : 'background.default',
        borderRadius: '8px',
        display: 'flex',
        paddingX: '20px',
        justifyContent: 'space-between'
      }}
    >
      <Grid container>
        <Grid item xs={12*0.7}>
          <Box sx={{width: '100%', display: 'flex', alignItems: 'center', gap: '10px'}}>
            <Typography 
              variant='subtitle2' 
              sx={{color: top ? color + '.primary' : 'text.primary', fontSize: '20px' }}
            >
              {fTSeparator(rankData.rank)}
            </Typography>
            <Image 
              src={rankData.image} 
              sx={{
                width: '50px', 
                height: '50px',
                minWidth: '50px',
                borderRadius: '50%',
                border: `solid 5px ${top ? theme.palette.primary.main : null}`,
              }}
            />
            <Box 
              sx={{
                textAlign: 'start', 
                marginTop: '5px', 
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography 
                variant='subtitle2' 
                sx={{
                  width: '100%',
                  color: 'text.primary', 
                  fontSize: '20px', 
                  ...ellipsisTwo
                }}
              >
                {rankData.publicName}
              </Typography>
              <Typography 
                variant='body2' 
                sx={{
                  color: rankData.self ? selfText : otherText,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {rankData.level}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12*0.3}>
          <Box 
            sx={{
              display: 'flex', 
              justifyContent: 'end',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Iconify 
              sx={{
                width: '25px', 
                height: '25px', 
                minWidth: '25px',
                color: rankData.self ? selfText : otherText
              }} 
              icon='mdi:alpha-b-box-outline'
            />
            <Typography
              variant='body1'
              sx={{
                textAlign: 'center',
                color: 'text.primary'
              }}
            >
              {fTSeparator(rankData.exp)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Button>
  )
}
