import React, { useState } from 'react';
import Iconify from '../../../components/Iconify';
import Lessons from './Lessons';
import Notes from './Notes';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from '../../../redux/store'
import Exercises from './Exercises';

export default function TabsMenu() {
  const { courseLessons } = useSelector((state) => state.lesson)
  const [currentTab, setCurrentTab] = useState('บทเรียน')

  const handleChangeTab = (newValue: string) => {
    setCurrentTab(newValue);
  };
  const isIncompletableWatchAll = courseLessons.enrolled ? courseLessons.courseProgress.lessonAmount - courseLessons.courseProgress.lessonCompletedAmount === 0? true : false : false
  const MENU_TABS = [
    {
      value: 'บทเรียน',
      icon: <Iconify icon={'mdi:book-open-page-variant'} width={20} height={20} />,
      component: <Lessons completable={courseLessons.completable} lessons={courseLessons.sections} completableDone={isIncompletableWatchAll} complete={courseLessons.enrolled}/>,
      enabled: true
    },
    {
      value: 'จดโน้ตของคุณ',
      icon: <Iconify icon={'akar-icons:file'} width={20} height={20} />,
      component: <Notes courseId={courseLessons.id} />,
      enabled: courseLessons.enrolled
    },
    {
      value: 'แบบฝึกหัด',
      icon: <Iconify icon={'fa6-solid:file-pen'} width={20} height={20} />,
      component: <Exercises lessons={courseLessons.sections} />,
      enabled: courseLessons.enrolled
    }
  ];

  const TabsWrapperStyle = styled('div')(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
    },
  }));

  return (
    <Box>
      <TabsWrapperStyle>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          textColor="inherit"
          allowScrollButtonsMobile
          onChange={(e, value) => handleChangeTab(value)}
          sx={{alignItems: 'center'}}
          TabIndicatorProps={{
            style: {transition: 'none'}
          }}
        >
          {MENU_TABS.map((tab) => tab.enabled && (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              icon={tab.icon}
              label={tab.value}
              iconPosition="start"
              sx={{padding: '10px 0px', alignItems: 'center', minHeight: '36px', marginRight: '10px' }}
            />
          ))}
        </Tabs>
      </TabsWrapperStyle>
      {MENU_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </Box>
  )
}
