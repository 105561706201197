import React, { useState, useEffect } from 'react'
import { Box, Stack, Typography, Button, Divider } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'

import Image from '../../../components/Image'
import Icon from '../../../components/Iconify'
import CourseLoggingSkeleton from '../../../components/skeleton/CourseLoggingSkeleton'
import axiosInstance from '../../../utils/axios'

import { courseDataType } from '../../../@types/courseData'
import { PreviewLogDataType, PreviewLogMetaType } from '../../../@types/courseLog'

import PreviewComponent from './PreviewDetail'

interface CourseHeaderProps {
  data?: courseDataType
}

function CourseHeader({ data }: CourseHeaderProps) {
  return (
    <>
      <Stack direction="column" alignItems="center" sx={{ gap: '10px', maxWidth: '50%' }}>
        <Image 
          sx={{ width: '150px', height: '150px', borderRadius: '8px' }}
          src={data?.images?.square} 
          alt=""
        />
        <Typography variant="h4" sx={{ textAlign: 'center' }}>{data?.title}</Typography> 
        <Divider sx={{ width: '40%', marginTop: '20px', marginBottom: '15px' }} />
      </Stack>
    </>
  )
}

export default function CourseChangeLog() {
  const { courseSlug } = useParams()
  const navigate = useNavigate()

  const [course, setCourse] = useState<courseDataType>()
  const [courseLog, setCourseLogs] = useState<PreviewLogDataType[]>([])
  const [metaCourseLog, setMetaCourseLog] = useState<PreviewLogMetaType>()
  const [page, setPage] = useState<number>(0)
  const [loadding, setLoading] = useState<boolean>(true)

  const getCourseChangeLog = async (courseSlug: string) => {
    try {
      const response = await axiosInstance.get(`courses/${courseSlug}/change-logs?page=${page + 1}&take=10`)
      const data = await response.data
      setCourseLogs((prev: PreviewLogDataType[]) => [...prev, ...data.data])
      setMetaCourseLog(data.meta)
      setPage((prev: number) => prev + 1)
    } catch {
      navigate('/404')
    }
  }

  const getCourseDetail = async (courseSlug: string) => {
    try {
      const response = await axiosInstance.get(`courses/${courseSlug}`)
      const data = await response.data
      setCourse(data)
      setLoading(false)
    } catch {
      navigate('/404')
    }
  }

  useEffect(() => {
    getCourseDetail(courseSlug as string)
    getCourseChangeLog(courseSlug as string)
  }, [])

  if (loadding) {
    return <CourseLoggingSkeleton />
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ 
        gap: '10px', 
        padding: '40px', 
        paddingTop: '60px',
        maxWidth:'1270px',
        width: '100%',
        margin: 'auto',
      }}
    >
      <CourseHeader data={course} />
      <Typography variant="h3" sx={{ marginTop: '20px' }}>รายละเอียดการอัปเดต</Typography>

      <Box
        sx={{
          marginTop: '5px',
          border: '1px solid',
          borderColor: 'grey.700',
          borderRadius: '8px',
          backgroundColor: 'background.paper',
          padding: courseLog.length > 0 ? '20px' : '50px',
          width: '100%',
        }}
      >
        {courseLog.map((log: PreviewLogDataType, index: number) => (
          <Box key={`log-${index}`}>
            <PreviewComponent courseLogData={log} />

            {index !== courseLog.length - 1 && (
              <Divider sx={{ marginY: '20px' }} />
            )}
          </Box>
        ))}

        {metaCourseLog?.hasNextPage && (
          <Stack 
            direction="row" 
            justifyContent="center"
            sx={{ marginTop: '20px' }}
          >
            <Button
              variant="outlined"
              sx={{ paddingX: '40px' }}
              onClick={() => metaCourseLog?.hasNextPage && getCourseChangeLog(courseSlug as string)}
            >
              อ่านเพิ่มเติม
            </Button>
          </Stack>
        )}

        {courseLog.length === 0 && (
          <Typography sx={{ textAlign: 'center' }}>{`- ไม่มีรายการอัปเดต -`}</Typography>
        )}
      </Box>

      <Stack direction="row" sx={{ gap: '10px', marginTop: '10px' }}>
        <a
          href={`/course/${courseSlug}`}
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            sx={{ color: 'text.button' }}
          >
            <Icon icon="mingcute:back-2-line" sx={{ fontSize: '20px', marginRight: '5px' }} />
            <Typography>กลับไปที่คอร์ส</Typography>
          </Button>
        </a>
      </Stack>
    </Stack>
  )
}