import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BundleType } from '../../@types/bundle';

import Cover from './BundleCover';
import Detail from './Detail';
import CourseDetailSkeleton from '../../components/skeleton/CourseDetail';

import { useParams } from 'react-router';

import axios from '../../utils/axios';

import RelatedBundle from './RelatedBundle';
import HelmetMetaData from '../../components/Helmet';
import { useLocation } from 'react-router';

export default function BundleDetail() {
  const [loading, setLoading] = useState(true);
  const [bundleData, setBundleData] = useState<BundleType>();
  const { bundleSlug } = useParams();

  async function getBundleMeta() {
    try {
      const response = await axios.get(`/bundles/${bundleSlug}`);
      const data = response.data;
      setBundleData(data);
      setLoading(false);
    } catch {
      return;
    }
  }

  useEffect(() => {
    getBundleMeta();
  }, []);

  const location = useLocation();

  return (
    <BundleDetailRootStyle>
      {loading || !bundleData ? (
        <CourseDetailSkeleton />
      ) : (
        <>
          <HelmetMetaData
            title={bundleData.bundle.title}
            description={bundleData.bundle.shortDescription}
            currentUrl={location.pathname}
          />
          <Cover bundleData={bundleData.bundle} bundleMeta={bundleData.meta} />
          <Box
            sx={{
              backgroundColor: 'background.paper',
              borderRadius: '0px 0px 16px 16px',
            }}
          >
            <Detail
              bundleData={bundleData.bundle}
              bundleMeta={bundleData.meta}
            />
          </Box>
          <RelatedBundle />
        </>
      )}
    </BundleDetailRootStyle>
  );
}

const BundleDetailRootStyle = styled(Box)(({ theme }) => ({
  maxWidth: '1270px',
  margin: 'auto',
}));
