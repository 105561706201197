import React from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Waypoint } from 'react-waypoint';
import Iconify from './Iconify';

export default function FooterSticky({child, show, setShow}: {child: React.ReactNode, show :boolean, setShow: Function}) {
  return (
    <Box>
      <Waypoint
        onEnter={({ previousPosition }) => {
          previousPosition === 'below' && setShow(true)
        }}
      />
        <FooterStickyStyle sx={{bottom: show ? '0px' : '-121px'}}>
            <Button
              variant="contained"
              onClick={() => (setShow(!show))}
              sx={{position: 'absolute', marginTop: '-10px', padding: 0}}
            >
              <Iconify
                icon={show ? 'dashicons:arrow-down-alt2' : 'dashicons:arrow-up-alt2'}
                sx={{ width: 24, height: 24 }}
              />
            </Button>
            {child}
        </FooterStickyStyle>
    </Box>
  )
}

const FooterStickyStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: '0',
  bottom: '0',
  backgroundColor: theme.palette.background.paper,
  width: '100vw',
  height: '121px',
  zIndex: 1201,
  display: 'flex',
  justifyContent: 'center',
  transition: 'bottom 0.5s',
  boxShadow: '0px -6px 20px -5px rgba(0,0,0,0.34)'
}));
