import React from 'react'
import { Stack, Typography} from '@mui/material'

import Icon from '../../Iconify'
import { BornBitsNotificationType, BornBitType } from '../../../@types/bornbit'

interface BornBitBodyProps {
  data: BornBitsNotificationType
}

interface EndIconProps {
  type: BornBitType
  point: number
}

function EndIcon({ type, point }: EndIconProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: type === BornBitType.RECEIVE 
          ? 'primary.main'
          : 'error.main',
        color: type === BornBitType.RECEIVE 
          ? 'common.black'
          : 'common.white',
        borderRadius: '20px',
        paddingX: '10px'
      }}
    >
      <Icon 
        icon={
          type === BornBitType.RECEIVE
            ? 'ic:round-plus'
            : 'ic:round-minus'
        }
        sx={{ marginLeft: '-5px' }}
      />
      <Typography>{point}</Typography>
    </Stack>
  )
}

export default function BornBitBody({ data }: BornBitBodyProps) {
  return {
    title: data?.type === BornBitType.RECEIVE
      ? 'คุณได้รับ BornBits'
      : 'คุณได้ใช้ BornBits',
    startIcon: null,
    endIcon: <EndIcon type={data.type} point={data.point} />
  }
}