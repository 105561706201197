import React, { useCallback, useState, useEffect } from 'react';
import { Box, alpha, IconButton, FormControlLabel, Switch, Typography, Button } from '@mui/material';
import Image from '../Image';
import Iconify from '../Iconify';
import { ReactSortable } from "react-sortablejs";
import ImageModal from '../modal/ImageModal';
import { CustomFile, ImageCommentType } from '../../@types/comments'

import {
  UploadMultiFile,
} from '../upload';

interface CommentImageEditorProps {
  label?: string
  files: CustomFile[]
  fileOrder: number[]
  uploaded?: ImageCommentType[]
  onReturnValue: Function
  reset?: boolean
}

export default function CommentImageEditor({ label, files, fileOrder, uploaded = [], onReturnValue, reset }: CommentImageEditorProps) {
  const [reorderMode, setReorderMode] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState('')

  const maxImage = 3
  const ordersIndex = Array.from({length: maxImage}, (_, i) => i + 1)

  const ordersIndexObjects = Array.from(Array(maxImage).keys()).map((index) => {return {id: `${index+1}`, order: index+1}})

  function onSetFiles(inputFiles: CustomFile[], deletedFile?: CustomFile) {
    const slicedFiles = inputFiles.slice(0, maxImage - uploaded.length)
    if(deletedFile) {
      const fileIndex = getIndexByFile(deletedFile)
      onReturnValue('fileOrder', fileOrder.filter((_, index) => index !== fileIndex))
    } else {
      const uploadedIndexs = uploaded.map((image) => image.displayOrder)
      onReturnValue('fileOrder', ordersIndex.filter((order) => !uploadedIndexs.includes(order)).slice(0, slicedFiles.length))
    }
    onReturnValue('files', slicedFiles)
  }

  function getIndexByFile(file: CustomFile) {
    return files.findIndex((value) => value.preview === file.preview)
  }

  function getIndexByOrder(order: number) {
    return fileOrder.findIndex((value) => value === order)
  }

  function removeUploadedImage(displayOrder: number) {
    onReturnValue('uploaded', uploaded.filter((image) => image.displayOrder !== displayOrder))
  }

  useEffect(() => {
    if(files.length + uploaded.length < maxImage) handleOrderRemoveImage()
  }, [files, uploaded])

  const removeFileImage = (file: CustomFile) => {
    const filteredItems = files.filter((_file: any) => _file !== file);
    onSetFiles(filteredItems, file);
  };

  function handleOrderRemoveImage() {
    const existedIndexs = [...fileOrder, ...uploaded.map((image) => image.displayOrder)]
    const removedIndex = ordersIndex.find((order) => order <= existedIndexs.length && !existedIndexs.includes(order))
    if(removedIndex) {
      onReturnValue('fileOrder', fileOrder.map((order) => order > removedIndex ? order-1: order))
      onReturnValue('uploaded', uploaded.map((image) => image.displayOrder > removedIndex ? {...image, displayOrder: image.displayOrder-1} : image))
    }
  }

  function reorder(data: {id: string, order: number}[]) {
    const orderedFile = data.reduce((acc, cur, index) => {
      const fileIndex = getIndexByOrder(cur.order)
      const isFile = fileIndex >= 0
      return isFile ? [...acc, {index: fileIndex, order: index+1}] : acc
    }, [] as {index: number, order: number}[])

    const ordered = data.map((each) => each.order)
    const orderedImage = ordered.reduce((acc, cur, index) => {
      const image = uploaded.find((image) => image.displayOrder === cur)
      return image ? [...acc, {...image, displayOrder: index + 1}] : acc
    }, [] as ImageCommentType[])

    onReturnValue('fileOrder', orderedFile.sort((a, b) => a.index - b.index).map((each) => each.order))
    onReturnValue('uploaded', orderedImage)
  }

  return (
    <Box>
      <FormControlLabel control={<Switch value={reorderMode} checked={reorderMode} onChange={() => setReorderMode(!reorderMode)} />} label="จัดเรียงรูปภาพ" disabled={fileOrder.length + uploaded.length <= 0}/>
      {
        reorderMode ?
        <>
          <Box sx={{marginTop: '-8px'}}>
            <Typography variant={'caption'} sx={{color: 'text.secondary'}}>ลากรูปภาพเพื่อจัดตำแหน่ง</Typography>
          </Box>
          {
            fileOrder.length + uploaded.length > 0 &&
              <ReactSortable list={ordersIndexObjects} setList={reorder} style={{display: 'flex', gap: '10px'}}>
                {
                  ordersIndexObjects.map((order, index) => {
                    const fileIndex = getIndexByOrder(order.order)
                    const isFile = fileIndex >= 0
                    const fileItem = isFile && files[fileIndex]
                    const path = fileItem ? fileItem.preview : uploaded.find((image) => image.displayOrder === order.order)?.path
                    return path ?
                      <Image id={order.id} key={`reorder-${index}`} alt="preview" src={path} sx={{width: '75px', height: '75px', borderRadius: '8px'}}/>
                      :
                      <Box id={order.id} key={`reorder-${index}`} sx={{display: 'none'}}></Box>
                  })
                }
              </ReactSortable>
          }
        </>
        :
        <Box>
          <Box sx={{marginTop: '-8px'}}>
            <Typography variant={'caption'} sx={{color: 'text.secondary'}}>ไฟล์รูปภาพขนาดไม่เกิน 2mbs</Typography>
          </Box>
          <Box sx={{display: 'flex', gap: '10px'}}>
            {
              fileOrder.length + uploaded.length > 0 &&
              <>
                {
                  ordersIndexObjects.map((order, index) => {
                  const fileIndex = getIndexByOrder(order.order)
                  const isFile = fileIndex >= 0
                  const fileItem = isFile && files[fileIndex]
                  const path = fileItem ? fileItem.preview : uploaded.find((image) => image.displayOrder === order.order)?.path

                  return path ?
                    <Box key={`preview-${index}`} sx={{position: 'relative'}}>
                      <Button sx={{padding: '0px'}} onClick={() => setSelectedImage(path)}>
                        <Image alt="preview" src={path} sx={{width: '75px', height: '75px', borderRadius: '8px'}}/>
                      </Button>
                      <IconButton
                        size="small"
                        onClick={() => fileItem ? removeFileImage(fileItem) : removeUploadedImage(uploaded.find((image) => image.displayOrder === order.order)?.displayOrder || 0)}
                        sx={{
                          top: -6,
                          p: '2px',
                          right: -6,
                          position: 'absolute',
                          color: 'common.white',
                          bgcolor: (theme) => alpha(theme.palette.error.main, 1),
                          '&:hover': {
                            bgcolor: (theme) => alpha(theme.palette.error.dark, 1),
                          },
                        }}
                      >
                        <Iconify icon={'eva:close-fill'} />
                      </IconButton>
                    </Box>
                    :
                    <Box key={`preview-${index}`} sx={{display: 'none'}}></Box>
                  })
                }
                <ImageModal src={selectedImage} open={selectedImage !== ''} handleClose={() => setSelectedImage('')}/>
              </>
            }
            {
              (uploaded.length + files.length) < maxImage &&
              <MultiImageFileInput files={files} setFiles={onSetFiles} maxInput={maxImage}/>
            }
          </Box>
        </Box>
      }
    </Box>
  )
}

function MultiImageFileInput({files, setFiles, maxInput}: {files: CustomFile[] , setFiles: Function, maxInput: number}) {
  const handleWorkImagesDrop = useCallback(
    (acceptedFiles: any) => {
      const prevImages = files
      const uploadImages = acceptedFiles.map((file: CustomFile) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      setFiles([...prevImages, ...uploadImages].slice(0, maxInput));
    },
    [files]
  );

  const handleRemoveWorkImage = (file: CustomFile | string) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems, file);
  };
  return (
    <UploadMultiFile
      accept={{ 'image/*': ['.jpeg', '.jpg', '.png', '.webp'] }}
      files={files}
      error={false}
      showPreview={false}
      maxSize={2097152}
      onDrop={handleWorkImagesDrop}
      onRemove={handleRemoveWorkImage}
      component={<Box sx={{height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Iconify icon={'ic:baseline-plus'} sx={{width: '40px', height: '40px'}}/></Box>}
      sx={{width: '75px', height: '75px' }}
    />
  )
}
