import React from 'react';
import { Box } from '@mui/material';

import Icon from './Iconify';

interface ReviewRateStarProps {
  width: number | string;
  height: number | string;
  display: number;
}

export default function ReviewRateStar({
  width,
  height,
  display,
}: ReviewRateStarProps): JSX.Element {
  const displayPercentage = (): string => {
    if (display >= 0.75) return '100%';
    else if (display < 7.5 && display >= 0.25) return '52%';
    else return '0%';
  };

  return (
    <Box sx={{ position: 'relative', height: height }}>
      <Icon
        icon="ant-design:star-filled"
        sx={{
          width: width,
          height: height,
          color: 'grey.500',
        }}
      />

      <Box
        sx={{
          width: displayPercentage(),
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Icon
          icon="ant-design:star-filled"
          sx={{
            width: width,
            height: height,
            color: 'warning.main',
          }}
        />
      </Box>
    </Box>
  );
}
