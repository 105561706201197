import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import EditorHeader from './EditorHeader';
import axiosInstance from '../../../utils/axios';
import ChangePreview from './ChangePreview';
import { profileTargetType } from '../../../@types/profile';
import dayjs from 'dayjs';

export default function TargetEditor({target}: {target: profileTargetType | null}) {
  const [initStatus, setInitStatus] = useState(true)
  const [targetText, setTargetText] = useState<string>(target ? target.value : '')
  const [date, setDate] = useState(target ? dayjs(target.updatedAt).format('DD/MM/YYYY') : '')

  async function onSave() {
    await axiosInstance.put('/users/profile/target', {value: targetText})
    setInitStatus(true)
  }

  function onChange(text: string) {
    setTargetText(text)
    setInitStatus(false)
    setDate(dayjs(new Date()).format('DD/MM/YYYY'))
  }

  return (
    <Box>
      <EditorHeader title='เป้าหมายหลัก / สิ่งที่กำลังตามหา' onSubmit={() => onSave()} disabled={initStatus}/>
      <TextField
        value={targetText}
        onChange={(e) => onChange(e.target.value)}
        sx={{textAlign: 'left', width: '100%', marginTop: '10px'}}
        placeholder={'ประวัติส่วนตัว'}
      />
      <ChangePreview>
        <Typography  variant='h6'>
          “{targetText}” - เมื่อวันที่ {date}
        </Typography>
      </ChangePreview>
    </Box>
  )
}
