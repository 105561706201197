import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import LectureSkeleton from '../../components/skeleton/Lecture';
import { ProjectSubmissionType } from '../../@types/projectSubmission'
import { useSelector } from '../../redux/store'
import axios from '../../utils/axios';

import CourseResult from './CourseResult';
import ProjectSubmission from './ProjectSubmission';
import IncompleteCourseSummary from './IncompleteCourseSummary'

const ContentCardStyle = styled(Card)(({ theme }) => ({
  backgroundColor: 'background.paper',
  color: 'text.primary',
  padding: '38px',
  maxWidth: '1062.38px',
  '@media (min-width: 2000px)': {
    maxWidth:'1280px'
  },
}));

export default function CourseFinal() {
  const { courseSlug } = useParams()
  const navigate = useNavigate()
  const [projectSubmissionData, setProjectSubmissionData] = useState<ProjectSubmissionType>({} as ProjectSubmissionType)
  const [loading, setLoading] = useState(true)
  const { courseLessons } = useSelector((state) => state.lesson)


  const getProjectSubmissionData = async () => {
    try {
      const response = await axios.get(`/courses/${courseSlug}/summary`)
      const data = await response.data
      setProjectSubmissionData(data)

      if (data.courseProgress.percentComplete !== 100 && data.completable === true) await navigate(`/course/${courseSlug}`)
      setLoading(false)
    } catch (error) {
      navigate(`/course/${courseSlug}`)
    }
  };

  useEffect(() => {
    setLoading(true)
    getProjectSubmissionData()
  }, [])

  return (
    <Container
      sx={{
        marginTop: '30px',
        maxWidth: '1062.38px',
        '@media (min-width: 2000px)': {
          maxWidth:'1280px'
        },
      }}>
      <ContentCardStyle sx={{margin: 'auto', padding: '0px'}}>
        {(loading 
          ? <LectureSkeleton/>
          : projectSubmissionData.courseProgress.percentComplete !== 100 && projectSubmissionData.courseProgress.certificateId === null
            ? <IncompleteCourseSummary courseTitle={courseLessons.title} percentProgress={courseLessons.courseProgress.percentComplete} sectionData={courseLessons.sections} />
            : projectSubmissionData.courseProgress.projectStatus === "INCOMPLETE" 
              ? <ProjectSubmission projectId={projectSubmissionData.project.id} description={projectSubmissionData.project.description} remainAttempt={projectSubmissionData.project.remainAttempt} coverImage={projectSubmissionData.project.coverImage || courseLessons.images.cover}/>
              : <CourseResult projectSubmissionData={projectSubmissionData}/>
        )}
      </ContentCardStyle>
    </Container>
  )
}
