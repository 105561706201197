import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';

import axios from '../../utils/axios';
import HelmetMetaData from '../../components/Helmet';
import {
  courseCatalogType,
  BundleCatalogType,
} from '../../@types/courseCatalog';

import Cover from './Cover';
import CourseCatalog from './CourseCatalog';
import { CatalogSkeleton } from './ProductSkeletons';
import CatalogAllCourse from './CatalogAllCourse';
import SearchSection from './SearchSection';

const recommendedTitleList = [
  'Continue Learning',
  'คอร์สเรียนแนะนำ',
  'คอร์สเรียนใหม่ล่าสุด',
];
export default function Main() {
  const [catalogLoading, setCatalogLoading] = useState<boolean>(true);
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [catalog, setCatalog] = useState<courseCatalogType[]>(
    [] as courseCatalogType[],
  );
  const [bundlesCatalog, setBundlesCatalog] = useState<BundleCatalogType[]>(
    [] as BundleCatalogType[],
  );

  const getCatalog = async (): Promise<void> => {
    try {
      setCatalogLoading(true);
      const response = await axios.get('/catalog');
      const responseBundle = await axios.get('/catalog/bundle');
      setCatalog(response.data.recommendedGroups);
      setBundlesCatalog(responseBundle.data);
      setCatalogLoading(false);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getCatalog();
  }, []);

  return (
    <>
      <HelmetMetaData />
      <Cover />
      <Container
        sx={{
          maxWidth: '1220px',
          padding: '0px !important',
          marginBottom: '20px',
        }}
      >
        <SearchSection searchMode={searchMode} setSearchMode={setSearchMode} />
        {!searchMode ? (
          <>
            <CourseCatalog
              bundles={bundlesCatalog}
              recommendedGroups={catalog.filter((each) =>
                recommendedTitleList.includes(each.title),
              )}
              catalog={catalog.filter(
                (each) => !recommendedTitleList.includes(each.title),
              )}
            />
            {catalogLoading && <CatalogSkeleton />}
            <CatalogAllCourse />
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
