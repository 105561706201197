import React, { useState, useEffect, useRef } from 'react'
import { Typography, Box, Stack } from '@mui/material'

import axiosInstance from '../../utils/axios'
import useAuth from '../../hooks/useAuth'
import { CommentType, CommentDetailType } from '../../@types/comments'
import CommentLoading from '../skeleton/CommentLoading'

import CommentCard from './CommentCard'
import CommentControl from './CommentControl'
import { InitialComment } from './initialComment'
import { PaginationStyled } from './PaginationStyled'

interface CommentProps {
  courseId: string,
  lessonId: string,
  enrolled?: boolean
}

function AnswerContainer({ courseId, lessonId }: CommentProps) {
  const topicComment = useRef<HTMLElement | null>(null)
  const selected = useRef<HTMLElement | null>(null)
  const { user } = useAuth()

  const [isData, setIsData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [answer, setAnswer] = useState<CommentType>(InitialComment)
  const [pageComment, setPageComment] = useState<number>(1)
  const [triggerFetchComment, setTriggerFetchComment] = useState<boolean>(true)

  const fetchAnswer = async () => {
    try {
      const response = await axiosInstance(`/comments/answer?courseId=${courseId}&lessonId=${lessonId}&take=5&page=${pageComment}`)
      const data = await response.data
      setAnswer(data)

      if (data.data.length > 0) setIsData(true)
    } catch {
    }
  }

  const fetchData = async () => {
    setLoading(true)
    await fetchAnswer();
    setLoading(false);
  };

  useEffect(() => {
    fetchData()
  }, [pageComment, triggerFetchComment]);

  const findMyComment = (): string => {
    if (isData) {
      return answer.data.filter((comment: CommentDetailType) => comment.userId === user?.id)[0]?.id
    } else {
      return ''
    }
  }

  return (
    <Box ref={selected}>
      <Stack direction="row" alignItems="flex-start" spacing={0.5} sx={{ marginTop: '40px', marginBottom: '40px' }}>
        <Typography id='homework-title' ref={topicComment} variant="h4" sx={{ color: 'text.primary' }}>ส่งการบ้านท้ายบท</Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>{ (answer?.meta.itemCount && answer?.meta.itemCount !== 0) ? `(${answer?.meta.itemCount})` : null}</Typography>
      </Stack>

      {!loading ? (
        <>
          {isData && (
            <>
              {answer.data.map((comment: CommentDetailType) => (
                <CommentCard
                  key={`comment-card-${comment.id}`}
                  courseId={courseId}
                  lessonId={lessonId}
                  commentData={comment}
                  childCommentCount={comment.child}
                  triggerstate={triggerFetchComment}
                  triggerFetchFunction={setTriggerFetchComment}
                />
              ))}
            </>
          )}

          {(answer?.meta.pageCount !== undefined && answer?.meta.pageCount > 1 && answer.data.length > 1) ? (
            <Box sx={{ marginBottom: '48px', display: 'flex', justifyContent: 'end' }}>
              <PaginationStyled
                count={answer?.meta.pageCount}
                page={pageComment}
                onChange={(_, value: number) => {
                  setPageComment(value)
                }}
              />
            </Box>
          ) : null}

          {!isData && (
            <CommentControl
              spacificType="ANSWER"
              spacificComment={isData ? findMyComment() : undefined}
              courseId={courseId}
              lessonId={lessonId}
              onNewComment={() => fetchData()}
            />
          )}
        </>
      ) : (
        <CommentLoading number={5} />
      ) }
    </Box>
  )
}

export { AnswerContainer }