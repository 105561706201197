import React, { useState } from 'react';
import { Container, Box, Stack, Grid, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TypeAnimation } from 'react-type-animation';
import ReactPlayer from 'react-player';

import { fTSeparator } from '../../../utils/formatNumber';
import { SplitColorText } from './PlanTitle';

interface PromoteBannerProps {
  monthlyPrice: number;
  yearlyPrice: number;
}

const tools = [
  { text: 'React', color: '#61DBFB' },
  { text: 'Vue', color: '#42B883' },
  { text: 'JavaScript', color: '#F0DB4F' },
  { text: 'TypeScript', color: '#007ACC' },
  { text: 'SQL', color: '#3C8098' },
  { text: 'OOP', color: '#FCADA0' },
  { text: 'Data Structure', color: '#FFE66C' },
  { text: 'Algorithm', color: '#D42DBB' },
  { text: 'Software Testing', color: '#9B7645' },
  { text: 'UI Design', color: '#00D377' },
  { text: 'Project Management', color: '#005ADA' },
  { text: 'CI/CD', color: '#FF6200' },
  { text: 'Docker', color: '#0DB7ED' },
  { text: 'Kubernetes', color: '#95B3E4' },
  { text: 'Go', color: '#29BEB0' },
  { text: 'Java', color: '#FB3B2C' },
  { text: 'Mobile Dev', color: '#00875E' },
];

function Promote() {
  const [color, setColor] = useState('');
  const theme = useTheme();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '20px',
          marginBottom: '30px',
        }}
      >
        <SplitColorText leftText="bornto" rightText="Dev" />
        {' Plus'}
      </Typography>

      <Typography
        sx={{
          fontSize: '32px',
          fontWeight: 'bold',
        }}
      >
        {'เรียนรู้แบบสบายใจ'}
      </Typography>
      <Typography
        sx={{
          fontSize: '38px',
          fontWeight: 'bold',
          color: theme.palette.primary.main,
        }}
      >
        {'อัพสกิลสายไอทีแบบไม่อั้น'}
      </Typography>
      <Typography
        sx={{
          fontSize: '32px',
          fontWeight: 'bold',
          color: color,
        }}
      >
        <span style={{ color: theme.palette.text.primary }}>
          {'ที่ให้คุณได้เรียน'}
        </span>
        <TypeAnimation
          wrapper="span"
          speed={75}
          deletionSpeed={80}
          repeat={Infinity}
          cursor={false}
          sequence={tools.flatMap((item) => [
            () => setColor(item.color),
            250,
            item.text,
            2000,
            '',
          ])}
          style={{
            marginLeft: '8px',
            fontWeight: 'bold',
            transition: 'all .2s ease-in-out',
          }}
        />
      </Typography>

      <Box sx={{ marginTop: '30px' }}>
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 'bold',
          }}
        >
          {'รวมถึงหลักสูตรอื่น ๆ ทั้ง'}
        </Typography>

        <Stack direction="row" alignItems="center" sx={{ gap: '5px' }}>
          <Box
            sx={{
              backgroundColor: 'error.main',
              borderRadius: '100%',
              width: '25px',
              height: '25px',
            }}
          />
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: 'bold',
            }}
          >
            {'สดแบบออนไลน์ และ พรีเมียม'}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

function VideoPromote() {
  return (
    <Box>
      <ReactPlayer
        width="100%"
        url="https://www.youtube.com/watch?v=ImpzH9Ui0pI"
        playing
        controls={true}
        loop={true}
      />
    </Box>
  );
}

function RegisterButton({ monthlyPrice, yearlyPrice }: PromoteBannerProps) {
  const gotoSubscriptionPlan = () => {
    const element = document.getElementById('register-plan');
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ marginTop: '80px' }}
    >
      <Button
        variant="contained"
        onClick={gotoSubscriptionPlan}
        sx={{
          padding: '10px 50px',
          width: 'fit-content',
          fontSize: '18px',
          marginBottom: '20px',
          color: 'text.button',
        }}
      >
        {'ลงทะเบียน'}
      </Button>
      {/* <Typography>
        {`เริ่มต้นเพียง ฿${fTSeparator(monthlyPrice)} / เดือน`}
      </Typography>
      <Typography>
        {`หรือ ประหยัดกว่าด้วยแพคเกจ ฿${fTSeparator(yearlyPrice)} / ปี`}
      </Typography> */}
    </Stack>
  );
}

export default function PromoteBanner({
  monthlyPrice,
  yearlyPrice,
}: PromoteBannerProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'background.default'
            : 'background.paper',
        padding: '100px 0px 80px',
        '@media (max-width: 899px)': {
          padding: '70px 0px 80px',
        },
      }}
    >
      <Container>
        <Grid container rowGap="40px">
          <Grid item xs={12} md={6.5}>
            <Promote />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <VideoPromote />
          </Grid>
        </Grid>
        <RegisterButton monthlyPrice={monthlyPrice} yearlyPrice={yearlyPrice} />
      </Container>
    </Box>
  );
}
