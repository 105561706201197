import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Skeleton, Stack } from "@mui/material";
import axiosInstance from '../../../utils/axios';
import { courseCatalogType } from '../../../@types/courseCatalog';
import CourseCatalog from '../../main/CourseCatalog';
import { Link } from 'react-router-dom';

export default function PlansCourses({courseCount}: {courseCount: number}) {

  const [catalog, setCatalog] = useState<courseCatalogType[]>([])
  const [loading, setLoading] = useState(true)

  async function getCatalog() {
    try {
      const res = await axiosInstance.get('/catalog/recommended-plan-course')
      setCatalog(res.data.recommendedGroups)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCatalog()
  }, [])

  return (
    <Container>
      {
        loading ?
          <CatalogSkeleton/>
          :
          <CourseCatalog catalog={catalog} />
      }
      <Stack direction={'row'} justifyContent={'center'} sx={{marginBottom: '30px'}}>
        <Button component={Link} to={'/search?group=borntoDev%2B'} target='_blank' variant='outlined'>
          ดู {courseCount} หลักสูตรทั้งหมด
        </Button>
      </Stack>
    </Container>
  )
}

function CatalogSkeleton() {
  return (
    <>
      <CourseGroupSkeleton/>
      <CourseGroupSkeleton/>
      <CourseGroupSkeleton/>
    </>
  )
}

function CourseGroupSkeleton() {
  return (
    <Box sx={{marginY: '20px'}}>
      <Skeleton variant="rectangular" width={500} height={35} sx={{marginLeft: '25px'}}/>
      <Stack direction={'row'} gap={2} sx={{margin: '20px 0px 30px 40px'}}>
        <Skeleton variant="rectangular" width={270} height={390} sx={{borderRadius: '10px'}} />
        <Skeleton variant="rectangular" width={270} height={390} sx={{borderRadius: '10px'}} />
        <Skeleton variant="rectangular" width={270} height={390} sx={{borderRadius: '10px'}} />
        <Skeleton variant="rectangular" width={270} height={390} sx={{borderRadius: '10px'}} />
      </Stack>
    </Box>
  )
}