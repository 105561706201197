import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import CardWithCover from '../../components/CardWithCover';
import CourseCardContent from '../../components/course/CourseCardContent';
import CourseCardButton from '../../components/course/CourseCardButton';
import BundleCardContent from '../../components/bundle/BundleCardContent';
import CardArrowSelector from '../../components/CardArrowSelector';
import {
  courseCatalogType,
  courseCatalogCardType,
  BundleCatalogType,
} from '../../@types/courseCatalog';

import { productConfig } from './productConfig';

interface CourseCatalogProps {
  recommendedGroups?: courseCatalogType[];
  catalog: courseCatalogType[];
  bundles?: BundleCatalogType[];
}

export default function CourseCatalog({
  recommendedGroups = [],
  catalog,
  bundles = [],
}: CourseCatalogProps) {
  return (
    <>
      {recommendedGroups.map((catalogItem: courseCatalogType) => (
        <Box key={catalogItem.title}>
          <Typography
            key={`${catalogItem.title}-title`}
            sx={{ marginX: '30px' }}
            variant="h3"
          >
            {catalogItem.title}
          </Typography>
          <Typography
            key={`${catalogItem.description}-description`}
            sx={{ marginX: '30px', color: 'text.secondary' }}
            variant="body1"
          >
            {catalogItem.description}
          </Typography>
          <CardArrowSelector
            key={`${catalogItem.title}-selector`}
            cards={<CourseCard courses={catalogItem.courses} />}
            cardWidth={285}
            sx={{ padding: '27px 8px 42px 8px' }}
          />
        </Box>
      ))}
      {bundles.length > 0 && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              gap: '15px',
              marginLeft: '30px',
              '@media (max-width: 1200px)': {
                flexDirection: 'column',
                alignItems: 'start',
              },
            }}
          >
            <Typography variant="h3">Bundles</Typography>
            <Typography
              component={Link}
              to={productConfig.bundle.link}
              sx={{ color: 'primary.main', marginBottom: '8px' }}
              variant="body1"
            >
              ดูแพ็กเกจทั้งหมด
            </Typography>
          </Box>
          <CardArrowSelector
            cards={<BundleCard bundles={bundles} />}
            cardWidth={285}
            sx={{ padding: '27px 8px 42px 8px' }}
          />
        </Box>
      )}

      {catalog.map((catalogItem: courseCatalogType) => (
        <Box key={catalogItem.title}>
          <Typography
            key={`${catalogItem.title}-title`}
            sx={{ marginX: '30px' }}
            variant="h3"
          >
            {catalogItem.title}
          </Typography>
          <Typography
            key={`${catalogItem.description}-description`}
            sx={{ marginX: '30px', color: 'text.secondary' }}
            variant="body1"
          >
            {catalogItem.description}
          </Typography>
          <CardArrowSelector
            key={`${catalogItem.title}-selector`}
            cards={<CourseCard courses={catalogItem.courses} />}
            cardWidth={285}
            sx={{ padding: '27px 8px 42px 8px' }}
          />
        </Box>
      ))}
    </>
  );
}

function CourseCard({ courses }: { courses: courseCatalogCardType[] }) {
  return (
    <>
      {courses.map((courseData) => (
        <Box
          key={courseData.title}
          sx={{ minWidth: '270px', maxWidth: '270px' }}
        >
          <CardWithCover
            key={courseData.title}
            cover={courseData.images.card}
            instructors={courseData.instructors}
            link={`/course/${courseData.slug}`}
            child={<CourseCardContent courseData={courseData} />}
            specialChild={<CourseCardButton courseData={courseData} />}
          />
        </Box>
      ))}
    </>
  );
}

function BundleCard({ bundles }: { bundles: BundleCatalogType[] }) {
  return (
    <>
      {bundles.map((bundleData) => (
        <Box
          key={bundleData.title}
          sx={{ minWidth: '270px', maxWidth: '270px' }}
        >
          <CardWithCover
            key={bundleData.title}
            cover={bundleData.images.card}
            label={
              <Typography
                variant="body1"
                sx={{
                  background:
                    'linear-gradient(162deg, rgba(251,193,87,1) 0%, rgba(227,67,11,1) 100%);',
                  padding: '5px 10px',
                  color: 'common.white',
                  borderRadius: '5px',
                }}
              >
                BUNDLE
              </Typography>
            }
            link={`/bundle/${bundleData.slug}`}
            child={<BundleCardContent bundle={bundleData} />}
          />
        </Box>
      ))}
    </>
  );
}
