import React, { useState, useMemo, useEffect, useRef } from 'react';

import { useParams, useLocation, NavLink as RouterLink } from 'react-router-dom';
import { Typography, Box, List, Collapse, Button, useTheme } from '@mui/material';
import { ListItemStyle, LessonIconStyle, ListItemIconStyle, SectionTime } from './style';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import CertificateSection from './CertificateSection';
import { sectionType, lessonType } from '../../..//@types/courseLesson';
import { useSelector } from '../../../redux/store'
import { alpha } from '@mui/material/styles';

export default function Lessons({lessons, complete,completable,completableDone}: {lessons: sectionType[], complete: boolean, completable: boolean,completableDone:boolean}) {
  return (
    <Box>
      {lessons.map((section) => (
        <SectionItem key={section.id} section={section}/>
      ))}
      <CertificateSection completable={completable} complete={complete} completableDone={completableDone}/>
    </Box>
  )
}

function SectionItem({ section }: {section: sectionType}) {
  const { lessonId } = useParams();
  const location = useLocation()

  const isCurrentSection = useMemo<boolean>(() => {
    return section.lessons.some(lesson => lesson.id === lessonId)
  }, [location.pathname])

  const [open, setOpen] = useState(isCurrentSection);

  useEffect(() => {
    setOpen((prev: boolean) => prev || isCurrentSection)
  }, [isCurrentSection])

  return (
    <Box sx={{marginBottom: '2px', marginTop: '2px'}}>
      <SectionDropdown
        section={section}
        icon={getIcon('ic_library')}
        active={isCurrentSection}
        open={open}
        onOpen={() => setOpen(!open)}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {section.lessons.map((item) => (
            <LessonItem key={item.title} lesson={item} />
          ))}
        </List>
      </Collapse>
    </Box>
  );
}

function SectionDropdown({ section, icon, open = false, active, onOpen }
: { section: sectionType, icon: React.ReactNode, open: boolean, active: boolean, onOpen: () => void }) {
  const { title, duration } = section;

    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        <ListItemIconStyle>{icon}</ListItemIconStyle>
        <Box sx={{width: '100%'}}>
          <Typography variant="subtitle2">{title}</Typography>
          <SectionTime><Iconify icon={'fa-solid:clock'} width={14} height={14} sx={{marginRight:'2.5px'}}/>{Math.floor(duration/60)} นาที</SectionTime>
        </Box>
        <Iconify
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ width: 16, height: 16, ml: 1 }}
        />
      </ListItemStyle>
    );
}

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const LESSON_ICONS: {[key: string]: string} = {
  VIDEO: 'ci:play-circle-outline',
  CHECKED: 'akar-icons:check',
  CAUTION: 'eva:alert-triangle-outline',
  DEVLAB: 'fluent:window-dev-edit-16-filled',
  TEXT: 'gg:loadbar-doc',
  QUIZ: 'ic:round-quiz',
  LOCKED: 'material-symbols:lock'
}

function LessonItem({ lesson }:{ lesson: lessonType}) {
  const { courseLessons } = useSelector((state) => state.lesson)
  const theme = useTheme()
  const { courseSlug, lessonId } = useParams();
  const duration = (duration: number) => {
    const secondLesson = '' + Math.floor(duration%60)
    return duration ? `(${Math.floor(duration/60)}:${'00'.substring(0, 2 - secondLesson.length) + secondLesson} นาที)` : ''
  }

  const lessonRef = useRef<HTMLAnchorElement | null>(null)
  const isCurrentLesson = useMemo<boolean>(() => {
    return lessonId === lesson.id
  }, [lessonId])

  useEffect(() => {
    if (isCurrentLesson) setTimeout(scrollToLesson, 500)
  }, [lessonId, lessonRef])

  function scrollToLesson() {
    if (lessonRef.current) {
      lessonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  function isLocked() {
    return (!courseLessons.enrolled || courseLessons.status === "PREREGISTER") && !lesson.isPreview
  }

  return (
      <Button
        ref={lessonRef}
        component={RouterLink}
        to={`/course/${courseSlug}/${lesson.id}`}
        color='primary'
        disabled={isLocked()}
        sx={{
          color: isCurrentLesson ? 'primary.main' : 'text.secondary',
          backgroundColor: alpha(theme.palette.primary.main, isCurrentLesson ? 0.1: 0),
          display: 'flex',
          marginLeft: '14px',
          marginRight: '14px',
          padding: '16px',
          textDecoration: 'none',
          borderRadius: '8px'
        }}
      >
        <LessonIconStyle>
          {
            isLocked() ?
            <Iconify
              sx={{ width: '20px', height: '20px' }}
              icon={LESSON_ICONS.LOCKED}/>
            :
            (lesson.completed || lesson.quizPassed === false) &&
            <Iconify
              sx={{ color: isCurrentLesson ? 'primary.main' : '', width: '20px', height: '20px' }}
              icon={lesson.completed ? LESSON_ICONS.CHECKED : LESSON_ICONS.CAUTION}/>
          }
        </LessonIconStyle>
        <LessonIconStyle>
          <Iconify
            sx={{ color: isCurrentLesson ? 'primary.main' : '', width: '20px', height: '20px' }}
            icon={LESSON_ICONS[lesson.type]}/>
        </LessonIconStyle>
        <Typography variant="subtitle2" sx={{width:'100%', wordBreak: 'break-word'}}>{lesson.title} {duration(lesson.duration)}</Typography>
      </Button>
  )
}

