
import React from 'react';
import { Box, Typography } from '@mui/material';
import Iconify from '../../components/Iconify';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export default function RedirectMenu({sx}: {sx?: object}){
    const theme = useTheme()
    return (
      <>
        {menuList.map((menu, index) =>
          <Box key={index} sx={sx || {}}>
          {
            menu.external ?
            <a style={{textDecoration: 'none'}} href={menu.link}>
              <Box
                key={`menu-${index}`}
                sx={{display: 'flex', gap: '20px', color: theme.palette.text.primary, ':hover': {color: 'primary.main'}}}>
                <Iconify icon={menu.icon} sx={{width: '24px', height: '24px'}} />
                <Typography variant="body1">{menu.title}</Typography>
              </Box>
            </a>
            :
            <Box
              component={RouterLink}
              to={menu.link}
              sx={{display: 'flex', gap: '20px', textDecoration: 'none', color: theme.palette.text.primary, ':hover': {color: 'primary.main'}}}>
              <Iconify icon={menu.icon} sx={{width: '24px', height: '24px'}} />
              <Typography variant="body1">{menu.title}</Typography>
            </Box>
          }
          </Box>
        )}
      </>
    )
  }

  const menuList = [
    {
      icon: 'fluent:document-16-filled',
      title: 'หลักสูตรทั้งหมด',
      link: '/search?sortBy=MostEnrolled',
      external: false,
    },
    {
      icon: 'ic:outline-laptop-mac',
      title: 'Dev Lab 3',
      external: true,
      link: 'https://borntodev.com/devlab'
    },
    {
      icon: 'ic:baseline-backup-table',
      title: 'บทความ',
      external: true,
      link: 'https://www.borntodev.com/blog/'
    },
    {
      icon: 'bxs:folder',
      title: 'โปรเจกต์ทั้งหมด',
      link: '/devproject?sortBy=submittedAt&sortOrder=desc',
      external: false
    },

  ]
