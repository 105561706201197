import React, { useMemo } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import Iconify from '../Iconify';
import Image from '../Image';

type ImageSizeType = {
  width: number;
  height: number;
};

export default function ImageModal({
  src,
  open,
  handleClose,
}: {
  src: string;
  open: boolean;
  handleClose: Function;
}) {
  const imageSize = useMemo<ImageSizeType>(() => {
    const image = document.createElement('img');
    image.src = src;
    return {
      width: image.width,
      height: image.height,
    };
  }, [src]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ backgroundColor: 'rgb(0 0 0 / 60%)' }}
      onClose={() => handleClose()}
    >
      <Box sx={{ height: '100%' }} onClick={() => handleClose()}>
        <IconButton
          sx={{
            position: 'fixed',
            right: '0',
            margin: '10px',
            width: '50px',
            height: '50px',
            zIndex: 2,
          }}
          aria-label="delete"
          size="small"
        >
          <Iconify
            icon="akar-icons:cross"
            sx={{
              width: '30px',
              height: '30px',
              color: 'white',
              cursor: 'pointer',
              ':hover': {
                color: 'error.main',
              },
            }}
          />
        </IconButton>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${src})`,
            backgroundPosition: 'center',
            ...(imageSize.width > window.innerWidth ||
            imageSize.height > window.innerHeight
              ? { backgroundSize: 'contain' }
              : {}),
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          <Image src={src} sx={{ width: '100%', height: '100%', opacity: 0 }} />
        </Box>
      </Box>
    </Modal>
  );
}
