import React from 'react'
import { Stack, Divider, Skeleton } from '@mui/material'

export default function CourseLoggingSkeleton() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ gap: '10px', padding: '40px', paddingTop: '60px' }}
    >
      <Skeleton variant="rounded" sx={{ width: '150px', height: '150px' }} />
      <Skeleton variant="rounded" sx={{ width: '300px', height: '30px' }} />
      <Divider sx={{ width: '20%', marginY: '20px' }} />

      <Stack
        direction="column"
        alignItems="center"
      >
        <Skeleton variant="rounded" sx={{ width: '400px', height: '40px' }} />
        <Skeleton variant="rounded" sx={{ marginY: '20px', width: '500px', height: '300px' }} />
        <Skeleton variant="rounded" sx={{ width: '150px', height: '40px' }} />
      </Stack>
    </Stack>
  )
}