import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import axiosInstance from '../../../utils/axios';
import ReviewRateStar from '../../../components/ReviewRateStar';
import { BundleReviewType } from '../../../@types/bundle';

import BundleReviewCard from './BundleReviewCard';
import { fTSeparator } from '../../../utils/formatNumber';

interface BundleReviewProps {
  bundleSlug: string;
  rateReview: number;
  reviewCount: number;
  courseCount: number;
}

export default function BundleReview({
  bundleSlug,
  rateReview,
  reviewCount,
  courseCount,
}: BundleReviewProps) {
  const [reviews, setReviews] = useState<BundleReviewType[]>([]);

  const getBundleReviews = async () => {
    try {
      const response = await axiosInstance(`/bundles/${bundleSlug}/reviews`);
      const data = await response.data;
      setReviews(data);
    } catch {
      return;
    }
  };

  useEffect(() => {
    getBundleReviews();
  }, []);

  if (reviews.length) {
    return (
      <Box sx={{ marginTop: '40px' }}>
        <Stack
          direction="row"
          alignItems="start"
          sx={{
            gap: '10px',
            marginBottom: '15px',
          }}
        >
          <Typography variant="h4">รีวิวจากผู้เรียนในเเพคเกจนี้</Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'text.disabled',
            }}
          >
            {`(${reviewCount} รีวิว)`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            gap: '15px',
          }}
        >
          <Box>
            <Typography variant="h2" color="primary">
              {rateReview.toFixed(1)}
            </Typography>
          </Box>
          <Stack direction="column" alignItems="start">
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                marginLeft: '-5px',
              }}
            >
              {Array(5)
                .fill(0)
                .map((_, index: number) => (
                  <ReviewRateStar
                    key={`bundle-star-${index}`}
                    width="25px"
                    height="25px"
                    display={rateReview - index}
                  />
                ))}
            </Stack>
            <Typography
              variant="overline"
              sx={{
                color: 'text.disabled',
                marginTop: '3px',
              }}
            >
              {`จากรีวิว ${reviewCount} รีวิว`}
            </Typography>
          </Stack>
        </Stack>

        <Grid
          container
          columns={{ xs: 1, md: 2 }}
          columnSpacing="10px"
          rowSpacing="10px"
          sx={{
            marginY: '20px',
          }}
        >
          <Grid item xs={1}>
            <Stack direction="column" sx={{ gap: '10px' }}>
              {reviews.map(
                (item: BundleReviewType, index: number) =>
                  index % 2 === 0 && (
                    <Box key={`reivew-card-${index}`}>
                      <BundleReviewCard data={item} />
                    </Box>
                  ),
              )}
            </Stack>
          </Grid>
          <Grid item xs={1}>
            <Stack direction="column" sx={{ gap: '10px' }}>
              {reviews.map(
                (item: BundleReviewType, index: number) =>
                  index % 2 !== 0 && (
                    <Box key={`reivew-card-${index}`}>
                      <BundleReviewCard data={item} />
                    </Box>
                  ),
              )}
            </Stack>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            marginTop: '40px',
            textAlign: 'center',
          }}
        >
          <Typography>
            {`จากผู้เรียนจริงทั้งหมด ${fTSeparator(
              reviewCount,
            )} รีวิวใน ${fTSeparator(courseCount)} หลักสูตร ของแพคเกจนี้`}
          </Typography>
        </Stack>
      </Box>
    );
  }

  return null;
}
