import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from '../../../redux/store'

import { ProjectSubmissionType } from '../../../@types/projectSubmission'
import ProjectSummary from './ProjectSummary'
import CourseSummary from './CourseSummary';

export default function CourseResult({ projectSubmissionData }: { projectSubmissionData: ProjectSubmissionType }) {
  const { courseLessons } = useSelector((state) => state.lesson)

  return (
    <Box sx={{ padding: '40px 40px' }}>
      {projectSubmissionData.courseProgress.projectStatus === "NONE" ? (
        <CourseSummary 
        projectSubmissionData={projectSubmissionData} 
        courseLessons={courseLessons} 
        />
        ) : (
        <ProjectSummary 
          projectSubmissionData={projectSubmissionData} 
          courseLessons={courseLessons} 
        />
      )}
    </Box>
  )
}
