import React, { useState, useEffect } from 'react'
import { Stack } from '@mui/material'

import { BornBitsNotificationType } from '../../@types/bornbit'
import { useSelector } from '../../redux/store'
import { RootState } from '../../redux/store'

import ToastAlert from '../Toast'
import BornBits from './detail/BornBitBody'
import Notification from './detail/NotificationBody'

type NotificationListType = {
  data: BornBitsNotificationType
  open: boolean
}

export default function BornBitToast() {
  const { bornBitNotification } = useSelector((state: RootState) => state.bornbit)
  const [notificationList, setNotificationList] = useState<NotificationListType[]>([])

  const handleClose = (id: string) => {
    setNotificationList((prev: NotificationListType[]) => {
      return prev.reduce((list: NotificationListType[], item: NotificationListType) => {
        if (item.data.id === id) return [...list, { ...item, open: false }]
        else return [...list, item]
      }, [])
    })
  }

  const handleToastBody = (data: BornBitsNotificationType) => {
    if (data.message) return Notification({ message: data.message })
    else return BornBits({ data: data })
  }

  useEffect(() => {
    if (bornBitNotification) {
      setNotificationList((prev: NotificationListType[]) => ([
        ...prev, {
          open: true,
          data: bornBitNotification
        }
      ]))
    }
  }, [bornBitNotification])

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        position: 'fixed',
        zIndex: 9999,
        left: '50%',
        bottom: 10,
        transform: 'translate(-50%, 0%)',
        transition: 'all',
      }}
    >
      {notificationList.map((each: NotificationListType, index: number) => (
        <ToastAlert 
          key={`toast-alert-${index}`}
          open={each.open}
          handleClose={() => handleClose(each.data.id)}
          {...handleToastBody(each.data)}
        />
      ))}
    </Stack>
  )
}