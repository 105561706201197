import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import ReviewRateStar from '../../../components/ReviewRateStar';
import {
  ProfileDetailTooltip,
  ProfileDetail,
} from '../../profile/ProfileDetailTooltip';
import { fDateThai } from '../../../utils/formatTime';
import { BundleReviewType } from '../../../@types/bundle';

interface BundleReviewCardProps {
  data: BundleReviewType;
}

export default function BundleReviewCard({ data }: BundleReviewCardProps) {
  return (
    <Box
      sx={{
        borderRadius: '15px',
        padding: '20px',
        backgroundColor: 'background.default',
        color: 'common.white',
      }}
    >
      <Stack direction="row" justifyContent="end">
        <Typography
          variant="caption"
          sx={{
            color: 'text.disabled',
          }}
        >
          {fDateThai(dayjs(data.createdAt).toDate())}
        </Typography>
      </Stack>

      {data.user.role === 'STUDENT' ? (
        <ProfileDetailTooltip
          title={ProfileDetail(data.user)}
          placement="bottom-start"
        >
          <Typography
            component={Link}
            to={`/profile/${data.userId}`}
            variant="subtitle1"
            sx={{
              color: 'text.primary',
              textDecoration: 'none',
              wordBreak: 'break-word',
              ':hover': {
                color: 'primary.main',
              },
            }}
          >
            {data.user.publicName}
          </Typography>
        </ProfileDetailTooltip>
      ) : (
        <Typography variant="subtitle1" sx={{ wordBreak: 'break-word' }}>
          {data.user.publicName}
        </Typography>
      )}

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginLeft: '-5px',
          marginY: '10px',
        }}
      >
        {Array(5)
          .fill(0)
          .map((_, index: number) => (
            <ReviewRateStar
              key={`bundle-star-${index}`}
              width="20px"
              height="20px"
              display={data.rate - index}
            />
          ))}
      </Stack>

      <Typography
        sx={{
          fontSize: '12px',
        }}
      >
        {data.review}
      </Typography>

      <Box
        sx={{
          color: 'text.disabled',
          fontSize: '12px',
        }}
      >
        ใน
        <Typography
          component={Link}
          to={`/course/${data.courseSlug}`}
          variant="body2"
          sx={{
            color: 'text.disabled',
            fontSize: '12px',
            textDecoration: 'none',
            cursor: 'pointer',
            ':hover': {
              color: 'primary.main',
            },
            paddingLeft: '5px',
          }}
        >
          {data.courseTitle}
        </Typography>
      </Box>
    </Box>
  );
}
