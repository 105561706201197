import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSearchParams, useParams } from 'react-router-dom';

import { lessonType } from '../../../@types/lesson';
import { ColorSchema } from '../../../theme/palette';
import Iconify from '../../../components/Iconify';
import RichViewer from '../../../components/editor/RichViewer';
import ResultAlert from '../../../components/ResultAlert';

export default function QuizDescription({
  lesson,
  continuous,
  startQuiz,
  remainAttempt,
}: {
  lesson: lessonType;
  continuous: boolean;
  startQuiz: () => Promise<void>;
  remainAttempt?: number;
}) {
  const currentRemainAttempt =
    remainAttempt || lesson.quizData.attemptData.remainAttempt;
  const [searchParams] = useSearchParams();
  const { courseSlug, lessonId } = useParams();
  const handleStartQuiz = () => {
    window.history.pushState({}, '', `/course/${courseSlug}/${lessonId}`);
    startQuiz();
  };

  return (
    <>
      <Box>
        {searchParams.get('error') === 'new_version' && (
          <Box sx={{ marginBottom: '10px' }}>
            <ResultAlert
              severity="warning"
              title="พบปัญหาจากแบบทดสอบ เนื่องจากมีการเปลี่ยนแปลงข้อมูลของโจทย์คำถาม"
              description="มีการเปลี่ยนแปลงข้อมูลของเนื้อหา จึงทำการยกเลิกการทำแบบทดสอบก่อนหน้า"
            />
          </Box>
        )}
        <Typography variant="h5">{lesson.title}</Typography>
        {lesson.quizData.quiz.title !== '' && (
          <Typography
            variant="h6"
            sx={{ color: 'secondary.main', marginY: '10px' }}
          >
            {lesson.quizData.quiz.title}
          </Typography>
        )}
        <RichViewer content={lesson.quizData.quiz.description} />
        <Box sx={{ marginY: '20px' }}>
          <TextValueHighlight
            label={'จำนวนข้อ : '}
            value={`${lesson.quizData.quiz.questionAmount} ข้อ`}
            sx={{ gap: '5px' }}
          />
          <TextValueHighlight
            label={'เวลาที่ใช้ : '}
            value={`${Math.floor(lesson.duration / 60)} นาที`}
            sx={{ gap: '5px' }}
          />
          <TextValueHighlight
            label={'เกณฑ์คะแนนที่ผ่าน : '}
            value={`${lesson.quizData.quiz.percentPassing}%`}
            sx={{ gap: '5px' }}
          />
          <TextValueHighlight
            label={'จำนวนครั้งที่เหลือ : '}
            value={`${currentRemainAttempt} ครั้ง`}
            sx={{ gap: '5px' }}
          />
          <Typography sx={{ color: 'text.secondary' }} variant="caption">
            หมายเหตุ: ผู้เรียนสามารถทำแบบทดสอบซ้ำได้ตามจำนวนที่เหลืออยู่
            โดยจะนำครั้งที่ผ่านที่ได้คะแนนมากที่สุดมาคิดการให้ใบรับรอง
            และจำนวนครั้งจะถูกหักหลังจากส่งแบบทดสอบ
          </Typography>
        </Box>

        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            sx={{ color: 'text.button', width: '348px', height: '50px' }}
            disabled={currentRemainAttempt <= 0}
            onClick={() => handleStartQuiz()}
          >
            <Typography variant="h6">
              {continuous ? 'ทำควิซต่อ' : 'เริ่มทำควิซ'}
            </Typography>
          </Button>
        </Box>
      </Box>
      <ModalQuizNotice
        remainAttempt={currentRemainAttempt}
        percentPassing={lesson.quizData.quiz.percentPassing}
        countdown={lesson.quizData.quiz.countdown}
      />
    </>
  );
}

function TextValueHighlight({
  label,
  value,
  color,
  sx,
}: {
  label: string;
  value: string;
  color?: ColorSchema;
  sx?: Object;
}) {
  return (
    <Box sx={{ display: 'flex', gap: '5px', ...sx }}>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
        {value}
      </Typography>
    </Box>
  );
}

function ModalQuizNotice({
  remainAttempt,
  percentPassing,
  countdown,
}: {
  remainAttempt: number;
  percentPassing: number;
  countdown: boolean;
}) {
  const [open, setOpen] = useState(true);
  const [warningChecked, setWarningChecked] = useState(false);
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWarningChecked(event.target.checked);
  };
  return (
    <Modal open={open}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '500px',
          backgroundColor: 'background.paper',
          padding: '20px 30px',
          borderRadius: '16px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ marginY: '5px', color: 'warning.dark' }}
        >
          <Iconify
            icon={'material-symbols:warning-rounded'}
            sx={{
              color: 'warning.main',
              width: '20px',
              height: '20px',
              margin: '0px 5px -5px 0px',
            }}
          />
          การทำแบบทดสอบนี้มีผลต่อการออกใบรับรอง (Certificate)
          หากทำไม่ผ่านในจำนวนครั้งที่กำหนดจะไม่ได้ใบรับรอง*
        </Typography>
        <TextValueHighlight
          label={'เกณฑ์คะแนนที่ผ่าน'}
          value={`${percentPassing}%`}
          sx={{ gap: '5px' }}
        />
        <TextValueHighlight
          label={'จำนวนครั้งที่เหลือ'}
          value={`${remainAttempt} ครั้ง`}
          sx={{ gap: '10px' }}
        />
        <Typography sx={{ color: 'text.secondary' }} variant="caption">
          หมายเหตุ: ผู้เรียนสามารถทำแบบทดสอบซ้ำได้ตามจำนวนที่เหลืออยู่
          โดยจะนำครั้งที่ผ่านที่ได้คะแนนมากที่สุดมาคิดการให้ใบรับรอง
          และจำนวนครั้งจะถูกหักหลังจากส่งแบบทดสอบ
        </Typography>
        {countdown ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={warningChecked}
                onChange={handleCheckboxChange}
              />
            }
            sx={{ color: 'error.main', marginTop: '20px', fontVariant: '' }}
            label="คำเตือน: แบบทดสอบนี้มีการจับเวลาในการทำแบบทดสอบ หากผู้เรียนตอบคำถามไม่ครบในเวลาที่กำหนดจะถือเป็นการสิ้นสุดและสรุปคะแนนทันที*"
          />
        ) : (
          <></>
        )}
        <Box>
          {countdown ? (
            <Button
              sx={{ color: 'text.button', float: 'right' }}
              variant="contained"
              onClick={() => setOpen(false)}
              disabled={!warningChecked}
            >
              <Iconify
                icon="material-symbols:check-circle-outline"
                sx={{ width: '20px', height: '20px' }}
              />
              รับทราบแล้ว
            </Button>
          ) : (
            <Button
              sx={{ color: 'text.button', float: 'right' }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              <Iconify
                icon="material-symbols:check-circle-outline"
                sx={{ width: '20px', height: '20px' }}
              />
              รับทราบแล้ว
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
