export enum BornBitType {
  RECEIVE = 'RECEIVE',
  PAY = 'PAY',
  NOTIFICATION = 'NOTIFICATION'
}

export type BornBitsNotificationType = {
  id: string
  cratedAt: string
  message?: string
  point: number
  totalPoint: number
  type: BornBitType
}