import React from 'react';
import Iconify from '../../../components/Iconify';
import { Button, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useParams, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export default function CertificateSection({complete, completable,completableDone} : {complete : boolean, completable:boolean,completableDone:boolean}) {
  const { courseSlug } = useParams();
  const theme = useTheme()
  const courseData = useSelector((state: RootState) => state.lesson.courseLessons)
  const textInButton = completable === true ? 'รับ Certificate' : 'สรุปบทเรียน'
  const txtHover = completable ? !complete ? 'การรับ Certificate และ ส่งโปรเจกต์ทำได้เมื่อเรียนจบครบ 100% ของบทเรียนทั้งหมด': '': !completableDone?'ต้องเรียนให้จบคอร์ส 100% ก่อน':''
  return (
    <Tooltip title={txtHover}>
      <Button
        variant="contained"
        color="success"
        startIcon={<Iconify icon={'dashicons:upload'} />}
        component={NavLink}
        disabled={(completable && !complete) || (!completable && !completableDone)}
        to={`/course/${courseSlug}/summary`}
        sx={{
          width: '100%',
          backgroundColor: alpha(theme.palette.success.main, 0.08),
          color: theme.palette.success.main,
            '&:hover': {
              backgroundColor: theme.palette.success.light,
              color: theme.palette.success.dark,
          },
          marginBottom: '10px'
        }}
      >
        {textInButton}
      </Button>
    </Tooltip>
  )
}
