import React, { useMemo } from 'react'
import { Box, Stack, Typography, Tooltip } from '@mui/material'
import dayjs from 'dayjs'

import Icon from '../../../components/Iconify'
import { fDate } from '../../../utils/formatTime'

import { PreviewLogDataType, SectionLogDataType, LessonLogDataType } from '../../../@types/courseLog'

interface PreviewDetailProps {
  courseLogData: PreviewLogDataType
}

interface SectionListProps {
  sectionData: SectionLogDataType[]
  title: string
}

function SectionList({ sectionData, title }: SectionListProps) {
  return (
    <Box sx={{ marginLeft: '5px' }}>
      <Typography variant="h6">{title}</Typography>
      {sectionData.map((section: SectionLogDataType, sectionIndex: number) => (
        <Box
          key={`new-section-${sectionIndex}`} 
          sx={{ marginLeft: '15px' }}
        >
          <Stack direction="row" alignItems="center">
            <Icon icon="ph:dot-bold" sx={{ fontSize: '20px' }} />
            <Typography>{section?.title}</Typography>
          </Stack>
          <Box sx={{ marginLeft: '20px' }}>
            {section?.lessons?.map((lesson: LessonLogDataType, lessonIndex: number) => (
              <Tooltip 
                arrow
                placement="bottom-start"
                title={lesson?.flag 
                  ? 'บทเรียน Quiz หรือ Devlab มีการเปลี่ยนแปลงเนื้อหาภายในซึ่งอาจส่งผลให้มีการเปลี่ยนแปลงประวัติการการเก็บคะแนนใหม่' 
                  : ''
                }
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                }}
              >
                <Stack 
                  key={`update-lesson-${lessonIndex}`} 
                  direction="row" 
                  alignItems="center"
                  sx={{ 
                    gap: '5px',
                    width: 'fit-content',
                  }}
                >
                  <Icon icon="ph:dot-bold" />
                  <Typography>{lesson?.title}</Typography>
                  {lesson?.flag && (
                    <Typography sx={{ color: 'error.main' }}>*</Typography>
                  )}
                </Stack>
              </Tooltip>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default function PreviewDetail({ courseLogData }: PreviewDetailProps) {
  const newContent = useMemo<SectionLogDataType[]>(() => {
    return courseLogData.detail.new
      .sort((prev: SectionLogDataType, curr: SectionLogDataType) => 
        prev.displayOrder - curr.displayOrder)
      .reduce((sectionList: SectionLogDataType[], section: SectionLogDataType) => {
        return [
          ...sectionList,
          {
            ...section,
            lessons: section.lessons.sort((prev: LessonLogDataType, curr: LessonLogDataType) => 
              prev.displayOrder - curr.displayOrder)
          }
        ]
      }, [])
  }, [courseLogData])

  const updateContent = useMemo<SectionLogDataType[]>(() => {
    return courseLogData.detail.update
      .sort((prev: SectionLogDataType, curr: SectionLogDataType) => 
        prev.displayOrder - curr.displayOrder)
      .reduce((sectionList: SectionLogDataType[], section: SectionLogDataType) => {
        return [
          ...sectionList,
          {
            ...section,
            lessons: section.lessons.sort((prev: LessonLogDataType, curr: LessonLogDataType) => 
              prev.displayOrder - curr.displayOrder)
          }
        ]
      }, [])
  }, [courseLogData])

  if (courseLogData) {
    return (
      <Box
        sx={{
          position: 'relative',
          paddingX: '5px',
          paddingTop: '10px',
          paddingBottom: '5px',
          marginTop: '8px',
          marginBottom: '10px',
        }}
      >
        <Box>
          <Typography
            variant="h5" 
            sx={{ textAlign: 'left', fontWeight: 'bold' }}
          >
            {fDate(dayjs().toDate())}
          </Typography>

          <Typography 
            variant="body2" 
            sx={{ color: 'text.secondary' }}
          >
            {courseLogData.note}
          </Typography>

          {newContent.length > 0 && (
            <SectionList 
              sectionData={newContent}
              title="รายการเพิ่มใหม่"
            />
          )}

          {updateContent.length > 0 && (
            <SectionList 
              sectionData={updateContent}
              title="รายการอัปเดต"
            />
          )}
        </Box>
      </Box>
    )
  }

  return null
}