import React from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import CardWithCover from '../../components/CardWithCover';
import CardArrowSelector from '../../components/CardArrowSelector';

export function ProductSkeletons({ amount = 16 }: { amount?: number }) {
  return (
    <>
      {Array.from(Array(amount).keys()).map((number) => (
        <Grid
          key={`product-skeleton-${number}`}
          item
          xs={12}
          sm={6}
          md={4}
          xl={3}
          sx={{ justifyContent: 'center', display: 'flex' }}
        >
          <Box key={`card-${number}`} sx={{ width: '270px' }}>
            <CardWithCover key={`skeleton-${number}`} skeletonMode={true} />
          </Box>
        </Grid>
      ))}
    </>
  );
}

export function ProductSkeletonsBlock() {
  return (
    <Box sx={{ paddingX: '30px' }}>
      <Grid container sx={{ rowGap: '30px' }}>
        <ProductSkeletons />
      </Grid>
    </Box>
  );
}

export function CatalogSkeleton() {
  return (
    <Box>
      <Typography sx={{ marginX: '30px' }} variant="h3">
        <Skeleton width={300} />
      </Typography>

      <CardArrowSelector
        cards={<ProductSkeletons amount={4} />}
        cardWidth={285}
        sx={{ padding: '27px 8px 42px 8px' }}
      />
    </Box>
  );
}
