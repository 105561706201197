import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography } from '@mui/material';


export default function Cover({url}: {url?: string}) {
  return (
    <CoverWrapStyle coverImage={url}>
      <Box>
        <Typography variant="h2">เงื่อนไขการจัดส่งโปรเจกต์</Typography>
        <Typography variant="h2">เพื่อขอรับใบ Certificate</Typography>
      </Box>
    </CoverWrapStyle>
  )
}

interface CoverWrapProps extends BoxProps {
  coverImage?: String;
}

const CoverWrapStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'coverImage' ,
})<CoverWrapProps>(({ coverImage, theme }) => ({
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${coverImage}")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: '400px',
  padding: '75px 0px 0px 75px',
  color: theme.palette.common.white,
  '@media (max-width:1200px)': {
    padding: '40px',
  }
}));
