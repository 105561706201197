import React from 'react';
import { Box, Typography } from '@mui/material';
import RichViewer from '../../../components/editor/RichViewer';
import { DeltaStatic } from 'quill';

export default function Description({description}: {description: string | DeltaStatic}) {
  return (
    <Box>
      <Typography variant="h4" sx={{marginBottom: '20px'}}>เกี่ยวกับ</Typography>
      <RichViewer content={description}/>
    </Box>
  )
}
