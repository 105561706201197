import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { profileContactType } from '../../../@types/profile';
import Image from '../../../components/Image';

export default function ContactLinks({contacts}: {contacts: profileContactType[]}) {
  const contactAmount = contacts.reduce((acc, contact) => contact.value ? acc + 1 : acc , 0)
  return (
    <Box sx={{width: '95%'}}>
      {
        contactAmount > 0 &&
          <Typography
            variant='h4'
            sx={{
              display: 'none',
              marginTop: '10px',
              '@media (max-width: 899px)': {
                display:'block',
              }
            }}
          >
            ช่องทางการติดต่อ
          </Typography>
      }

      {
        contacts.map((contact, index) =>
          contact.value &&
            <ContactLink
              key={`contact-${index+1}`}
              icon={contact.baseContact.iconUrl}
              link={contact.url}
              account={contact.value}
            />
        )
      }
    </Box>
  )
}

export function ContactLink({icon, link, account}: {icon: string, link: string, account: string}) {
  return (
    <a href={link} style={{textDecoration: 'none'}} target="_blank" rel="noreferrer">
      <Button
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginBottom: '5px',
          color: 'text.primary',
          width: '100%',
          justifyContent: 'start',
          textAlign: 'left'
        }}
        color="info"
      >
        <Image src={icon} sx={{width: '30px', height: '30px', minWidth: '30px'}}/>
        <Typography variant={'subtitle2'} sx={{wordBreak: 'break-all'}}>{account}</Typography>
      </Button>
    </a>
  )
}

