import { Box, Typography } from "@mui/material"
import React from "react"
import Iconify from "../../components/Iconify"

export default function CourseError(){

  return(
    <Box sx={{textAlign: 'center', marginY: '100px'}}>
      <Box>
        <Iconify icon={'fa-solid:tools'} sx={{width: '40px', height: '40px'}}/>
      </Box>
      <Typography variant="h3">บทเรียนนี้กำลังปิดปรับปรุง</Typography>
      <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
        <Typography variant="subtitle1">ขออภัยในความไม่สะดวก </Typography>
        <Typography variant="subtitle1" sx={{color: 'primary.main'}}>โปรดลองอีกครั้งในภายหลัง</Typography>
      </Box>
    </Box>
  )
}
