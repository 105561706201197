import React from 'react';
import Button from '@mui/material/Button';
import { Modal, Card } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

// ----------------------------------------------------------------------

const styles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24
}

export function ConfirmationDialog( { text, open, handleCancel, handleConfirm } :
  {text: string, open: boolean, handleCancel: () => void, handleConfirm: () => void}) {
  return (
    <Modal
      open={open}
      onClose={handleCancel}
    >
      <Card sx={{ ...styles }}>
        <DialogTitle id="alert-dialog-title">
          {text}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>ยกเลิก</Button>
          <Button onClick={handleConfirm} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Card>
    </Modal>
  )
}

