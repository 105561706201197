import React, { useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { Box, Grid, Typography } from '@mui/material';
import { categoryType } from '../../..//@types/courseData'
import { courseCatalogCardType } from '../../..//@types/courseCatalog'
import CardWithCover from '../../../components/CardWithCover'
import CourseCardContent from '../../../components/course/CourseCardContent'
import { Link as RouterLink } from 'react-router-dom';


export default function RelatedCourses({categories, courseId} : {categories: categoryType[], courseId: string}) {
  const [courses, setCourses] = useState<courseCatalogCardType[]>([] as courseCatalogCardType[])
  const catsParams = categories.reduce((prev: string, cur: categoryType) => `${prev}&categories=${cur.title}`, '')
  useEffect(() => {
    getSearchCourse()
  }, [])
  const getSearchCourse = async (): Promise<void> => {
    try {
      const response = await axios.get(`/courses?enroll=NotEnrolled&take=4&excludeCourseIds=${courseId}${catsParams}`)
      setCourses(response.data.data)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      {courses.length ?
        <Box sx={{paddingBottom: '50px'}}>
          <Typography sx={{margin: '0px 0px 20px 60px'}} variant="h4">คอร์สออนไลน์ที่เกี่ยวข้อง</Typography>
          <CourseSearchResult courses={courses}/>
          <Box sx={{display: 'flex', justifyContent:'flex-end'}}>
            <Typography
              component={RouterLink}
              to={`/search${categories.length ? `?categories=${categories[0].title}` : ''}`}
              variant="body1"
              sx={{color: 'text.secondary', textAlign: 'end', margin: '15px 20px 0px 0px'}}
            >
              ดูเพิ่มเติม
            </Typography>
          </Box>
        </Box>
        : <></>
      }
    </>
  )
}

function CourseSearchResult({courses}: {courses: courseCatalogCardType[]}) {
  return (
      <Box sx={{padding: '10px'}}>
        <Grid container sx={{rowGap: '20px'}}>
          {courses.map((courseData)=>
          <Grid key={courseData.id} item xs={12} sm={6} md={4} xl={3} sx={{justifyContent: 'center', display: 'flex'}}>
             <Box sx={{width: '290px',}}>
               <CardWithCover
                  cover={courseData.images.card}
                  instructors={courseData.instructors}
                  link={`/course/${courseData.slug}`}
                  child={<CourseCardContent
                    courseData={courseData}
                  />}
                />
            </Box>
          </Grid>
        )}
        </Grid>
      </Box>
  )
}
