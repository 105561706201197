import React from 'react';
import { Typography, Alert } from '@mui/material';
import { Palette, PaletteColor } from '@mui/material';
import { styled } from '@mui/material/styles';

export default function ResultAlert({severity, title = '', description = '', content, sx={}}:
  {severity: 'success' | 'info' | 'warning' | 'error', title?: string, description?: string, content?: JSX.Element, sx?: Object}) {

  return(
    <AlertStyle severity={severity} sx={{marginTop: '10px', '& .MuiAlert-message': {width: '100%'}, ...sx}}>
      {
        content ||
        <>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="body2" sx={{display:'inline'}}>{description}</Typography>
        </>
      }

    </AlertStyle>
  )
}

const AlertStyle = styled(Alert)(({ severity, theme }) =>
  {
    type PaletteKey = keyof {
      [Key in keyof Palette as Palette[Key] extends PaletteColor ? Key : never]: true;
    }
  return {
    backgroundColor: theme.palette[severity as PaletteKey].lighter,
    color: theme.palette[severity as PaletteKey].dark,
    borderRadius: '8px'
}});
