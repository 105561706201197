import { devlabTaskType, devlabLastSubmissionType } from '../../../../@types/devlab'

export const devlabTaskInitial: devlabTaskType = {
  id: '',
  devlabId: '',
  devlabTaskId: 1,
  displayOrder: 1,
  maxScore: 0,
  nextTask: '',
  prevTask: '',
  percentProgress: 0,
  userStatus: "NEVER",
  passedTime: '',
  devlabDetail: {
    createdAt: '',
    updatedAt: '',
    devlabLevel: 1,
    devlabName: '',
    devlabDescription: '',
    devlabInputDescription: '',
    devlabOutputDescription: '',
    devlabConstraints: '',
    devlabUser: {
      userNicename: '',
      displayName: '',
      userImage: '',
      isAdmin: false,
    },
    example: [
      {
        id: 1,
        devlabId: '',
        input: '',
        output: '',
      }
    ],
    testcase: [
      {
        id: 1,
        devlabId: '',
        input: '',
        output: '',
        score: 0,
      }
    ]
  },
  devlab: {
    id: '',
    lessonId: '',
    createdAt: '',
    updatedAt: '',
    allowLanguages: [],
    description: '',
    maxScore: 0,
    percentPassing: 0
  }
}

export const submissionInitial: devlabLastSubmissionType = {
  code: '',
  createdAt: '',
  devlabId: 0,
  langId: 0,
  passed: false,
  time: 0,
}