import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Tabs, Tab } from '@mui/material';

import Icon from '../../components/Iconify';
import CourseProgressTable from './CourseProgressTable';
import { HEADER } from '../../config';

interface EnrolledCoursesProps {
  isSelf: boolean;
  profileId: string;
}

enum TabType {
  CONTINUOUS = 'CONTINUOUS',
  COMPLETE = 'COMPLETE',
}

type OptionListType = {
  label: string;
  labelShort: string;
  type: TabType;
  icon: string;
};

const exportDataText =
  '⚠️ หากท่านเคยลงทะเบียนไว้กับเราก่อนหน้าแล้วไม่พบหลักสูตร อาจเป็นไปได้ว่าท่านยังไม่ได้ทำการโอนย้ายข้อมูลมายังระบบใหม่ โดยสามารถทำได้โดยการ';

const options: OptionListType[] = [
  {
    label: 'Continue Learning',
    labelShort: 'Continuous',
    type: TabType.CONTINUOUS,
    icon: 'fluent:learning-app-20-filled',
  },
  {
    label: 'Completed Courses',
    labelShort: 'Complete',
    type: TabType.COMPLETE,
    icon: 'tabler:certificate',
  },
];

export default function EnrolledCourses({
  isSelf,
  profileId,
}: EnrolledCoursesProps) {
  const [selected, setSelected] = useState<TabType>(
    isSelf ? TabType.CONTINUOUS : TabType.COMPLETE,
  );

  const handleChangeTab = (event: React.SyntheticEvent, value: TabType) => {
    setSelected(value);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '20px',
          position: 'relative',
        }}
      >
        <Box
          id="continueLearning"
          sx={{ position: 'absolute', top: -HEADER.MAIN_DESKTOP_HEIGHT }}
        />
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={selected}
          onChange={handleChangeTab}
        >
          {options.map((item: OptionListType, index: number) => {
            if (!isSelf && item.type === TabType.CONTINUOUS) return null;
            else
              return (
                <Tab
                  key={`tab-${index}`}
                  label={item.label}
                  value={item.type}
                  icon={<Icon icon={item.icon} sx={{ fontSize: '30px' }} />}
                  iconPosition="start"
                  sx={{ fontSize: '22px' }}
                />
              );
          })}
        </Tabs>
      </Box>

      {selected === TabType.CONTINUOUS ? (
        <>
          <Typography variant="body1" sx={{ marginY: '10px' }}>
            {exportDataText}
            <Link to="/event/transfer">
              <Typography
                sx={{ color: 'primary.main', textDecoration: 'underline' }}
                component="span"
              >
                คลิกที่นี่
              </Typography>
            </Link>
          </Typography>
          <CourseProgressTable complete={false} profileId={profileId} />
        </>
      ) : (
        <>
          <CourseProgressTable complete={true} profileId={profileId} />
        </>
      )}
    </>
  );
}
