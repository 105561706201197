import React, { useState } from 'react'
import { Typography, Box, Grid, Stack, Button, FormGroup, Checkbox, Divider, Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'

import { CategoryAndTagsType, PropsFilterButton } from '../../../@types/projectGallery'
import Icon from '../../../components/Iconify'


const FilterButton = styled(Button)(({ theme }) => ({
  width: '123px',
  height: '36px',
  color: theme.palette.text.primary,
  borderColor: theme.palette.grey[700]
}))

const ModalBoxStyle2 = styled(Box)(({ theme }) => ({
  maxWidth: '400px',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'translate',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[700],
    borderRadius: '6px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.palette.grey[900],
    }
  }
}))


export default function FilterButtonComonent({ tagsList, categoryList, filterSelected, handleSumitFilter, setSearchMode }: PropsFilterButton) {
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [filter, setFilter] = useState<{ tags: string[], categories: string[] }>({ tags: [], categories: [] })

  const findDuplicateItem = (type: string, playload: string) => {
    const dupFilterItem = [...filter[type as 'tags' | 'categories']]
    const duplicateIndex = dupFilterItem.findIndex((item: string) => item === playload)

    if (duplicateIndex === -1) setFilter({ ...filter, [type]: [...dupFilterItem, playload] })
    else setFilter({ ...filter, [type]: dupFilterItem.filter((item: string) => item !== playload) })
  }


  return (
    <Stack justifyContent="flex-end">
      <Box sx={{ position: 'relative' }}>
        <FilterButton variant="outlined" onClick={() => setOpenFilterModal(!openFilterModal)}>
          <Icon icon="bx:filter" sx={{ width: 20, height: 20, marginRight: '5px' }} />
          <Typography variant="body1">Filter</Typography>
        </FilterButton>
      </Box>

      <Drawer
        anchor="right"
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
      >

        <ModalBoxStyle2>
          <Box 
            sx={{ 
              width: '100%',
              padding: '30px',
              paddingBottom: '0px',
              position: 'sticky', 
              top: 0, 
              zIndex: 2, 
              backgroundColor: 'background.paper',
            }}
          >

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '10px', textAlign: 'left' }}>
              <Typography variant="h4" >Filter</Typography>
              <Stack direction="row" alignItems="center" sx={{ gap: '10px' }}>
                <Typography
                  variant="body1"
                  sx={{ color: 'primary.main', cursor: 'pointer', '&:hover': { color: 'primary.dark' } }}
                  onClick={() => setFilter({ tags: [], categories: [] })}
                >
                  ล้าง
                </Typography>

                <Icon
                  icon="entypo:cross"
                  sx={{
                    width: '25px',
                    height: '25px',
                    color: 'text.primary',
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'text.secondary'
                    }
                  }}
                  onClick={() => setOpenFilterModal(false)}
                />

              </Stack>
            </Stack>

            <Box sx={{ padding: '10px', borderRadius: '8px', borderColor: 'primary.lighter', borderWidth: '1px', borderStyle: 'solid' }}>
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                onClick={() => {
                  if (filter.tags.length !== 0 || filter.categories.length !== 0) {
                    setSearchMode('filter')
                    handleSumitFilter({ ...filterSelected, search: '', category: '', ...filter })
                  } else {
                    setSearchMode('main')
                    handleSumitFilter({ ...filterSelected, search: '', category: 'all', tags: [], categories: [] })
                  }
                  setOpenFilterModal(!openFilterModal)
                }}
              >
                Search
              </Button>
            </Box>

            <Divider variant="middle" sx={{ margin: '20px 0px', boxShadow: '0px 2px 5px 0px rgba(46, 46, 46, .2)' }} />
          </Box>

          <FormGroup sx={{ textAlign: 'left', padding: '30px', paddingTop: '0px' }}>
            <Typography variant="h6">Tags</Typography>
            <Grid container columns={{ xs: 1, sm: 2 }} sx={{ marginTop: '10px', marginBottom: '10px' }}>
              {tagsList?.map((item: CategoryAndTagsType, index: Number) => (
                <Grid item xs={1} key={`filter-tags-${index}`}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => findDuplicateItem('tags', item.title)}
                  >
                    <Checkbox checked={filter.tags.filter((value: string) => value === item.title).length ? true : false} />
                    <Typography variant="body1">{item.title} {`(${item.count})`}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>

            <Divider variant="middle" sx={{ margin: '20px 0px' }} />
            <Typography variant="h6" sx={{ textAlign: 'left' }}>Category</Typography>
            <Grid container columns={1} sx={{ marginTop: '10px', marginBottom: '10px' }}>
              {categoryList?.map((item: CategoryAndTagsType, index: Number) => (
                <Grid item xs={1} key={`filter-category-${index}`}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => findDuplicateItem('categories', item.title)}
                  >
                    <Checkbox checked={filter.categories.filter((value: string) => value === item.title).length ? true : false} />
                    <Typography variant="body1">{item.title} {`(${item.count})`}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>

          </FormGroup>
        </ModalBoxStyle2>
      </Drawer>

    </Stack>
  )
}