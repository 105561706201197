import React from 'react';
import CardWithCover from '../CardWithCover';
import { BundleCatalogType } from '../../@types/courseCatalog';
import BundleCardContent from './BundleCardContent';
import { Typography } from '@mui/material';

export function BundleCard({ bundleData }: { bundleData: BundleCatalogType }) {
  return (
    <CardWithCover
      key={bundleData.title}
      cover={bundleData.images.card}
      label={
        <Typography
          variant="body1"
          sx={{
            background:
              'linear-gradient(162deg, rgba(251,193,87,1) 0%, rgba(227,67,11,1) 100%);',
            padding: '5px 10px',
            color: 'common.white',
            borderRadius: '5px',
          }}
        >
          BUNDLE
        </Typography>
      }
      link={`/bundle/${bundleData.slug}`}
      child={<BundleCardContent bundle={bundleData} />}
    />
  );
}
