import React from "react";
import { Box, Chip, Grid, alpha, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import Iconify from "../../Iconify";

interface CheckinCalendarType {
  amount: number; //day amount in month
  checkinLogs: number[]; //day in month that checked
  startIndex: number; //first day index in week
  shiftMonth: number; //shift index form current month
  loading?: boolean;
}

const weekArray = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export default function CheckinCalendar({amount, checkinLogs, startIndex, shiftMonth, loading = false}: CheckinCalendarType) {
  const nowDate = dayjs().date()
  const filledDayAmount = 7 * 6
  const theme = useTheme()

  return (
    <>
      <Grid container >
        {
          weekArray.map((each) => (
            <GridCheckinCalendarItemStyled 
              key={`log-item-${each}`}
              xs={12/7} 
              item 
              sx={{
                marginY: '5px'
              }}
            >
              <ChipCheckinCalendarItemStyled 
                label={each}
                color={'primary'}
                variant={'outlined'}
              />
            </GridCheckinCalendarItemStyled>
          ))
        }
      </Grid>
      <Grid container sx={{position: 'relative'}}>
        <Box 
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            borderRadius: '8px',
            display: loading ? 'flex': 'none',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >

          <Iconify sx={{width: '40px', height: '40px', color: 'text.secondary'}} icon='eos-icons:loading' />
        </Box>

        {
          Array(filledDayAmount).fill(1).map((each, index) => 
            {
              // before or after day in month for fill calendar
              const blank = index < startIndex || index >= startIndex + amount
              const checked = checkinLogs.includes(index + 1 - startIndex)
              const label = blank ? '' : index + 1 - startIndex
              // further today index
              const further = shiftMonth === 0 ? (index >= nowDate + startIndex) : shiftMonth > 0 ? true : false
              return (
                <GridCheckinCalendarItemStyled 
                  key={`log-item-${index}`}
                  xs={12/7} 
                  item 
                >
                  <ChipCheckinCalendarItemStyled 
                    label={label}
                    disabled={loading || !blank && !further && !checked}
                    color={loading ? 'default' : checked ? 'primary' : 'default'}
                    variant={checked || loading || blank ? 'filled' : 'outlined'}
                    sx={{
                      marginY: '5px'
                    }}
                  />
                </GridCheckinCalendarItemStyled>
              )
            }
          )
        }
      </Grid>
    </>
  )
}

const GridCheckinCalendarItemStyled = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const ChipCheckinCalendarItemStyled = styled(Chip)(() => ({
  width: '35px',
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  'span': {
    padding: '0px',
  },
}));
