import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  courseLessons: {
    enrolled: false,
    completable:true,
    status: '',
    title: '',
    sections: [],
    id: '',
    images: {
      cover: '',
      card: '',
      square: ''
    },
    slug: '',
    lessonAmount: 0,
    courseProgress:{
      certificateId: null,
      completed: false,
      courseId: '',
      id: '',
      learningDuration: 0,
      lessonAmount:0,
      lessonCompletedAmount: 0,
      percentComplete: 0,
      projectStatus: '',
      quizAmount: 0,
      quizCompletedAmount: 0,
      quizMaxScore: 0,
      quizSumScore: 0,
      totalDuration: 0,
      userId: ''
    }
  },
  lesson: {},
  lessonTimeStamp: 0,
  seekTimeStamp: 0,
  onSeek: false,
  error: null
};

const slice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    getLessonSuccess(state, action) {
      state.lesson = action.payload
    },
    getCourseLessonsSuccess(state, action) {
      state.courseLessons = action.payload
    },
    setLessonTimeStamp(state, action) {
      state.lessonTimeStamp = action.payload
    },
    setSeekTimeStamp(state, action) {
      state.seekTimeStamp = action.payload
    },
    setOnSeek(state, action) {
      state.onSeek = action.payload
    },
    hasError(state, action) {
      state.error = action.payload
    },
    setInitialCourseData(state) {
      state.courseLessons = { ...state.courseLessons, courseProgress: initialState.courseLessons.courseProgress }
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
  getLessonSuccess,
  setLessonTimeStamp,
  setSeekTimeStamp,
  setOnSeek,
  getCourseLessonsSuccess,
  hasError,
  setInitialCourseData
} = slice.actions;

export function getLesson(courseSlug: string, lessonId: string) {
  return async () => {
    try {
      const response = await axios.get(`/courses/${courseSlug}/lessons/${lessonId}`)
      dispatch(getLessonSuccess(response.data))
    } catch (error) {
      dispatch(hasError(error))
    }
  }
}

export function getCourseLessons(courseSlug: string) {
  return async () => {
    try {
      const response = await axios.get(`/courses/${courseSlug}/content`);
      dispatch(getCourseLessonsSuccess(response.data))
    } catch (error) {
      dispatch(hasError(error))
    }
  }
}
