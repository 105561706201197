import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import axiosInstance from '../../utils/axios';

import { ProjectDetailType } from '../../@types/projectGallery';
import RepoCard from '../../components/RepoCard';
import HelmetMetaData from '../../components/Helmet';

import ProjectDetailSkeleton from '../../components/skeleton/projectDetail';
import { initialGitParams, initialProjectDetail } from './initialState';
import ProjectOverview from './ProjectOverview';
import RecommededProject from './RecommendedProject';

type GitParamsType = {
  username: string;
  repository: string;
};

function ProjectDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [imageSelected, setImageSelected] = useState<string>('');
  const [projectData, setProjectData] =
    useState<ProjectDetailType>(initialProjectDetail);
  const [recommendedProjectList, setRecommendedProjectList] =
    useState<ProjectDetailType[]>();
  const [gitParams, setGitParams] = useState<GitParamsType>(initialGitParams);
  const [workLink, setWorkLink] = useState<string>('');

  const [loading, setLoading] = useState<Boolean>(true);
  const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);

  const getParamsGitAPI = (link: string): GitParamsType => {
    const pathArray: string[] = link.split('/');
    const repository = pathArray[pathArray.length - 1];
    const username = pathArray[pathArray.length - 2];

    return { username: username, repository: repository };
  };

  const favoriteAction = async () => {
    if (projectData !== undefined) {
      if (projectData.isFavorite === 0) {
        setProjectData({
          ...projectData,
          isFavorite: 1,
          favorite: projectData.favorite + 1,
        });
        await axiosInstance.post(`/projects/${projectId}/favorite`);
      } else {
        setProjectData({
          ...projectData,
          isFavorite: 0,
          favorite: projectData.favorite - 1,
        });
        await axiosInstance.delete(`/projects/${projectId}/favorite`);
      }
    }
  };

  const saveAction = async () => {
    if (projectData !== undefined) {
      if (projectData.isSave === 0) {
        setProjectData({ ...projectData, isSave: 1 });
        await axiosInstance.post(`/projects/${projectId}/save`);
      } else {
        setProjectData({ ...projectData, isSave: 0 });
        await axiosInstance.delete(`/projects/${projectId}/save`);
      }
    }
  };

  useEffect(() => {
    const fetchProjectDataById = async () => {
      try {
        const response = await axiosInstance.get(`/projects/${projectId}`);
        const data = await response.data;
        await setProjectData(data);
        await setImageSelected(data?.youtubeLink || '');
        setWorkLink(data?.worksLink);
        await setGitParams(getParamsGitAPI(data?.worksLink));
        await setLoading(false);
      } catch {
        navigate('/devproject', { replace: true });
      }
    };

    setLoading(true);
    fetchProjectDataById();
  }, [location]);

  useEffect(() => {
    const fetchRecommendProject = async () => {
      const response = await axiosInstance.get(
        `/projects/${projectId}/suggestion?take=4`,
      );
      const data = await response.data;
      await setRecommendedProjectList(data.data);
    };

    fetchRecommendProject();
  }, [location]);

  useEffect(() => {
    const getWidth = () => setWidthWindow(window.innerWidth);

    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, []);

  return (
    <>
      <HelmetMetaData />
      <Container sx={{ marginY: '50px', maxWidth: '1270px' }}>
        {!loading ? (
          <Box>
            <ProjectOverview
              width={widthWindow}
              projectDetail={projectData}
              imageSelected={imageSelected}
              setImageSelected={setImageSelected}
              favoriteAction={favoriteAction}
              saveAction={saveAction}
              workLink={workLink}
            />

            <Box sx={{ marginTop: '30px' }}>
              <RepoCard
                userName={gitParams.username}
                repoName={gitParams.repository}
                workLink={workLink}
              />
            </Box>

            {recommendedProjectList?.length !== 0 ? (
              <RecommededProject projectList={recommendedProjectList} />
            ) : null}
          </Box>
        ) : (
          <ProjectDetailSkeleton width={widthWindow} />
        )}
      </Container>
    </>
  );
}

export default ProjectDetail;
