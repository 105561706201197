import React from 'react';
// @mui
import { Box, Stack, Card, Avatar, Tooltip, Typography } from '@mui/material';
import { CardContent, Skeleton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

// components
import Image from './Image';
import SvgIconStyle from './SvgIconStyle';

import { instructorType } from '../@types/courseData';

type Props = {
  cover?: string;
  instructors?: instructorType[];
  child?: React.ReactNode;
  specialChild?: React.ReactNode;
  skeletonMode?: boolean;
  link?: string;
  label?: JSX.Element;
};

interface InstructorIconProps {
  instructors: instructorType[];
}

const CourseCard = styled(Card)({
  backgroundColor: 'Background.paper',
  borderRadius: '15px',
  boxShadow:
    '0px 2px 10px 3px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
  transition: 'all .25s ease-in-out',
  ':hover': {
    transform: 'scale(1.05)',
  },
});

export default function CardWithCover({
  cover,
  instructors,
  child,
  specialChild,
  skeletonMode,
  link,
  label,
}: Props) {
  return (
    <CourseCard sx={{ position: 'relative', marginY: '15px' }}>
      <Box
        component={link ? RouterLink : Box}
        to={link ? link : ''}
        sx={{ textDecoration: 'none' }}
      >
        <Box sx={{ position: 'relative' }}>
          {instructors && <InstructorIcon instructors={instructors} />}

          {label && (
            <Box
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1,
              }}
            >
              {label}
            </Box>
          )}

          {skeletonMode ? (
            <Skeleton variant="rectangular" height={202.5} />
          ) : (
            <Image alt="cover" src={cover} ratio="4/3" />
          )}
        </Box>
        <CardContent
          sx={{
            width: 1,
            height: '100%',
            maxHeight: '270px',
            paddingBottom: '10px !important',
          }}
        >
          {skeletonMode ? (
            <Box sx={{ height: '174px' }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box>
          ) : (
            child
          )}
        </CardContent>
      </Box>
      {!skeletonMode && specialChild}
    </CourseCard>
  );
}

function InstructorIcon({ instructors }: InstructorIconProps) {
  const theme = useTheme();
  return (
    <>
      {instructors.length === 1 && (
        <SvgIconStyle
          src="/icons/ic_avatar_frame.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 7,
            bottom: -15,
            left: 0,
            position: 'absolute',
            color: 'background.svg',
          }}
        />
      )}
      <Avatar
        alt={instructors[0].publicName}
        src={instructors[0].imageUrl}
        sx={{
          width: 32,
          height: 32,
          zIndex: 9,
          left: 24,
          bottom: -16,
          position: 'absolute',
        }}
      />

      {instructors.length > 1 && (
        <>
          <Box
            sx={{
              width: 40,
              height: 40,
              zIndex: 7,
              left: 20,
              bottom: -19.7,
              position: 'absolute',
              border: '1px',
              borderRadius: '100%',
              backgroundColor: 'background.svg',
            }}
          />
          <Box
            sx={{
              width: 40,
              height: 40,
              zIndex: 7,
              left: 52,
              bottom: -19.7,
              position: 'absolute',
              border: '1px',
              borderRadius: '100%',
              backgroundColor: 'background.svg',
            }}
          />
          {instructors.length === 2 ? (
            <Avatar
              alt={instructors[1].publicName}
              src={instructors[1].imageUrl}
              sx={{
                width: 32,
                height: 32,
                zIndex: 8,
                left: 56,
                bottom: -16,
                position: 'absolute',
              }}
            />
          ) : (
            <Tooltip
              arrow
              placement="bottom-start"
              title={instructors.map((item: instructorType, index: number) => {
                if (index !== 0)
                  return (
                    <Box key={`instructor-name-${index}`}>
                      <Typography variant="body2">{`• ${item.publicName}`}</Typography>
                    </Box>
                  );
                else return null;
              })}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 32,
                  height: 32,
                  zIndex: 8,
                  left: 56,
                  bottom: -16,
                  borderRadius: '50%',
                  border: `1px solid ${theme.palette.text.secondary}`,
                  borderStyle: 'dashed',
                  position: 'absolute',
                  color: 'text.secondary',
                }}
              >
                {`+${instructors.length - 1}`}
              </Stack>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}
