import { IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import Iconify from "../../Iconify"
import { Dayjs } from "dayjs"

interface MonthSelectorProps {
  date: Dayjs;
  shift: Function;
  shiftMonth: number;
  disabled?: boolean;
  maxLeft?: number | null;
  maxRight?: number | null;
}

export default function MonthSelector({date, shift, shiftMonth, maxLeft = null, maxRight = null, disabled = false}: MonthSelectorProps) {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <ArrowButton 
        variant='left' 
        edge={maxLeft === null ? false : shiftMonth <= maxLeft * -1} 
        onClick={() => shift(1)} 
        disabled={disabled}
      />
      <MonthLabel date={date}/>
      <ArrowButton 
        variant='right'
        edge={maxRight === null ? false : shiftMonth >= maxRight}
        onClick={() => shift(-1)} 
        disabled={disabled}
      />
    </Stack>
  )
}

function MonthLabel({date}: {date: Dayjs}) {
  return (
    <Stack direction={'column'} alignItems={'center'}  sx={{marginY: '10px'}}>
      <Typography variant='h3' sx={{lineHeight: '30px'}}>{date.format('MMMM')}</Typography>
      <Typography variant='caption' sx={{color: 'text.secondary'}}>{date.year()}</Typography>
    </Stack>
  )
}

interface ArrowButtonProp {
  variant: 'left' | 'right';
  edge?: boolean;
  disabled?: boolean;
  onClick: Function;
}

function ArrowButton({variant, edge = false, disabled = false, onClick}: ArrowButtonProp) {
  return (
    <IconButton sx={{width: '40px'}} disabled={disabled || edge} onClick={() => onClick()}>
      {
        edge ?
          <></>
        :
          <Iconify 
            icon={variant === 'left' ? 'dashicons:arrow-left-alt2' : 'dashicons:arrow-right-alt2'}
          />
      }
    </IconButton>
    
  )
}