import React, { useState, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/store';

import ProfileProjectsViewer from './ProfileProjectsViewer';
import ProfileProjectsList from './ProfileProjectsList';
import ProfileProjectsEditor from './ProfileProjectsEditor';

interface ProjectsProfileProps {
  profileId: string;
  editMode: boolean;
  name: string;
}

export default function ProjectsProfile({
  profileId,
  editMode,
  name,
}: ProjectsProfileProps) {
  const { userProject } = useSelector((state: RootState) => state.profile);

  const projectAmount = useMemo(() => {
    if (userProject) return userProject.meta.itemCount;
    else return 0;
  }, [userProject]);

  const projectList = useMemo(() => {
    if (userProject) return userProject.data;
    else return [];
  }, [userProject]);

  const [allMode, setAllMode] = useState(false);

  return (
    <Box sx={{ marginY: '20px' }}>
      {projectList && projectList.length > 0 ? (
        <Box
          sx={{
            padding: '20px 20px 0px 20px',
            '@media (max-width: 899px)': {
              padding: '20px 15px',
            },
          }}
        >
          {!editMode ? (
            <>
              <Typography variant="h4" sx={{ marginBottom: '10px' }}>
                {`โปรเจกต์ของ ${name} ในระบบ`}
              </Typography>
              {allMode ? (
                <ProfileProjectsList profileId={profileId} />
              ) : (
                <ProfileProjectsViewer
                  projectList={projectList}
                  projectAmount={projectAmount}
                  setAllMode={setAllMode}
                />
              )}
            </>
          ) : (
            <ProfileProjectsEditor profileId={profileId} />
          )}
        </Box>
      ) : null}
    </Box>
  );
}
