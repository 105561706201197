import React from 'react';
import { Box, Grid } from '@mui/material';
import { BundleMetaType } from '../../../@types/bundle';
import Image from '../../../components/Image';
import { useTheme } from '@mui/material/styles';

export default function Features({
  bundleMeta,
}: {
  bundleMeta: BundleMetaType;
}) {
  return (
    <Grid container>
      {getFeature(bundleMeta).map(
        (feature, index) =>
          feature.show && (
            <Feature key={`Feature-${index}`} feature={feature} />
          ),
      )}
    </Grid>
  );
}

type FeatureType = {
  icon: string;
  text: string;
};

function getFeature(bundleMeta: BundleMetaType) {
  const features = [
    {
      title: 'duration',
      icon: 'https://storage.borntodev.com/academy/content/icon-time-YEuBOLKP_lpJGsEKCldQkaiWfkxahf-gTLZjV6ekoh4g59tiRMa7ui6qyu3YO.webp',
      text:
        bundleMeta.lessonMeta.lessonDuration / 3600 > 1
          ? `${Math.round(bundleMeta.lessonMeta.lessonDuration / 3600)} ชั่วโมง`
          : `${Math.round(bundleMeta.lessonMeta.lessonDuration / 3600)} นาที`,
      show: bundleMeta.lessonMeta.lessonDuration > 0,
    },
    {
      title: 'lesson',
      icon: 'https://storage.borntodev.com/academy/content/icon-lesson-1ouprhsp1ueaNUqr9QNEXmsatON5hA-ALzIoNb3K7ZF4I8Vfw3klIX6FsXUe4.webp',
      text: `จำนวน ${bundleMeta.lessonMeta.lessonCount} บทเรียน`,
      show: bundleMeta.lessonMeta.lessonCount > 0,
    },
    {
      title: 'quiz',
      icon: 'https://storage.borntodev.com/academy/content/icon-quiz-ptkbKd3UwPqfB8t0xM9HIihqUzv9mN-IWzvCT0sQ8FMRcqJHpEgBSGBd6twnf.webp',
      text: `แบบทดสอบ ${bundleMeta.lessonMeta.quizCount} ชุด`,
      show: bundleMeta.lessonMeta.quizCount > 0,
    },
    {
      title: 'project',
      icon: 'https://storage.borntodev.com/academy/content/icon-project-cVdRKIiJtIgyfGQx7U81SUpzUfrsjy-6GgaYpRJ0ca-_UhpU9X2YhgXNXoOAo.webp',
      text: 'มีโปรเจกต์จบบทเรียน',
      show: bundleMeta.hasProject,
    },
    {
      title: 'online',
      icon: 'https://storage.borntodev.com/academy/content/icon-online-FK-4rYqZvVVNnByMNUCb5viQVMpTCs-0JU21QNmXl1OWC9iTMj-bMGS_mX0bv.webp',
      text: 'เรียนออนไลน์ 100 %',
      show: true,
    },
    {
      title: 'certificate',
      icon: 'https://storage.borntodev.com/academy/content/icon-cert-eV0gTjk_XhwsQDmxWJz1FuvAkTIsgH-NoO03dYqL93ZagssFkDnDOoD-fdcGo.webp',
      text: 'มีประกาศนียบัตร',
      show: true,
    },
  ];
  return features;
}

function Feature({ feature }: { feature: FeatureType }) {
  const theme = useTheme();
  return (
    <Grid item xs={12} md={4}>
      <Box
        sx={{
          alignItems: 'center',
          marginBottom: '25px',
          display: 'flex',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            padding: '11px',
            border: `${theme.palette.text.secondary} 1px solid`,
            borderRadius: '100px',
          }}
        >
          <Image
            disabledEffect
            visibleByDefault
            alt="feature"
            src={feature.icon}
            sx={{ width: 30, height: 30 }}
          />
        </Box>
        <Box>{feature.text}</Box>
      </Box>
    </Grid>
  );
}
