import React from 'react';
import { Button } from '@mui/material';
import Iconify from '../Iconify';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

import axios from '../../utils/axios';

export default function LogoutButton() {
  const {logout} = useAuth()
  const navigate = useNavigate()
  async function userLogout() {
    await localStorage.removeItem('bb-check-in')
    await axios.post(`/auth/logout`)
    navigate('/')
    logout()
  }
  return (
    <Button onClick={() => userLogout()} color='error' variant='contained' sx={{marginTop: '40px', width: '100%', padding: '10px'}}>
      <Iconify icon='material-symbols:logout-rounded' width={24} height={24} sx={{marginRight: '5px'}}/>
      ออกจากระบบ
    </Button>
  )
}
