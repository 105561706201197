import React from 'react'
import { Button, Stack, Typography } from '@mui/material'

import Icon from '../../../../components/Iconify'


function TaskLogin() {
  const handleRegister = () => {
    window.open('https://www.borntodev.com/devlab/login', '_blank')
  }

  return (
    <Stack 
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ 
        backgroundColor: '#00000060', 
        paddingY: '100px',
        borderRadius: '8px', 
        marginBottom: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
      }}
    >
        <Icon
          icon="material-symbols:lock"
          sx={{
            width: '70px',
            height: '70%',
            marginBottom: '10px',
            color: 'common.white'
          }}
        />
        <Typography variant="h6" color="primary">คุณยังไม่มีบัญชี Devlab </Typography>
        <Typography variant="body2" sx={{ color: 'common.white' }}>กรุณาสร้างบัญชีเพื่อทำโจทย์</Typography>

        <Button variant="outlined" sx={{ marginTop: '30px' }} onClick={() => handleRegister()}>
          <Typography>เปิดใช้งานระบบ Devlab</Typography>
        </Button>
    </Stack>
  )
}

export default TaskLogin