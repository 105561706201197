import React from 'react';
import { Typography, Box } from '@mui/material';
import { fTSeparator } from '../../../utils/formatNumber';
import { WcProductDataType } from '../../../@types/courseCatalog';

export function FreeSectionLabel() {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="body1"
          sx={{
            backgroundColor: 'success.main',
            padding: '1px 5px',
            color: 'common.white',
            borderRadius: '5px',
          }}
        >
          OPEN ACCESS
        </Typography>
      </Box>
      <Typography variant="h3">หลักสูตรเรียนฟรี ไม่มีค่าใช้จ่าย</Typography>
      <Typography variant="body1" sx={{ color: 'success.main' }}>
        เรียนอัพสกิลกับเราในโครงการ OPEN ACCESS ได้แล้ววันนี้
      </Typography>
    </>
  );
}

export function PremiumSectionLabel({
  wcProduct,
  hasBadge,
}: {
  wcProduct: WcProductDataType;
  hasBadge?: Boolean;
}) {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="body1"
          sx={{
            background:
              'linear-gradient(90deg, rgba(121,9,72,1) 0%, rgba(227,11,71,1) 50%, rgba(223,76,137,1) 100%);',
            paddingX: '10px',
            color: 'common.white',
            borderRadius: '5px',
          }}
        >
          {hasBadge && 'PREMIUM'}
        </Typography>
      </Box>
      <Typography variant="h3">
        คอร์สเรียนราคา {fTSeparator(wcProduct.price)}.-
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: 'text.secondary', display: 'flex', gap: '10px' }}
      >
        พิเศษจากราคาคอร์สเรียนปกติ{' '}
        <Typography variant="body1" sx={{ textDecoration: 'line-through' }}>
          {fTSeparator(wcProduct.regularPrice)}.-
        </Typography>
      </Typography>
    </>
  );
}

export function PremiumSaleSectionLabel({
  wcProduct,
}: {
  wcProduct: WcProductDataType;
}) {
  const isDiscount = wcProduct.regularPrice > wcProduct.price;
  return (
    <>
      {isDiscount ? (
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="body1"
            sx={{
              backgroundColor: 'error.main',
              padding: '1px 5px',
              color: 'common.white',
              borderRadius: '5px',
            }}
          >
            SALE
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      <Typography variant="h3">หลักสูตรโปรโมชั่นพิเศษ</Typography>
      <Typography variant="body1" sx={{ color: 'error.main' }}>
        เรียนได้ไม่จำกัดระยะเวลา พร้อมปรับปรุงเนื้อหาฟรี
      </Typography>
    </>
  );
}

export function SubscriptionSectionLabel({
  recommendedPlan,
  yearPrice,
}: {
  recommendedPlan: string;
  yearPrice?: number;
}) {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="body1"
          sx={{
            backgroundColor: 'error.main',
            padding: '1px 5px',
            color: 'common.white',
            borderRadius: '5px',
          }}
        >
          SALE
        </Typography>
      </Box>
      <Typography variant="h3">สำหรับ {recommendedPlan} เท่านั้น</Typography>
      {yearPrice && (
        <Typography variant="body1" sx={{ color: 'error.main' }}>
          เฉลี่ยเพียงเดือนละ {(yearPrice / 12).toFixed(2)}฿ เมื่อชำระเป็นรายปี
        </Typography>
      )}
    </>
  );
}

export function LearnSectionLabel({
  basicPlan,
  plan,
}: {
  basicPlan: boolean;
  plan: string;
}) {
  return (
    <>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        ยินดีต้อนรับสมาชิก
      </Typography>
      <Typography
        variant="h3"
        sx={{ color: 'primary.main', textAlign: 'center' }}
      >
        {basicPlan ? 'borntoDev' : plan}
      </Typography>
    </>
  );
}
