import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import { courseCatalogCardType } from '../../@types/courseCatalog';
import axiosInstance from '../../utils/axios';

import ProductSearchResult from '../main/ProductSearchResult';

interface CourseProgressTableProps {
  complete: boolean;
  profileId: string;
}

export default function CourseProgressTable({
  complete,
  profileId,
}: CourseProgressTableProps) {
  const [courseProgressData, setCourseProgressData] = useState<
    courseCatalogCardType[]
  >([] as courseCatalogCardType[]);

  const [courseLoading, setCourseLoading] = useState<boolean>(true);
  const [curPage, setCurPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<boolean>(false);

  const getCourseProgressData = async (page: number): Promise<void> => {
    try {
      setCourseLoading(true);
      const apiPath = complete
        ? `/courses/completed?sortBy=Newest&page=${
            page + 1
          }&take=15&userId=${profileId}`
        : `/courses/continuing?take=15&page=${page + 1}`;

      const response = await axiosInstance.get(apiPath);
      const data = await response.data;

      if (!data.meta.hasNextPage) setLastPage(true);
      setCurPage((prev: number) => prev + 1);
      setCourseProgressData([...courseProgressData, ...data.data]);
      setCourseLoading(false);
    } catch {
      setCourseLoading(false);
      setLastPage(true);
    }
  };

  const handleNextPage = () => {
    if (!lastPage) getCourseProgressData(curPage);
  };

  useEffect(() => {
    getCourseProgressData(curPage);
  }, [complete]);

  return (
    <Box
      sx={{
        padding: '10px',
        backgroundColor: 'background.paper',
        borderRadius: '16px',
        boxShadow: '0px 16px 32px -4px #0000003D',
        marginBottom: '40px',
      }}
    >
      <ProductSearchResult
        type="course"
        loading={courseLoading}
        courses={courseProgressData}
        bundles={[]}
      />

      {courseProgressData.length === 0 && (
        <Stack direction="row" justifyContent="center" sx={{ padding: '40px' }}>
          <Typography>{'- ยังไม่มีข้อมูล -'}</Typography>
        </Stack>
      )}
      <Waypoint onEnter={handleNextPage} />
    </Box>
  );
}
