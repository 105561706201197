import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import VideoPlayer from './VideoPlayer';
import LectureCover from './LectureCover';
import Content from './Content';
import NextLesson from './NextLesson';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import { setLessonTimeStamp } from '../../../redux/slices/lesson';
import { lessonType } from '../../../@types/lesson';
import FooterSticky from '../../../components/FooterSticky';

import PreviewAlert from './PreviewAlert';
import Comments from '../../../components/comment';

export default function Lecture({ lesson }: { lesson: lessonType }) {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLessonTimeStamp(0));
  }, [dispatch, lesson]);

  const CourseData = useSelector(
    (state: RootState) => state.lesson.courseLessons,
  );
  const isShowComment = CourseData?.status !== 'PREREGISTER';

  return (
    <>
      {lesson.type === 'VIDEO' ? (
        <VideoPlayer
          video={lesson.video}
          progress={lesson.videoProgress}
          sectionId={lesson.sectionId}
          courseId={CourseData.id}
          setShowFooter={setShow}
        />
      ) : (
        <LectureCover url={lesson.coverImageUrl || CourseData.images.cover} />
      )}
      <Box sx={{ padding: '38px', backgroundColor: 'background.paper' }}>
        {!CourseData.enrolled && <PreviewAlert />}
        <Content title={lesson.title} content={lesson.content} />
        {isShowComment && (
          <Comments
            isAnswerLesson={lesson.isEndQuestion}
            lessonId={lesson.id}
          />
        )}

        {lesson.nextLesson && (
          <FooterSticky
            setShow={setShow}
            show={show}
            child={
              <NextLesson nextLesson={lesson.nextLesson} setShow={setShow} />
            }
          />
        )}
      </Box>
    </>
  );
}
