import React from 'react';
import App from './App';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import { AuthProvider } from './contexts/authContext';
import { HelmetProvider } from 'react-helmet-async';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AuthProvider>
    <ReduxProvider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ReduxProvider>
  </AuthProvider>
);
