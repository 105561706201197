import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { WcProductType } from '../../../@types/bundle';
import Iconify from '../../../components/Iconify';
import { fTSeparator } from '../../../utils/formatNumber';
import useAuth from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';

export default function SaleSection({
  wcProduct,
  owned,
}: {
  wcProduct: WcProductType;
  owned: boolean;
}) {
  return (
    <Box>
      <ProductSaleSection wcProduct={wcProduct} owned={owned} />
    </Box>
  );
}

function ProductSaleSection({
  wcProduct,
  owned,
}: {
  wcProduct: WcProductType;
  owned: boolean;
}) {
  const { user } = useAuth();
  const isNotLoggedIn = !user;
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="body1"
          sx={{
            background:
              'linear-gradient(162deg, rgba(255,192,0,1) 0%, rgba(0,163,4,1) 57%, rgba(11,227,183,1) 100%);',
            paddingX: '10px',
            color: 'common.white',
            borderRadius: '5px',
          }}
        >
          BUNDLE
        </Typography>
      </Box>
      <Typography variant="h3">
        แพ็กเกจราคา {fTSeparator(wcProduct.data.price)}.-
      </Typography>
      {wcProduct.data.price < wcProduct.data.regularPrice && (
        <Typography
          variant="body1"
          sx={{ color: 'text.secondary', display: 'flex', gap: '10px' }}
        >
          พิเศษจากราคาปกติ{' '}
          <Box component="span" sx={{ textDecoration: 'line-through' }}>
            {fTSeparator(wcProduct.data.regularPrice)}.-
          </Box>
        </Typography>
      )}
      {isNotLoggedIn ? (
        <Button
          component={Link}
          to="/login"
          color="primary"
          variant="contained"
          sx={{
            width: '100%',
            color: 'common.black',
            padding: '10px',
            marginTop: '25px',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>เข้าสู่ระบบ</Typography>
        </Button>
      ) : owned ? (
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100%',
            color: 'common.black',
            padding: '10px',
            marginTop: '25px',
          }}
          disabled
        >
          <Typography sx={{ fontWeight: 'bold' }}>เป็นเจ้าของแล้ว</Typography>
        </Button>
      ) : (
        <a
          href={wcProduct.data.url}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button
            color="primary"
            variant="contained"
            sx={{
              width: '100%',
              color: 'common.black',
              padding: '10px',
              marginTop: '25px',
            }}
          >
            <Iconify
              icon="clarity:shopping-cart-solid"
              width={20}
              height={20}
            />
            <Typography sx={{ fontWeight: 'bold' }}>
              ลงทะเบียนแพ็กเกจนี้ {fTSeparator(wcProduct.data.price)}.-
            </Typography>
          </Button>
        </a>
      )}
    </>
  );
}
