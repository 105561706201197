import React, { useState, useEffect, forwardRef  } from 'react';
import { Box, TextField, Autocomplete, Button, IconButton } from '@mui/material';
import { profileSkillType } from '../../../@types/profile';
import { Skill } from '../profileDetail/Skills';
import axiosInstance from '../../../utils/axios';
import ChangePreview from './ChangePreview';
import Iconify from '../../../components/Iconify';
import _ from 'lodash'
import { ReactSortable } from 'react-sortablejs';
import { styled } from '@mui/material/styles';
import EditorHeader from './EditorHeader';

interface profileSkillEditorType {
  id: number
  name: string
}

const SortContainerStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  marginY: '10px',
  flexWrap: 'wrap'
}))

const CustomComponent = forwardRef<unknown, any>((props, ref) => {
  return ref && <SortContainerStyle ref={ref}>{props.children}</SortContainerStyle>;
});

function profileSkillEditorFormat(profileStatus: profileSkillType[]) {
  return profileStatus.map((each, index) => {
    return {
      id: index + 1,
      name: each.name
    } as profileSkillEditorType
  })
}

export default function SkillsEditor({skills}: {skills: profileSkillType[]}) {
  const [initStatus, setInitStatus] = useState(true)
  const [skillsData, setSkillsData] = useState<profileSkillEditorType[]>(profileSkillEditorFormat(skills))


  function onAddSkill(name: string) {
    setSkillsData([ ...skillsData,
      {
        id: skillsData.length + 1,
        name: name
      }
    ])
    setInitStatus(false)
  }

  function onRemove(name: string) {
    //filter
    const filtered = skillsData.filter((each) => each.name !== name).sort((a, b) => a.id - b.id)
    //reorder
    const reordered = filtered.map((each, index) => {return {...each, id: index+1}})
    setSkillsData(reordered)
    setInitStatus(false)
  }

  function reorderedSkills(data: profileSkillEditorType[]) {
    const reorderedSkills = data.map((each, index) => ({...each, id: index+1}))
    if(!_.isEqual(reorderedSkills, skillsData)){
      setInitStatus(false)
      setSkillsData(reorderedSkills)
    }
  }

  async function onSave() {
    await axiosInstance.put('/users/profile/skills', {skills: skillsData})
    setInitStatus(true)
  }

  return (
    <Box>
      <EditorHeader title={'สกิลหลักที่มี'} onSubmit={onSave} disabled={initStatus}/>
      <SkillSearchAdder onAddSkill={onAddSkill} skillsData={skillsData}/>
      <Box sx={{marginTop: '10px'}}>
        <ReactSortable tag={CustomComponent} list={skillsData} setList={reorderedSkills}>
          {
            skillsData.map((skill, index) =>
              <Box key={`skillEditor-${index+1}`} sx={{position: 'relative', cursor: 'grab'}}>
                <IconButton sx={{position: 'absolute', top: -15, right: -15}} onClick={() => onRemove(skill.name)}>
                  <Iconify icon={'mdi:close-circle'} sx={{color: 'grey.400'}}/>
                </IconButton>
                <Skill text={skill.name}/>
              </Box>
            )
          }
        </ReactSortable>
      </Box>

      <ChangePreview>
        <Box sx={{display: 'flex', gap: '10px'}}>
          {
            skillsData.map((skill, index) => <Skill key={`skill-${index+1}`} text={skill.name}/>)
          }
        </Box>
      </ChangePreview>
    </Box>
  )
}

interface skillSuggestionType {
  name: string
  count: number
}

function SkillSearchAdder({onAddSkill, skillsData}: {onAddSkill: Function, skillsData: profileSkillEditorType[]}) {
  const [search, setSearch] = useState('')
  const [skill, setSkill] = useState('')
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    getSearchSuggestion()
    setSkill(search)
  }, [search])

  const [skillSuggestionList, setSkillSuggestionList] = useState<skillSuggestionType[]>([] as skillSuggestionType[])

  async function getSearchSuggestion() {
    const response = await axiosInstance.get(`/users/profile/skills?take=5&search=${search}`)
    setSkillSuggestionList(response.data)
  }

  function isDuplicated() {
    return skillsData.find((each) => each.name === skill) !== undefined
  }

  function onSubmit() {
    onAddSkill(skill)
    setSearch('')
    setSkill('')
    setRefresh(refresh + 1)
  }

  return (
    <>
      <Autocomplete
        freeSolo
        key={refresh}
        options={skillSuggestionList}
        getOptionLabel={(option: any) => option.name ? `${option.name} (${option.count}🤖)` : search}
        onChange={(event: any, option: any) => {
          setSkill(typeof option === 'string' ? option : option.name)}
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(event) => setSearch(typeof event.target.value === 'string' ? event.target.value : '')}
            value={skill}
            placeholder={'Skill'}
            sx={{marginY: '10px'}}
          />
        )}
      />
      {
        skill &&
        <Box sx={{display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px'}}>
          <Skill text={skill}/>
          <Button variant='outlined' sx={{padding: '5px'}} onClick={onSubmit} disabled={isDuplicated()}>+ เพิ่ม</Button>
        </Box>
      }
    </>
  )
}
