import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import Image from '../../../components/Image';
import { courseDataType } from '../../..//@types/courseData';
import StartCourse from '../../../components/course/StartCourse';

export default function CourseDetailFooter({
  courseData,
}: {
  courseData: courseDataType;
}) {
  return (
    <Box
      sx={{
        maxWidth: '1220px',
        width: '100%',
        padding: '14px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media (max-width: 1200px)': {
            display: 'block',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '25px',
            '@media (max-width: 1200px)': {
              // paddingX: '20px',
            },
          }}
        >
          <Image
            disabledEffect
            visibleByDefault
            alt="course-image"
            effect="blur"
            src={courseData.images.cover || '/logos/borntodev-800x800.png'}
            sx={{
              width: '168px',
              minWidth: '168px',
              height: '95px',
              borderRadius: '8px',
              display: 'none',
              '@media (min-width: 1200px)': {
                display: 'block',
              },
            }}
          />
          <Box sx={{ width: '100%' }}>
            {/* instructor name */}
            <Typography sx={{ color: 'primary.light' }} variant="caption">
              {courseData.instructors.length
                ? `${courseData.instructors[0].publicName}`
                : ''}
            </Typography>
            {/* course title */}
            <Typography
              sx={{
                color: 'text.primary',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '@media (min-width: 1200px)': {
                  maxWidth: '480px',
                },
              }}
              variant="h5"
            >
              {courseData.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <StartCourse
            slug={courseData.slug}
            userLearningStatus={courseData.userLearningStatus}
            courseStatus={courseData.status}
            wcProduct={courseData.wcProduct}
            variant={'footer'}
          />
          <Button
            color="primary"
            variant="outlined"
            sx={{
              width: '200px',
              height: '70px',
              borderRadius: '8px',
              fontSize: '20px',
              '@media (max-width: 1200px)': {
                fontSize: '14px',
                width: '150px',
                height: 'auto',
              },
            }}
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
          >
            ดูวิดิโอตัวอย่าง
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
