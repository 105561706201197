import React from 'react'
import { Box, Stack, Skeleton } from '@mui/material'

export default function projectDetailSkeleton({ width }: { width: number }) {
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Skeleton variant="circular" width="64px" height="64px" />
        <Box>
          <Skeleton variant="text" height="40px" width="200px" />
          <Skeleton variant="text" height="20px" width="200px" />
        </Box>
      </Stack>

      <Skeleton variant="rectangular" height={`${width + 100}px`} sx={{ marginTop: '40px', borderRadius: '16px', maxHeight: '1140px' }} />

      <Box sx={{ marginTop: '80px', marginBottom: '50px' }}>
        <Skeleton variant="text" height="40px" width="200px" />
        <Box>
          <Skeleton variant="text" height="20px" sx={{ fmarginTop: '25px' }} />
          <Skeleton variant="text" height="20px" sx={{ fmarginTop: '25px' }} />
          <Skeleton variant="text" height="20px" sx={{ fmarginTop: '25px' }} />
        </Box>
      </Box>
    </Box>
  )
}