import React, { useState } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import { Box, Stack, Grid, Typography, Divider, LinearProgress, Button, Collapse } from '@mui/material'
import ReactQuill from 'react-quill'
import dayjs from 'dayjs'

import Icon from '../../../../components/Iconify'
import Image from '../../../../components/Image'
import Editor from '../../../../components/editor/EditorBody'
import useResponsive from '../../../../hooks/useResponsive'
import { devlabTaskType, devlabUserExampleType, devlabLanguageType } from '../../../../@types/devlab'

interface TaskDescriptionProps {
  data: devlabTaskType
  languageList: devlabLanguageType[]
}
interface DetailSectionProps {
  title: string
  description?: string
  specific?: React.ReactNode
}

interface CaseSectionProps {
  input?: string
  output?: string
  index: number
}

interface ProgressTaskProps {
  status: string
  percent: number
  passDate: string
  nextTask?: string | null
}

interface LanguageChipProps {
  text: string
  index: number
  languageAmount: number
}

function LanguageChip({ text, index, languageAmount }: LanguageChipProps) {
  const [check, setCheck] = useState<boolean>(false)

  return (
    <Stack
      key={`langs-chip-${index}`}
      direction="row"
      gap="5px"
      onMouseOver={() => setCheck(true)}
      onMouseOut={() => setCheck(false)}
    >
      <Typography>{text.split(' ')[0]}</Typography>
      <Collapse orientation="horizontal" in={check}>
        <Typography sx={{ color: '#8f8f8f' }}>{text.split(' ').splice(1, 10).join('')}</Typography>
      </Collapse>

      {languageAmount -1 !== index && (
        <Typography color="primary">{', '}</Typography>
      )}
    </Stack>
  )
}

function DetailSection({ title, description, specific }: DetailSectionProps) {
  if (description || specific) {
    return (
      <Box>
        <Typography color="primary" variant="subtitle1" sx={{ marginBottom: '6px' }}>{title}</Typography>
        {description && (
          <Editor read showText>
            <ReactQuill
              value={description}
              readOnly={true}
              modules={{toolbar: false, syntax: true}}
              theme={"snow"}
            />
          </Editor>
        )}
        {specific}
        <Divider sx={{ marginY: '25px' }} />
      </Box>
  )}

  return null
}

function CaseSection({ input, output, index }: CaseSectionProps) {
  if (output !== '' || input !== '') {
    return (
      <Box>
        <Stack direction="row" alignItems="center" gap="6px">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: 'grey.700', width: '25px', height: '25px', borderRadius: '100%' }}
          >
            <Typography sx={{ color: 'common.white' }}>{index}</Typography>
          </Stack>
          <Typography>{`ตัวอย่างที่ ${index}`}</Typography>
        </Stack>
        <Grid container alignItems="stretch" columns={{ xs: 2 }} columnSpacing="10px" sx={{ marginTop: '10px' }}>
          <Grid item xs={1} sx={{ position: 'relative', overflow: 'hidden' }}>
            <Typography sx={{ marginBottom: '5px' }} variant="body2">Input: </Typography>
            <Box
              sx={{
                backgroundColor: 'background.default',
                color: 'text.secondary',
                padding: '10px',
                minHeight: '40px',
                height: '100%',
                whiteSpace: 'pre-wrap'
              }}
            >
              <Typography variant="body2">{input}</Typography>
            </Box>
          </Grid>
          <Grid item xs={1} sx={{ position: 'relative', overflow: 'hidden' }}>
          <Typography sx={{ marginBottom: '5px' }} variant="body2">Output: </Typography>
          <Box
            sx={{
              backgroundColor: 'background.default',
              color: 'text.secondary',
              padding: '10px',
              minHeight: '40px',
              height: '100%',
              whiteSpace: 'pre',
              overflow: 'auto',
            }}
          >
            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>{output}</Typography>
          </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return null
}

function ProgressTask({ status, percent, nextTask, passDate }: ProgressTaskProps) {
  const location = useLocation()
  const { devlabId } = useParams()
  const mediaScreen = useResponsive('down', 730)

  const statusConfig: any = {
    'NEVER': {
      text: 'ไม่เคยทำ',
      color: 'grey.600',
    },
    'PASS': {
      text: 'ผ่านแล้ว',
      color: 'secondary.main',
    },
    'NOTPASS': {
      text: 'ยังไม่ผ่าน',
      color: 'error.main',
    }
  }

  const handleListPath = (): string => {
    const path = location.pathname
    const devlabPath = `/devlab/${devlabId}`
    return path.replaceAll(devlabPath, '')
  }

  return (
    <Box 
      sx={{ 
        backgroundColor: 'background.paper', 
        padding: '20px', 
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'
      }}
    >
      <Stack
        direction={mediaScreen ? 'column' : 'row' }
        justifyContent={mediaScreen ? 'center' : 'space-between'}
        alignItems={mediaScreen ? 'center' : 'end' }
        sx={{ gap: mediaScreen ? '20px' : '0px' }}
      >
        <Stack
          direction="row"
          alignItems="start"
          gap="8px"
          order={mediaScreen ? 2 : 1 }
          sx={{ flexWrap: 'wrap' }}
        >
          <Box>
            <Typography variant="h6">ความคืบหน้าของการทำโจทย์</Typography>
            {passDate !== '' && passDate && (
              <Typography variant="caption" sx={{ marginTop: '-50px', color: '#949494' }}>
                {`ผ่านโจทย์เมื่อ ${dayjs(passDate).format('DD/MM/YYYY')}`}
              </Typography>
            )}
          </Box>

          {status && (
            <>
              <Box
                sx={{
                  backgroundColor: statusConfig[status]?.color,
                  color: 'common.white',
                  borderRadius: '6px',
                  paddingY: '2px',
                  paddingX: '15px'
                }}
              >
                <Typography>{statusConfig[status]?.text}</Typography>
              </Box>
            </>
          )}
        </Stack>
        <Stack direction="row" sx={{ gap: '10px' }} order={mediaScreen ? 1 : 2 }>
          <Button
            component={Link}
            to={handleListPath()}
            variant="outlined"
            sx={{
              position: 'flex',
              alignItems: 'center',
              gap: '3px'
            }}
          >
            <Icon icon="material-symbols:list-rounded" sx={{ width: '20px', height: '20px' }} />
            <Typography>รายการโจทย์</Typography>
          </Button>
          <Button
            href={`${handleListPath()}/devlab/${nextTask}`}
            disabled={!nextTask}
            variant="contained"
            sx={{
              color: 'text.button',
              position: 'flex',
              alignItems: 'center',
              gap: '3px'
            }}
          >
            <Typography>โจทย์ถัดไป</Typography>
            <Icon icon="material-symbols:arrow-forward-rounded" sx={{ width: '20px', height: '20px' }} />
          </Button>
        </Stack>
      </Stack>

      {status !== 'NEVER' && (
        <Box sx={{ marginTop: mediaScreen ? '5px' : '14px' }}>
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
          >
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                value={percent}
                variant="determinate"
                color={status === 'PASS' ? 'secondary' : 'primary'}
                sx={{
                  height: '7px',
                  borderRadius: 5,
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5
                  }
                }}
              />
            </Box>
            <Typography variant="body1" sx={{ color: 'text.primary' }}>{`${percent.toFixed(2)}%`}</Typography>
          </Stack>
        </Box>
      )}
    </Box>
  )
}

export default function TaskDescription({ data, languageList }: TaskDescriptionProps) {
  const mediaScreen = useResponsive('down', 730)

  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        <ProgressTask
          passDate={data.passedTime}
          status={data.userStatus}
          percent={data.percentProgress}
          nextTask={data?.nextTask}
        />
      </Box>

      <Box 
        id="detail" 
        sx={{ 
          borderRadius: '8px', 
          backgroundColor: 'background.paper', 
          marginBottom: '20px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'
        }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: 'background.dark',
            borderStartStartRadius: '8px',
            borderStartEndRadius: '8px',
            paddingX: '20px',
            paddingY: '40px'
          }}
        >
          <Box>
            <Typography variant="h4" sx={{ wordBreak: 'break-all' }}>{data.devlabDetail.devlabName}</Typography>
            <Stack direction="row" alignItems="center" gap="10px">
              {!mediaScreen && (
                <Typography variant='body2'>ระดับความยาก :</Typography>
              )}

              <Stack direction="row" alignItems="center" sx={{ marginTop: '-4px' }}>
                {Array(5).fill(0).map((_, index: number) => (
                  <Icon
                    key={`star-level-${index}`}
                    icon="material-symbols:star-rounded"
                    sx={{
                      fontSize: '18px',
                      marginLeft: '-4px',
                      color: index < data.devlabDetail.devlabLevel ? 'primary.main' : 'grey.600'
                    }}
                  />
                ))}
              </Stack>

            </Stack>
          </Box>
        </Stack>
        <Box sx={{ padding: '20px' }}>
          <DetailSection
            title="ภาษาที่ใช้ได้"
            specific={languageList?.length && (
              <Stack direction="row" gap="5px" flexWrap="wrap">
                {languageList?.map((item: devlabLanguageType, index: number) => (
                  <LanguageChip
                    key={`langs-chip-${index}`}
                    text={item.text}
                    index={index}
                    languageAmount={languageList.length}
                  />
                ))}
              </Stack>
            )}
          />
          <DetailSection title="คำอธิบาย" description={data.devlabDetail.devlabDescription} />
          <DetailSection title="รูปเเบบ Input" description={data.devlabDetail.devlabInputDescription} />
          <DetailSection title="รูปเเบบ Output" description={data.devlabDetail.devlabOutputDescription} />
          <DetailSection title="ข้อจำกัด" description={data.devlabDetail.devlabConstraints} />

          <Stack direction="column" sx={{ gap: '20px' }}>
            {data.devlabDetail.example?.map((item: any, index: number) => (
              <CaseSection
                key={`case-example-${index}`}
                index={index + 1}
                input={item?.input}
                output={item?.output}
              />
            ))}
          </Stack>

          {data?.devlabDetail?.devlabUser?.userImage !== '' && (
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              gap="5px"
              sx={{
                marginTop:
                  data.devlabDetail.example?.every((item: devlabUserExampleType) => item.input !== '' || item.output !== '')
                  ? '40px'
                  : '0px'
              }}
            >
              <Stack direction="column" justifyContent="end" alignItems="end">
                <Typography variant="caption" sx={{ color: 'grey.400' }}>สร้างสรรค์โดย</Typography>
                <Typography variant="button" sx={{ marginTop: '-5px' }}>{data.devlabDetail.devlabUser.displayName}</Typography>
              </Stack>
              <Image
                src={data.devlabDetail.devlabUser.userImage}
                sx={{
                  width: '35px',
                  height: '35px',
                  backgroundColor: 'background.paper',
                  borderRadius: '100%'
                }}
              />
            </Stack>
          )}
        </Box>
      </Box>
    </>
  )
}
