import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import EditorHeader from './EditorHeader';
import axiosInstance from '../../../utils/axios';
import ChangePreview from './ChangePreview';

export default function Bio({bio}: {bio: string}) {
  const [initStatus, setInitStatus] = useState(true)
  const [bioText, setBioText] = useState<string>(bio)

  async function onSave() {
    await axiosInstance.put('/users/profile/bio', {bio: bioText})
    setInitStatus(true)
  }

  function onChange(text: string) {
    setBioText(text)
    setInitStatus(false)
  }

  return (
    <Box>
      <EditorHeader title='เกี่ยวกับฉัน' onSubmit={() => onSave()} disabled={initStatus}/>
      <TextField
        value={bioText}
        onChange={(e) => onChange(e.target.value)}
        sx={{textAlign: 'left', width: '100%', marginY: '10px'}}
        placeholder={'ประวัติส่วนตัว'}
        multiline
        rows={3}
      />
      <ChangePreview>
        <Typography variant='caption'>
          {bioText}
        </Typography>
      </ChangePreview>
    </Box>
  )
}
