import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Stack, Grid, Typography, Tooltip } from '@mui/material'

import Icon from '../../../components/Iconify'
import Image from '../../../components/Image'
import axiosInstance from '../../../utils/axios'
import useResponsive from '../../../hooks/useResponsive'
import { BundleSuggestType } from '../../../@types/courseData'

interface BundleSuggestProps {
  slug: string
  coursePrice: number
}

type BundleSuggestCardType = {
  bundle: BundleSuggestType,
  displayOrder: number
}

interface BundleSuggestCardProps {
  bundle: BundleSuggestCardType
  coursePrice: number
}

function BundleSuggestCard({ bundle, coursePrice }: BundleSuggestCardProps) {
  const sizeSE = useResponsive('down', 600)

  return (
    <Box
      component={Link}
      to={`/bundle/${bundle.bundle.bundle.slug}`}
      sx={{
        borderRadius: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        border: '1px solid',
        borderColor: 'background.neutral',
        color: 'text.primary',
        textDecoration: 'none',
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'center' }}
          >
            <Box sx={{ width: !sizeSE ? '150px': '100%' }}>
              <Image 
                alt=""
                src={bundle?.bundle?.bundle?.images?.square}
                style={{
                  height: '150px',
                  width: !sizeSE ? '150px': '100%',
                  borderRadius: sizeSE ? '16px 16px 0px 0px' : '16px 0px 0px 16px'
                }}
              />
            </Box>

            <Stack 
              direction="column" 
              justifyContent="center" 
              sx={{
                height: '100%',
                padding: '10px',
              }}
            >
              <Stack direction="row" alignItems="center" sx={{ marginBottom: '5px', gap: '2px' }}>
                <Icon icon="ic:round-star" sx={{ color: 'primary.main', width: '22px', height: '22px' }} />
                <Typography sx={{ fontSize: '14px' }}>{`${bundle.bundle.meta?.review?.average?.toFixed(1)}/5.0`}</Typography>
              </Stack>

              <Box>
                <Tooltip
                  arrow
                  title={bundle.bundle.bundle.title}
                >
                  <Typography 
                    sx={{ 
                      fontSize: '16px',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {bundle.bundle.bundle.title}
                  </Typography>
                </Tooltip>
                <Typography 
                  sx={{ 
                    wordBreak: 'break-word',
                    fontSize: '12px',
                    display: 'block',
                    maxHeight: '2.8em',
                    lineHeight: '1.4em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {bundle.bundle.bundle.shortDescription}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="end"
            sx={{
              width: '100%',
              height: '100%',
              paddingRight: '15px',
              paddingBottom: sizeSE ? '15px' : '0px'
            }}
          >
            <Typography
              sx={{
                width: '100%',
                textAlign: 'end',
              }}
            >
              {(bundle?.bundle.bundle?.wcProduct?.data?.price ?? 0) >= coursePrice ? 'จ่ายเพิ่มเพียง':'ประหยัดไปได้'}
            </Typography>
            <Typography
              sx={{
                width: '100%',
                textAlign: 'end',
              }}
            >
              {new Intl.NumberFormat().format( Math.abs((bundle?.bundle.bundle?.wcProduct?.data?.price ?? 0) - coursePrice))} บาท
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default function BundleSuggest({ slug, coursePrice }: BundleSuggestProps) {
  const [bundleList, setBundleList] = useState<BundleSuggestCardType[]>([])

  const getBundleSuggestion = async () => {
    try {
      const response = await axiosInstance.get(`courses/${slug}/relateBundles`)
      const data = await response.data
      setBundleList(data)
    } catch {}
  }

  const sortBundleList = (bundles: BundleSuggestCardType[]): BundleSuggestCardType[] => {
    return bundles.sort((a: BundleSuggestCardType, b: BundleSuggestCardType) => a.displayOrder - b.displayOrder)
  }

  useEffect(() => {
    getBundleSuggestion()
  }, [])

  if (bundleList.length > 0) {
    return (
      <Box sx={{ marginY: '35px' }}>
        <Stack direction="row" alignItems="center" sx={{ gap: '5px', marginBottom: '10px' }}>
          <Icon icon="fluent-emoji:pushpin" sx={{ width: '20px', height: '20px' }} />
          <Typography>หรือ อัพเกรดสุดคุ้มกับคอร์สเรียนแบบแพคเกจ</Typography>
        </Stack>
  
        <Stack direction="column" sx={{ gap: '15px' }}>
          {sortBundleList(bundleList).map((bundle: BundleSuggestCardType, index: number) => (
            <BundleSuggestCard 
              key={`bundle-suggest-card-${index}`}
              bundle={bundle}
              coursePrice={coursePrice}
            />
          ))}
        </Stack>
      </Box>
    )
  }

  return null
}