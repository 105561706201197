export const iconConfig = {
  figma: 'solar:figma-bold-duotone',
  github: 'mdi:github',
  other: 'material-symbols:link-rounded'
}

export function getLinkIcon(url: string) {
  if (url.includes('figma.com')) {
    return iconConfig.figma
  } else if (url.includes('github.com')) {
    return iconConfig.github
  } else {
    return iconConfig.other
  }
}
