import {
  categoryType,
  featureType,
  instructorType,
  recommendedSoftwareType,
  tagType,
  courseDataType,
  CourseStatusType,
  ProjectStatusType,
} from '../../@types/courseData';
import { WcProductType } from '../../@types/courseCatalog';
import { sectionType } from '../../@types/courseLesson';
import {
  CourseGroupType,
  PlanChecklistType,
  VariationsType,
} from '../../@types/plan';

const initialCourseProgress = {
  certificateId: '',
  completed: false,
  completedAt: '',
  courseId: '',
  id: '',
  learningDuration: 0,
  lessonAmount: 0,
  lessonCompletedAmount: 0,
  percentComplete: 0,
  projectStatus: ProjectStatusType.INCOMPLETE,
  quizAmount: 0,
  quizCompletedAmount: 0,
  quizMaxScore: 0,
  quizSumScore: 0,
  totalDuration: 0,
  userId: '',
};

export const userLearningStatus = {
  enrolled: false,
  enrollable: false,
  recommendedPlan: {
    id: '',
    title: '',
    description: '',
    basicPlan: false,
    isBusinessPlan: false,
    planChecklist: [] as PlanChecklistType[],
    userCurrentPlan: false,
    courseCount: 0,
    variations: [] as VariationsType[],
    courseGroups: [] as CourseGroupType[],
    avgReview: 0,
    isRecommendedPlan: false,
  },
};

export const initialCourseData: courseDataType = {
  categories: [] as categoryType[],
  courseProgress: initialCourseProgress,
  images: {
    card: '',
    cover: '',
    square: '',
  },
  description: '',
  features: [] as featureType[],
  id: '',
  instructors: [] as instructorType[],
  previewVideo: '',
  recommendedSoftwares: [] as recommendedSoftwareType[],
  sections: [] as sectionType[],
  shortDescription: '',
  slug: '',
  tags: [] as tagType[],
  title: '',
  userLearningStatus: userLearningStatus,
  durationText: '',
  updatedAt: '',
  createdAt: '',
  status: CourseStatusType.SOON,
  wcProduct: {
    id: 0,
    image: '',
    name: '',
    price: 0,
    regularPrice: 0,
    salePrice: 0,
    salePageUrl: '',
    slug: '',
    type: WcProductType.SINGLE,
    url: '',
    status: '',
    shortDescription: '',
  },
};

export const initialOverviewData = {
  avg: 0,
  count: 0,
  max: 0,
  student: 0,
  rate: [
    {
      count: 0,
      rate: 5,
    },
    {
      count: 0,
      rate: 4,
    },
    {
      count: 0,
      rate: 3,
    },
    {
      count: 0,
      rate: 2,
    },
    {
      count: 0,
      rate: 1,
    },
  ],
};
