import React from 'react'
import { Typography, Box, Stack, Avatar } from '@mui/material'

import { ProjectDetailType } from '../../@types/projectGallery'
import ProjectCard from '../../components/ProjectCard'
import Icon from '../../components/Iconify'
import GeneralLink from '../../components/link/General'
import { ProfileDetail, ProfileDetailTooltip } from '../profile/ProfileDetailTooltip'
import TextLink from '../../components/link/Text'

interface PropsRecommendedProject {
  projectList?: ProjectDetailType[],
}

export default function RecommededProject({ projectList }: PropsRecommendedProject) {
  return (
    <Box sx={{ marginTop: '79px' }}>
      <Typography sx={{ fontWeight: 'regular', fontSize: '24px' }}>ผลงานอื่นๆ ที่น่าสนใจจากคอร์สเรียนเดียวกัน</Typography>

      <Stack
        direction="row"
        spacing={4}
        sx={{
          marginTop: '40px',
          paddingBottom: '20px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.4em',
            height: '0.5em',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'translate',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.700',
            borderRadius: '6px',
            ':hover': {
              backgroundColor: 'grey.800'
            }
          }
        }}
      >
        {projectList?.map((item: ProjectDetailType, index: Number) => (
          <Box key={`recommended-project-${index}`}>
            <ProjectCard
              topicName={item.name}
              backgroundImage={item.coverImage}
              routerPathName={`/devproject/${item.id}`}
            />

            <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '10px' }}>
              <Stack direction="row" alignItems="center" justifyContent="center">
              <GeneralLink link={item?.user?.id && item?.user?.role === 'STUDENT'  ? `/profile/${item?.user?.id}` : ''}>
                {
                  item?.anonymous ?
                    <Avatar src={`${item.user.imageUrl}`} sx={{ width: '24px', height: '24px', marginRight: '15px' }} alt='user-image'/>
                    :
                    <>
                    {
                      item?.user?.role === 'STUDENT' ?
                      <ProfileDetailTooltip
                        title={ProfileDetail(item?.user)}
                      placement="top-start">
                      <Box>
                      <Avatar src={`${item.user.imageUrl}`} sx={{ width: '24px', height: '24px', marginRight: '15px' }} alt='user-image'/>
                      </Box>
                    </ProfileDetailTooltip>
                    :
                    <Avatar src={`${item.user.imageUrl}`} sx={{ width: '24px', height: '24px', marginRight: '15px' }} alt='user-image'/>
                    }
                    </>
                }
              </GeneralLink>
              {
                item?.anonymous ?
                <>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{item.user.publicName}</Typography>
                </>
                :
                <>
                {
                  item?.user?.role === 'STUDENT' ?
                    <ProfileDetailTooltip
                      title={ProfileDetail(item?.user)}
                      placement="top-start">
                        <Box>
                          <TextLink text={item.user.publicName} link={`/profile/${item?.user?.id}`} typographyVariant="body2" sx={{ fontWeight: 'bold' }} />
                        </Box>
                    </ProfileDetailTooltip>
                      :
                    <Box>
                      <TextLink text={item.user.publicName} typographyVariant="body2" sx={{ fontWeight: 'bold' }} />
                    </Box>
                }
                </>
              }
              </Stack>

              <Stack direction="row" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <Icon icon="heroicons-solid:thumb-up" sx={{ width: '19px', height: '19px', paddingRight: '3px' }} />
                  <Typography variant="body2">{item.favorite}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <Icon icon="bi:eye-fill" sx={{ width: '19px', height: '19px', paddingRight: '3px' }} />
                  <Typography variant="body2">{item.view}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
