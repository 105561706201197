import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from '../../../components/Image';
import UserCard from '../../../components/auth/UserCard';
import { Link as RouterLink } from 'react-router-dom';

export default function Profile() {
  return (
    <Box sx={{display: 'flex'}}>
      <Box component={RouterLink} to='/'>
        <Image
          disabledEffect
          visibleByDefault
          alt="logo"
          src="/logos/school-sqr-80x80.png"
          sx={{ maxWidth: 54, maxHeight: 54, minWidth: 54,  margin: '9px' }}
        />
      </Box>
      <ProfileBoxStyle >
        <UserCard />
      </ProfileBoxStyle>
    </Box>
  )
}

const ProfileBoxStyle = styled( Box )(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
