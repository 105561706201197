import React, { useState, useEffect } from 'react'
import { DeltaStatic } from 'quill'
import { Box, Stack, Typography, Button } from '@mui/material'

import { ColorSchema } from '../../../../theme/palette';
import RichViewer from '../../../../components/editor/RichViewer'
import ResultAlert from '../../../../components/ResultAlert'
import Icon from '../../../../components/Iconify'
import { devlabLanguageType } from '../../../../@types/devlab'
import axiosInstance from '../../../../utils/axios'

interface DevlabDescriptionProps {
  completed?: boolean
  nextLesson?: string
  lessonTitle?: string
  description?: string | DeltaStatic
  taskCount?: number
  time?: number
  passCondition?: string
  languageList?: string[]
}

interface TextValueHighlightProps {
  label: string
  value: string
  color?: ColorSchema
  sx?: Object
}

function TextValueHighlight({label, value, color, sx}: TextValueHighlightProps) {
  return (
    <Box sx={{display: 'flex', gap: '5px', ...sx}}>
      <Typography variant='subtitle2'>
        {label}
      </Typography>
      <Typography variant='subtitle2' sx={{color: 'primary.main'}}>
        {value}
      </Typography>
    </Box>
  )
}

export default function DevlabDescription({ completed = false, nextLesson, lessonTitle, description, taskCount, time, passCondition, languageList }: DevlabDescriptionProps) {
  const [language, setLanguage] = useState<devlabLanguageType[]>([])

  const getLanguage = async () => {
    try {
      const list = await languageList?.join(',')
      const response = await axiosInstance.get(`/devlab/languages?${list ? `langId=${list}` : ''}`)
      setLanguage(response.data)
    } catch {
      return;
    }
  }

  useEffect(() => {
    getLanguage()
  }, [languageList])

  return (
    <Box 
      sx={{ 
        backgroundColor: 'background.paper',
        borderRadius: '8px',
        padding: '40px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap="10px"
        sx={{ marginBottom: '20px' }}
      >
        <Typography variant="h5">{lessonTitle}</Typography>
        {nextLesson && (
          <Button href={nextLesson} variant="contained" sx={{ color: 'text.button' }}>
            <Typography variant="button">บทเรียนต่อไป</Typography>
            <Icon icon="ic:baseline-navigate-next" sx={{ width: 'fit-content', height: 'fit-content', fontSize: '20px' }} />
          </Button>
        )}
      </Stack>

      {completed && (
        <Box sx={{ marginBottom: '20px', marginTop: '10px' }}>
          <ResultAlert
            severity="success"
            title="ขอแสดงความยินดี ! คุณได้คะแนนมากกว่าเกณฑ์ค่าเฉลี่ย !!"
            description="ผลการทดสอบของคุณอยู่ในเกณฑ์ผ่าน และยอดเยี่ยม เราเป็นกำลังใจให้สู้ต่อไป !"
          />
        </Box>
      )}

      <RichViewer content={description ?? ''}/>
      <Box sx={{marginY: '20px'}}>
        <TextValueHighlight label={'จำนวนข้อ : '} value={`${taskCount} ข้อ`} sx={{gap: '5px'}}/>
        <TextValueHighlight label={'ใช้เวลาประมาณ : '} value={`${Math.floor(time as number / 60)} นาที`} sx={{gap: '5px'}}/>
        <TextValueHighlight label={'เกณฑ์คะแนนที่ผ่านในแต่ละข้อ : '} value={`${passCondition?.toString()}%` ?? ''} sx={{gap: '5px'}}/>
        <TextValueHighlight label={'ภาษาที่ใช้ได้ : '} value={language?.reduce((sum: string, item: devlabLanguageType) => `${sum} ${item.text.split(' ')?.[0]}`, '') ?? ''} sx={{gap: '5px'}}/>
      </Box>
    </Box>
  )
}
