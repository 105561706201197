import React from 'react';
import { Box, Typography, LinearProgress, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

type CourseProgressType = {
  current: number;
  new: number;
};

export default function CourseProgress() {
  const courseData = useSelector(
    (state: RootState) => state.lesson.courseLessons,
  );
  const courseAmount = courseData.enrolled
    ? courseData.courseProgress.lessonAmount
    : courseData.lessonAmount;
  const courseCompleted = courseData.enrolled
    ? courseData.courseProgress.lessonCompletedAmount
    : 0;

  const [progress, setProgress] = React.useState<CourseProgressType>({
    current: courseData.courseProgress?.percentComplete || 0,
    new: courseData.courseProgress?.percentComplete || 0,
  });
  const [animation, setAnimation] = React.useState<boolean>(false);
  const intervalRef = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (intervalRef.current && progress.current >= progress.new) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
      setAnimation(false);
    }
  }, [progress]);

  React.useEffect(() => {
    setProgress((prev: CourseProgressType) => ({
      ...prev,
      new: courseData.courseProgress?.percentComplete || 0,
    }));
    if (courseData) addProgress();
  }, [courseData]);

  const addProgress = () => {
    const stepTime = Math.abs(Math.floor(4000 / (100 - progress.current)));

    if (intervalRef.current === null && progress.current !== 100) {
      intervalRef.current = window.setInterval(() => {
        setAnimation(true);
        setProgress((prevProgress: CourseProgressType) => ({
          ...prevProgress,
          current: prevProgress.current + 1,
        }));
      }, stepTime);
    }
  };

  return (
    <Box sx={{ marginY: '10px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'bottom',
        }}
      >
        <Typography
          sx={{ minWidth: '80px', marginRight: '10px' }}
          variant="subtitle2"
        >
          ความคืบหน้า
        </Typography>
        <Typography
          component={RouterLink}
          to={`/course/${courseData.slug}`}
          sx={{
            textAlign: 'right',
            textDecoration: 'none',
            color: 'text.primary',
            ':hover': {
              color: 'primary.main',
            },
          }}
          variant="body2"
        >
          {courseData.title}
          {courseData.completable && (
            <Typography
              id="progressPercent"
              component="span"
              variant="body2"
              sx={{
                display: 'inline',
                color: 'text.secondary',
                marginLeft: '5px',
                transition: 'all .25s ease-in-out',
                ...(animation
                  ? {
                      fontSize: '18px',
                      color: 'primary.light',
                    }
                  : {}),
              }}
            >
              {`${progress.current}%`}
            </Typography>
          )}
        </Typography>
      </Box>
      {courseData?.completable ? '' : <Divider sx={{ marginY: '10px' }} />}
      {courseData?.completable ? (
        <LinearProgress
          value={progress.current}
          variant="determinate"
          color="success"
          sx={{
            borderRadius: '8px',
          }}
        />
      ) : (
        <Typography
          component="span"
          variant="body2"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            color: 'text.primary',
          }}
        >
          เรียนไปแล้ว {courseCompleted} / {courseAmount} บทเรียน
        </Typography>
      )}
    </Box>
  );
}
