import React from 'react';
import { Box, Badge, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { HEADER } from '../../config';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import UserCard from '../../components/auth/UserCard';
import useResponsive from '../../hooks/useResponsive';

import NavMobile from './NavMobile';
import RedirectMenu from './RedirectMenu';

export default function Nav() {
  return <>{useResponsive('up', 1200) ? <HeadNav /> : <NavMobile />}</>;
}

function HeadNav() {
  return (
    <HeadNavStyle>
      <Container sx={{ height: '100%', minWidth: '1270px' }}>
        {/* <Notifications /> */}
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '60px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Box component={RouterLink} to={'/'}>
              <Image
                visibleByDefault
                alt="logo"
                src="/logos/school-full-225x72.png"
                sx={{ width: '175px' }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
              <RedirectMenu />
            </Box>
          </Box>
          <UserCard />
        </Box>
      </Container>
    </HeadNavStyle>
  );
}

function Notifications() {
  return (
    <Box sx={{ position: 'relative', height: '0px' }}>
      <Box sx={{ position: 'absolute', right: '0', top: '32px' }}>
        <Badge badgeContent={8} color="error">
          <Iconify
            icon="eva:bell-fill"
            sx={{ width: '24px', height: '24px' }}
          />
        </Badge>
      </Box>
    </Box>
  );
}

const HeadNavStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  height: HEADER.MAIN_DESKTOP_HEIGHT,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  boxShadow: '0px 2px 20px -5px rgba(0,0,0,0.30)',
}));
