import React, { useState, useEffect, useRef } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Waypoint } from 'react-waypoint';
import { useMatch } from 'react-router-dom'
import Marquee from 'react-fast-marquee';
import dayjs from 'dayjs'

import { AnnounceBarType } from '../@types/notification'
import axiosInstance from '../utils/axios';
import { fDateThai } from '../utils/formatTime'
import { HEADER } from '../config'
import { useSelector } from '../redux/store';

import Icon from './Iconify'

interface AnnounceBarProps {
  localStoreName?: string
  announceData?: AnnounceBarType
  display: boolean
  isLoading: boolean
  isShowComponent?: boolean
  closeButton?: boolean
}

interface AnnounceBarStyleProps {
  show?: boolean
}

const AnnounceBarStyle = styled(Box, {
  shouldForwardProp: (props) => props !== 'show'
})<AnnounceBarStyleProps>(({ show = true }) => ({
  position: 'fixed',
  zIndex: 5,
  width: '100%',
  transition: 'all .2s ease-in-out',
  opacity: show ? 1 : 0,
  marginTop: show ? `${HEADER.MAIN_DESKTOP_HEIGHT}px` : '0px',
  '@media (max-width: 1200px)': {
    marginTop: show ? `${HEADER.MOBILE_HEIGHT}px` : '0px',
  },
}))

function AnnounceBar({ 
  localStoreName,
  announceData, 
  display,
  isLoading = true, 
  isShowComponent = true,
  closeButton = true
}: AnnounceBarProps) {
  const [showAnnounce, setShowAnnounce] = useState<boolean>(false)
  const [activateAnimation, setActivateAnimation] = useState<boolean>(true)
  
  const titleRef = useRef<HTMLElement | null>(null)
  const barRef = useRef<HTMLElement | null>(null)

  const closeAnnounceBar = () => {
    setShowAnnounce(false)
    localStorage.setItem('announceBar', JSON.stringify({
      id: announceData?.id,
      closeAt: dayjs().toDate()
    }))
  }

  const handleAnimation = () => {
    if (barRef.current && titleRef.current) {
      const diff = barRef.current.clientWidth - titleRef.current.clientWidth
      if (diff < 10) setActivateAnimation(true)
      else setActivateAnimation(false)
    }
  }

  useEffect(() => {
    handleAnimation()
  })

  useEffect(() => {
    (() => {
      if (localStoreName) {
        const announce = localStorage.getItem(localStoreName)
  
        if (announce !== null && !isLoading) {
          const data = JSON.parse(announce)
          
          if (dayjs().diff(dayjs(data?.closeAt), 'day') > 30 || announceData?.id !== data?.id) {
            setShowAnnounce(true)
            localStorage.removeItem(localStoreName)
          } else setShowAnnounce(false)
        } else if (!isLoading) setShowAnnounce((true))
      }
    })()
  }, [announceData, isLoading, localStoreName])

  useEffect(() => {
    window.addEventListener('resize', () => handleAnimation())
    return () => window.removeEventListener('resize', () => handleAnimation())
  }, [])

  if (!showAnnounce || !isShowComponent || isLoading) return null

  return (
    <>
      <AnnounceBarStyle show={display}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            gap: '10px',
            width: '100%',
            height: '35px',
            padding: '5px',
            textAlign: 'center',
            background: announceData?.backgroundColor,
          }}
        >
          <Box ref={barRef} sx={{ width: '100%', position: 'relative' }}>
            <a 
              href={announceData?.link ?? ''}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
                color: announceData?.titleColor,
                pointerEvents: announceData?.link ? 'auto' : 'none'
              }}
            >
              <Typography
                ref={titleRef}
                sx={{ 
                  width: 'fit-content',
                  position: 'absolute',
                  visibility: 'hidden',
                  zIndex: 0,
                }}
              >
                {announceData?.title}
              </Typography>

              {activateAnimation ? (
                <Marquee pauseOnHover>
                  <Typography sx={{ marginX: '25px' }}>
                    {announceData?.title}
                  </Typography>
                </Marquee>
              ) : (
                <Typography sx={{ textAlign: 'center' }}>
                  {announceData?.title}
                </Typography>
              )}
            </a>
          </Box>

          {closeButton && (
            <Icon
              icon="basil:cross-solid"
              onClick={closeAnnounceBar}
              sx={{
                fontSize: '25px',
                cursor: 'pointer',
                color: announceData?.titleColor,
              }}
            />
          )}
        </Stack>
      </AnnounceBarStyle>

      <Box
        sx={{
          height: showAnnounce ? '35px' : '0px',
          transition: 'all .5s'
        }}
      />
    </>
  )
}

export default function Announcement() {
  const [show, setShow] = useState<boolean>(true)

  const [announceLoading, setAnnounceLoading] = useState(true)
  const [announceData, setAnnounceData] = useState<AnnounceBarType>()

  const matchSubscriptionPage = useMatch('/subscription')
  const { plusAnnounce } = useSelector((state) => state.notification);
  const [plusAnnounceLoading, setPlusAnnounceLoading] = useState(true)
  const [pluseAnnounceData, setPlusAnnounceData] = useState<Pick<AnnounceBarType, 'title' | 'titleColor' | 'backgroundColor'>>()

  const getAnnounceBarData = async () => {
    try {
      const response = await axiosInstance.get('/notifications/announcebar')
      const data = await response.data
      setAnnounceData(data)
      setAnnounceLoading(false)
    } catch {}
  }

  useEffect(() => {
    getAnnounceBarData()
  }, [])

  useEffect(() => {
    if (plusAnnounce) {
      setPlusAnnounceLoading(false)
      setPlusAnnounceData({
        title: `🎉 คุณได้เป็นสมาชิกแผน borntoDev Plus เรียบร้อยแล้ว (สิ้นสุดเมื่อวันที่ ${fDateThai(plusAnnounce.nextPaymentDate)})`,
        backgroundColor: '#00AB55',
        titleColor: '#FFFFFF'
      })
    }
  }, [plusAnnounce])

  return (
    <>
      <AnnounceBar 
        display={show}
        localStoreName="announceBar"
        announceData={announceData}
        isLoading={announceLoading}
      />
      <AnnounceBar 
        display={show}
        isShowComponent={Boolean(matchSubscriptionPage)}
        localStoreName="plusBar"
        announceData={pluseAnnounceData as AnnounceBarType}
        isLoading={plusAnnounceLoading}
        closeButton={false}
      />

      <Box 
        sx={{ 
          marginTop: '20vh',
          position: 'absolute',
          zIndex: -1,
          backgroundColor: 'transition',
        }}
      >
        <Waypoint 
          onLeave={() => setShow(false)}
          onEnter={() => setShow(true)}
        />
      </Box>
    </>
  )
}