import React, { useState, useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import axiosInstance from '../../../utils/axios'

import { CategoryAndTagsType, PropsGalleryControl } from '../../../@types/projectGallery'

import FilterButton from './GalleryFilterButton'
import CategoryButton from './GalleryCategoryButton'
import SortButton from './GallerySortButton'
import GalleryTextField from './GalleryTextField'


function GalleryControls({ widthWindow, searchMode, onChangeSearchMode, searchParams, submitSearchParams }: PropsGalleryControl) {
  const sortList: string[] = ['Popular', 'A-Z', 'Z-A', 'Latest', 'Oldest']
  const [categoryList, setCategoryList] = useState<CategoryAndTagsType[]>()
  const [tagsList, setTagsList] = useState<CategoryAndTagsType[]>()


  useEffect(() => {
    const fetchCategoryAndTagsList = async () => {
      try {
        const categories = await axiosInstance.get('projects/gallery/categories')
        const tags = await axiosInstance.get('projects/gallery/tags')
        await setCategoryList(categories.data)
        await setTagsList(tags.data)
      } catch (error) {
        return
      }
    }

    fetchCategoryAndTagsList()
  }, [])


  return (
    <Box sx={{ paddingTop: '57px', textAlign: 'center' }}>
      <GalleryTextField 
        searchMode={searchMode}
        setSearchMode={onChangeSearchMode}
        textSearch={searchParams}
        submitSearchParams={submitSearchParams}
      />

      <Box sx={{ marginTop: '40px', marginBottom: '40px', width: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ gap: widthWindow > 1200 ? '0px' : '20px', width: '100%' }}>
          <SortButton
            sortList={sortList}
            sortSelected={searchParams}
            submitSortSelected={submitSearchParams}
          />

          {widthWindow > 1200 ? (
            <CategoryButton
              searchMode={searchMode}
              setSearchMode={onChangeSearchMode}
              widthWindow={widthWindow}
              categoryList={categoryList}
              categorySelected={searchParams}
              submitCategorySelected={submitSearchParams}
            />
          ) : null}

          <FilterButton
            tagsList={tagsList}
            categoryList={categoryList}
            filterSelected={searchParams}
            handleSumitFilter={submitSearchParams}
            setSearchMode={onChangeSearchMode}
          />
        </Stack>

        {widthWindow < 1200 ? (
          <Box sx={{ marginTop: '20px', width: '100%' }}>
            <CategoryButton
              searchMode={searchMode}
              setSearchMode={onChangeSearchMode}
              widthWindow={widthWindow}
              categoryList={categoryList}
              categorySelected={searchParams}
              submitCategorySelected={submitSearchParams}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default GalleryControls