import React from 'react';
import {
  Typography,
  Box,
  ListItemText,
  Collapse,
  Grid,
  ListItemButton,
} from '@mui/material';
import {
  CourseBundleMetaType,
  SectionBundleMetaType,
} from '../../../@types/bundle';
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { fDateThai } from '../../../utils/formatTime';

export default function CoursePath({
  courses,
  lastUpdated,
}: {
  courses: CourseBundleMetaType[];
  lastUpdated: Date;
}) {
  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Typography variant="h4" sx={{ marginY: '20px' }}>
        แพ็กเกจนี้ประกอบด้วย
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{ minWidth: '50px', display: 'flex', justifyContent: 'center' }}
        >
          <Iconify
            icon={'ph:map-pin-fill'}
            sx={{ width: '30px', height: '30px' }}
          />
        </Box>
        <Typography variant="h5">จุดเริ่มต้น</Typography>
      </Box>
      <PathLine />
      {courses.map((course, index) => (
        <CourseLine key={`CourseLine-${index}`} course={course} />
      ))}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{ minWidth: '50px', display: 'flex', justifyContent: 'center' }}
        >
          <Iconify
            icon={'ri:compass-3-fill'}
            sx={{ width: '40px', height: '40px' }}
          />
        </Box>
        <Typography variant="h5">เสร็จสิ้น</Typography>
      </Box>
      {dayjs().diff(lastUpdated, 'day') <= 90 && (
        <>
          <Typography variant="h4" sx={{ marginTop: '50px' }}>
            การันตีความสดใหม่
          </Typography>
          <Typography variant="body1">
            อัพเดทหลักสูตรล่าสุดเมื่อ : {fDateThai(lastUpdated)}
          </Typography>
        </>
      )}
    </Box>
  );
}

function PathLine() {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', marginY: '10px' }}>
      <Box sx={{ minWidth: '50px', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            borderLeft: `1px solid ${theme.palette.text.primary}`,
            height: '30px',
            width: '0px',
          }}
        ></Box>
      </Box>
      <Box sx={{ width: '100%' }}></Box>
    </Box>
  );
}

function CourseLine({ course }: { course: CourseBundleMetaType }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const duration =
    course.duration / 3600 >= 1
      ? `${Math.round(course.duration / 3600)} ชั่วโมง`
      : `${Math.round(course.duration / 60)} นาที`;
  return (
    <Box>
      <ListItemButton
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: alpha(theme.palette.primary.main, open ? 0.1 : 0),
          borderRadius: '8px',
          padding: '10px 10px 10px 0px',
        }}
        onClick={() => setOpen(!open)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{ minWidth: '50px', display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src={course.images.square}
              sx={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
              }}
            />
          </Box>
          <Box>
            <Typography variant="h6">{course.title}</Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              ใช้เวลาในการเรียน {duration}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Iconify
            icon={
              open ? 'dashicons:arrow-up-alt2' : 'dashicons:arrow-down-alt2'
            }
            sx={{ width: 24, height: 24 }}
          />
        </Box>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {course.sections.map((section) => (
          <SectionLine key={section.id} section={section} />
        ))}
        <Box sx={{ margin: '20px 50px' }}>
          <Typography
            component={Link}
            to={`/course/${course.slug}`}
            sx={{ color: 'primary.main' }}
          >
            {'>> ไปที่คอร์สเรียน'}
          </Typography>
        </Box>
      </Collapse>
      <PathLine />
    </Box>
  );
}

function SectionLine({ section }: { section: SectionBundleMetaType }) {
  return (
    <>
      <ListItemText sx={{ marginLeft: '40px' }}>
        <Grid container sx={{ alignItems: 'center', marginTop: '15px' }}>
          <Grid item xs={3.5} sm={2.65}>
            <Typography
              sx={{
                fontSize: '36px',
                color: 'text.secondary',
                lineHeight: '1',
              }}
            >
              {section.displayOrder.toString().padStart(2, '0')}
            </Typography>
          </Grid>
          <Grid item xs={8.5} sm={9.35}>
            <Typography variant="subtitle1">{section.title}</Typography>
          </Grid>
        </Grid>
      </ListItemText>
    </>
  );
}
