import React from 'react'
import { Box, Skeleton } from '@mui/material'

export default function QuizSkeleton() {
  return (
    <>
      <Skeleton sx={{backgroundColor: 'grey.400'}} width={'50%'} height={50}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'70%'} height={40}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'80%'} height= '200px'/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'40%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'80%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'70%'}/>

        <Box sx={{marginTop: '10px'}}/>

        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'80%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'90%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'85%'}/>
    </>
  )
}
