import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: null
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setActive(state, action) {
      state.active = action.payload
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
  setActive
} = slice.actions;

