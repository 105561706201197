import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  Button,
  alpha,
  useTheme,
  Select,
  MenuItem,
  Skeleton,
} from '@mui/material';

import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { fTSeparator } from '../../utils/formatNumber';
import {
  SubscriptionPlanType,
  PlanChecklistType,
  PeriodType,
  PlanCardProps,
  VariationsType,
} from '../../@types/plan';
import useAuth from '../../hooks/useAuth';

export default function PlanSelector({
  loading,
  subscriptionPlans = [],
  onholdLink,
}: PlanSelectorProp) {
  if (loading === false && subscriptionPlans.length === 0) return <></>;

  return (
    <Container id="register-plan" sx={{ marginY: '30px' }}>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        แพ็คเกจเรียนออนไลน์บน borntoDev ได้ไม่จำกัด
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        สมัครแบบรายปี ประหยัดกว่า ดูได้ทุกหลักสูตร พร้อมทำโปรเจกต์จบหลักสูตร
        ตรวจสอบโดยผู้เชี่ยวชาญ
      </Typography>
      <SubscriptionPlanSale
        loading={loading}
        subscriptionPlans={subscriptionPlans}
        onholdLink={onholdLink}
      />
      <SubscriptionNote />
    </Container>
  );
}

interface PlanSelectorProp {
  loading: boolean;
  subscriptionPlans: SubscriptionPlanType[];
  onholdLink?: string;
}

function SubscriptionPlanSale({
  loading,
  subscriptionPlans = [],
  onholdLink,
}: PlanSelectorProp) {
  const plusPlan = subscriptionPlans.find(
    (plan) => !plan.basicPlan && plan.userCurrentPlan,
  );

  return (
    <Box sx={{ position: 'relative' }}>
      {plusPlan && <PlusPlanOwnSection plusPlan={plusPlan} />}
      <Box sx={{ marginTop: '30px' }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'center',
              flexWrap: 'wrap',
              marginY: '10px',
            }}
          >
            <Skeleton variant="rounded" width={360} height={465} />
            <Skeleton variant="rounded" width={360} height={465} />
          </Box>
        ) : (
          <Plans
            subscriptionPlans={subscriptionPlans}
            disabled={onholdLink !== undefined}
          />
        )}
        <SubDescriptionLabel />
      </Box>

      {onholdLink && <OnholdRequiredAction onholdLink={onholdLink} />}
    </Box>
  );
}

function OnholdRequiredAction({ onholdLink }: { onholdLink: string }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: alpha(theme.palette.background.default, 0.8),
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4">คุณมีแพลนที่ชำระเงินไม่สำเร็จ</Typography>
      <Typography sx={{ color: 'primary.main' }}>
        กรุณายกเลิกหรือต่ออายุเพื่อดำเนินการต่อ
      </Typography>
      <a
        href={onholdLink}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Button
          variant="contained"
          sx={{ width: '100%', marginTop: '20px', color: 'text.button' }}
        >
          ไปที่หน้าดำเนินการ
        </Button>
      </a>
    </Box>
  );
}

function PlusPlanOwnSection({ plusPlan }: { plusPlan: SubscriptionPlanType }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginY: '70px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            boxShadow: '0px 16px 32px -4px #0000003D',
            borderRadius: '6px',
            padding: '20px 30px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ color: 'primary.main' }}>
            แพลนปัจจุบันของคุณ
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Image src={'/icons/ic_plan_starter.png'} sx={{ width: '63px' }} />
            <Typography variant="h2">{plusPlan.title}</Typography>
          </Box>
        </Box>
        {!isBusiness(plusPlan) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
            ตรวจสอบสถานะปัจจุบันของแพลน
            <a
              href={'https://www.borntodev.com/my-account/subscriptions/'}
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.palette.primary.main }}
            >
              คลิกที่นี่
            </a>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function isBusiness(plan: SubscriptionPlanType) {
  return plan.isBusinessPlan === true;
}

function Plans({
  subscriptionPlans,
  disabled,
}: {
  subscriptionPlans: SubscriptionPlanType[];
  disabled: boolean;
}) {
  const isBusinessExisted = subscriptionPlans.some((each) => isBusiness(each));
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      {subscriptionPlans.map((plan, index) =>
        isBusiness(plan) ? (
          <BusinessPlanCard
            key={`BusinessPlanCard-${index}`}
            subscriptionPlan={plan}
          />
        ) : (
          <RegularPlanCard
            key={plan.id}
            subscriptionPlan={plan}
            planCardContent={plan}
            disabled={isBusinessExisted || disabled}
          />
        ),
      )}
    </Box>
  );
}

function BusinessPlanCard({
  subscriptionPlan,
}: {
  subscriptionPlan: SubscriptionPlanType;
}) {
  return (
    <PlanCard
      title={subscriptionPlan.title}
      priceLabel={
        <Typography
          variant="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
          }}
        >
          <Iconify icon="bxs:business" sx={{ marginTop: '10px' }} />
          องค์กร
        </Typography>
      }
      description={
        <a
          href={'https://www.borntodev.com/'}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          ติดต่อ Admin
        </a>
      }
      basicPlan
      features={
        <>
          {subscriptionPlan.planChecklist.map(
            (item: PlanChecklistType, index: number) => (
              <Feature key={`feature-${index}`} feature={item} />
            ),
          )}
        </>
      }
      button={
        <Button
          variant="outlined"
          sx={{ width: '100%', marginTop: '20px' }}
          disabled
        >
          แพลนปัจจุบันของคุณ
        </Button>
      }
    />
  );
}

function RegularPlanCard({
  planCardContent,
  subscriptionPlan,
  disabled,
}: {
  planCardContent: SubscriptionPlanType;
  subscriptionPlan: SubscriptionPlanType;
  disabled: boolean;
}) {
  const [selectedVariationId, setSelectedVariationId] = useState(
    getInitialVariationId(planCardContent),
  );
  const selectedVariation = getViationById(selectedVariationId);
  const isDefaultVariation =
    subscriptionPlan.basicPlan ||
    selectedVariation?.price === selectedVariation?.pricePerMonth;

  const { isAuthenticated } = useAuth();

  function getViationById(id: string) {
    return planCardContent.variations.find((each) => each.id === id);
  }

  function getInitialVariationId(planCardContent: SubscriptionPlanType) {
    const defaultPlan = planCardContent.variations.find(
      (each) => each.defaultVariation,
    );
    return defaultPlan?.id || planCardContent.variations[0]?.id;
  }

  function getPriceLabel() {
    if (subscriptionPlan.basicPlan) {
      return <Typography variant="h2">ฟรี</Typography>;
    } else {
      return <PriceLabel price={selectedVariation?.pricePerMonth || 0} />;
    }
  }

  function getDescription() {
    if (selectedVariation === undefined) return '';
    const intervalText =
      selectedVariation.billingInterval > 1
        ? `${selectedVariation.billingInterval} `
        : '';
    const periodText =
      intervalText + periodLocale[selectedVariation.billingPeriod];
    return subscriptionPlan.description !== ''
      ? subscriptionPlan.description
      : `ดูได้ ${subscriptionPlan.courseCount} หลักสูตร` +
          (!isDefaultVariation
            ? ` เพียง ${fTSeparator(selectedVariation.price)} บาท/${periodText}`
            : '');
  }

  function getReferencePeriodText(period?: PeriodType) {
    if (!period || isDefaultVariation) return '';
    else if (period === 'DAY' || period === 'WEEK') return '(คิดจาก 30 วัน)';
    else return '(คิดจาก 1 เดือน)';
  }

  return (
    <PlanCard
      title={planCardContent.title}
      variationSelector={
        !subscriptionPlan.basicPlan ? (
          <VariationsSelector
            variations={planCardContent.variations}
            selectedId={selectedVariationId}
            setSelectedId={setSelectedVariationId}
          />
        ) : (
          <></>
        )
      }
      priceLabel={getPriceLabel()}
      refPeriodText={getReferencePeriodText(selectedVariation?.billingPeriod)}
      description={getDescription()}
      basicPlan={planCardContent.basicPlan}
      features={
        <>
          {subscriptionPlan.planChecklist.map(
            (item: PlanChecklistType, index: number) => (
              <Feature key={`feature-${index}`} feature={item} />
            ),
          )}
        </>
      }
      button={
        disabled || subscriptionPlan.basicPlan ? (
          <Button
            variant="outlined"
            sx={{ width: '100%', marginTop: '20px' }}
            disabled
          >
            เลือก
          </Button>
        ) : subscriptionPlan.userCurrentPlan ? (
          <Button
            variant="outlined"
            sx={{ width: '100%', marginTop: '20px' }}
            disabled
          >
            แพลนปัจจุบันของคุณ
          </Button>
        ) : (
          <a
            href={
              !isAuthenticated
                ? `/login?redirectUrl=${window.location.href}`
                : selectedVariation?.url || ''
            }
            target={!isAuthenticated ? '_self' : '_blank'}
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              sx={{
                width: '100%',
                marginTop: '20px',
                color: 'text.button',
              }}
            >
              เลือก
            </Button>
          </a>
        )
      }
    />
  );
}

function PlanCard({
  title,
  variationSelector = <></>,
  priceLabel,
  refPeriodText = '',
  description,
  basicPlan,
  features,
  button,
}: PlanCardProps) {
  return (
    <Card
      sx={{
        maxWidth: '360px',
        width: '100%',
        textAlign: 'center',
        borderRadius: '16px',
        marginY: '10px',
      }}
    >
      <Box
        sx={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Typography
            variant="overline"
            sx={{ color: 'text.secondary', lineHeight: '60px' }}
          >
            {title}
          </Typography>
          <Box sx={{ height: '55px' }}>{variationSelector}</Box>
          {priceLabel}
          <Typography
            variant="caption"
            component={'p'}
            sx={{ color: 'text.disabled', lineHeight: '1px', minHeight: '1px' }}
          >
            {refPeriodText}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: 'primary.main', lineHeight: '50px' }}
          >
            {description}
          </Typography>
          <Image
            src={`/icons/${basicPlan ? 'ic_plan_free' : 'ic_plan_starter'}.png`}
            sx={{ width: '63px', margin: '12px auto 24px auto' }}
          />
          {features}
        </Box>
        <Box>{button}</Box>
      </Box>
    </Card>
  );
}

interface VariationsSelectorProps {
  variations: VariationsType[];
  selectedId: string;
  setSelectedId: Function;
}

const periodLocale = {
  DAY: 'วัน',
  WEEK: 'สัปดาห์',
  MONTH: 'เดือน',
  YEAR: 'ปี',
};

function VariationsSelector({
  variations,
  selectedId,
  setSelectedId,
}: VariationsSelectorProps) {
  function getOptionLabel(variation: VariationsType): string {
    const period = periodLocale[variation.billingPeriod];
    const label = `${variation.billingInterval} ${period}`;
    return label;
  }

  return (
    <Box>
      <Select
        sx={{ width: '125px' }}
        size="small"
        value={selectedId}
        onChange={(value) => setSelectedId(value.target.value)}
      >
        {variations.map((each) => (
          <MenuItem key={each.id} value={each.id}>
            {getOptionLabel(each)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

function SubDescriptionLabel() {
  return (
    <Typography
      variant="caption"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        color: 'text.secondary',
      }}
    >
      * รวมภาษีมูลค่าเพิ่ม (VAT 7%)
    </Typography>
  );
}

function Feature({ feature }: { feature: PlanChecklistType }) {
  return (
    <Box
      sx={{
        color: feature.enable ? 'text.primary' : 'text.disabled',
        display: 'flex',
        marginY: '8px',
      }}
    >
      <Iconify
        icon="material-symbols:check"
        width={24}
        height={24}
        sx={{ marginRight: '5px', width: '24px', minWidth: '24px' }}
      />
      <Typography sx={{ textAlign: 'left' }}>{feature.content}</Typography>
    </Box>
  );
}

function PriceLabel({ price }: { price: number }) {
  const locale = {
    MONTHLY: 'เดือน',
    YEARLY: 'ปี',
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        gap: '10px',
      }}
    >
      <Typography variant="h2">{fTSeparator(price)}.-</Typography>
      <Typography
        variant="body2"
        sx={{ marginBottom: '8px', color: 'text.secondary' }}
      >
        / {locale.MONTHLY}
      </Typography>
    </Box>
  );
}

function SubscriptionNote() {
  return (
    <Box sx={{ marginTop: '30px' }}>
      <Typography
        component={'p'}
        variant="caption"
        sx={{ color: 'text.secondary' }}
      >
        {locale.note1}
      </Typography>
      <Typography
        component={'p'}
        variant="caption"
        sx={{ color: 'text.secondary', marginTop: '5px' }}
      >
        {locale.note2}
      </Typography>
    </Box>
  );
}

const locale = {
  note1:
    '*หลักสูตรสอนสดผ่านไลฟ์ใน borntoDev+ เป็นการเปิดสอนสดให้ผู้เรียนได้เข้าเรียน และ พิมพ์ถามตอบ พูดคุยกับอาจารย์ในขณะนั้นได้เลย โดยจะจัดเดือนละ 1 - 3 คลาส ใช้เวลาต่อหลักสูตรอยู่ที่ 3 - 9 ชั่วโมงต่อหลักสูตร (ขึ้นกับหลักสูตรนั้น ๆ ) จำกัดผู้เรียนต่อหลักสูตรที่ 250 ท่าน / รอบ โดยให้สิทธิ์สำหรับผู้ที่สนใจลงทะเบียนที่ลงทะเบียนทันในระยะเวลาที่กำหนด',
  note2:
    '**การสนับสนุนในรูปแบบเฉพาะตัวจะเปิดให้บริการในวัน และ เวลาทำการ (09.30 - 18.00 น.) ของวันจันทร์ - ศุกร์ ไม่รวมวันหยุดทำการผ่านช่องทางออนไลน์',
};
