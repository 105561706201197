import React from 'react';
import { Box, Typography } from '@mui/material';
import RichViewer from '../../../components/editor/RichViewer';
import { DeltaStatic } from 'quill';

export default function Detail({description}: {description: DeltaStatic|string}) {
  return (
    <Box>
      <Typography variant="h5" sx={{paddingBottom: '30px'}}>
        {locale.projectCourseTitle}
      </Typography>
      <RichViewer content={description}/>
      {/* <Typography variant="h5" sx={{paddingTop: '30px'}}>
        {locale.projectGeneralTitle}
      </Typography> */}
      <Typography variant="body1" sx={{whiteSpace: 'pre-line', paddingTop: '20px'}}>
        <RichViewer content={locale.projectGeneralDescription}/>
      </Typography>
      <Typography variant="h5" sx={{paddingTop: '30px'}}>
        {locale.devGalleryTitle}
      </Typography>
      <Typography variant="body1" sx={{whiteSpace: 'pre-line', paddingTop: '20px'}}>
        {locale.devGalleryDescription}
      </Typography>
    </Box>
  )
}

const locale = {
  projectCourseTitle: 'รายละเอียดโปรเจกต์สำหรับหลักสูตร',
  projectGeneralTitle: 'รายละเอียดโปรเจกต์ทั่วไป',
  devGalleryTitle: 'ผลงานของคุณจะถูกจัดแสดงใน DevProject Gallery',
  projectGeneralDescription: {
  "ops": [
    {
      "attributes": {
        "bold": true
      },
      "insert": "การทำส่งโปรเจกต์เพื่อรับใบประกาศนียบัตรรับรองความสามารถ"
    },
    {
      "attributes": {
        "header": 2
      },
      "insert": "\n"
    },
    {
      "attributes": {
        "bold": true
      },
      "insert": "ขอขอบคุณผู้เรียนทุกท่านที่ตั้งใจเรียนจนจบหลักสูตรนี้ ❤️"
    },
    {
      "insert": "\nทีมงานทุกท่านคาดหวังเป็นอย่างยิ่งว่าท่านจะประทับใจในบทเรียนของเรา\nหากมีข้อเสนอแนะ สามารถส่งมาในช่องทางการติดต่อ Facebook, Email หรือ Discord Community ของทาง borntoDev ได้ทุกเมื่อ\n"
    },
    {
      "attributes": {
        "italic": true,
        "bold": true
      },
      "insert": "\"สุดท้ายนี้ขอให้ทุกท่านประสบความสำเร็จในเส้นทางที่ฝันไว้ทุกคนครับ :)\""
    },
  ]
} as DeltaStatic,
  devGalleryDescription: `โปรเจกต์ของคุณที่ถูกอนุมัติจะถูกนำไปเผยแพร่ใน DevProject Gallery เพื่อโชว์ความสามารถและแบ่งปันความรู้แก่เพื่อนๆ โดยคุณสามารถเลือกได้ว่าจะแสดงตัวตนของคุณเมื่อเผยแพร่โปรเจกต์หรือไม่`,
}


