import React from 'react';
import { Box, Grid, Button, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';

import { ProfileDataType } from '../../../@types/profile';
import Iconify from '../../../components/Iconify';
import { setProfileData } from '../../../redux/slices/profile';

import ContactLinksEditor from './ContactLinksEditor';
import ContactLanguages from './ContactLanguages';
import BioEditor from './BioEditor';
import TargetEditor from './TargetEditor';
import SkillsEditor from './SkillsEditor';
import ProfileCoverEditor from './ProfileCoverEditor';

interface ProfileEditorProps {
  profileData: ProfileDataType;
  swapToDetail: Function;
}

export default function ProfileEditor({
  profileData,
  swapToDetail,
}: ProfileEditorProps) {
  const dispatch = useDispatch();

  return (
    <Box sx={{ padding: '10px' }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => swapToDetail()}
        sx={{ marginY: '30px', color: 'text.button' }}
      >
        <Iconify icon="ri:arrow-go-back-fill" />
        {'กลับสู่หน้าดูโปรไฟล์'}
      </Button>
      <ProfileCoverEditor
        userData={profileData.user}
        setUserData={(newPublicName: string) =>
          dispatch(
            setProfileData({
              ...profileData,
              user: { ...profileData.user, publicName: newPublicName },
            }),
          )
        }
        profileStatus={profileData.profileStatus}
        experience={profileData.experience}
        coverImage={profileData.coverImage}
      />
      <Grid container gap={0.1} sx={{ marginBottom: '10px' }}>
        <Grid item xs={12} sm={3.6}>
          <ContactLinksEditor contactsData={profileData.contacts} />
          <Divider sx={{ marginY: '10px' }} />
          <ContactLanguages langs={profileData.communicationLanguages} />
          <Divider sx={{ marginY: '10px' }} />
        </Grid>
        <Grid item xs={12} sm={8.3}>
          <BioEditor bio={profileData.user.bio} />
          <Divider sx={{ marginY: '10px' }} />
          <TargetEditor target={profileData.target} />
          <Divider sx={{ marginY: '10px' }} />
          <SkillsEditor skills={profileData.skills} />
          <Divider sx={{ marginY: '10px' }} />
        </Grid>
      </Grid>
    </Box>
  );
}
