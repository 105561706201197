import React, {ReactNode} from 'react'
import { Link } from 'react-router-dom'

export default function GeneralLink({link, children}: {link?: string, children?: ReactNode}) {
  return (
    <>
      {
        link ?
        <Link to={link} style={{textDecoration: 'none'}}>
          {children}
        </Link>
        :
        <>{children}</>
      }
    </>
  )
}
