import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Question from './Question';
import { quizDataType, quizAnswerType } from '../../../@types/quiz';
import ShadowButton from '../../../components/ShadowButton';



export default function QuizAnswerTotal({questions, answers, setQPage, submitQuiz}:
  { questions: quizDataType,
    answers: quizAnswerType[],
    setQPage: React.Dispatch<React.SetStateAction<string>>,
    submitQuiz: (answers: quizAnswerType[]) => Promise<void>
  }) {
  return (
    <Box>
      <Typography variant="h5">{questions.quiz.title}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          '@media (max-width: 899px)': {
            display: 'block',
          },
        }}
      >
        <Box sx={{marginY: '15px'}}>
          <Typography variant="h5">คุณต้องการส่งคำตอบเลยไหม ?</Typography>
          <Typography variant="body1">หากยังไม่มั่นใจสามารถตรวจคำตอบที่คุณส่งจาก<br/>ข้อมูลที่แสดงด้านล่างได้เลย</Typography>
        </Box>
        <QuizAnswerTotalButtons setQPage={setQPage} submitQuiz={submitQuiz} answers={answers}/>
      </Box>

      {questions.quiz.questions.map((question, index) => {return (
        <Question
          question={question}
          key={question.id}
          answer={answers.find((answer) => answer.questionId === question.id)}
          chooseAnswer={()=>{}}
          writeAnswer={()=>{}}
          index={index}
          reviewMode
        />
      )})}
      <Box sx={{marginTop: '30px'}}></Box>
      <QuizAnswerTotalButtons setQPage={setQPage} submitQuiz={submitQuiz} answers={answers}/>
    </Box>
  )
}

function QuizAnswerTotalButtons ({setQPage, submitQuiz, answers} : {
  setQPage: React.Dispatch<React.SetStateAction<string>>,
  submitQuiz: (answers: quizAnswerType[]) => Promise<void>,
  answers: quizAnswerType[]
  }) {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', gap: '20px'}}>
      <Button
        variant="outlined"
        color="tertiary"
        onClick={() => {setQPage('sheet')}}
        sx={{width: '200px', height: '50px'}}
      >
        <Typography variant="h5">ย้อนกลับ</Typography>
      </Button>
      <ShadowButton
        variant="contained"
        color="secondary"
        sx={{width: '200px', height: '50px'}}
        onClick={() => {submitQuiz(answers)}}
      >
        <Typography variant="h5">ส่งข้อสอบ</Typography>
      </ShadowButton>
    </Box>
  )
}
