import React from 'react';
import { Box, Grid, Typography, Button, Stack } from '@mui/material';
import Image from '../../../components/Image';
import { useTheme } from '@mui/material/styles';
import { featureType, categoryType, recommendedSoftwareType, tagType } from '../../../@types/courseData';
import { Link as RouterLink } from 'react-router-dom'

export default function Properties({features, categories, recommendedSoftwares, tags}:
  {features: featureType[], categories: categoryType[], recommendedSoftwares: recommendedSoftwareType[], tags: tagType[]}) {
  return (
    <Box>
      {features.map((feature) => <Feature  key={feature.text} feature={feature}/>)}
      { categories.length > 0 &&
        <Box sx={{display: 'flex', gap:'10px', flexWrap: 'wrap', marginTop: '30px'}}>
          <Typography variant="h5">หมวดหมู่</Typography>
          {categories.map((category) => <Category key={category.id} category={category}/>)}
        </Box>
      }
      { tags.length > 0 &&
        <Box sx={{marginTop: '30px'}}>
          <Typography variant="h5">กลุ่ม</Typography>
          <Box sx={{display: 'flex', gap:'10px', flexWrap: 'wrap', }}>
            {tags.map((tag) => <Tag key={tag.id} tag={tag}/>)}
          </Box>
        </Box>
      }
      { recommendedSoftwares.length > 0 &&
        <Box sx={{marginTop: '30px'}}>
          <Typography variant="h5">ซอฟต์แวร์แนะนำสำหรับคอร์สนี้</Typography>
          {recommendedSoftwares.map((recommendedSoftware) => <Software key={recommendedSoftware.title} recommendedSoftware={recommendedSoftware}/>)}
        </Box>
      }
    </Box>
  )
}

function Feature({feature}: {feature: featureType}) {
  const theme = useTheme()
  return (
    <Box sx={{alignItems: 'center', marginTop: '15px', display: 'flex', gap: '20px'}}>
      <Box sx={{padding: '11px', border: `${theme.palette.text.secondary} 1px solid`, borderRadius: '100px'}}>
        <Image
          disabledEffect
          visibleByDefault
          alt="feature"
          src={feature.icon}
          sx={{ width: 30, height: 30 }}
        />
      </Box>
      <Box>
        {feature.text}
      </Box>
    </Box>
  )
}

function Category({category}: {category: categoryType}) {
  return (
    <Button
      component={RouterLink}
      sx={{
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        borderRadius:'50px',
        color: 'text.button',
      }}
      to={`/search?categories=${category.title}`}
      variant="contained"
      color="primary"
    >
      {category.title}
    </Button>
  )
}

function Tag({tag}: {tag: tagType}) {
  return (
    <Button
      // component={RouterLink}
      sx={{
        borderRadius:'50px',
      }}
      // to={`/search?tags=${tag.title}`}
      variant="outlined"
      color="primary"
    >
      {tag.title}
    </Button>
  )
}

function Software({recommendedSoftware}: {recommendedSoftware: recommendedSoftwareType}) {
  return (
    <Box sx={{alignItems: 'center', marginTop: '15px', display: 'flex', gap: '20px'}}>
      <Box>
          <Image
            disabledEffect
            visibleByDefault
            alt="logo"
            src={recommendedSoftware.icon}
            sx={{ maxWidth: 40, maxHeight: 40, minWidth: 40 }}
          />
      </Box>
      <Box>
        <a href={recommendedSoftware.link} style={{textDecoration: 'none'}} target='_blank' rel="noreferrer">
          <Typography sx={{color: 'text.primary', ':hover': {color: 'primary.main'}}} variant="h6">{recommendedSoftware.title}</Typography>
        </a>
      </Box>
    </Box>
  )
}

