import React from 'react'
import NotiController from '../components/notification/NotiController'
import DeviceLimitSocketController from '../components/diviceLimit/DeviceLimitSocketController'
import BornBitSocketController from '../components/notificationToast/BornBitSockerController';
import useAuth from '../hooks/useAuth';

export default function EventSocket() {

  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) return <></>

  return (
    <>
      <NotiController/>
      <BornBitSocketController />
      <DeviceLimitSocketController/>
    </>
  )
}