import React, { useState, useEffect } from 'react';
import QuizDescription from './QuizDescription';
import QuizQuestions from './QuizQuestions';
import QuizResult from './QuizResult';
import axios from '../../../utils/axios';
import { lessonType, quizAttempts } from '../../../@types/lesson';
import { questionType, quizDataType } from '../../../@types/quiz';
import { useDispatch } from '../../../redux/store';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { getCourseLessons } from '../../../redux/slices/lesson';
import QuizSkeleton from '../../../components/skeleton/Quiz';
import dayjs from 'dayjs';
import { Box, Button, Modal, Typography } from '@mui/material';
import Iconify from '../../../components/Iconify';
import _ from 'lodash';

interface ModalQuizTimeoutProps {
  open: boolean;
  setOpen: Function;
}

export default function Quiz({ lesson }: { lesson: lessonType }) {
  const dispatch = useDispatch();
  const { courseSlug } = useParams();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();

  const [continuous, setContinuous] = useState<boolean>(false);
  const [remainAttempt, setRemainAttempt] = useState<number>(
    lesson.quizData.attemptData.remainAttempt,
  );
  const [page, setPage] = useState('description');
  const [loading, setLoading] = useState(false);
  const [quizData, setQuizData] = useState(lesson);
  const [open, setOpen] = useState(false);

  const [limitTime, setLimitTime] = useState<dayjs.Dayjs>(dayjs());
  const [status, setStatus] = useState<string>();

  const [questions, setQuestions] = useState<quizDataType>({
    allQuestionAttempt: [],
    quiz: {
      id: '',
      lessonId: '',
      title: '',
      description: '',
      maxScore: 0,
      percentPassing: 0,
      questionAmount: 0,
      questions: [],
      countdown: false
    },
    quizAttemptId: '',
    status: 'PROGRESS',
    createdAt: new Date()
  });
  const [lastSubmit, setLastSubmit] = useState(false);
  const quizId = lesson.quizData.quiz.id;

  const handleCheckedPage = () => {
    const data: quizAttempts[] = [...lesson.quizData.attemptData.quizAttempts];
    const continuous: boolean = data.some(
      (item: quizAttempts) => item.status === 'PROGRESS',
    );

    if (data.length === 0 || searchParam.get('error') === 'new_version') {
      setPage('description');
      setContinuous(false);
    } else if (continuous) {
      setPage('description');
      setContinuous(true);
    } else {
      setPage('result');
      setContinuous(false);
    }
  };

  const getQuestions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/quiz/${quizId}/questions`);
      if(response) {
        const endTime = dayjs(response.data.createdAt).add(lesson.duration,'second');
        const totalDuration = endTime.diff(dayjs(),'second');
        const shuffleQuestionsQuizChoices = {
          ...response.data,
          quiz: {
            ...response.data.quiz,
            questions: response.data.quiz.questions.map((question: questionType) => {return {...question, questionChoices: _.shuffle(question.questionChoices)}})
          }
        }
        setLimitTime(endTime)
        setQuestions(shuffleQuestionsQuizChoices)
        setStatus(response.data.status)
        if(response.data.quiz.countdown && response.data.status === 'PROGRESS' && totalDuration <= 0) {
          submitQuiz(response.data.quizAttemptId || '')
        } else {
          setPage('questions')
          setLoading(false)
        }
      }
    } catch (error: any) {
      error.statusCode === 410 && navigate(`/course/${courseSlug}/${lesson.id}?error=new_version`);
    }
  };

  const submitQuiz = async (quizAttemptId: string) => {
    const payload = { quizAttemptId: quizAttemptId };
    try {
      setLoading(true);
      const response = await axios.post(`/quiz/${quizId}/submit`, payload);
      const data = response.data;

      if (data.completed) {
        // @ts-ignore redux type error
        dispatch(getCourseLessons(courseSlug || ''));
      }
      setQuizData(response.data);
      setContinuous(false)
      setPage('result');
      setRemainAttempt(data.quizData.attemptData.remainAttempt);
      setLastSubmit(true);
      setLoading(false);

    } catch (error: any)  {
      error.statusCode === 410 && navigate(`/course/${courseSlug}/${lesson.id}?error=new_version`);
    }
  };

  useEffect(() => {
    if (lesson) {
      handleCheckedPage()
    };
  }, [lesson]);

  useEffect(() => {
    setRemainAttempt((prev: number) => (continuous ? prev + 1 : prev));
  }, [continuous]);

  return (
    <>
      {loading ? (
        <QuizSkeleton />
      ) : page === 'description' ? (
        <QuizDescription
          lesson={lesson}
          continuous={continuous}
          remainAttempt={remainAttempt}
          startQuiz={getQuestions}
        />
      ) : page === 'questions' ? (
        <QuizQuestions questions={questions} submitQuiz={submitQuiz} limitTime={limitTime} status={status}/>
      ) : page === 'result' ? (
        <QuizResult
          quizData={quizData}
          lastSubmit={lastSubmit}
          setPage={setPage}
        />
      ) : (
        <QuizSkeleton />
      )}
      <ModalQuizTimeout open={open} setOpen={setOpen}/>
    </>
  );
}

function ModalQuizTimeout({open=false, setOpen}: ModalQuizTimeoutProps) {
  return (
    <Modal open={open}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '500px',
          backgroundColor: 'background.paper',
          padding: '20px 30px',
          borderRadius: '16px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ marginY: '5px', color: 'warning.dark' }}
        >
          <Iconify
            icon={'material-symbols:warning-rounded'}
            sx={{
              color: 'warning.main',
              width: '20px',
              height: '20px',
              margin: '0px 5px -5px 0px',
            }}
          />
          00:00:00 หมดเวลา !!
        </Typography>
        <Typography sx={{ color: 'text.secondary' }} variant="caption">
          เวลาที่ท่านใช้ในการทำแบบทดสอบนี้หมดลงแล้ว ท่านสามารถดูผลการทำแบบทดสอบได้ที่หน้าผลการทดสอบ
        </Typography>
        <Box>
            <Button
              sx={{ color: 'text.button', float: 'right' }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              ปิด
            </Button>
        </Box>
      </Box>
    </Modal>
  );
}
