import React from 'react';
import { Box, Container, Typography } from "@mui/material";

export default function Contact() {
  return (
    <Box sx={{margin: '70px 0px -30px 0px', textAlign: 'center', paddingY: '60px', backgroundColor: 'common.black'}}>
      <Container>
        <Typography sx={{fontSize: '40px'}}>
          ติดต่อสอบถามเพิ่มเติม
        </Typography>
        <Typography sx={{fontSize: '40px'}}>
          สำหรับ
          <Box component={'span'} sx={{color: 'primary.main', fontWeight: 'bold'}}>องค์กร</Box>
        </Typography>
        <Typography sx={{ fontSize: '28px', marginY: '20px' }}>
          080-575-7942 (คุณปรวรรณ)
        </Typography>
        <Typography>
          เรายินดีให้คำปรึกษาเบื้องต้นเกี่ยวกับหลักสูตรต่าง ๆ
        </Typography>
        <Typography>
          ตามความต้องการของหน่วยงานคุณ ไม่ว่าจะเป็นภาครัฐ หรือ เอกชน
        </Typography>
      </Container>
    </Box>
  )
}