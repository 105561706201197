import React from 'react'
import { Stack, Skeleton } from '@mui/material'

interface CommentLoadingProps {
  number: number
}

export default function CommentLoading({ number }: CommentLoadingProps) {
  const elementArray = new Array(number).fill(number)

  return (
    <>
      {elementArray.map((each, index) => (
        <Stack key={`skeleton-${index}`} direction="row" sx={{ width: '100%', marginBottom: '40px' }}>
          <Skeleton
            variant="circular"
            sx={{ backgroundColor: 'grey.400', marginRight: '16px' }}
            width={48}
            height={48}
          />

          <Stack direction="column" sx={{ width: '80%' }}>
            <Skeleton
              variant="text"
              sx={{ backgroundColor: 'grey.400' }}
              width={130}
            />

            <Skeleton
              variant="text"
              sx={{ backgroundColor: 'grey.400' }}
              width={100}
              height={15}
            />

            <Skeleton
              variant="rectangular"
              sx={{ backgroundColor: 'grey.400', marginTop: '10px' }}
              height={100}
              width="100%"
            />
          </Stack>
        </Stack>
      ))}
    </>
  )
}
