import React, { useState } from 'react';
import { Typography, TextField, Divider, Stack, Checkbox, FormControlLabel } from '@mui/material';
import { profileExperienceType } from '../../../@types/profile';

import dayjs from 'dayjs'
import EditorHeader from './EditorHeader';
import ChangePreview from './ChangePreview';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axiosInstance from '../../../utils/axios';

interface DatePickerType {
  startDate: dayjs.Dayjs | null
  endDate: dayjs.Dayjs | null
}

export default function ProfileExperienceEditor({experience}: {experience: profileExperienceType | null}) {
  const [dateSearch, setDateSearch] = useState<DatePickerType>(getDatePickerInit())
  const [position, setPosition] = useState(experience?.position ? experience.position : '')
  const [isCurrent, setIsCurrent] = useState<boolean>(experience?.isCurrent ? experience?.isCurrent : true )
  const [isInit, setIsInit] = useState(true)

  const getExperienceText = () => {
    const positionStatusText = isCurrent ? 'ตำแหน่งงานปัจจุบัน' : 'ประสบการณ์ทำงาน'
    const positionText = position
    const duration = dayjs(dateSearch.endDate || new Date()).diff(dayjs(dayjs(dateSearch.startDate)), 'year')
    const durationText = duration > 0 ? ` (ประสบการณ์รวม ${duration} ปี)` : ''
    return `${positionStatusText} : ${positionText}${durationText}`
  }

  function getDatePickerInit() {
    return {
      startDate: experience?.startDate ? dayjs(experience.startDate) : dayjs(new Date()),
      endDate: experience?.endDate? dayjs(experience.endDate) : dayjs(new Date())
    }
  }

  async function onSave() {
    const payload = {
      position: position,
      isCurrent: isCurrent,
      startDate: dayjs(dateSearch.startDate).toDate(),
      endDate: dayjs(dateSearch.endDate).toDate()
    }
    await axiosInstance.put('/users/profile/experience', payload)
    setIsInit(true)
  }

  return (
    <>
      <EditorHeader title={'ประสบการณ์ทำงาน'} onSubmit={onSave} disabled={isInit}/>
      <TextField
        value={position}
        onChange={(e) => {setPosition(e.target.value); setIsInit(false)}}
        sx={{'.MuiInputBase-input' : {padding: '10px'}, width: '100%', marginY: '10px'}}
        placeholder='ตำแหน่งงาน'
      />
      <FormControlLabel
        label='ตำแหน่งงานปัจจุบัน'
        control={<Checkbox checked={isCurrent} onChange={(e) => {setIsCurrent(e.target.checked); setIsInit(false)}} />}
      />

      <Stack direction="row" sx={{ gap: '10px' }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
          >
            <Stack direction="row" sx={{ gap: '10px' }}>
              <DatePicker
                label="Start at"
                maxDate={dayjs(dateSearch.endDate).subtract(1, 'day')}
                value={dateSearch.startDate}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    setDateSearch({ ...dateSearch, startDate: newValue })
                    setIsInit(false)
                  }
                }
                }
              />

              {
                !isCurrent &&
                <DatePicker
                  label="End at"
                  disableFuture={true}
                  minDate={dayjs(dateSearch.startDate).add(1, 'day')}
                  value={dateSearch.endDate}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      setDateSearch({ ...dateSearch, endDate: newValue })
                      setIsInit(false)
                    }
                  }
                  }
                />
              }
            </Stack>
          </LocalizationProvider>

        </Stack>
      <ChangePreview>
        <Typography variant="overline" sx={{color: 'text.secondary', display: 'block'}}>
          {position && getExperienceText()}
        </Typography>
      </ChangePreview>
      <Divider sx={{margin: '10px 0 40px 0'}}/>
    </>
  )
}
