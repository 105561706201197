import React, { useState, useEffect } from 'react'
import { Modal, Box, Stack, Grid, CircularProgress, Typography, Button } from '@mui/material'
import { useParams, useLocation } from 'react-router'
import _ from 'lodash'

import Icon from '../Iconify'
import { devlabResultOutputType, devlabResultType } from '../../@types/devlab'

interface SubmissionModalProps {
  data?: devlabResultType | null
  open: boolean
  nextTask: string | null
  onClose: Function
  onSubmit: Function
}

interface BodyProps {
  pass: number
  all: number
  nextTask?: boolean
  path?: string
  onClose: Function
}

interface FailureBodyProps {
  onSubmit: Function
  onClose: Function
}

function LoadingBody() {
  return (
    <>
      <Icon icon="ion:time-outline" sx={{ width: '90px', height: '90px' }} />
      <Box sx={{ marginBottom: '50px', marginTop: '10px', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ color: 'primary.main' }}>กำลังส่งคำตอบ</Typography>
        <Typography variant="body1">กรุณารอสักครู่</Typography>
      </Box>
      <CircularProgress />
    </>
  )
}

function SuccessBody({ pass, all, nextTask, path, onClose }: BodyProps) {
  return (
    <>
      <Icon icon="clarity:success-standard-line" sx={{ width: '90px', height: '90px', color: 'success.main' }} />
      <Box sx={{ marginBottom: '35px', marginTop: '10px', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ color: 'success.main' }}>ผ่านโจทย์นี้แล้ว</Typography>
        <Typography variant="caption" sx={{ color: 'grey.600' }}>{`( ผ่านเเล้ว ${pass} / ${all} ข้อ )`}</Typography>
      </Box>
      <Grid container columnSpacing="5px">
        <Grid item xs={6}>
          <Button 
            variant="outlined"
            color="tertiary"
            onClick={() => onClose()} 
            sx={{ width: '100%' }}
          >
            <Typography>อยู่หน้านี้ต่อ</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button 
            href={path}
            variant="contained"  
            sx={{ width: '100%',  color: 'text.button' }}
          >
            <Typography>{nextTask ? 'โจทย์ถัดไป' : 'สรุปผล'}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function NotPassBody({ pass, all, onClose }: BodyProps) {
  return (
    <>
      <Icon icon="system-uicons:cross-circle" sx={{ width: '90px', height: '90px', color: 'error.main' }} />
      <Box sx={{ marginBottom: '35px', marginTop: '10px', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ color: 'error.main' }}>ไม่ผ่านโจทย์ข้อนี้</Typography>
        <Typography variant="caption" sx={{ color: 'grey.600' }}>{`( ผ่านเเล้ว ${pass} / ${all} ข้อ )`}</Typography>
      </Box>
      <Grid container columnSpacing="5px">
        <Grid item xs={6}>
          <Button 
            variant="outlined"
            color="tertiary"
            onClick={() => onClose()} 
            sx={{ width: '100%' }}
          >
            <Typography>อยู่หน้านี้ต่อ</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button 
            variant="contained" 
            onClick={() => onClose()} 
            sx={{ width: '100%',  color: 'text.button' }}
          >
            <Typography>โจทย์ถัดไป</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function FailureBody({ onClose, onSubmit }: FailureBodyProps) {
  return (
    <>
      <Icon icon="ph:warning-light" sx={{ width: '90px', height: '90px', color: 'primary.main' }} />
      <Box sx={{ marginBottom: '35px', marginTop: '10px', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ color: 'primary.main' }}>เกิดปัญหาการส่งคำตอบ</Typography>
        <Typography variant="body1">กรุณาทำการส่งข้อมูลอีกครั้ง</Typography>
      </Box>
      <Grid container columnSpacing="5px">
        <Grid item xs={6}>
          <Button 
            variant="outlined"
            color="tertiary"
            onClick={() => onClose()} 
            sx={{ width: '100%' }}
          >
            <Typography>ยกเลิก</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button 
            variant="contained" 
            onClick={() => onSubmit()} 
            sx={{ width: '100%',  color: 'text.button' }}
          >
            <Typography>ส่งคำตอบ</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default function SubmissionModal({ data, open, nextTask, onClose, onSubmit }: SubmissionModalProps) {
  const [resultObject, setResultObject] = useState({})
  const [waitting, setWaitting] = useState<boolean>(true)

  const { lessonId } = useParams()
  const location = useLocation()

  const handlePath = (taskId: string | null): string => {
    const path = location.pathname.split('/')

    if (taskId) return `${path.slice(0, 5).join('/')}/${taskId}`
    else return `${path.slice(0, 3).join('/')}/${lessonId}`
  }

  const soundVictory = () => {
    const audio = new Audio('https://www.borntodev.com/wp-content/uploads/2020/04/Jazz-Win.wav');
    audio.play();
  };

  const handleCloseModal = () => {
    setWaitting(true)
    setResultObject({})
    onClose()
  }

  const handleSubmitAgain = () => {
    setWaitting(true)
    setResultObject({})
    onSubmit()
  }

  const handleBodyModal = (): React.ReactNode => {
    const pass = data?.results?.filter((item: devlabResultOutputType) => item.status.id === 3)?.length ?? 0
    const all = data?.results?.length ?? 0

    if (!data?.fail) {
      if (data?.status === 'PASS') {
        return (
          <SuccessBody 
            pass={pass} 
            all={all} 
            nextTask={nextTask !== null} 
            path={handlePath(nextTask)} 
            onClose={handleCloseModal} 
          />
        )
      } 
      else return (
        <NotPassBody
          pass={pass}
          all={all}
          nextTask={nextTask !== null} 
          path={handlePath(nextTask)}
          onClose={handleCloseModal} 
        />
      ) 
    } else {
      return <FailureBody onClose={handleCloseModal} onSubmit={handleSubmitAgain} />
    }
  }

  useEffect(() => {
    if (open && data && !_.isEqual(resultObject, data)) {
      setWaitting(false)
      setResultObject(data)
    }

    if (data?.status === 'PASS') soundVictory()
  }, [data])

  return (
    <Modal open={open}>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          maxWidth: '300px',
          maxHeight: '400px',
          backgroundColor: 'background.paper',
          borderRadius: '8px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Stack 
          direction="column" 
          justifyContent="center" 
          alignItems="center" 
          sx={{ height: '100%', paddingX: '30px', paddingY: '60px' }}
        >
          {!waitting && !data?.fail && 
            <Icon 
              icon="radix-icons:cross-2" 
              onClick={() => handleCloseModal()}
              sx={{ 
                width: '20px', 
                height: '20px', 
                position: 'absolute', 
                top: '10px', 
                right: '10px', 
                cursor: 'pointer' 
              }} 
            />
          }

          {
            waitting && !data?.fail
              ? <LoadingBody /> 
              : handleBodyModal()
          }
        </Stack>
      </Box>
    </Modal>
  )
}