import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from '../../../utils/axios';
import { useParams , useNavigate } from 'react-router'
import { Box , Typography } from '@mui/material';


function WcProducts()  {
  const { wcProductSlug } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() =>{
    fetchData()
  },)
  
  async function fetchData() {
    try{
      const response = await axios.get(`/wc-products/${wcProductSlug}`)
      if(response){
        setLoading(false)
      }
      const productType = response.data.type
      const isLinked = response.data.linked
      if(isLinked === false){
        window.location.replace(`https://www.borntodev.com/product/${wcProductSlug}?isLinked=${response.data.linked}`)
      }else{
        if(productType === 'SINGLE'){
          navigate(`/course/${response.data.course.slug}`)
        }else{
          navigate(`/bundle/${response.data.bundle.slug}`)
        }
      }
    }
    catch{
      navigate('/404')
    }
  }
  return (
    <>
      {loading?
      <Box sx={{justifyContent:'center',display:"flex",height:"calc(100dvh - 88px)",alignItems:"center"}}>
        <Typography variant={'h2'}>กำลัง Redirect...</Typography>
      </Box>
      :
      <></>}
    </>
  )
}

export default WcProducts