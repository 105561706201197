import { combineReducers } from 'redux';
import lesson from './slices/lesson';
import config from './slices/config';
import socket from './slices/socket';
import notification from './slices/notification';
import device from './slices/device';
import comment from './slices/comment';
import bornbit from './slices/bornbit';
import profile from './slices/profile';

const rootReducer = combineReducers({
  lesson: lesson,
  config: config,
  socket: socket,
  notification: notification,
  device: device,
  comment: comment,
  bornbit: bornbit,
  profile: profile,
});

export { rootReducer };
