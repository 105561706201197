import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import Iconify from './Iconify';

export default function CardArrowSelector({cards, cardWidth, sx = {}}: {cards: ReactElement, cardWidth: number, sx?: Object}) {
  const ref = createRef<HTMLElement>()

  const [disabledLeft, setDisabledLeft] = useState(false)
  const [disabledRight, setDisabledRight] = useState(false)

  function scrollClick(forward: boolean, move: boolean) {
    const clientWidth = ref.current?.clientWidth || 0
    const scrollLeft = ref.current?.scrollLeft || 0
    const scrollWidth = ref.current?.scrollWidth || 0
    const childNodesLength = ref.current?.childNodes?.length || 0

    const scrollAmount = Math.floor(clientWidth/cardWidth)*cardWidth
    const currentScroll = scrollLeft + (forward ? scrollAmount : -scrollAmount)

    const isLeftEnd = currentScroll <= 0
    const isRightEnd = clientWidth + currentScroll >= scrollWidth
    const isNotOverClientWidth = scrollLeft === 0 && childNodesLength*cardWidth <= clientWidth

    setDisabledLeft(isNotOverClientWidth || isLeftEnd)
    setDisabledRight(isNotOverClientWidth || isRightEnd)

    if(move) ref.current!.scrollLeft = currentScroll
  }

  useEffect(() => {
    scrollClick(false, false)
  }, [])

  useEffect(() => {

    window.addEventListener('resize', () => scrollClick(false, false))
    return () => window.removeEventListener('resize', () => scrollClick(false, false));
  }, [ref])

  return (
    <Box sx={{position: 'relative', paddingX: '30px'}}>
      <Button
        disabled={disabledLeft}
        color={'tertiary'}
        onClick={() => scrollClick(false, true)}
        sx={{color: 'text.primary', position:'absolute', left: 0, zIndex: 5, height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '0px', minWidth: '0px'}}
      >
        <Iconify sx={{width: '30px', height: '30px'}} icon='dashicons:arrow-left-alt2'/>
      </Button>
      <Button
        color={'tertiary'}
        disabled={disabledRight}
        onClick={() => scrollClick(true, true)}
        sx={{color: 'text.primary', position:'absolute', right: 0, zIndex: 5, height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '0px', minWidth: '0px'}}
      >
        <Iconify sx={{width: '30px', height: '30px'}} icon='dashicons:arrow-right-alt2'/>
      </Button>
        <CourseSelectorContainerStyle ref={ref} sx={sx}>
          { cards }
        </CourseSelectorContainerStyle>
    </Box>
  )
}

const CourseSelectorContainerStyle = styled(Box)(({theme}) => ({
  display: 'flex',
  overflowX: 'scroll',
  flexWrap: 'nowrap',
  gap: 15,
  '::-webkit-scrollbar': {display: 'none'},
  scrollBehavior: 'smooth'
}))
