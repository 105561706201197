import React from 'react';
import Chart from 'react-apexcharts'
import { useTheme, alpha } from '@mui/material/styles';
import {Box, Typography} from '@mui/material/';

interface AveragePointScoreChartType {
  averageScore: number,
  score: number,
  counts: {
    sumScore: number,
    count: number
  }[],
  passed: boolean,
  label: string
}

export default function AveragePointScoreChart ({averageScore, score, counts, passed, label}: AveragePointScoreChartType){

  const theme = useTheme()
  const chartColor = passed ? theme.palette.success : theme.palette.error

  const quizAttemptData = counts.reduce((prev: {sumScore: number[], count: number[]}, curr) => (
      { sumScore: [...(prev.sumScore), curr.sumScore],
        count: [...(prev.count), curr.count]
      }), {sumScore: [], count: []})
  const chartData = [
      { name: 'จำนวนคน', data: quizAttemptData.count }
    ]
  const chartOptions = {
    colors: [chartColor.main],
    xaxis: {
      categories: quizAttemptData.sumScore,
      axisTicks: { show: false },
      labels: { show: false },
      axisBorder: { show: false }
    },
    yaxis: {
      axisTicks: { show: false },
      labels: { show: false },
      axisBorder: { show: false }//
    },
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      background: 'none'
    },
    grid: {
      show: false,
    },
    dataLabels: {
      formatter: function () {
        return '0'
      },
      style: {
        colors: [
        function ({ dataPointIndex } : {dataPointIndex: number}) {
          return alpha(chartColor.main, quizAttemptData.sumScore[dataPointIndex] === score ? 1 : 0)
        }
      ]
      },
      background: {
        borderWidth: 0,
        borderRadius: 100,
        foreColor: alpha('#fff', 0),
        dropShadow: {}
      }
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0.2,
      }
    },
    theme: {
      mode: 'dark' as 'dark' | 'light'
    }
  };

  return (
    <Box sx={{textAlign: 'center', paddingTop: '20px'}}>
      <Typography variant="subtitle1" sx={{color: theme.palette.text.secondary}}>
        {label}
      </Typography>
      <Typography variant="h3">{averageScore}</Typography>
      <Chart type="area" series={chartData} options={chartOptions} height={76} width={260}/>
    </Box>
  )
}
