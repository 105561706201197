import React from 'react'
import { Select, MenuItem } from '@mui/material'
import { PropsSortButton } from '../../../@types/projectGallery'


export default function SortButtonComponent({ sortList, sortSelected, submitSortSelected }: PropsSortButton) {
  const selectSort = (item: string): { sortBy: string, sortOrder: string } => {
    let result = { sortBy: '', sortOrder: '' }

    if (item === 'A-Z') result = { sortBy: 'name', sortOrder: 'asc' }
    else if (item === 'Z-A') result = { sortBy: 'name', sortOrder: 'desc' }
    else if (item === 'Latest') result = { sortBy: 'submittedAt', sortOrder: 'desc' }
    else if (item === 'Oldest') result = { sortBy: 'submittedAt', sortOrder: 'asc' }

    return result
  }

  const getSort = (): string => {
    let result = 'Popular'

    if (sortSelected.sortBy === 'name') {
      if (sortSelected.sortOrder === 'asc') result = 'A-Z'
      else if (sortSelected.sortOrder === 'desc') result = 'Z-A'
    } else if (sortSelected.sortBy === 'submittedAt') {
      if (sortSelected.sortOrder === 'desc') result = 'Latest'
      else if (sortSelected.sortOrder === 'asc') result = 'Oldest'
    }

    return result
  }

  return (
    <Select
      value={getSort()}
      sx={{ height: '36px', width: '123px', fontSize: '14px', padding: '2px' }}
    >
      {sortList.map((item: string, index: Number) => (
        <MenuItem
          value={item}
          key={`sort-label-${index}`}
          onClick={() => submitSortSelected({ ...sortSelected, ...selectSort(item) })}
        >
          {item}
        </MenuItem>
      ))}
    </Select>
  )
}