
import React from 'react';
import { Outlet } from 'react-router-dom';
import Nav from './HeadNav';
import { styled } from '@mui/material/styles';
import { HEADER } from '../../config';
import Footer from '../../components/BorntodevFooter'
import AnnounceBar from '../../components/AnnounceBar';

export default function MainLayout() {

  const MainStyle = styled('main')(({ theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MAIN_DESKTOP_HEIGHT,
    '@media (max-width: 1200px)': {
      paddingTop: HEADER.MOBILE_HEIGHT
    }
  }))

  return (
    <>
      <Nav/>
      <AnnounceBar />
      <MainStyle>
        <Outlet/>
      </MainStyle>
      <Footer/>
    </>
  )
}
