import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  TextField,
  Typography,
  Button,
  Box,
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { styled } from '@mui/material/styles';
import axios from '../../../utils/axios';
import { useTheme } from '@mui/material/styles';
import ResultAlert from '../../../components/ResultAlert';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ContentCardStyle = styled(Card)(({ theme }) => ({
  backgroundColor: 'background.paper',
  color: 'text.primary',
  padding: '38px',
  maxWidth: '1062.38px',
  '@media (min-width: 2000px)': {
    maxWidth: '1280px',
  },
}));

export default function CourseTransfer() {
  const [page, setPage] = useState<'CONSENT' | 'EMAIL' | 'OTP' | 'MESSAGE'>(
    'CONSENT',
  );
  const [email, setEmail] = useState('');
  const [otpId, setOtpId] = useState('');
  const { isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isInitialized && !isAuthenticated)
      navigate('/login?event=transfer', { replace: true });
  }, [isInitialized]);

  return (
    <Container
      sx={{
        marginY: '125px',
        maxWidth: '800px',
      }}
    >
      <ContentCardStyle sx={{ margin: 'auto', padding: '30px' }}>
        <Typography variant={'h3'} sx={{ textAlign: 'center' }}>
          ระบบโอนย้ายคอร์สเรียนจากบัญชี Academy
        </Typography>
        <Typography variant={'subtitle1'} sx={{ marginTop: '10px' }}>
          ขั้นตอนการดำเนินการ
        </Typography>
        <li>
          กรอก email ของผู้ใช้ที่มีคอร์สเรียนในระบบ <AcademyLinkText />{' '}
          เพื่อทำการย้ายข้อมูลคอร์สเรียนที่เป็นเจ้าของแล้วเข้าสู่ระบบ borntoDev
          School หลังจากผ่านการตรวจสอบ ระบบจะทำการส่ง OTP ไปยัง email ดังกล่าว
        </li>
        <LiTypography text="หลังจากกรอก OTP ที่ตรงกันแล้วบัญชีผู้ใช้ปัจจุบันที่เข้าสู่ระบบจะได้รับคอร์สเรียนทั้งหมดที่บัญชี Academy ที่เชื่อมต่อกับ email ดังกล่าวหลังจากดำเนินการโอนย้ายข้อมูลเสร็จสิ้น" />
        <Typography variant={'subtitle1'} sx={{ marginTop: '10px' }}>
          ✅ ข้อมูลที่จะถูกย้ายมาในระบบใหม่ :
        </Typography>
        <LiTypography text="หลักสูตรทั้งหมดที่เคยลงทะเบียนไว้ใน academy.borntodev.com ทั้งรูปแบบฟรี และ พรีเมียม" />
        <Typography variant={'subtitle1'} sx={{ marginTop: '10px' }}>
          ⚠️ ข้อมูลที่จะไม่ถูกย้ายมาในระบบใหม่ :
        </Typography>
        <LiTypography text="ความคืบหน้า, การทำ Quiz ของหลักสูตรในการเรียน" />
        <LiTypography text="ใบ Certificate ในระบบ Academy (ท่านสามารถดาวน์โหลดของปัจจุบันไว้ก่อนได้)" />
        <LiTypography text="ความคิดเห็น / Comment การตอบคำถามท้ายบทเรียนระบบก่อนหน้า (หากมีการส่งการบ้านในระบบก่อนหน้าแล้ว ไม่ต้องส่งซ้ำในระบบใหม่ สามารถทำต่อจากเดิมได้เลย)" />
        <Typography variant={'subtitle1'} sx={{ marginTop: '10px' }}>
          หมายเหตุ
        </Typography>
        <Typography variant={'body1'} sx={{ color: 'error.main' }}>
          *การโอนย้ายข้อมูลสามารถทำได้เพียง 1 ครั้งต่อ 1 บัญชีทั้ง borntoDev
          Academy และ borntoDev School
        </Typography>
        {page === 'CONSENT' ? (
          <ConsentForm setPage={setPage} />
        ) : page === 'EMAIL' ? (
          <EmailForm
            setPage={setPage}
            setOtpId={setOtpId}
            email={email}
            setEmail={setEmail}
          />
        ) : page === 'OTP' ? (
          <OTPForm setPage={setPage} otpId={otpId} email={email} />
        ) : (
          <Box>
            <ResultAlert
              severity={'success'}
              title={'การโอนย้ายข้อมูลสำเร็จ'}
              description={
                'ตรวจสอบข้อมูลคอร์สเรียนที่ถูกโอนย้ายผ่าน email หรือหน้าประวัติบัญชีผู้ใช้ได้เลย!'
              }
            />
            <Box sx={{ margin: '20px 50px' }}>
              <Typography
                component={Link}
                to={'/profile'}
                sx={{ color: 'primary.main' }}
              >
                {'>> ตรวจสอบคอร์สเรียนทั้งหมดของคุณ'}
              </Typography>
            </Box>
          </Box>
        )}
      </ContentCardStyle>
    </Container>
  );
}

function LiTypography({ text }: { text: string }) {
  return (
    <Typography component="li" sx={{ marginLeft: '10px' }}>
      {text}
    </Typography>
  );
}

function ConsentForm({ setPage }: { setPage: Function }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        variant="contained"
        sx={{ color: 'text.button', marginTop: '10px' }}
        onClick={() => setPage('EMAIL')}
      >
        เข้าใจแล้วและดำเนินการต่อ
      </Button>
    </Box>
  );
}

function EmailForm({
  setPage,
  setOtpId,
  email,
  setEmail,
}: {
  setPage: Function;
  setOtpId: Function;
  email: string;
  setEmail: Function;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getEmailTransferRequest = async () => {
    try {
      const res = await axios.post('/account-transfer/request-otp', {
        email: email,
      });
      setOtpId(res.data.id);
      setLoading(false);
      setPage('OTP');
    } catch (err: any) {
      setError(err.message);
    }
  };

  const sendEmail = async () => {
    setLoading(true);
    await getEmailTransferRequest();
    setLoading(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          gap: '10px',
        }}
      >
        <TextField
          placeholder="กรอก email ระบบ Academy"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <Button
          variant="contained"
          sx={{ color: 'text.button' }}
          onClick={() => sendEmail()}
          disabled={loading}
        >
          ส่ง OTP
        </Button>
      </Box>
      <Typography
        sx={{ width: '100%', color: 'error.main', textAlign: 'center' }}
      >
        {error}
      </Typography>
    </Box>
  );
}

function OTPForm({
  setPage,
  otpId,
  email,
}: {
  setPage: Function;
  otpId: string;
  email: string;
}) {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const getOTPTransferRequest = async () => {
    try {
      const payload = {
        otpId: otpId,
        otp: otp,
        email: email,
      };
      await axios.post('/account-transfer/confirm-transfer', payload);
      setPage('MESSAGE');
    } catch (err: any) {
      setError(err.message);
    }
  };

  const sendOTP = async () => {
    setLoading(true);
    await getOTPTransferRequest();
    setLoading(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          gap: '10px',
        }}
      >
        <TextField
          placeholder="กรอก OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          disabled={loading}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => sendOTP()}
          disabled={loading}
        >
          ยืนยัน
        </Button>
      </Box>
      <Typography
        sx={{ width: '100%', color: 'error.main', textAlign: 'center' }}
      >
        {error}
      </Typography>
    </Box>
  );
}

function AcademyLinkText() {
  const theme = useTheme();
  return (
    <a
      href="https://academy.borntodev.com"
      style={{
        color: theme.palette.primary.main,
        textDecoration: 'none',
        wordBreak: 'break-all',
      }}
    >
      borntoDev Academy(https://academy.borntodev.com)
    </a>
  );
}
