import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import dayjs from 'dayjs'
import Image from './Image';
import Iconify from './Iconify';

export default function Footer() {
  return (
    <Box sx={{ backgroundColor: 'background.paper', color: 'text.primary', marginTop: '30px' }}>
      <Container>
        <Box sx={{display: 'flex', paddingY: '55px'}}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Image
                alt='facebook-contact'
                onClick={() => window.location.href = 'https://www.facebook.com/borntodev/'}
                onMouseDown={(e) => e.button === 1 && window.open('https://www.facebook.com/borntodev/')}
                src='/footer/Get-Lost-in-Stockholm-300x267.jpg'
                sx={{maxWidth:'300px',width: '100%', height: '282px', cursor: 'pointer'}}>
              </Image>
            </Grid>
            <Grid item xs={12} sm={6} md={2.65}>
              <LinkListColumn column={0}/>
            </Grid>
            <Grid item xs={12} sm={6} md={2.65}>
              <LinkListColumn column={1}/>
            </Grid>
            <Grid item xs={12} sm={6} md={2.65}>
              <LinkListColumn column={2}/>
              <Box sx={{marginBottom: '30px'}}>
                <Typography variant='subtitle1' sx={{marginBottom: '15px'}}>
                  สนใจข้อมูลเพิ่มเติมสามารถติดต่อ
                </Typography>
                <Box sx={{color: 'text.secondary', display: 'flex', alignItems: 'center', gap: '5px'}}>
                  <Iconify icon='bi:telephone-fill' height={20} width={20}/>
                  02-096-2899
                </Box>
                <Box sx={{color: 'text.secondary', display: 'flex', alignItems: 'center', gap: '5px', }}>
                  <Iconify icon='bi:line' height={20} width={20}/>
                  ID Line: <a
                    href='https://line.me/ti/p/~@borntoDev'
                    style={{textDecoration: 'none'}}>
                      <Typography sx={{ color: 'text.secondary', ':hover': {color: 'primary.main'}}}>
                        @borntoDev
                      </Typography>
                    </a>
                </Box>
                <Box sx={{color: 'text.secondary', display: 'flex', alignItems: 'center', gap: '5px', }}>
                  (จันทร์ ถึง ศุกร์ 09.30 - 18.00 น.)
                </Box>
              </Box>
              <Box sx={{marginBottom: '30px'}}>
                <Typography variant='subtitle1' sx={{marginBottom: '15px'}}>
                  ได้รับการรับรองจาก
                </Typography>
                <Image
                  alt='dbd'
                  src='/footer/bns_registered.png'
                  onClick={() =>
                    window.open(
                      'https://www.trustmarkthai.com/callbackData/popup.php?data=f1-30-5-8a1326d9daee2d7222cac4676a3287546e98d91d6e2&markID=firstmar',
                      'MsgWindow', `width=700,height=600`)
                  }
                  sx={{width:'123px',height: '54px', cursor: 'pointer'}}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{
          color:'text.secondary',
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '20px',
          '@media (max-width: 599px)': {
            display: 'block'
          }
        }} >
          <Box>
            <Typography variant="caption">
              สงวนลิขสิทธิ์ © {dayjs().year() + 543} - ข้อมูลและเนื้อหาทั้งหมด - บริษัท บอร์นทูเดฟ จำกัด
            </Typography>
            <Box>
              <a href='https://www.borntodev.com/privacy-policy/' style={{textDecoration: 'none'}}>
                <Typography
                  variant="caption"
                  sx={{color:'text.secondary', transition: 'color 0.5s', ':hover': {color: 'text.primary'}}}
                >
                  นโยบายรักษาข้อมูลส่วนบุคคล
                </Typography>
              </a>
              <a href='https://www.borntodev.com/terms-of-service/' style={{textDecoration: 'none', marginLeft: '3px'}}>
                <Typography
                  variant="caption"
                  sx={{color:'text.secondary', transition: 'color 0.5s', ':hover': {color: 'text.primary'}}}
                >
                  ข้อตกลงและเงื่อนไขการใช้บริการ
                </Typography>
              </a>
            </Box>
          </Box>
          <Box sx={{display: 'flex', gap: '15px', marginTop: '10px'}}>
            {
              iconLinks.map((each) => <IconLink key={each.title} icon={each.icon} link={each.link}/>)
            }
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

function LinkListColumn({column} : {column: number}) {
  return (
    <Box>
      {
        footerData[column].map((category) =>
          <Box key={category.title} sx={{marginBottom: '30px'}}>
            <Typography variant='subtitle1' sx={{marginBottom: '15px'}}>
              {category.title}
            </Typography>
            <>
            {
              category.rows.map((row, index) =>
                <Box key={row.title} sx={{ marginY: '8px' }}>
                  <a href={row.link} key={row.title} style={{textDecoration: 'none'}}>
                    <Box sx={{color: 'text.secondary', display: 'flex', alignItems: 'center', gap: '5px', ':hover': {color: 'primary.main'}}}>
                      <Iconify icon={row.icon} height={20} width={20}/>
                      {row.title}
                    </Box>
                  </a>
                </Box>
              )
            }
            </>
          </Box>
        )
      }
    </Box>
  )
}

function IconLink({link, icon}: {link: string, icon: string}) {
  return (
    <a href={link} style={{textDecoration: 'none'}}>
      <Iconify
        sx={{color:'text.secondary', transition: 'color 0.5s', ':hover': {color: 'text.primary'}}}
        icon={icon}
        height={21}
        width={21}
      />
    </a>
  )
}

const iconLinks = [
  {title: 'youtube', icon: 'akar-icons:youtube-fill', link: 'https://www.youtube.com/c/borntoDevTH'},
  {title: 'github', icon: 'entypo-social:github', link: 'https://github.com/BorntoDev'},
  {title: 'telephone', icon: 'bi:telephone-fill', link: 'tel:020027862'},
  {title: 'mail', icon: 'ci:mail', link: 'mailto:support@borntodev.com'},
]

const footerData = [
  [
    {
      title: 'เลือกเรียนรู้',
      rows: [
        {icon: 'akar-icons:star', title: 'คอร์สเรียนพรีเมียมออนไลน์', link: 'https://www.borntodev.com/courses-2017/'},
        {icon: 'akar-icons:youtube-fill', title: 'ความรู้ฟรีที่ YouTube', link: 'https://www.youtube.com/c/borntodevth'},
        {icon: 'fa-regular:building', title: 'หลักสูตรสำหรับองค์กร', link: 'https://www.borntodev.com/borntodev-for-business/'},
      ]
    },
    {
      title: 'สนับสนุนพวกเรา',
      rows: [
        {icon: 'bi:badge-ad-fill', title: 'ติดต่อลงโฆษณา', link: 'https://www.facebook.com/borntodev/'},
        {icon: 'fa-regular:handshake', title: 'เป็น Partner กับเรา', link: 'https://www.facebook.com/borntodev/'},
      ]
    }
  ],
  [
    {
      title: 'สำหรับภาคการศึกษา',
      rows: [
        {icon: 'fa-solid:graduation-cap', title: 'คอร์สเรียนในราคาพิเศษ', link: 'https://www.borntodev.com/edu/'},
      ]
    },
    {
      title: 'ศูนย์ให้ความช่วยเหลือ',
      rows: [
        {icon: 'fa:question-circle-o', title: 'คำถามที่พบบ่อย', link: 'https://www.borntodev.com/faq/'},
        {icon: 'fa6-regular:user', title: 'นโยบายความเป็นส่วนตัว', link: 'https://www.borntodev.com/%e0%b8%99%e0%b9%82%e0%b8%a2%e0%b8%9a%e0%b8%b2%e0%b8%a2%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b9%80%e0%b8%9b%e0%b9%87%e0%b8%99%e0%b8%aa%e0%b9%88%e0%b8%a7%e0%b8%99%e0%b8%95%e0%b8%b1%e0%b8%a7/'},
        {icon: 'bx:heart', title: 'นโยบายการคืนเงิน', link: 'https://www.borntodev.com/refund-policy/'},
      ]
    }
  ],
  [
    {
      title: 'เกี่ยวกับ BorntoDev',
      rows: [
        {icon: 'ci:id-card', title: 'borntoDev คืออะไร ?', link: 'https://www.borntodev.com/about/'},
        {icon: 'fa-solid:university', title: 'ร่วมสอนกับเรา', link: 'https://www.borntodev.com/teaching/'}
      ]
    }
  ]

]
