import React from 'react';
import { Box } from "@mui/material";

export default function PlanTitle({sx = {}}: {sx?: Object}) {
  return (
    <SplitColorText 
      sx={{...sx}}
      leftText='bornto'
      rightText='Dev+'
      leftColor='text.primary'
      rightColor='primary.main'
    />
  )
}

interface SplitColorTextProp {
  sx?: Object
  leftColor?: string;
  rightColor?: string;
  leftText: string;
  rightText: string;
}

export function SplitColorText({
  sx = {}, 
  leftColor = 'text.primary', 
  rightColor = 'primary.main', 
  leftText, rightText
}: SplitColorTextProp ) {
  return (
    <Box component={'span'} sx={{color: leftColor, ...sx}}>
      {leftText}
      <Box component={'span'} sx={{color: rightColor}}>
        {rightText}
      </Box>
    </Box>
  )
}