import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import Iconify from '../../../components/Iconify';
import { profileContactType } from '../../../@types/profile';
import Image from '../../../components/Image';
import { ContactLink } from '../profileDetail/ContactLinks';
import EditorHeader from './EditorHeader';
import axiosInstance from '../../../utils/axios';
import ChangePreview from './ChangePreview';
import { ReactSortable } from 'react-sortablejs';
import _ from 'lodash';

interface profileContactEditorType {
  id: number;
  value?: string;
  displayOrder: number;
  baseContact: {
    id: string;
    title: string;
    iconUrl: string;
    baseUrl: string;
  };
}

function profileContactEditorFormat(profileStatus: profileContactType[]) {
  return profileStatus.map((each, index) => {
    return {
      id: index + 1,
      value: each.value,
      displayOrder: each.displayOrder || index + 1,
      baseContact: {
        id: each.baseContact.id,
        title: each.baseContact.title,
        iconUrl: each.baseContact.iconUrl,
        baseUrl: each.baseContact.baseUrl,
      },
    } as profileContactEditorType;
  });
}

export default function ContactLinksEditor({
  contactsData,
}: {
  contactsData: profileContactType[];
}) {
  const [initStatus, setInitStatus] = useState(true);
  const [contacts, setContacts] = useState<profileContactEditorType[]>(
    profileContactEditorFormat(contactsData),
  );

  function onChange(id: string, value: string) {
    const editedValue = {
      ...contacts.find((each) => each.baseContact.id === id),
      value: value,
    } as profileContactEditorType;
    const editedArray = [
      ...contacts.filter((each) => each.baseContact.id !== id),
      editedValue,
    ].sort((a, b) => a.displayOrder - b.displayOrder);
    setInitStatus(false);
    setContacts(editedArray);
  }

  function getUnAddedContact() {
    return contacts.filter((each) => each.value === undefined);
  }

  async function onSave() {
    const formatted = contacts
      .map((each) => {
        return { value: each.value, baseContact: { id: each.baseContact.id } };
      })
      .filter((each) => each.value !== undefined);
    const payload = { contacts: formatted };
    await axiosInstance.put('/users/profile/contacts', payload);
    setInitStatus(true);
  }

  function addNew(id: string) {
    const newContact = contacts.find((each) => each.baseContact.id === id);
    const filtered = contacts.filter((each) => each.baseContact.id !== id);
    if (newContact) {
      const currentAdded = filtered.filter((each) => each.value !== undefined);
      //reorder
      const added = [
        ...currentAdded,
        { ...newContact, displayOrder: currentAdded.length + 1, value: '' },
      ];
      const addedLen = added.length;
      const unadded = filtered
        .filter((each) => each.value === undefined)
        .map((each, index) => {
          return { ...each, displayOrder: addedLen + index + 1 };
        });
      //compound
      const addResult = [...added, ...unadded].sort(
        (a, b) => a.displayOrder - b.displayOrder,
      );
      setContacts(addResult);
      setInitStatus(false);
    }
  }

  function onRemove(id: string) {
    //filter
    const removedContact = contacts.find((each) => each.baseContact.id === id);
    const filtered = contacts
      .filter((each) => each.baseContact.id !== id)
      .sort((a, b) => a.displayOrder - b.displayOrder);
    //reorder
    const reordered = filtered.map((each, index) => {
      return { ...each, displayOrder: index + 1 };
    });
    //addtemplate
    if (removedContact) {
      const removedId = removedContact.id;
      const removeResult = [
        ...reordered,
        {
          id: removedId,
          displayOrder: reordered.length + 1,
          baseContact: removedContact?.baseContact,
        },
      ];
      setContacts(removeResult);
      setInitStatus(false);
    }
  }

  function reorderContacts(data: profileContactEditorType[]) {
    const reorderedContacts = data.map((each, index) => ({
      ...each,
      displayOrder: index + 1,
    }));
    if (!_.isEqual(reorderedContacts, contacts)) {
      setInitStatus(false);
      setContacts(reorderedContacts);
    }
  }

  return (
    <Box sx={{ width: '95%' }}>
      <EditorHeader
        title="ช่องทางการติดต่อ"
        onSubmit={() => onSave()}
        disabled={initStatus}
      />
      <Typography variant="caption" sx={{ color: 'warning.main' }}>
        {
          '(สามารถกดปุ่มใน "ตัวอย่างการเปลี่ยนแปลง" ด้านล่างเพื่อตรวจสอบความถูกต้องของ Account Social Media ที่ต้องการแชร์)'
        }
      </Typography>
      {
        <ReactSortable list={contacts} setList={reorderContacts}>
          {contacts.map((contact, index) => (
            <ContactLinkEditor
              key={`ContactLinkEditor-${index + 1}`}
              disabled={contact.value === undefined}
              contact={contact}
              onChange={onChange}
              onRemove={onRemove}
            />
          ))}
        </ReactSortable>
      }
      {getUnAddedContact().length > 0 && (
        <AddNewContact addNew={addNew} unAddedContact={getUnAddedContact()} />
      )}
      <ChangePreview>
        {contacts.map(
          (contact, index) =>
            contact.value !== undefined && (
              <ContactLink
                key={`ContactLink-${index + 1}`}
                icon={contact.baseContact.iconUrl}
                link={contact.baseContact.baseUrl + contact.value}
                account={contact.value}
              />
            ),
        )}
      </ChangePreview>
    </Box>
  );
}

function AddNewContact({
  addNew,
  unAddedContact,
}: {
  addNew: Function;
  unAddedContact: profileContactEditorType[];
}) {
  const [selected, setSelected] = useState(
    unAddedContact[0]?.baseContact.id || '',
  );
  useEffect(() => {
    unAddedContact.length > 0 && setSelected(unAddedContact[0].baseContact.id);
  }, [unAddedContact]);

  return (
    <Box sx={{ display: 'flex', gap: '10px', marginTop: '15px' }}>
      <FormControl fullWidth>
        <InputLabel>Contact</InputLabel>
        <Select
          value={selected}
          label="Contact"
          onChange={(e) => setSelected(e.target.value)}
        >
          {unAddedContact.map((contact, index) => (
            <MenuItem
              key={`MenuItem-Contact-${index + 1}`}
              value={contact.baseContact.id}
            >
              {contact.baseContact.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="outlined"
        sx={{ padding: '5px' }}
        onClick={() => addNew(selected)}
      >
        + เพิ่ม
      </Button>
    </Box>
  );
}

function ContactLinkEditor({
  contact,
  onChange,
  onRemove,
  disabled,
}: {
  contact: profileContactEditorType;
  onChange: Function;
  onRemove: Function;
  disabled: boolean;
}) {
  if (disabled) return <></>;
  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton
        sx={{ position: 'absolute', top: -13, right: -13 }}
        onClick={() => onRemove(contact.baseContact.id)}
      >
        <Iconify icon={'mdi:close-circle'} sx={{ color: 'error.main' }} />
      </IconButton>
      <Card
        sx={{
          marginY: '15px',
          padding: '10px',
          backgroundColor: 'background.default',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            marginBottom: '5px',
            color: 'text.primary',
            width: '100%',
            justifyContent: 'start',
            cursor: 'grab',
          }}
        >
          <Image
            src={contact.baseContact.iconUrl}
            sx={{ width: '30px', height: '30px' }}
          />
          <Typography variant={'subtitle2'} sx={{ wordBreak: 'break-all' }}>
            {contact.baseContact.title}
          </Typography>
        </Box>
        <TextField
          placeholder="ชือบัญชี"
          sx={{ '.MuiInputBase-input': { padding: '10px' }, width: '100%' }}
          value={contact.value}
          onChange={(e) => onChange(contact.baseContact.id, e.target.value)}
        />
      </Card>
    </Box>
  );
}
