import React from 'react';
import { Box, Typography, Button, Grid, TextField, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
import { questionType, quizAnswerType, questionChoiceType }  from '../../../@types/quiz';
import RichViewer from '../../../components/editor/RichViewer';

export default function Question({question, answer, chooseAnswer, writeAnswer, index, reviewMode}:
  { question: questionType,
    answer?: quizAnswerType,
    chooseAnswer: (choiceId: string, questionId: string) => void,
    writeAnswer: (keyword: string, questionId: string) => void,
    index: number,
    reviewMode?: boolean
  })
  {
  return (
    <Box>
      <Typography variant="h5" key={`index-${question.id}`} sx={{marginTop: '10px'}}>ข้อที่ {index+1}</Typography>
      <RichViewer sx={{marginY: '20px', userSelect: 'none'}} content={question.text}/>
      { answer &&
        <QuestionAnswers
          answerType={question.answerType}
          displayType={question.displayType}
          choices={question.questionChoices}
          chooseAnswer={chooseAnswer}
          writeAnswer={writeAnswer}
          answer={answer}
          reviewMode={reviewMode}
        />
      }
      { reviewMode && <Divider sx={{marginTop: '20px', }}/>  }
    </Box>
  )
}

function QuestionAnswers({answerType, displayType, choices, chooseAnswer, writeAnswer, answer, reviewMode}:
  { answerType: string,
    displayType: string,
    choices: questionChoiceType[],
    chooseAnswer: (choiceId: string, questionId: string) => void,
    writeAnswer: (keyword: string, questionId: string) => void,
    answer: quizAnswerType,
    reviewMode?: boolean
  }) {
    return (
      <>
        { answerType === 'MULTIPLE' &&
            <Box sx={{display: 'flex', marginY: '10px', textDecoration: 'underline'}}>
              <Typography>
                (เลือกได้มากกว่า 1 คำตอบ)
              </Typography>
              <Typography sx={{color: 'error.main'}}>
                *
              </Typography>
            </Box>
        }
        <Grid container spacing={1}>
          {answerType === 'KEYWORD' ? <QuestionKeyword writeAnswer={writeAnswer} answer={answer} reviewMode={reviewMode}/> :
          choices.map((choice) => <QuestionChoice key={choice.id} choice={choice} displayType={displayType} chooseAnswer={chooseAnswer} answer={answer} reviewMode={reviewMode}/>)}
        </Grid>
      </>
    )
}

function QuestionKeyword({writeAnswer, answer, reviewMode}:
{writeAnswer: (keyword: string, questionId: string) => void, answer: quizAnswerType, reviewMode?: boolean}) {
  return (
    <AnswerKeywordStyle
      value={answer.answers[0].text}
      onChange={(e) => writeAnswer(e.target.value, answer.questionId)}
      id="outlined-basic"
      label="กรอกคำตอบของคุณ"
      variant="outlined"
      disabled={reviewMode}
      fullWidth multiline
    />
  )
}

function QuestionChoice({choice, displayType, chooseAnswer, answer, reviewMode} :
  { choice: questionChoiceType,
    displayType: string,
    chooseAnswer: (choiceId: string, questionId: string) => void,
    answer: quizAnswerType,
    reviewMode?: boolean
  }) {
    const isSelected = answer.answers.find(each => each.choiceId === choice.id)
    const amountDisplayChoiceInLine = { "CODE": 12, "IMAGE": 2.4, "TEXT": 6 }
    const ChoiceRender = {
      "TEXT": <ChoiceTextContent text={choice.text}/>,
      "IMAGE": <ChoiceImageContent text={choice.text} image={choice.image}/>,
      "CODE": <ChoiceCodeContent text={choice.text} code={choice.code}/>,
    }

    return (
      <Grid item xs={12} sm={amountDisplayChoiceInLine[displayType as keyof typeof amountDisplayChoiceInLine]}>
        <ChoiceButtonStyle
          sx={reviewMode ?
            { cursor: "context-menu",
              boxShadow: 'none',
              ':hover': {
                color: 'text.primary',
                backgroundColor: 'background.default',
                boxShadow: 'none'}
            } : {}}
          disabled={reviewMode && !isSelected}
          variant='contained'
          onClick={() => chooseAnswer(choice.id, answer.questionId)}
        >
          {isSelected && <SelectedChoice/>}
          {ChoiceRender[displayType as keyof typeof ChoiceRender]}
        </ChoiceButtonStyle>
      </Grid>
    )
}

function ChoiceTextContent({text}: {text: string}) {
  return (
    <Box sx={{height: '100%'}}>
      <Typography variant="h5" sx={{wordBreak: 'break-word'}}>
        {text}
      </Typography>
    </Box>
  )
}

function ChoiceCodeContent({text, code}: {text: string, code: string}) {
  return (
    <>
      <Typography variant="h5" sx={{wordBreak: 'break-word'}}>
        {text}
      </Typography>
      <Box sx={{width:'100%', marginTop: '10px'}}>
        <RichViewer sx={{padding: '10px', borderRadius: '8px'}} content={code} />
      </Box>
    </>
  )
}

function ChoiceImageContent({text, image}: {text: string, image: string}) {
  return (
      <Box sx={{width: '100%', height: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
        <Image
            disabledEffect
            visibleByDefault
            alt="choice-image"
            effect="blur"
            src={image}
            sx={{ maxWidth: 80, maxHeight: 80, minWidth: 80, minHeight: 80, marginTop: '40px', borderRadius: '10px' }}
        />
        <Typography variant="subtitle2" sx={{wordBreak: 'break-word', textAlign: 'center', marginTop: '20px'}}>
            {text}
        </Typography>
      </Box>
  )
}

function SelectedChoice() {
  return (
    <Box sx={{position: 'absolute', right: 10, top: 10}}>
      <Iconify sx={{color: 'primary.light'}} icon='akar-icons:check-box-fill' width={20} height={20}/>
    </Box>
  )
}

const ChoiceButtonStyle = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '16px',
  padding: '25px',
  textAlign: 'left',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.default,
  alignItems: "flex-start",
  flexDirection: "column",
  textTransform: 'none'
}));

const AnswerKeywordStyle = styled(TextField)(({ theme }) => ({
  marginTop: '20px',
  marginLeft: '8px',
  '& label.Mui-focused': {
      color: theme.palette.text.secondary,
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.text.primary,
    '& fieldset': {
      borderColor: theme.palette.text.secondary,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.text.secondary,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.text.secondary,
    },
  },
}));



