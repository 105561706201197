import React from 'react'
import { Stack } from '@mui/material'

import { devlabTaskType } from '../../../../../@types/lesson'
import TaskCard from './TaskCard'

interface DevlabTaskProps {
  taskList?: devlabTaskType[]
}

export default function DevlabTask({ taskList }: DevlabTaskProps) {
  return (
    <Stack
        direction="column" 
        gap="5px"
        sx={{ 
          backgroundColor: 'background.paper', 
          borderRadius: '8px', 
          paddingY: '40px', 
          paddingX: '20px', 
          marginBottom: '20px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'
        }}
      >
        {taskList && taskList?.map((item: devlabTaskType, index: number) => (
          <TaskCard 
            key={`devlab-task-${index}`}
            title={item?.devlabDetail?.devlabName}
            level={item?.devlabDetail?.devlabLevel}
            taskId={String(item?.id)}
            author={item?.devlabDetail?.devlabUser}
            status={item?.userStatus}
            passDate={item?.passedTime}
          />
        ))}
      </Stack>
  )
}
