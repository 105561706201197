
import React, { useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import Profile from './Profile'
import TabsMenu from './TabsMenu'
import { NAVBAR } from '../../../config'
import { Drawer, Box } from '@mui/material'
import { useDispatch } from '../../../redux/store'
import { getCourseLessons, setInitialCourseData } from '../../../redux/slices/lesson'
import { LessonParamsType } from '../../..//@types/params'
import useResponsive from '../../../hooks/useResponsive'
import HeadNav from './HeadNav'
import HeadNavMobile from './HeadNavMobile'
import CourseProgress from '../../../components/course/CourseProgress'
import Iconify from '../../../components/Iconify'

export default function Navs() {
  const [openNav, setOpenNav] = useState(false)
  const params = useParams()
  useEffect(() => {
    setOpenNav(false)
  }, [params])

  return (
    <>
      <HeadNav/>
      <HeadNavMobile setOpenNav={setOpenNav}/>
      <LessonNav openNav={openNav} setOpenNav={setOpenNav}/>
    </>
  )
}

function LessonNav({openNav, setOpenNav} : {openNav: boolean, setOpenNav : Function}) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { courseSlug } = useParams<LessonParamsType>();

  useEffect(() => {
    // @ts-ignore redux type error
    dispatch(getCourseLessons(courseSlug || ''))
    // @ts-ignore redux type error
    return () => dispatch(setInitialCourseData()) as void
  }, [courseSlug])

  return (
    <LessonNavStyle
      variant={useResponsive('up', 1200) ? 'permanent' :'temporary'}
      anchor="right"
      open={openNav}
      onClose={() => setOpenNav(false)}
    >
      <Box sx={{textAlign: 'right'}}>
        <Iconify
          icon='dashicons:menu-alt3'
          sx={{
            width: '24px',
            height: '24px',
            margin: '15px',
            cursor: 'pointer',
            textAlign: 'right',
            ':hover' : {color: 'primary.main'},
            '@media (min-width: 1200px)': {
              display: 'none'
            },
          }}
          onClick={() => setOpenNav(false)}
        />
      </Box>
      <Profile/>
      <Box
        sx={{
          border: `2px solid ${theme.palette.text.secondary}`,
          padding: '10px',
          marginTop: '10px',
          borderRadius: '8px'
        }}
      >
        <CourseProgress/>
      </Box>
      <TabsMenu/>
    </LessonNavStyle>
  )
}

const LessonNavStyle = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper' : {
    maxWidth: NAVBAR.DASHBOARD_WIDTH,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'none',
    padding: '1rem 0.5rem 1rem 1rem',
    borderLeft: '0px',
    marginRight: '5px'
  },
  '*::-webkit-scrollbar': {
      width: '0.5em'
    },
    '*::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'slategrey',
      borderRadius: '8px'
    }
}));
