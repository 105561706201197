import React from 'react';
import { Box, Typography, Button, Step, StepLabel, StepConnector, Stepper } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CircleScoreChart from '../../../components/charts/CircleScoreChart';
import AveragePointScoreChart from '../../../components/charts/AveragePointScoreChart';
import ResultAlert from '../../../components/ResultAlert'
import { ProjectSubmissionType } from '../../../@types/projectSubmission'
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from '@mui/material';
import Iconify from '../../../components/Iconify'
import { ProjectSubmissionChecklistType } from '../../../@types/projectSubmission'
import CertificateButton from '../../../components/course/CertificateButton'
import Form from '../Form'

export default function CourseResult({ projectSubmissionData, courseLessons }: { projectSubmissionData: ProjectSubmissionType, courseLessons: any }) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '@media (max-width: 699px)': {
            display: 'block',
          }
        }}
      >
        <Typography variant="h5">สรุปผลการเรียนทั้งหมด</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'end',
            '@media (max-width: 499px)': {
              flexDirection: 'column',
            }
          }}
        >
          <Button
            variant={projectSubmissionData.submissionData?.status !== 'APPROVED' ? "contained" : "outlined"}
            sx={{ borderRadius: '8px', minWidth: 'auto', whiteSpace: 'nowrap', maxHeight: '36px' }}
            disabled={projectSubmissionData.submissionData?.status !== 'APPROVED'}
            color="tertiary"
            href={`/devproject/${projectSubmissionData.submissionData.id}`}
          >
            <Iconify icon="ic:round-folder-shared" sx={{ width: '20px', height: '20px', marginRight: '5px' }} />
            ดูผลงานของคุณ
          </Button>
          <CertificateButton
            completedCourse={projectSubmissionData.submissionData?.status === 'APPROVED'}
            certificateId={projectSubmissionData.courseProgress.certificateId}
            courseId={courseLessons.id}
            courseName={courseLessons.title} />
        </Box>
      </Box>

      <ProjectResultAlert
        projectStatus={projectSubmissionData.submissionData?.status || "NO_PROJECT"}
        remainAttempt={projectSubmissionData.project?.remainAttempt}
        maxAttempt={projectSubmissionData.project?.maxAttempt}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '@media (max-width: 899px)': {
            flexDirection: 'column',
            justifyContent: 'center'
          }
        }}
      >
        {
          projectSubmissionData.courseProgress.quizAmount > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                '@media (max-width: 899px)': {
                  flexDirection: 'column',
                  justifyContent: 'center'
                }
              }}
            >
              <CircleScoreChart
                passed={true}
                score={projectSubmissionData.courseProgress.quizSumScore}
                maxScore={projectSubmissionData.courseProgress.quizMaxScore}
              />
              {
                projectSubmissionData.courseUsersScoreSummary !== null &&
                projectSubmissionData.courseUsersScoreSummary?.averageScore ?
                <AveragePointScoreChart
                  averageScore={projectSubmissionData.courseUsersScoreSummary.averageScore}
                  score={projectSubmissionData.courseProgress.quizSumScore}
                  counts={projectSubmissionData.courseUsersScoreSummary.counts}
                  passed={true}
                  label={'คะแนนเฉลี่ยของผู้เรียนทั้งหมด'}
                />
                :
                <></>
              }
            </Box>
          )
        }
        <ProjectStatus projectStatus={projectSubmissionData.submissionData.status} />
      </Box>

      <ProjectChecklist checklists={projectSubmissionData.submissionData.checklists} projectStatus={projectSubmissionData.submissionData.status} comment={projectSubmissionData.submissionData.comment || ''}/>
      {projectSubmissionData.submissionData?.status === "REJECTED" && <Form projectId={projectSubmissionData.project.id} remainAttempt={projectSubmissionData.project.remainAttempt} submissionData={projectSubmissionData.submissionData} />}
    </Box>
  )
}

function ProjectResultAlert({ projectStatus, remainAttempt, maxAttempt }: { projectStatus: "APPROVED" | "PENDING" | "REJECTED" , remainAttempt?: number, maxAttempt?: number }) {
  const alertText = {
    "APPROVED": {
      severity: "success",
      title: 'ขอแสดงความยินดี ! คุณเรียนจบหลักสูตรของเราเรียบร้อย',
      description: 'นี่คือก้าวแรกของความสำเร็จของคุณ อย่าลืมพัฒนาทักษะต่อไป เพื่อให้คุณนั้นแข็งแกร่งยิ่งขึ้น'
    },
    "PENDING": {
      severity: "info",
      title: `โปรดรอซักหน่อย ¬ ตอนนี้โปรเจกต์ของคุณกำลังอยู่ในคิวการตรวจสอบ`,
      description: 'ทางเราได้ประมวลผลคะแนนอื่น ๆ ระหว่างเรียนของคุณเรียบร้อย สามารถดูระหว่างทางได้เลยนะ !'
    },
    "REJECTED": {
      severity: "error",
      title: `ขอแสดงความเสียใจ คุณได้คะแนนต่ำกว่าเกณฑ์ ทำได้อีก ${remainAttempt}/${maxAttempt} ครั้ง`,
      description: 'ผลการทดสอบของคุณอยู่ในเกณฑ์ไม่ผ่าน เราขอเป็นกำลังใจให้คุณพยายามต่อไป !'
    }
  }
  return (
    <ResultAlert
      severity={alertText[projectStatus].severity as "success" | "info" | "error"}
      title={alertText[projectStatus].title}
      description={alertText[projectStatus].description}
    />
  )
}

function ProjectStatus({ projectStatus }: { projectStatus: "APPROVED" | "PENDING" | "REJECTED" }) {
  return (
    <Box sx={{ maxWidth: '425px', margin: '10px auto' }}>
      <Typography
        variant="subtitle1"
        sx={{ color: 'text.secondary', marginY: '15px', textAlign: 'center' }}
      >
        ตอนนี้ท่านได้ทำแบบทดสอบไปแล้ว
      </Typography>
      <Stepper alternativeLabel connector={<QuizConnector />}>
        <Step completed={true} active={false}>
          <StepLabel StepIconComponent={QuizStepper}><Typography variant='body2' sx={{ color: 'text.secondary' }}>
            บริษัทได้รับข้อมูล ของผู้เรียน</Typography>
          </StepLabel>
        </Step>
        <Step completed={true} active={false}>
          <StepLabel StepIconComponent={QuizStepper}><Typography variant='body2' sx={{ color: 'text.secondary' }}>
            บริษัทกำลังดำเนินการ ตรวจสอบโปรเจกต์</Typography>
          </StepLabel>
        </Step>
        <Step completed={projectStatus !== 'PENDING'}>
          <StepLabel StepIconComponent={QuizStepper}><Typography variant='body2' sx={{ color: 'text.secondary' }}>
            ดำเนินการตรวจสอบ เสร็จสิ้น</Typography>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  )
}

function QuizStepper(props: StepIconProps) {
  const theme = useTheme()
  const { active, completed } = props;
  const icon = completed ? 'akar-icons:check' : active ? 'gridicons:cross' : 'ci:dot-03-m'
  const color = completed ? theme.palette.success.main : active ? theme.palette.error.main : theme.palette.grey[400]
  return (
    <Iconify sx={{ color: color, marginTop: '5px' }} icon={icon}></Iconify>
  );
}

const QuizConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.error.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderRadius: 1
  }
}));

function ProjectChecklist({ checklists, projectStatus, comment }: { checklists: ProjectSubmissionChecklistType[], projectStatus: "APPROVED" | "PENDING" | "REJECTED", comment: string }) {
  const theme = useTheme()
  return (
    <Box sx={{ boxShadow: `0px 8px 16px 0px ${theme.palette.background.neutral}`, padding: '10px 20px', borderRadius: '8px' }}>
      <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>ผลการตรวจโปรเจกต์ของคุณ</Typography>
      {
        projectStatus === "PENDING" ?
          <Typography variant='body1' sx={{ color: 'text.secondary', marginBottom: '10px' }}>
            ขณะนี้ยังไม่สามารถแสดงผลได้ เนื่องจากอยู่ในระหว่างการตรวจสอบโปรเจกต์ ซึ่งกระบวนการนี้อาจใช้ระยะเวลา 7 - 14 วัน กรณีมีผู้เรียนส่งข้อมูลเข้ามาจำนวนมาก
          </Typography>
          :
          <>
            <Typography variant='body1' sx={{ color: 'text.secondary', marginBottom: '10px'}}>
              {comment}
            </Typography>
            {checklists.map((item: ProjectSubmissionChecklistType) =>
              <>
                <Typography variant='body1' sx={{ color: item.passed ? '#27AE60' : '#E02D69' }}>
                  {item.passed ? '✓' : '✗'} {item.description}
                </Typography>
                <Typography variant='caption' sx={{ color: 'text.secondary', marginLeft: '19px' }}>
                  {item.comment}
                </Typography>
              </>
            )}
          </>
      }
    </Box>
  )
}
