import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';

import {
  CommentDetailType,
  CommentQuestionStatus,
} from '../../@types/comments';
import useAuth from '../../hooks/useAuth';
import { dispatch, useSelector } from '../../redux/store';
import axiosInstance from '../../utils/axios';

import EditorBodyStyle from '../../components/editor/EditorBody';
// -> Elements from Components
import Icon from '../Iconify';
import Image from '../Image';
import ImageModal from '../modal/ImageModal';

import {
  ProfileDetail,
  ProfileDetailTooltip,
} from '../../pages/profile/ProfileDetailTooltip';
import {
  removeCommentQuestionQueqeById,
  setLastCommentUpdated,
} from '../../redux/slices/comment';
import Iconify from '../Iconify';
import GeneralLink from '../link/General';
import TextLink from '../link/Text';
import CommentEditor from './CommentEditor';
import ModalDeleteComment from './modal/ModalDeleteComment';
// -> Element from Comments Components
import ModalReplyComment from './modal/ModalReplyComment';

interface CommentFieldProps {
  focus: string;
}

interface CommentWrapperProps {
  commentType: 'TALK' | 'QUESTION' | 'CLOSED' | 'ANSWER';
  isRead: boolean;
  children?: JSX.Element;
  isSelf: boolean;
  isCorrect?: boolean;
  firstChildIsCorrect?: boolean;
}

interface CommentDetailProps {
  data: CommentDetailType;
  setData: Function;
  disableReplyButton: boolean;
  parentCommentId?: string;
  parantCommentType: 'TALK' | 'QUESTION' | 'CLOSED' | 'ANSWER';
  courseId?: string;
  lessonId?: string;
  triggerstate: boolean;
  triggerFetchFunction: Function;
  selected: boolean;
}

interface CommentCardProps {
  commentData: CommentDetailType;
  childCommentCount: number;
  courseId: string;
  lessonId: string;
  triggerstate: boolean;
  triggerFetchFunction: Function;
  selected?: boolean;
}

const commentConfig = {
  answer: {
    color: '#B45CB4',
    title: 'กำลังรอตรวจ',
  },
  questionn: {
    color: 'info.main',
    title: 'คำถาม',
  },
};

export const CommentField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'focus',
})<CommentFieldProps>(({ focus, theme }) => ({
  '&.MuiTextField-root': {
    'textarea, fieldset': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.grey[400],
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor:
        focus === 'focus'
          ? theme.palette.primary.main
          : focus === 'error'
            ? theme.palette.error.main
            : theme.palette.grey[400],
    },
  },
}));

export const NameInputField = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    input: {
      color: theme.palette.grey[500],
    },
    fieldset: {
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[500_24],
    },
  },
  height: '56px',
  marginTop: '24px',
}));

const commentLabel = ['QUESTION', 'CLOSED'];

function CommentWrapper({
  commentType,
  children,
  isCorrect,
  firstChildIsCorrect,
}: CommentWrapperProps) {
  const isShowAnswerLabel =
    commentType === 'ANSWER' && !isCorrect && !firstChildIsCorrect;
  const isShowQuestionLabel = commentLabel.includes(commentType);

  const borderMarginTop = () => {
    const defaultMargin = 60;
    const addlabelTop = isShowAnswerLabel || isShowQuestionLabel ? 45 : 0;
    const addCorrectTop = isCorrect ? 55 : 0;
    return defaultMargin + addlabelTop + addCorrectTop;
  };

  return (
    <Box sx={{ marginBottom: '20px', position: 'relative' }}>
      {isShowAnswerLabel || isShowQuestionLabel ? (
        <>
          <Box textAlign="left" sx={{ margin: '0px 0px 15px 75px' }}>
            <Stack direction="row" sx={{ gap: '5px' }}>
              <Box
                sx={{
                  width: 'fit-content',
                  padding: '2px 7px',
                  borderRadius: '3px',
                  backgroundColor:
                    commentType === 'ANSWER'
                      ? commentConfig.answer.color
                      : commentConfig.questionn.color,
                }}
              >
                <Typography>
                  {commentType === 'ANSWER'
                    ? commentConfig.answer.title
                    : commentConfig.questionn.title}
                </Typography>
              </Box>

              {commentType === 'CLOSED' && (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    gap: '5px',
                    width: 'fit-content',
                    padding: '2px 7px',
                    borderRadius: '8px',
                    backgroundColor: 'success.dark',
                  }}
                >
                  <Icon
                    icon="material-symbols:check-circle-outline-rounded"
                    sx={{ width: '15px', height: '15px' }}
                  />
                  <Typography variant="body2">ตอบเรียบร้อยเเล้ว</Typography>
                </Stack>
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              height: '30px',
              width: '100%',
              top: 0,
              padding: '14px 0px 0px 24px',
              zIndex: 0,
            }}
          >
            <Box
              sx={{
                zIndex: 0,
                border: 'rgba(145, 158, 171, 0.24) solid 1px',
                borderWidth: '1px 0px 0px 1px',
                borderRadius: '10px 0px 0px 0px',
                height: '100%',
                width: '100%',
              }}
            />
          </Box>
        </>
      ) : (
        <></>
      )}
      {children}
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '35px',
          top: 0,
          padding: `${borderMarginTop()}px 0px 0px 24px`,
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            zIndex: 0,
            border: 'rgba(145, 158, 171, 0.24) solid 1px',
            borderWidth: '0px 0px 1px 1px',
            borderRadius: '0px 0px 0px 10px',
            height: '100%',
            width: '100%',
          }}
        />
      </Box>
      <Divider sx={{ margin: '10px 0px 0px 35px' }} />
    </Box>
  );
}

function CommentDetail({
  data,
  setData,
  disableReplyButton,
  parantCommentType,
  courseId,
  lessonId,
  selected,
}: CommentDetailProps) {
  const { user } = useAuth();
  const theme = useTheme();

  const { courseLessons } = useSelector((state) => state.lesson);
  const buttonGroupMediaMatched = useMediaQuery('(min-width:950px)');
  const nameMediaMatched = useMediaQuery('(min-width:600px)');

  const [openTextFieldReply, setOpenTextFieldReply] = useState<boolean>(false);
  const [openTextFieldEdit, setOpenTextFieldEdit] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalReply, setOpenModalReply] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<string>('');
  const [showFullText, setShowFullText] = useState<boolean>(true);

  const [editError, setEditError] = useState<boolean>(false);
  const [replyError, setReplyError] = useState<boolean>(false);

  const cardRef = useRef<HTMLDivElement>(null);
  const [editorHeight, setEditorHeight] = useState(0);
  const [bgComment, setBGComment] = useState('background.neutral');

  const { lastCommentUpdated } = useSelector((state) => state.comment);

  useEffect(() => {
    if (lastCommentUpdated?.id === data.id) {
      setData(lastCommentUpdated, 'UPDATED');
      dispatch(setLastCommentUpdated(null));
    }
  }, [lastCommentUpdated]);

  const contentStyle = {
    deleted: {
      fontStyle: 'italic',
      color: theme.palette.text.disabled,
      borderRadius: '10px',
    },
    default: {
      borderRadius: '10px',
    },
  };

  const handleText = (text: string): string => {
    try {
      if (JSON.parse(text) > 0) {
        return text;
      }
      return JSON.parse(text);
    } catch {
      return text;
    }
  };

  const onUpdateComment = async (content: any) => {
    try {
      if (content.get('text') !== '') {
        const response = await axiosInstance.put(
          `/comments/${data.id}`,
          content,
        );
        const responseData = await response.data;
        setData({ ...data, ...responseData }, 'EDITED');

        setOpenTextFieldEdit(false);
      } else {
        setEditError(true);
      }
    } catch {
      return;
    }
  };

  const onReplyComment = async (content: any) => {
    try {
      if (content !== '') {
        const reponse = await axiosInstance.post(
          `/comments?courseId=${courseId}&lessonId=${lessonId}`,
          content,
        );
        const reponseData = await reponse.data;
        setData(reponseData, 'CREATED');
        setOpenTextFieldReply(false);
      } else {
        setReplyError(true);
      }
    } catch {
      setOpenModalReply(true);
    }
  };

  const onDeleteComment = async () => {
    try {
      await axiosInstance.delete(`comments/${data.id}`);
      setData(
        { ...data, text: 'ข้อความถูกลบไปแล้ว', status: 'DELETED' },
        'EDITED',
      );
      setOpenModalDelete(false);
    } catch {
      return;
    }
  };

  function isEnrolled() {
    return courseLessons.enrolled;
  }

  useEffect(() => {
    const refs = cardRef.current;
    if (refs && editorHeight === 0) {
      setEditorHeight(refs.clientHeight);

      if (refs.clientHeight >= 140) setShowFullText(false);
      else setShowFullText(true);
    }
  }, [cardRef, editorHeight]);

  useEffect(() => {
    if (editorHeight !== 0) setEditorHeight(0);
  }, [data]);

  function blinkBGComment() {
    const light = 'background.neutral';
    const dark = 'background.paper';
    const interval = 200;
    setBGComment(dark);
    setTimeout(() => setBGComment(light), interval * 2);
    setTimeout(() => setBGComment(dark), interval * 3);
    setTimeout(() => setBGComment(light), interval * 4);
  }

  const isWaitQuestion =
    data.commentQuestion?.status === 'WAIT_BOT_ANSWER' ||
    data.commentQuestion?.status === 'WAIT_USER_DECISION';

  return (
    <Stack direction="row" sx={{ width: '100%', color: 'text.primary' }}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ marginRight: '10px' }}
      >
        <>
          {data?.user.role === 'STUDENT' ? (
            <GeneralLink
              link={
                data.userId && data.user.role === 'STUDENT'
                  ? `/profile/${data.userId}`
                  : ''
              }
            >
              <ProfileDetailTooltip
                title={ProfileDetail(data?.user)}
                placement="top-start"
              >
                <Box>
                  <Avatar
                    sx={{ width: '48px', height: '48px', cursor: 'pointer' }}
                    alt={data?.user?.publicName}
                    src={data?.user?.imageUrl}
                  />
                </Box>
              </ProfileDetailTooltip>
            </GeneralLink>
          ) : (
            <Avatar
              sx={{ width: '48px', height: '48px' }}
              alt={data?.user?.publicName}
              src={data?.user?.imageUrl}
            />
          )}
        </>

        {data.isCorrect && (
          <Stack direction="column" alignItems="center">
            <Icon
              icon="material-symbols:check-circle-outline-rounded"
              sx={{
                color: 'success.main',
                fontSize: '35px',
                marginTop: '10px',
              }}
            />
            <Typography variant="caption" sx={{ color: 'success.main' }}>
              {parantCommentType === 'ANSWER' ? 'ถูกต้อง' : 'คำตอบ'}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Box sx={{ width: '100%' }}>
        {data.user.role !== 'STUDENT' && (
          <Typography
            variant="caption"
            sx={{
              padding: '2px 6px',
              backgroundColor: data.isBot
                ? 'tertiary.main'
                : data.user.role === 'ADMIN'
                  ? 'primary.main'
                  : 'secondary.light',
              borderRadius: '5px',
              color: 'common.black',
            }}
          >
            {data.isBot ? 'BOT' : data.user.role}
          </Typography>
        )}

        <Stack
          direction={buttonGroupMediaMatched ? 'row' : 'column'}
          justifyContent="space-between"
          sx={{ marginTop: '5px' }}
        >
          <Box>
            <Stack
              direction={nameMediaMatched ? 'row' : 'column'}
              alignItems={nameMediaMatched ? 'center' : 'start'}
              sx={{ gap: '8px' }}
            >
              <>
                {data?.user?.role === 'STUDENT' ? (
                  <ProfileDetailTooltip
                    title={ProfileDetail(data?.user)}
                    placement="top-start"
                  >
                    <Box>
                      <TextLink
                        link={
                          data.userId && data.user.role === 'STUDENT'
                            ? `/profile/${data.userId}`
                            : ''
                        }
                        text={`${data.user.publicName}`}
                        typographyVariant="subtitle1"
                        sx={{ cursor: 'pointer' }}
                      />
                    </Box>
                  </ProfileDetailTooltip>
                ) : (
                  <TextLink
                    link={''}
                    text={`${data.user.publicName}`}
                    typographyVariant="subtitle1"
                  />
                )}
              </>
              {data?.userId === user?.id && (
                <Typography variant="subtitle1" sx={{ color: 'primary.dark' }}>
                  ความคิดเห็นของคุณ
                </Typography>
              )}
            </Stack>

            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {String(dayjs(data.createdAt).format('DD MMM YYYY (HH:mm)'))}
            </Typography>
          </Box>

          {isEnrolled() && (
            <Box>
              <Grid container item xs={12} spacing={1} lg="auto">
                <Grid item lg="auto">
                  {data?.userId === user?.id &&
                    data.status !== 'DELETED' &&
                    !data.isCorrect && (
                      <Typography
                        variant="button"
                        sx={{
                          color: 'grey.600',
                          cursor: 'pointer',
                          ':hover': { color: 'grey.700' },
                        }}
                        onClick={() => setOpenTextFieldEdit(!openTextFieldEdit)}
                      >
                        Edit
                      </Typography>
                    )}
                </Grid>

                <Grid item lg="auto">
                  {data?.userId === user?.id &&
                    data.status !== 'DELETED' &&
                    !data.isCorrect && (
                      <Typography
                        variant="button"
                        sx={{
                          color: 'error.main',
                          cursor: 'pointer',
                          ':hover': { color: 'error.dark' },
                        }}
                        onClick={() => setOpenModalDelete(true)}
                      >
                        Delete
                      </Typography>
                    )}
                </Grid>

                <Grid item lg="auto">
                  <Box sx={{ width: '40px' }}>
                    {data.type !== 'CLOSED' &&
                      !disableReplyButton &&
                      !isWaitQuestion && (
                        <Typography
                          variant="button"
                          sx={{
                            color: 'primary.main',
                            ':hover': { color: 'primary.dark' },
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            setOpenTextFieldReply(!openTextFieldReply)
                          }
                        >
                          Reply
                        </Typography>
                      )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Stack>

        <Box sx={{ marginTop: '10px' }}>
          {!openTextFieldEdit ? (
            <>
              <span id={`comment-${data.id}${selected ? '-selected' : ''}`} />
              <Box
                sx={{
                  backgroundColor: bgComment,
                  borderRadius: '10px',
                  transition: 'all 0.75s',
                }}
              >
                <EditorBodyStyle
                  read
                  showText={showFullText}
                  ref={cardRef}
                  noBackgroundColor
                >
                  <ReactQuill
                    placeholder="แสดงความคิดเห็น...."
                    value={handleText(data.text)}
                    readOnly={true}
                    modules={{ toolbar: false, syntax: true }}
                    theme={'snow'}
                    style={
                      data.status === 'DELETED'
                        ? contentStyle.deleted
                        : contentStyle.default
                    }
                  />
                </EditorBodyStyle>
              </Box>

              {data.status !== 'DELETED' && editorHeight >= 140 && (
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      cursor: 'pointer',
                      width: 'fit-content',
                      marginTop: '8px',
                      ':hover': { color: 'text.secondary' },
                    }}
                    onClick={() => {
                      setShowFullText(!showFullText);
                      if (!showFullText) return;
                      blinkBGComment();
                      const element = document.getElementById(
                        `comment-${data.id}${selected ? '-selected' : ''}`,
                      );
                      if (element) {
                        element.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        });
                      }
                    }}
                  >
                    {showFullText ? (
                      <>
                        แสดงน้อยลง
                        <Iconify icon="dashicons:arrow-up-alt2" />
                      </>
                    ) : (
                      <>
                        แสดงทั้งหมด
                        <Iconify icon="dashicons:arrow-down-alt2" />
                      </>
                    )}
                  </Typography>
                </Box>
              )}

              <Box sx={{ marginTop: '20px' }}>
                {data.status !== 'DELETED' && (
                  <Stack direction="row" sx={{ gap: '8px', flexWrap: 'wrap' }}>
                    {[...data.commentImages]
                      .sort((a, b) => a.displayOrder - b.displayOrder)
                      .map((image, index: number) => (
                        <Image
                          key={`image-comment-section-${index}`}
                          src={image.path}
                          sx={{
                            width: '150px',
                            height: '150px',
                            borderRadius: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => setShowImage(image.path)}
                        />
                      ))}
                  </Stack>
                )}
              </Box>
            </>
          ) : (
            <CommentEditor
              error={editError}
              onError={() => setEditError(false)}
              data={data}
              typeEditor="edit"
              open={openTextFieldEdit}
              submitButton="เเก้ไข"
              onCancel={() => setOpenTextFieldEdit(false)}
              onSubmit={(content: any) => onUpdateComment(content)}
            />
          )}

          {openTextFieldReply && (
            <Box sx={{ marginTop: '30px' }}>
              <Typography variant="subtitle1">{`ตอบกลับความคิดเห็น @${data.user.publicName}`}</Typography>
              <Stack direction="row" sx={{ marginTop: '10px' }}>
                <Avatar
                  sx={{ width: '40px', height: '40px', marginRight: '10px' }}
                  alt={user?.username}
                  src={user?.imageUrl}
                />

                <Box sx={{ width: '100%' }}>
                  <CommentEditor
                    error={replyError}
                    onError={() => setReplyError(false)}
                    data={data}
                    typeEditor="reply"
                    open={openTextFieldReply}
                    submitButton="ตอบกลับ"
                    onCancel={() => setOpenTextFieldReply(false)}
                    onSubmit={(content: any) => onReplyComment(content)}
                  />
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>

      <ModalDeleteComment
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        commentId={data.id}
        deleteCommentFunction={() => onDeleteComment()}
      />

      <ModalReplyComment
        openModal={openModalReply}
        onCloseModal={() => setOpenModalReply(false)}
      />

      <ImageModal
        src={showImage}
        open={showImage !== ''}
        handleClose={() => setShowImage('')}
      />
    </Stack>
  );
}

const botsCat = ['LEARNING', 'PROGRAMMING'];
const openStatusByError = ['OPEN_BY_BOT_FAILED', 'OPEN_BY_BOT_QUOTA_REACHED'];

function CommentCard({
  commentData,
  childCommentCount,
  courseId,
  lessonId,
  triggerstate,
  triggerFetchFunction,
  selected = false,
}: CommentCardProps) {
  const { user } = useAuth();
  const [parentComment, setParentComment] =
    useState<CommentDetailType>(commentData);
  const [childComment, setChildComment] = useState<CommentDetailType[]>([]);

  const [moreCommentStatus, setMoreCommentStatus] = useState<boolean>(false);
  const isSelf: boolean = parentComment.userId === user?.id;

  const checkCorrectAnswer = (): boolean => {
    const myComment: boolean = parentComment.userId === user?.id;
    const parentCorrect: boolean = parentComment.isCorrect;
    const childCorrect: boolean = childComment.some((item) => item.isCorrect);

    if (parentComment.type === 'ANSWER')
      return Boolean(parentCorrect || childCorrect || myComment);
    else return true;
  };

  const refetchComment = async () => {
    try {
      const response = await axiosInstance(
        `/comments/${parentComment.id}?courseId=${courseId}&lessonId=${lessonId}`,
      );
      const data = await response.data;
      setMoreCommentStatus(false);
      setParentComment(data);
      data.firstChild && setChildComment([data.firstChild]);
      dispatch(setLastCommentUpdated(data));
    } catch {
      return;
    }
  };

  const getChildComment = async () => {
    try {
      if (!moreCommentStatus) {
        const response = await axiosInstance.get(
          `/comments/${commentData.id}/child`,
        );
        const data = await response.data.data;
        setChildComment([...data]);
      }
    } catch {
      return;
    }
  };

  const handleShowMore = () => {
    if (!moreCommentStatus) {
      setMoreCommentStatus(true);
      getChildComment();
    } else {
      setMoreCommentStatus(false);
      setChildComment((prevState: CommentDetailType[]) => {
        const length = prevState.filter(
          (item: CommentDetailType) => item.isCorrect,
        ).length;

        if (length === 0) return childComment.slice(-1);
        else
          return prevState
            .filter((item: CommentDetailType) => item.isCorrect)
            .slice(-1);
      });
    }
  };

  const setParentCommentData = (
    data: CommentDetailType,
    type: 'EDITED' | 'CREATED',
  ) => {
    if (type === 'EDITED') {
      setParentComment({ ...parentComment, ...data });
      dispatch(setLastCommentUpdated({ ...parentComment, ...data }));
    } else if (type === 'CREATED') {
      setChildComment((prevState: CommentDetailType[]) => [...prevState, data]);
    } else if (type === 'UPDATED') {
      setParentComment(data);
    }
  };

  useEffect(() => {
    if (commentData.firstChild !== null && childComment.length === 0) {
      setChildComment([commentData.firstChild]);
    }
  }, [commentData]);

  const isShowQuestionStatus =
    (parentComment.type === 'QUESTION' || parentComment.type === 'CLOSED') &&
    // if WAIT_USER_DECISION show to self only
    !(
      !isSelf && parentComment.commentQuestion?.status === 'WAIT_USER_DECISION'
    );

  function isOpenQHideLabel() {
    const isOpenStatusByError = openStatusByError.includes(
      parentComment.commentQuestion?.status || '',
    );
    if (isOpenStatusByError && parentComment.child >= 1) return true;
    const isOpen = parentComment.commentQuestion?.status === 'OPEN';
    const isBotCat = botsCat.includes(
      parentComment.commentQuestion?.category || '',
    );
    if (isOpen && !isBotCat && parentComment.child >= 1) return true;
    if (isOpen && isBotCat && parentComment.child >= 2) return true;
    return false;
  }

  return (
    <CommentWrapper
      commentType={parentComment.type}
      isRead={commentData.isRead}
      isSelf={isSelf}
      isCorrect={commentData.isCorrect}
      firstChildIsCorrect={commentData.firstChild?.isCorrect}
    >
      <>
        <CommentDetail
          data={parentComment}
          setData={(data: CommentDetailType, type: 'EDITED' | 'CREATED') =>
            setParentCommentData(data, type)
          }
          disableReplyButton={false || !checkCorrectAnswer()}
          parentCommentId={parentComment.id}
          parantCommentType={parentComment.type}
          courseId={courseId}
          lessonId={lessonId}
          triggerstate={triggerstate}
          triggerFetchFunction={triggerFetchFunction}
          selected={selected}
        />

        {childComment.map((item: CommentDetailType) => (
          <Box
            key={`child-comment-${item.id}`}
            sx={{ marginTop: '0px', marginLeft: '40px' }}
          >
            <CommentDetail
              data={item}
              setData={(data: CommentDetailType, type: string) => {
                setChildComment((prevState: CommentDetailType[]) =>
                  prevState.map((comment: CommentDetailType) => {
                    if (comment.id === item.id) {
                      if (type !== 'UPDATED')
                        dispatch(setLastCommentUpdated(data));
                      return data;
                    } else return comment;
                  }),
                );
              }}
              parantCommentType={parentComment.type}
              disableReplyButton={true}
              triggerstate={triggerstate}
              triggerFetchFunction={triggerFetchFunction}
              selected={selected}
            />
          </Box>
        ))}

        {childCommentCount > 1 ? (
          <Box sx={{ marginLeft: '100px', marginTop: '20px' }}>
            <Button
              variant="text"
              disableRipple={true}
              sx={{
                color: 'grey.500',
                padding: '0px',
                marginLeft: '25px',
                marginTop: '-30px',
                ':hover': {
                  backgroundColor: 'transparent',
                  color: 'grey.600',
                },
              }}
              onClick={() => handleShowMore()}
            >
              {!moreCommentStatus
                ? `ความคิดเห็นเพิ่มเติม (${childCommentCount - 1})`
                : 'เเสดงความคิดเห็นน้อยลง'}
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {isShowQuestionStatus &&
        !isOpenQHideLabel() &&
        parentComment.commentQuestion?.status ? (
          <QuestionStatusLabel
            questionStatus={parentComment.commentQuestion?.status}
            commentId={parentComment.id}
            refetchComment={refetchComment}
          />
        ) : (
          <></>
        )}
      </>
    </CommentWrapper>
  );
}

function QuestionStatusLabel({
  questionStatus,
  commentId,
  refetchComment,
}: {
  questionStatus: CommentQuestionStatus;
  commentId: string;
  refetchComment: Function;
}) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  async function sendUserQuestionDecision(
    decision: 'accept-answer' | 'request-info',
  ) {
    try {
      setLoading(true);
      await axiosInstance.post(`/comments/${commentId}/${decision}`);
      refetchComment();
    } catch (_) {
      setError('เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่ในภายหลัง');
    }
  }

  const questionStatusOptions = {
    WAIT_BOT_ANSWER: {
      title: 'คำถามนี้อยู่ในกระบวนการสร้างคำตอบอัตโนมัติ',
      icon: 'line-md:coffee-half-empty-twotone-loop',
      subComponent: (
        <WaitBotQuestion
          commentId={commentId}
          refetchComment={refetchComment}
        />
      ),
    },
    OPEN_BY_BOT_QUOTA_REACHED: {
      title: 'คำถามนี้ถูกส่งไปให้เจ้าหน้าที่ดูแลแล้ว',
      icon: 'line-md:telegram',
      subComponent: (
        <Typography
          variant="caption"
          sx={{ color: 'text.secondary', textAlign: 'center' }}
        >
          (เนื่องจากเกินโควต้าการสร้างคำตอบอัตโนมัติของช่วงเวลานี้แล้ว)
        </Typography>
      ),
    },
    OPEN_BY_BOT_FAILED: {
      title: 'คำถามนี้ถูกส่งไปให้เจ้าหน้าที่ดูแลแล้ว',
      icon: 'line-md:telegram',
      subComponent: <></>,
    },
    OPEN: {
      title: 'คำถามนี้ถูกส่งไปให้เจ้าหน้าที่ดูแลแล้ว',
      icon: 'line-md:telegram',
      subComponent: <></>,
    },
    WAIT_USER_DECISION: {
      title: 'คุณพอใจในคำตอบอัตโนมัติหรือไม่?',
      icon: '',
      subComponent: (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '15px',
            flexWrap: 'wrap',
            marginTop: '10px',
          }}
        >
          <Button
            onClick={() => sendUserQuestionDecision('accept-answer')}
            variant="contained"
            disabled={loading}
          >
            พึงพอใจ
            <Iconify
              icon="line-md:circle-to-confirm-circle-transition"
              sx={{ width: '20px', height: '20px' }}
            />
          </Button>
          <Button
            variant="outlined"
            onClick={() => sendUserQuestionDecision('request-info')}
            disabled={loading}
          >
            ต้องการถามผู้ดูแลต่อ
            <Iconify
              icon="line-md:account"
              sx={{ width: '20px', height: '20px' }}
            />
          </Button>
        </Box>
      ),
    },
    CLOSED_BY_STALE: {
      title: 'คำถามนี้ถูกปิดเนื่องจากไม่มีการเคลื่อนไหวจากผู้ถามเกิน 7 วัน',
      icon: 'pajamas:expire',
      subComponent: <></>,
    },
  };

  type QuestionStatusOptionsKey = keyof typeof questionStatusOptions;

  if (!(questionStatus in questionStatusOptions)) return <></>;

  return (
    <Stack sx={{ margin: '10px 0px 10px 35px', gap: '5px' }}>
      <Chip
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography>
              {
                questionStatusOptions[
                  questionStatus as QuestionStatusOptionsKey
                ].title
              }
            </Typography>
            <Iconify
              icon={
                questionStatusOptions[
                  questionStatus as QuestionStatusOptionsKey
                ].icon
              }
              sx={{ width: '20px', height: '20px', minWidth: '20px' }}
            />
          </Box>
        }
        sx={{
          height: 'auto',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
            paddingY: '5px',
          },

          margin: '0 auto',
        }}
      />
      <Typography sx={{ textAlign: 'center' }}>
        {
          questionStatusOptions[questionStatus as QuestionStatusOptionsKey]
            .subComponent
        }
      </Typography>
      {error ? (
        <Typography sx={{ color: 'error.main', textAlign: 'center' }}>
          {error}
        </Typography>
      ) : (
        <></>
      )}
    </Stack>
  );
}

function WaitBotQuestion({
  commentId,
  refetchComment,
}: {
  commentId: string;
  refetchComment: Function;
}) {
  const { commentQuestionQueqes } = useSelector((state) => state.comment);

  useEffect(() => {
    const isSelfQueqeReady = commentQuestionQueqes.some(
      (each) => each.id === commentId && each.status === 'READY',
    );
    if (isSelfQueqeReady) {
      refetchComment();
      dispatch(removeCommentQuestionQueqeById(commentId));
    }
  }, [commentQuestionQueqes]);

  return (
    <Typography
      variant="caption"
      sx={{ color: 'text.secondary', textAlign: 'center' }}
    >
      (กระบวนการนี้อาจใช้เวลานาน ระบบจะแจ้งเตือนผู้ถามอีกครั้งเมื่อการสร้างเสร็จสิ้น)
    </Typography>
  );
}

export default CommentCard;
