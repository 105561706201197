import React from 'react';
import { Box, Typography } from '@mui/material';

interface BioProps {
  bio: string;
  editButton: JSX.Element | null;
}

export default function Bio({ bio, editButton }: BioProps) {
  if (bio === '')
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        {editButton}
      </Box>
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Typography variant="h4">เกี่ยวกับฉัน</Typography>
        {editButton}
      </Box>
      <Typography variant="caption">{bio}</Typography>
    </Box>
  );
}
