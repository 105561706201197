import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  BundleCatalogType,
  courseCatalogCardType,
} from '../../@types/courseCatalog';
import { ProductConfigType } from './productConfig';
import { CourseCard } from '../../components/course/CourseCard';
import { BundleCard } from '../../components/bundle/BundleCard';
import { ProductSkeletons } from './ProductSkeletons';

interface ProductSearchResultProps {
  courses: courseCatalogCardType[];
  bundles: BundleCatalogType[];
  type?: keyof ProductConfigType;
  loading: boolean;
}

export default function ProductSearchResult({
  courses,
  bundles,
  type,
  loading,
}: ProductSearchResultProps) {
  return (
    <Box>
      <Grid container>
        {type === 'bundle'
          ? bundles.map((bundleData, index: number) => (
              <Grid
                key={`bundle-card-${index}`}
                item
                xs={12}
                sm={6}
                md={4}
                xl={3}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                <Box sx={{ width: '270px', marginX: '5px' }}>
                  <BundleCard bundleData={bundleData} />
                </Box>
              </Grid>
            ))
          : courses.map((courseData, index: number) => (
              <Grid
                key={`course-card-${index}`}
                item
                xs={12}
                sm={6}
                md={4}
                xl={3}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                <Box sx={{ width: '270px', marginX: '5px' }}>
                  <CourseCard courseData={courseData} />
                </Box>
              </Grid>
            ))}
        {loading && <ProductSkeletons />}
      </Grid>
    </Box>
  );
}
