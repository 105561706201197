import React from 'react'
import { Box, Skeleton } from '@mui/material'

export default function LectureSkeleton() {
  return (
    <Box sx={{backgroundColor: 'background.paper'}}>
      <Box sx={{position: 'relative', width: '100%', paddingBottom: '37.65%'}}>
        <Skeleton variant="rectangular" width={'100%'} height={'100%'} sx={{position: 'absolute', backgroundColor: 'grey.400'}}/>
      </Box>
      <Box sx={{padding: '0px 30px 30px 30px', }}>
      <Box sx={{marginTop: '30px'}}></Box>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'50%'} height={50}/>

        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'80%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'90%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'90%'}/>

        <Box sx={{ marginTop: '30px' }}></Box>

        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'90%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'80%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'100%'}/>
        <Skeleton sx={{backgroundColor: 'grey.400'}} width={'70%'}/>
      </Box>
    </Box>
  )
}
