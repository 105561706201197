import React from 'react'
import { Button } from '@mui/material'
import axiosInstance from '../../utils/axios'
import { useSelector } from 'react-redux';
import { RootState, dispatch } from '../../redux/store';
import { 
  addOldNotifications,
  setNotiCursor,
  addOldAnnouncements,
  setAnnounceCursor 
} from '../../redux/slices/notification';

interface NotificationMoreProps {
  type: 'SYSTEM' | 'ADMIN'
}

export default function NotificationMore({ type }: NotificationMoreProps) {
  const { notiCursor, announceCursor } = useSelector((state: RootState) => state.notification);
  async function getMoreNotifications() {
    try {
      if (type === 'SYSTEM') {
        const response = await axiosInstance.get(`/notifications/user-notifications?cursor=${notiCursor}&take=10`)
        dispatch(setNotiCursor(response.data.cursor || null))
        dispatch(addOldNotifications(response.data.data))
      } else {
        const response = await axiosInstance.get(`/notifications/announcements?cursor=${announceCursor}&task=10`)
        dispatch(setAnnounceCursor(response.data.cursor || null))
        dispatch(addOldAnnouncements(response.data.data))
      }
    } catch {
      return
    }
    
  }

  if(type === 'SYSTEM' && !notiCursor) return null
  else if (type === 'ADMIN' && !announceCursor) return null

  return (
    <Button 
      onClick={getMoreNotifications}
      color='tertiary' 
      sx={{ width: '100%', position: 'relative', marginTop: '30px'}}
    >
      {type === 'SYSTEM' ? 'แจ้งเตือนเพิ่มเติม' : 'ประกาศเพิ่มเติม'}
    </Button>
  )
}