import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Stack, Grid, Button } from '@mui/material'
import { SpeedDial, SpeedDialAction } from '@mui/material'

import { devlabTaskType, devlabResultType, devlabLastSubmissionType, devlabLanguageType } from '../../../../@types/devlab'
import Icon from '../../../../components/Iconify'
import DevlabQuestionSkeleton from '../../../../components/skeleton/DevlabQuestionSkeleton'
import useResponsive from '../../../../hooks/useResponsive'
import axiosInstance from '../../../../utils/axios'

import TaskDescription from './TaskDescription'
import TaskGrader from './TaskGrader'
import TaskResult from './TaskResult'
import TaskLogin from './TaskLogin'

import { devlabTaskInitial } from './initialDevlab'
import CourseNoPermission from '../../CourseNoPermission'

interface ControlButtonProps {
  option?: string
  handleOption: Function
}

interface ButtonComponentProps {
  onAction: Function
  icon: string
}

type FloatingButtonType = {
  icon: string
  elementId: string
}

function ButtonComponent({ icon, onAction }: ButtonComponentProps) {
  return (
    <Button
      variant="contained"
      onClick={() => onAction('detail')}
      sx={{ 
        backgroundColor: 'background.paper', 
        height: '60px',
        ':hover': {
          backgroundColor: 'primary.main'
        }
      }}
    >
      <Icon icon={icon} sx={{ width: '20px', height: '20px', color: 'text.primary' }} />
    </Button>
  )
}

function ControlFloatButtonGroup({ handleOption }: ControlButtonProps) {
  const [openOption, setOpenOption] = React.useState<boolean>(false)
  const buttonList: FloatingButtonType[] = [
    { icon: 'material-symbols:output-circle', elementId: 'result'},
    { icon: 'material-symbols:code', elementId: 'grader'},
    { icon: 'ooui:view-details-ltr', elementId: 'detail'},
  ]

  return (
    <SpeedDial
      ariaLabel=""
      color="primary.dark"
      sx={{ position: 'fixed', bottom: '20px', right: '20px' }}
      open={openOption}
      onClick={() => setOpenOption(!openOption)}
      icon={<Icon icon="ic:round-code" sx={{ color: 'text.button', width: '25px', height: '25px', transform: 'rotate(270deg)' }} />}
    >
      {buttonList.map((item: FloatingButtonType, index: number) => (
        <SpeedDialAction
          key={`floating-button-${index}`}
          onClick={() => handleOption(item.elementId)}
          icon={<Icon icon={item.icon} sx={{ width: '20px', height: '20px' }} />}
          sx={{
            marginBottom: '-2px',
            width: '50px',
            height: '50px',
            backgroundColor: 'grey.300',
            color: 'grey.600',
            ':hover': {
              backgroundColor: 'grey.400',
              color: 'grey.700'
            }
          }}
        />
      ))}
    </SpeedDial>
  )
}

function ControlButtonGroup({ handleOption }: ControlButtonProps) {
  return (
    <Grid item xs={.5}>
      <Stack
        direction="column"
        gap="5px"
        sx={{
          position: 'sticky',
          top: 0,
          paddingTop: '120px',
          marginLeft: '5px'
        }}
      >
        <ButtonComponent icon="ooui:view-details-ltr" onAction={() => handleOption('detail')} />
        <ButtonComponent icon="material-symbols:code" onAction={() => handleOption('grader')} />
        <ButtonComponent icon="material-symbols:output-circle" onAction={() => handleOption('result')} />
      </Stack>
    </Grid>
  )
}

function DevlabQuestion() {
  const mediaSize = useResponsive('down', 900)
  const { devlabId } = useParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [devlabStatus, setDevlabStatus] = useState<boolean>(false)
  const [devlabTask, setDevlabTask] = useState<devlabTaskType>(devlabTaskInitial)
  const [devlabResult, setDevlabResult] = useState<devlabResultType>()

  const [languageList, setLanguageList] = useState<devlabLanguageType[]>([])
  const [devlabCode, setDevlabCode] = useState<devlabLastSubmissionType | null>(null)

  const handleScroll = (id: string) => {
    const element = document.getElementById(id)
    element?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  const handleFetchData = async () => {
    await Promise.all([getCheckDevlab(), getDevlabTask()])
    await setLoading(false)
  }

  const getCheckDevlab = async () => {
    try {
      const response = await axiosInstance.get('/devlab/account')

      if (response.data) setDevlabStatus(true)
      else setDevlabStatus(false)

    } catch {
      setDevlabStatus(false)
    }
  }

  const getDevlabTask = async () => {
    try {
      const response = await axiosInstance.get(`/devlab/tasks/${devlabId}`)
      setDevlabTask(response.data)
    } catch {
      return;
    }
  }

  const getLanguage = async () => {
    try {
      if (!loading) {
        const languageList = await devlabTask.devlab.allowLanguages.join(',')
        const response = await axiosInstance.get(`/devlab/languages?${languageList ? `langId=${languageList}` : ''}`)
        setLanguageList(response.data)
      }
    } catch {
      return;
    }
  }

  const onSubmission = async (langId: number, code: string) => {
    try {
        const response = await axiosInstance.post(`devlab/tasks/${devlabTask.devlabTaskId}/submission?lessonId=${devlabTask.devlab.lessonId}`, {
          langId: langId,
          code: code
        })
        setDevlabResult({...response.data, fail: false})
        getDevlabTask()
    } catch {
      setDevlabResult({ results: [], fail: true })
    }
  }

  useEffect(() => {
    getLanguage()
  }, [loading])

  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <Container
      sx={{
        marginTop: '30px',
        maxWidth: '1062.38px',
        '@media (min-width: 2000px)': {
          maxWidth:'1280px'
        }
      }}
    >
      {loading ? (
        <DevlabQuestionSkeleton />
      ) : 
        devlabTask.id === '' ?
        <CourseNoPermission title={'เนื้อหาบทเรียนถูกล็อค'}/>
        :
        (
        <>
          <Grid container>
            <Grid item xs={mediaSize ? 12 : 11}>
              {!devlabStatus ? (
                <>
                  <TaskLogin />
                  <TaskDescription
                    data={devlabTask}
                    languageList={languageList}
                  />
                </>
              ) : (
                <>
                  <TaskDescription
                    data={devlabTask}
                    languageList={languageList}
                  />
                  <TaskGrader
                    taskId={devlabTask.devlabTaskId}
                    nextTask={devlabTask.nextTask}
                    codeValue={devlabCode}
                    setCodeValue={setDevlabCode}
                    onSubmit={(id: number, code: string) => onSubmission(id, code)}
                    result={devlabResult}
                    languageList={languageList}
                    handleScroll={handleScroll}
                  />
                  <TaskResult
                    codeValue={devlabCode}
                    taskResult={devlabResult}
                  />
                </>
              )}
            </Grid>

            {!mediaSize && devlabStatus && (
              <ControlButtonGroup handleOption={(elementId: string) => handleScroll(elementId)} />
            )}
          </Grid>

          {mediaSize && devlabStatus && (
            <ControlFloatButtonGroup
              handleOption={(elementId: string) => handleScroll(elementId)}
            />
          )}
        </>
      )}
    </Container>
  )
}

export default DevlabQuestion
