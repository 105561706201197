
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import LessonNav from './LessonNav';
import { styled } from '@mui/material/styles';
import { HEADER, NAVBAR } from '../../config';
import { useParams, useNavigate } from 'react-router-dom';

export default function LessonLayout() {
  const { courseSlug } = useParams();
  const MainStyle = styled('main')(({ theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    paddingBottom: HEADER.MOBILE_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingTop: HEADER.MOBILE_HEIGHT,
    },
  }));

  const navigate = useNavigate()

  useEffect(() => {
    !courseSlug && navigate('/404', { replace: true })
  }, [])

  return (
    <>
      <LessonNav/>
      <MainStyle>
        <Outlet/>
      </MainStyle>
    </>
  )
}
