import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Button,
  Typography,
  Collapse,
  Divider,
} from '@mui/material';
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
  matchPath,
} from 'react-router-dom';

import Iconify from '../../components/Iconify';
import CourseFilterControl from '../../components/main/CourseFilterControl';
import CourseAutocomplete from '../../components/main/CourseAutocomplete';

import useAuth from '../../hooks/useAuth';
import { ColorSchema } from '../../theme/palette';

import { SearchFilterType } from '../../@types/courseSearch';

import { productConfig, ProductConfigType } from './productConfig';

interface CatalogSearchFilterProps {
  loading: boolean;
  resetParams: boolean;
  type?: keyof ProductConfigType;
  applySearchFilter: (params?: string) => void;
  defaultOpenController?: boolean;
}

export const initialFilters: SearchFilterType = {
  sortBy: 'MostEnrolled',
  enroll: 'All',
  own: 'All',
  group: '',
  categories: '',
  search: '',
};

function isBundle(type?: keyof ProductConfigType) {
  return type === (productConfig.bundle.name as keyof ProductConfigType);
}

function currentProductConfig(type?: keyof ProductConfigType) {
  return productConfig[type || ('course' as keyof ProductConfigType)];
}

export default function CatalogSearchFilter({
  loading,
  resetParams,
  type,
  applySearchFilter,
  defaultOpenController = false,
}: CatalogSearchFilterProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const { isAuthenticated } = useAuth();

  const [showController, setShowController] = useState(defaultOpenController);
  const [filters, setFilters] = useState<SearchFilterType>({
    ...initialFilters,
    search: searchParam.get('search') ?? '',
    group: searchParam.get('group') ?? '',
    categories: searchParam.get('categories') ?? '',
    ...(searchParam.get('enroll')
      ? { enroll: searchParam.get('enroll') as string }
      : {}),
  });

  const resetFilter = (forceReset?: boolean) => {
    setFilters(initialFilters);
    handleURL(initialFilters);

    const params = new URLSearchParams(initialFilters);

    if (forceReset) applySearchFilter();
    else applySearchFilter(params.toString());
  };

  const handleSearchText = (text: string) => {
    setFilters((params) => ({
      ...params,
      search: text,
    }));
  };

  const handleURL = (
    searchParam: any,
    pathname?: string,
    replace?: boolean,
  ) => {
    const paramsObject = Object.keys(searchParam).reduce(
      (result: {}, key: string) => {
        if (['search', 'categories'].includes(key) && searchParam[key] !== '') {
          return { ...result, [key]: searchParam[key] };
        } else if (['group', 'plan'].includes(key)) {
          return {
            ...result,
            ...(searchParam[key] !== '' ? { group: searchParam[key] } : {}),
          };
        } else return result;
      },
      {},
    );

    const params = new URLSearchParams(paramsObject);
    const paramString = params.toString() ? `?${params.toString()}` : '';

    if (replace) {
      window.history.replaceState(
        null,
        '',
        `${pathname ?? location.pathname}${paramString}`,
      );
    } else {
      window.history.pushState(
        null,
        '',
        `${pathname ?? location.pathname}${paramString}`,
      );
    }
  };

  const sendSearchFilterParams = (
    filtersParams?: SearchFilterType,
    statusPage?: 'REFRESH' | 'NOT_CHANGE_URL' | 'CHANGE_URL',
  ) => {
    const paramsResult = {
      ...filters,
      ...filtersParams,
      group: filtersParams?.group === 'Premium' ? 'Premium' : '',
      plan:
        !['All', 'Premium'].includes(filtersParams?.group as string) &&
        filtersParams?.group
          ? (filtersParams?.group as string)
          : '',
    };

    setFilters((prev: SearchFilterType) => ({ ...prev, ...filtersParams }));

    if (statusPage !== 'NOT_CHANGE_URL') {
      handleURL(paramsResult, undefined, statusPage === 'REFRESH');
    }

    const params = new URLSearchParams(paramsResult);
    applySearchFilter(params.toString());
  };

  const getAllCourse = () => {
    resetFilter();
    navigate({ pathname: currentProductConfig(type).link });
  };

  const getMyCourse = () => {
    const paramsResult = {
      ...initialFilters,
      ...(type === 'bundle' ? { own: 'Owned' } : { enroll: 'Enrolled' }),
    };

    sendSearchFilterParams(paramsResult, 'NOT_CHANGE_URL');
  };

  const onCloseController = () => {
    setShowController(false);
    resetFilter(true);
    navigate('/');
  };

  useEffect(() => {
    (() => {
      const isSearchPath = matchPath({ path: '/search' }, location.pathname);
      const isSearchBundlePath = matchPath(
        { path: '/search/bundle' },
        location.pathname,
      );

      if (
        location.search !== '' ||
        Boolean(isSearchBundlePath) ||
        Boolean(isSearchPath)
      ) {
        sendSearchFilterParams(filters, 'REFRESH');
        setShowController(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (resetParams) setFilters(initialFilters);
  }, [resetParams]);

  return (
    <Box sx={{ margin: '50px 30px 30px 30px' }}>
      {type && (
        <Typography
          variant="h3"
          color={productConfig[type]?.color || 'secondary'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginY: '20px',
          }}
        >
          <Iconify
            icon={productConfig[type].icon}
            sx={{
              marginRight: '5px',
              minWidth: '35px',
              minHeight: '35px',
            }}
          />
          {`ค้นหา${productConfig[type]?.word}`}
        </Typography>
      )}

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          gap: '10px',
          '@media (max-width:800px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            padding: '10px',
            backgroundColor: 'background.neutral',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          <CourseAutocomplete
            data={currentProductConfig(type)}
            value={filters.search}
            onChange={handleSearchText}
            onSearch={sendSearchFilterParams}
          />
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            gap: '10px',
            '@media (max-width:800px)': {
              width: '100%',
            },
          }}
        >
          <Button
            color="tertiary"
            onClick={() => setShowController((prev: boolean) => !prev)}
            sx={{
              whiteSpace: 'nowrap',
              height: '40px',
              minWidth: '90px',
            }}
          >
            <Iconify
              icon="fa:filter"
              sx={{
                marginRight: '10px',
                width: '20px',
                height: '20px',
              }}
            />
            {'ตัวกรอง'}
          </Button>
          <Stack direction="row" sx={{ gap: '10px' }}>
            {!showController && (
              <ResponsiveFilterButton
                onClickFunction={getAllCourse}
                color={
                  currentProductConfig(type)?.color ||
                  productConfig.course.color
                }
                shortText={showController ? 'Clear' : 'All'}
                icon={
                  currentProductConfig(type)?.icon || productConfig.course.icon
                }
                text={`${currentProductConfig(type)?.word}ทั้งหมด`}
              />
            )}

            {isAuthenticated && (
              <ResponsiveFilterButton
                onClickFunction={getMyCourse}
                color="primary"
                icon="mdi:person-check"
                shortText="Owned"
                text={type === 'bundle' ? 'แพ็กเกจของฉัน' : 'คอร์สของฉัน'}
              />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Collapse in={showController}>
        <Box
          sx={{
            padding: '18px',
            backgroundColor: 'background.paper',
            borderRadius: '8px',
            marginTop: '20px',
          }}
        >
          <CourseFilterControl
            disableClickFn={loading}
            isBundle={isBundle(type)}
            filters={filters}
            setFilters={sendSearchFilterParams}
          />
          <Divider
            sx={{
              marginY: '20px',
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              gap: '10px',
              '@media (max-width: 560px)': {
                flexDirection: 'column-reverse',
              },
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={onCloseController}
              sx={{
                whiteSpace: 'nowrap',
                height: '40px',
                minWidth: '90px',
              }}
            >
              <Iconify
                icon="eva:arrow-back-outline"
                width={20}
                height={20}
                sx={{ marginRight: '5px' }}
              />
              {'ออกจากโหมดค้นหา'}
            </Button>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                gap: '10px',
                '@media (max-width: 560px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <ProductTypeSwitch
                isLoading={loading}
                type={isBundle(type) ? 'course' : 'bundle'}
              />
            </Stack>
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
}

interface ResponsiveFilterButtonProps {
  onClickFunction: Function;
  color: ColorSchema;
  icon: string;
  shortText: string;
  text: string;
}

function ResponsiveFilterButton({
  onClickFunction,
  color,
  icon,
  shortText,
  text,
}: ResponsiveFilterButtonProps) {
  return (
    <Button
      onClick={() => onClickFunction()}
      color={color}
      variant="outlined"
      sx={{
        whiteSpace: 'nowrap',
        height: '40px',
        minWidth: '90px',
        gap: '5px',
        '@media (max-width:800px)': { minWidth: '30px' },
      }}
    >
      <Iconify icon={icon} sx={{ minWidth: '20px', minHeight: '20px' }} />
      <Typography
        variant="subtitle2"
        sx={{
          '@media (min-width:500px)': { display: 'none' },
          '@media (max-width:374px)': { display: 'none' },
        }}
      >
        {shortText}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ '@media (max-width:500px)': { display: 'none' } }}
      >
        {text}
      </Typography>
    </Button>
  );
}

interface ProductTypeSwitchProps {
  isLoading: boolean;
  type?: keyof ProductConfigType;
}

function ProductTypeSwitch({ isLoading, type }: ProductTypeSwitchProps) {
  return (
    <>
      {type && (
        <Button
          component={Link}
          to={productConfig[type].link}
          color={productConfig[type]?.color || 'secondary'}
          variant="outlined"
          disabled={isLoading}
          sx={{
            whiteSpace: 'nowrap',
            height: '40px',
            minWidth: '90px',
          }}
        >
          <Iconify
            icon={
              isLoading
                ? 'line-md:moon-filled-to-sunny-filled-loop-transition'
                : productConfig[type].icon
            }
            sx={{
              marginRight: '5px',
              minWidth: '20px',
              minHeight: '20px',
            }}
          />
          {isLoading ? 'กำลังค้นหา' : `ดู${productConfig[type].word}ทั้งหมด`}
        </Button>
      )}
    </>
  );
}
