import React, { useState } from 'react'
import { Box, Stack, Drawer, Typography, Badge } from '@mui/material'
import { Tabs, Tab } from '@mui/material'
import { styled } from '@mui/material/styles'
import NotiItem from './NotiItem';
import AnnounceItem from './AnnounceItem';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from '../../redux/store';
import axiosInstance from '../../utils/axios';
import { setAnnouncements, setNotifications, updateNotificationCount } from '../../redux/slices/notification';
import { useNavigate } from 'react-router'
import NotificationMore from './NotificationMore';
import dayjs from 'dayjs';

interface NotiDrawerProps {
  open: boolean
  setAnchorEl: Function
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 13,
    padding: '0 4px',
  },
}))

const announceIcon = [
  { icon: 'icomoon-free:newspaper', color: 'primary.main' },
  { icon: 'material-symbols:notification-important', color: 'error.light' },
  { icon: 'solar:ticket-sale-bold', color: '#B95CF2' },
  { icon: 'material-symbols:deployed-code-update', color: 'secondary.light' },
  { icon: 'clarity:event-solid', color: 'info.main' },
]

export default function NotiDrawer({ open, setAnchorEl }: NotiDrawerProps) {
  const navigate = useNavigate()
  const { notiCount, announceCount, notifications, announcements } = useSelector((state: RootState) => state.notification);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notiTab, setNotiTab] = useState('SYSTEM')

  async function readAll(){
    if (notiTab === 'SYSTEM' && notiCount === 0) return
    else if (notiTab === 'ADMIN' && announceCount === 0) return

    try {
      if (notiTab === 'SYSTEM') {
        await axiosInstance.post('/notifications/user-notifications/read')
        const readNotifications = notifications.map((each) => ({ ...each, readAt: each.readAt || dayjs.toString() }))
        dispatch(setNotifications(readNotifications))
      } else {
        await axiosInstance.post('/notifications/announcements/read')
        const readAnnoucements = announcements.map((each) => ({ ...each, readAt: each.readAt || dayjs().toString() }))
        dispatch(setAnnouncements(readAnnoucements))
      }
      // @ts-ignore redux type error
      dispatch(updateNotificationCount())
    } catch (err) {
      return
    }
  }
  
  async function clickNoti(id: string, link: string) {
    try {
      await axiosInstance.post(`/notifications/user-notifications/${id}/read`).then(() => {
        const readNotifications = notifications.map((each) => {
          if(each.id === id) return {...each, readAt: dayjs.toString()}
          return each
        })
        dispatch(setNotifications(readNotifications))
      })
      // @ts-ignore redux type error
      dispatch(updateNotificationCount()).then(() => {
        handleClose()
        navigate(link)
      })
    } catch (error) {
      return
    }
  }

  async function clickAnnounce(id: string) {
    try {
      await axiosInstance.post(`/notifications/announcements/${id}/read`)
      const readAnnouncements = announcements.map((each) => {
        if (each.id === id) return {...each, readAt: dayjs().toString()}
        else return each
      })

      dispatch(setAnnouncements(readAnnouncements))
      // @ts-ignore redux type error
      dispatch(updateNotificationCount())
      handleClose()
      navigate(`/notification/${id}`)
    } catch {
      handleClose()
      navigate(`/notification/${id}`)
    }
  }

  function handleChangeNotice(event: any, newItem: string) {
    setNotiTab(newItem)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          width: '350px',
          borderLeft: '0px',
          padding: '20px 30px'
        },
        '*::-webkit-scrollbar': {
          width: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'slategrey',
          borderRadius: '8px'
        }
      }}
    >
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
          <Tabs value={notiTab} onChange={handleChangeNotice}>
            <Tab 
              value="SYSTEM"
              label={
                <Stack direction="row" sx={{ gap: '15px' }}>
                  <Typography>การแจ้งเตือน</Typography>
                  <StyledBadge badgeContent={notiCount} color="error" />
                </Stack>
              }
            />
            <Tab 
              value="ADMIN"
              label={
                <Stack direction="row" sx={{ gap: '15px' }}>
                  <Typography>ประกาศ</Typography>
                  <StyledBadge badgeContent={announceCount} color="error" />
                </Stack>
              }
            />
          </Tabs>
        </Box>
      
        {notiTab === 'SYSTEM' ? (
          <>
            <Typography variant='h4'>
              การแจ้งเตือน ({notiCount})
            </Typography>
            <Typography 
              variant='caption'
              onClick={readAll} 
              sx={{
                color: notiCount ? 'primary.main' : 'text.disabled', 
                textDecoration: 'underline', 
                cursor: notiCount ? 'pointer': 'context-menu'
              }} 
            >
              ทำเครื่องหมายว่าอ่านแล้วทั้งหมด
            </Typography>
            <Box sx={{display: 'flex', gap: '10px', flexDirection: 'row', flexWrap: 'wrap', marginTop: '20px'}}>
              { notifications.map((each, index) => <NotiItem key={`NotiItem-${index+1}`} notiData={each} clickNoti={clickNoti}/>) }
            </Box>
          </>
        ) : (
          <>
            <Typography variant='h4'>
              ประกาศ ({announceCount})
            </Typography>
            <Typography 
              variant='caption'
              onClick={readAll} 
              sx={{
                color: announceCount ? 'primary.main' : 'text.disabled', 
                textDecoration: 'underline', 
                cursor: announceCount ? 'pointer': 'context-menu'
              }} 
            >
              ทำเครื่องหมายว่าอ่านแล้วทั้งหมด
            </Typography>
            <Box sx={{display: 'flex', gap: '10px', flexDirection: 'row', flexWrap: 'wrap', marginTop: '20px'}}>
              { [...announcements].map((each, index) => <AnnounceItem key={`AnnounvrItem-${index+1}`} announceData={each} clickAnnounce={clickAnnounce}/>) }
            </Box>
          </>
        )}

        <NotificationMore type={notiTab as 'SYSTEM' | 'ADMIN'} />
      </Box>
    </Drawer>
  )
}