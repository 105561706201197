import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { BundlePropertiesListType } from '../../../@types/bundle';

export default function PropertiesList({
  propertiesList,
  title,
  icon,
}: {
  propertiesList: BundlePropertiesListType[];
  title: string;
  icon: JSX.Element;
}) {
  return (
    <Box>
      <Typography variant="h4" sx={{ marginY: '20px' }}>
        {title}
      </Typography>
      <Grid container>
        {propertiesList.map((property, index) => (
          <Grid
            key={`${title}-${index}`}
            item
            xs={12}
            md={propertiesList.length > 3 ? 6 : 12}
            sx={{ marginY: '5px' }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ minWidth: '20px', textAlign: 'center' }}>{icon}</Box>
              <Box>{property.text}</Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
