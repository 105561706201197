import React, { useEffect, useState } from 'react';
import { Modal, Box, Stack, Typography, Button, Divider } from '@mui/material';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControlLabelProps,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import useAuth from '../../../hooks/useAuth';
import axiosInstance from '../../../utils/axios';

interface PublicNameEditorProps {
  setUserName: Function;
  open: boolean;
  onClose: Function;
}

interface LabelRadioProps {
  name: string;
  type: string;
}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main,
  },
}));

function LabelRadio({ name, type }: LabelRadioProps) {
  return (
    <Stack direction="column" sx={{ gap: '-5px' }}>
      <Typography variant="h6">{name}</Typography>
      <Typography variant="caption">{`( ${type} )`}</Typography>
    </Stack>
  );
}

export default function PublicNameEditor({
  setUserName,
  open,
  onClose,
}: PublicNameEditorProps) {
  const [option, setOption] = useState<string>('FULL-NAME');
  const [userOption, setUserOption] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const { initialize } = useAuth();

  const handleSubmitData = async () => {
    try {
      setLoading(true);
      await updateConfigName(option === 'DISPLAY-NAME' ? true : false);
      await initialize();
      setUserName(
        option === 'DISPLAY-NAME'
          ? userOption.displayName
          : `${userOption?.firstName} ${userOption?.lastName}`,
      );
      await onClose();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getConfigName = async () => {
    try {
      const response = await axiosInstance.get(
        '/users/profile/config-publicname',
      );
      const data = await response.data;
      setUserOption(data);
      setOption(data.isShowDisplayName ? 'DISPLAY-NAME' : 'FULL-NAME');
    } catch {
      return;
    }
  };

  const updateConfigName = async (show: boolean) => {
    try {
      await axiosInstance.put('/users/profile/config-publicname', {
        isShowDisplayName: show,
      });
    } catch {
      return;
    }
  };

  useEffect(() => {
    getConfigName();
  }, []);

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'background.paper',
          width: '100%',
          maxWidth: '600px',
          padding: '40px 20px',
          borderRadius: '8px',
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            เลือกชื่อที่ต้องการแสดง
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            ชื่อผู้ใช้งานสามารถแก้ไข โดย
            <a href='https://www.borntodev.com/my-account/edit-account/' style={{ textDecoration: 'none' }} target='_blank' rel="noreferrer">
              <Typography component='span' sx={{ color: 'primary.main', textDecoration: 'underline' }}>คลิกที่นี่</Typography>
            </a>
          </Typography>
          <Typography variant='caption' sx={{color: 'text.secondary', textAlign: 'center'}}>
            *หากเปลี่ยนแปลงชื่อผ่านลิงก์แล้วข้อมูลยังไม่เปลี่ยนแปลงให้ทำการเข้าสู่ระบบใหม่อีกครั้ง
          </Typography>

          <Divider sx={{ marginTop: '15px', width: '80%' }} />
          <FormControl sx={{ width: 'fit-content', marginY: '20px' }}>
            <RadioGroup
              value={option}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setOption(event.target.value)
              }
              sx={{ gap: '10px' }}
            >
              <StyledFormControlLabel
                checked={option === 'FULL-NAME'}
                value="FULL-NAME"
                control={<Radio />}
                label={
                  <LabelRadio
                    name={`${userOption?.firstName} ${userOption?.lastName}`}
                    type="firstname lastname"
                  />
                }
              />
              <StyledFormControlLabel
                checked={option === 'DISPLAY-NAME'}
                value="DISPLAY-NAME"
                control={<Radio />}
                label={
                  <LabelRadio
                    name={userOption?.displayName}
                    type="display-name"
                  />
                }
              />
            </RadioGroup>
          </FormControl>

          <Stack
            direction="row"
            justifyContent="center"
            sx={{ marginTop: '20px', gap: '10px' }}
          >
            <Button variant="outlined" onClick={() => onClose()}>
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              sx={{ color: 'text.button' }}
              disabled={loading}
              onClick={() => handleSubmitData()}
            >
              {loading && (
                <CircularProgress
                  size="18px"
                  color="inherit"
                  sx={{ marginRight: '8px' }}
                />
              )}
              บันทึก
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
