import React from 'react';
import { Box, Stack, Grid, Skeleton } from '@mui/material';

export default function ProfileSkeleton() {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          paddingBottom: '50px',
          borderBottomLeftRadius: '18px',
          borderBottomRightRadius: '18px',
        }}
      >
        <Box
          sx={{ position: 'relative', width: '100%', paddingBottom: '300px' }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{
              position: 'absolute',
              borderRadius: '16px',
            }}
          />
        </Box>
        <Skeleton
          variant="circular"
          width="200px"
          height="200px"
          sx={{
            backgroundColor: 'grey.500',
            marginTop: '-90px',
            marginLeft: '30px',
          }}
        />

        <Grid container rowGap="20px" sx={{ marginTop: '20px' }}>
          <Grid item xs="auto" sx={{ paddingX: '20px' }}>
            <Skeleton
              variant="rounded"
              width="200px"
              height="30px"
              sx={{ marginBottom: '10px' }}
            />

            {Array(3)
              .fill(0)
              .map((_, index: number) => (
                <Stack
                  key={`devlab-skeleton-${index}`}
                  direction="row"
                  alignItems="center"
                  sx={{ gap: '5px', marginBottom: '5px' }}
                >
                  <Skeleton variant="circular" width="30px" height="30px" />
                  <Skeleton variant="rounded" width="150px" height="25px" />
                </Stack>
              ))}
          </Grid>
          <Grid item xs={12} sm={7} sx={{ paddingX: '20px' }}>
            <Skeleton
              variant="rounded"
              width="100%"
              height="30px"
              sx={{ maxWidth: '300px' }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100px"
              sx={{ marginTop: '10px' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
