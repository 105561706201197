import React from 'react';
import { Typography, Box, Grid, useTheme, Button } from '@mui/material';
import { ProjectDetailType } from '../../../@types/projectGallery'
import { ProjectGalleryCard } from  '../../projectGallery/GalleryProjectList/GalleryProjectGroup'

import { styled, alpha } from "@mui/material/styles";
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';

export default function ProjectsProfileViewer({projectList, projectAmount, setAllMode}: {projectList: ProjectDetailType[], projectAmount: number, setAllMode: Function}) {
  const featureAmount = projectAmount === 4 ? 4 : 3
  const featureProjects = projectList.slice(0, featureAmount)
  const secondaryProjects = projectAmount <= 4 ? [] : projectList.slice(featureAmount, 7)
  const theme = useTheme()
  return (
    <>
      <Grid justifyContent="flex-start" container sx={{ gap: '26px 10px' }} >
        { featureProjects.map((item, index) =>
          <Grid item xs={11.91} sm={5.8} md={3.8} lg={2.91} key={`project-f3-card-${index+1}`} justifyContent="center" sx={{ display: 'grid' }}>
            <ProjectGalleryCard projectData={item} path={`/devproject/${item.id}`}/>
          </Grid>
        )}
        {
          secondaryProjects.length > 0 &&
          <Grid item xs={11.91} sm={5.8} md={3.8} lg={2.91} justifyContent="center" sx={{ display: 'grid', position: 'relative' }}>
            <BoxProjectCard color='tertiary' sx={{color: 'text.primary'}} onClick={() => setAllMode(true)}>
              {
                secondaryProjects.map((each) => <Image src={each.coverImage} alt={'project'} sx={{width: '110px', height: '110px', borderRadius: '8px'}}/> )
              }
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: alpha(theme.palette.background.default, 0.5),
                  top: 0,
                  left: 0,
                  flexDirection: 'column',
                }}
              >
                <Typography variant='h1'>+{projectAmount-3}</Typography>
              </Box>
              <BoxProjectName/>
              <Typography sx={{marginTop: '10px'}}>
                <Iconify icon={'mdi:cursor-pointer'}/>กดเพื่อดูโปรเจคทั้งหมด...
              </Typography>
            </BoxProjectCard>
          </Grid>
        }
      </Grid>
    </>
  )
}



const BoxProjectName = styled(Box) ({
  background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 39%, rgba(0,0,0,0.02) 100%)',
  borderColor: 'transparent',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '30%',
})


const BoxProjectCard = styled(Button)({
  justifyContent: 'start',
  position: 'relative',
  cursor: 'pointer',
  width: '250px',
  height: '250px',
  borderRadius: '10px',
  display: 'flex',
  padding: '10px',
  gap: '10px',
  flexWrap: 'wrap',
  '& .MuiBox-root': {
    borderRadius: '10px'
  },
  '&:hover': {
    '& .Box-ProjectName': {
      height: '50%',
      transition: '0.3s',
    },
    '& .MuiTypography-root': {
      height: '50%',
      transition: '0.3s',
    },
    '& .MuiBox-root img': {
      transform: 'scale(1.5)',
      transition: '0.3s',
    }
  }
})
