import React, { ReactNode } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export default function ChangePreview({children}: {children?: ReactNode}) {
  const theme = useTheme()
  return (
    <Box>
      <Typography variant='caption' sx={{color: 'text.disabled'}}>ตัวอย่างการเปลี่ยนแปลง</Typography>
      <Box sx={{padding: '10px', marginBottom: '5px', border: `solid 1px ${theme.palette.grey[50048]}`, borderRadius: '5px'}}>
        {children}
      </Box>
    </Box>
  )
}
