import React from "react";
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Box } from '@mui/material'
import { styled } from "@mui/material/styles";

import Image from '../components/Image'

interface PropsCard {
  topicName: string,
  backgroundImage: string,
  routerPathName: string
}

const ImageCard = styled(Image)({
  borderRadius: '10px',
  overflow: 'hidden'
})

const BoxProjectName = styled(Box) ({
  padding: '20px',
  background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 39%, rgba(0,0,0,0.02) 100%)',
  borderColor: 'transparent',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '30%',
})

const BoxProjectCard = styled(Box)({
  position: 'relative',
  cursor: 'pointer',
  width: '250px',
  height: '250px',
  '& .MuiBox-root': {
    borderRadius: '10px'
  },
  '&:hover': {
    '& .Box-ProjectName': {
      height: '50%',
      transition: '0.3s',
    },
    '& .MuiTypography-root': {
      height: '50%',
      transition: '0.3s',
    },
    '& .MuiBox-root img': {
      transform: 'scale(1.5)',
      transition: '0.3s',
    }
  }
})

function ProjectCard({ topicName, backgroundImage, routerPathName }: PropsCard) {
  return (
    <Box component={RouterLink} to={routerPathName}>
      <BoxProjectCard>
        <ImageCard
          src={`${backgroundImage}`}
          alt="project-cover"
          sx={{ width: '250px', height: '250px', transition: '0.3s' }}
        />

        <BoxProjectName className="Box-ProjectName" sx={{ position: 'absolute', bottom: 0, width: '100%', height: '30%' }}>
          <Typography
            variant="body1"
            sx={{
              color: 'common.white',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            >
              {topicName}
            </Typography>
        </BoxProjectName>
      </BoxProjectCard>
    </Box>
  )
}

export default ProjectCard
