import React, { useState, useEffect } from 'react'
import { Typography, Modal, Box, Button, Stack, TextField, CircularProgress, Switch, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import Icon from '../Iconify'
import axiosInstance from '../../utils/axios'

interface CertButtonProps {
  completedCourse: boolean,
  certificateId: string | null,
  courseId: string
  courseName: string
}

interface ReviewCourseButtonProps {
  courseId: string
  courseName: string
}

interface ReviewModalProps {
  courseId: string,
  openModal: boolean,
  setOpenModal: Function
  courseName: string
}

interface CommentStyleProps {
  focus: string
}


const ButtonBody = styled(Button)(({ theme }) => ({
  height: '36px',
  padding: '6px 18px',
  borderRadius: '8px',
  color: theme.palette.common.white
}))


const ReviewTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'focus'
})<CommentStyleProps>(({ focus, theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.grey[200],
  marginTop: '10px',
  fontSize: '14px',
  '&.MuiTextField-root': {
    'textarea, fieldset': {
      color: theme.palette.common.black,
      borderColor: theme.palette.grey[400],
    },
    '&:hover fieldset': {
      borderColor: focus === 'focus' ? theme.palette.primary.main : focus === 'error' ? theme.palette.error.main : theme.palette.grey[400]
    },
    borderRadius: '8px',
  }
}))

const RatingLabel = ['ควรปรับปรุง', 'ไม่ค่อยดี', 'พอใช้', 'ดีมาก', 'ยอดเยี่ยม!']


function ReviewModal({ courseId, openModal, setOpenModal, courseName }: ReviewModalProps) {
  const [focusTextField, setFocusTextField] = useState<boolean>(false)
  const [reviewText, setReviewText] = useState<string>('')

  const [errorTextField, setErrorTextField] = useState<boolean>(false)
  const [errorRateIcon, setErrorRateIcon] = useState<boolean>(false)

  const [hoverRateIcon, setHoverRateIcon] = useState<boolean[]>(Array(5).fill(false))
  const [rateCount, setRateCount] = useState<number>(0)

  const [openModalLoading, setOpenModalLoading] = useState<boolean>(false)
  const [anonymous, setAnonymous] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    setRateCount(0)
    setReviewText('')
    setErrorTextField(false)
    setErrorRateIcon(false)
    setAnonymous(false)
  }, [openModal])

  const onSubmitReview = async () => {
    try {
      if (!errorTextField) {
        await setOpenModalLoading(true)
        const res = await axiosInstance.post(`/courses/${courseId}/review`, {
          rate: rateCount,
          review: reviewText,
          anonymous: anonymous
        })
        navigate(`/certificate/${res.data.certificateId}`)
      }
    } catch (error) {
      await setOpenModalLoading(false)
      await setOpenModal(false)
    }
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        disableAutoFocus={true}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            maxWidth: '600px',
            width: '100%',
            padding: '20px 30px',
            color: 'grey.800',
            backgroundColor: 'common.white',
            borderRadius: '16px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Box>
            <Typography variant="h4">รีวิวคอร์สเรียน</Typography>
            <Typography variant="body1">{courseName}</Typography>
          </Box>

          <Stack direction="column" alignItems="center" sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <Stack direction="row" justifyContent="center">
              {Array(5).fill(true, 0, rateCount).fill(false, rateCount, 5).map((rateItem: boolean, index: number) => (
                <Icon
                  key={`rating-icon-${index}`}
                  icon="ant-design:star-filled"
                  sx={{
                    width: '40px',
                    height: '40px',
                    cursor: 'pointer',
                    color: rateItem ? 'warning.main' : hoverRateIcon[index] ? 'warning.light' : errorRateIcon ? 'error.light' : 'grey.500',
                  }}
                  onMouseEnter={() => setHoverRateIcon(Array(5).fill(true, 0, index + 1).fill(false, index + 1, 5))}
                  onMouseLeave={() => setHoverRateIcon(Array(5).fill(false))}
                  onClick={() => {
                    setErrorRateIcon(false)
                    setRateCount(index + 1)
                  }}
                />
              ))}
            </Stack>
            {
              rateCount ?
              <Box>
                <Typography variant='subtitle1' sx={{color: 'primary.dark'}}>{RatingLabel[rateCount-1]}</Typography>
              </Box>
              :
              <></>
            }


            {errorRateIcon ? (
              <Typography variant="caption" sx={{ color: 'error.main' }}>กรุณาเลือกระดับความพึงพอใจของคุณ</Typography>
            ) : null}
          </Stack>

          <Box sx={{ padding: '15px', backgroundColor: 'grey.300', borderRadius: '8px' }}>
            <Typography variant="subtitle1">ความคิดเห็นของคุณ:</Typography>
            <ReviewTextField
              multiline
              rows={5}
              error={errorTextField}
              focus={errorTextField ? 'error' : focusTextField ? 'focus' : 'default'}
              placeholder="เเสดงความคิดเห็น.."
              value={reviewText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setErrorTextField(event.target.value.replace(/(?:\r\n|\r|\n|\s)/g, '').length === 0)
                setReviewText(event.target.value)
              }}
              onFocus={() => setFocusTextField(true)}
              onBlur={() => setFocusTextField(false)}
            />

            {errorTextField ? (
              <Typography variant="caption" sx={{ color: 'error.main' }}>กรุณาใส่เเสดงความคิดเห็นของคุณ</Typography>
            ) : null}
          </Box>

          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            sx={{
              marginTop: '10px',
              marginBottom: '25px',
              '.MuiSwitch-track': {
                backgroundColor: 'grey.400'
              }
            }}
          >
            <Switch
              checked={anonymous}
              onChange={() => setAnonymous(!anonymous)}
            />
              <Typography variant="subtitle2" sx={{ color: 'grey.600' }}>รีวิวเเบบไม่เเสดงตัวตน</Typography>

              {window.innerWidth > 470 ? (
                <Typography variant="caption" sx={{ marginLeft: '5px', color: 'grey.600' }}>(ระบบจะทำการปิดชื่อเเละข้อมูลของผู้เรียน)</Typography>
              ) : null}
          </Stack>

          <Stack direction="row" justifyContent="end" sx={{ marginTop: '15px' }} spacing={1}>
            <Button
              sx={{
                backgroundColor: 'grey.500',
                color: 'common.white',
                ':hover': {
                  backgroundColor: 'grey.700',
                }
              }}
              onClick={() => setOpenModal(false)}
            >
              ยกเลิก
            </Button>
            <Button
              sx={{
                backgroundColor: 'primary.main',
                color: 'common.white',
                ':hover': {
                  backgroundColor: 'primary.dark',
                }
              }}
              onClick={() => {
                if (reviewText !== '' && rateCount !== 0) onSubmitReview()
                else {
                  if (reviewText === '') setErrorTextField(true)
                  if (rateCount === 0) setErrorRateIcon(true)
                }
              }}
            >
              ยืนยัน
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openModalLoading}
        disableAutoFocus={true}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: '20px 50px',
            height: '150px',
            color: 'common.black',
            backgroundColor: 'common.white',
            borderRadius: '16px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <CircularProgress />
          <Typography variant="subtitle1" sx={{ marginTop: '20px' }}>กำลังส่งรีวิว โปรดลองสักครู่</Typography>
        </Stack>
      </Modal>
    </>
  )
}


export default function CertificateButton({ completedCourse, certificateId, courseId, courseName }: CertButtonProps) {
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false)
  const getCertifiacate = () => {
    if (certificateId === null) setOpenReviewModal(true)
  }
  return (
    <>
      <ButtonBody
        color="success"
        variant="contained"
        href={certificateId !== null ? `/certificate/${certificateId}` : ''}
        onClick={() => getCertifiacate()}
        disabled={!completedCourse}
      >
        <Icon
          icon="el:download-alt"
          sx={{
            width: '11px',
            height: '11px',
            marginRight: '8px',
          }}
        />
        <Typography variant="button">รับใบ Certificate</Typography>
      </ButtonBody>


      <ReviewModal
        courseId={courseId}
        openModal={openReviewModal}
        setOpenModal={setOpenReviewModal}
        courseName={courseName}
      />
    </>
  )
}

export function ReviewCourseButton({ courseName, courseId }: ReviewCourseButtonProps) {
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false)

  return (
    <>
      <Button
        variant='outlined'
        onClick={() => setOpenReviewModal(true)}
        sx={{borderRadius: '100px',}}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon
            icon="tabler:certificate"
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
          รีวิวเพื่อรับ certificate
        </Box>
      </Button>
      <ReviewModal
        courseId={courseId}
        openModal={openReviewModal}
        setOpenModal={setOpenReviewModal}
        courseName={courseName}
      />
    </>
  )
}
