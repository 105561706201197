import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import axios from '../../../utils/axios';
import { useSelector } from 'react-redux';
import { useDispatch, RootState } from '../../../redux/store';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  setLessonTimeStamp,
  setOnSeek,
  getCourseLessons,
} from '../../../redux/slices/lesson';
import { videoType, videoProgressType } from '../../../@types/lesson';
import queryString from 'query-string';
import Iconify from '../../../components/Iconify';
import BytearkPlayer from './player/BytearkPlayer';

export default function VideoPlayer({
  video,
  progress,
  sectionId,
  courseId,
  setShowFooter,
}: {
  video: videoType;
  progress: videoProgressType;
  sectionId: string;
  courseId: string;
  setShowFooter: Function;
}) {
  const { search } = useLocation();
  const { at } = queryString.parse(search);
  const dispatch = useDispatch();
  const { courseSlug } = useParams();
  const navigate = useNavigate()
  const courseData = useSelector(
    (state: RootState) => state.lesson.courseLessons,
  );

  const [prevTimeStamp, setPrevTimeStamp] = useState<number>(
    progress?.timestamp ?? 0,
  );
  const lessonTimeStamp = useSelector(
    (state: RootState) => state.lesson.lessonTimeStamp,
  );

  function updateVideoProgress(time: number) {
    if (!courseData.enrolled || courseData.status === 'PREREGISTER') return;
    dispatch(setLessonTimeStamp(time));
  }

  useEffect(() => {
    (() => {
      if (!courseData.enrolled || courseData.status === 'PREREGISTER') return;
      if (
        lessonTimeStamp - prevTimeStamp >= 5 ||
        lessonTimeStamp - prevTimeStamp <= 0
      ) {
        setPrevTimeStamp(lessonTimeStamp);
        const payload = {
          lessonId: video.lessonId,
          lessonVideoId: video.id,
          duration: video.duration,
          timestamp: lessonTimeStamp,
          courseId: courseId,
        };
        putLessonVideoProgress(payload);
      }
    })();
  }, [lessonTimeStamp]);

  const putLessonVideoProgress = async (payload: {
    lessonId: string;
    lessonVideoId: string;
    duration: number;
    timestamp: number;
    courseId: string;
  }) => {
    try {
      const res = await axios.put(`/progress/video`, payload);
      res.data.ended && setLessonSuccess();
    } catch (error: any) {
      if(error?.statusCode === 403) navigate(`/course/${courseSlug}`, { replace: true })
    }
  };

  function setLessonSuccess() {
    const currentSection = courseData.sections.find(
      (section: any) => section.id === sectionId,
    ) as any;
    const currentLesson = currentSection.lessons.find(
      (lesson: any) => lesson.id === video.lessonId,
    ) as any;
    // @ts-ignore redux type error
    !currentLesson.completed && dispatch(getCourseLessons(courseSlug || ''));
  }

  function onEnded() {
    setShowFooter(true);
    putLessonVideoProgress({
      lessonId: video.lessonId,
      lessonVideoId: video.id,
      duration: video.duration,
      timestamp: lessonTimeStamp,
      courseId: courseId,
    });
  }

  function ism3u8() {
    return video.url.split('.').at(-1) === 'm3u8';
  }

  return (
    <>
      {ism3u8() ? (
        <BytearkPlayer
          url={video.url}
          onProgress={updateVideoProgress}
          at={at || ''}
          timestamp={progress?.ended ? 0 : progress?.timestamp}
          onEnded={onEnded}
        />
      ) : (
        <Box
          sx={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}
        >
          <ReactLessonPlayer
            url={video.url}
            onProgress={updateVideoProgress}
            at={at || ''}
            timestamp={progress?.ended ? 0 : progress?.timestamp}
            onEnded={onEnded}
          />
        </Box>
      )}
    </>
  );
}

interface ReactPlayerType {
  url: string;
  onProgress: Function;
  onEnded: Function;
  timestamp: number;
  at?: string | (string | null)[];
}

function ReactLessonPlayer({
  url,
  onProgress,
  onEnded,
  timestamp,
  at,
}: ReactPlayerType) {
  const dispatch = useDispatch();

  const videoRef = React.createRef<ReactPlayer>();
  const seekTimeStamp = useSelector(
    (state: RootState) => state.lesson.seekTimeStamp,
  );
  const onSeek = useSelector((state: RootState) => state.lesson.onSeek);

  useEffect(() => {
    if (videoRef?.current && onSeek) {
      videoRef.current.seekTo(seekTimeStamp);
      dispatch(setOnSeek(false));
    }
  }, [onSeek]);

  return (
    <ReactPlayer
      ref={videoRef}
      url={url}
      controls={true}
      width="100%"
      height="100%"
      style={{ position: 'absolute' }}
      onProgress={(e) => onProgress(e.playedSeconds)}
      onReady={(e) =>
        e.seekTo(at ? parseFloat(at as string) : timestamp, 'seconds')
      }
      onEnded={() => onEnded()}
      playing={true}
    />
  );
}

export function VideoMaintenance() {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Iconify
          sx={{ color: 'primary.main', width: '40px', height: '40px' }}
          icon="material-symbols:video-camera-front-off-rounded"
        />
        <Iconify
          sx={{ color: 'primary.main', width: '35px', height: '35px' }}
          icon="icon-park-solid:spanner"
        />
        <Typography>
          ขณะนี้ระบบดูวิดิโอกำลังปิดปรับปรุง เพื่อไปใช้ระบบใหม่ที่ดียิ่งขึ้น
        </Typography>
      </Box>
    </Box>
  );
}
