import React, { useState } from 'react';
import {
  Box,
  FormControlLabel,
  Checkbox,
  Divider,
  useTheme,
  Typography,
} from '@mui/material';

import { profileStatusType } from '../../../@types/profile';

import axiosInstance from '../../../utils/axios';
import { ProfileStatus } from '../profileDetail/ProfileCover';
import EditorHeader from './EditorHeader';
import ChangePreview from './ChangePreview';

interface ProfileStatusEditorType {
  id: string;
  isActive: boolean;
  title: string;
  displayOrder: number;
}

interface ProfileStatusEditorProps {
  profileStatus: profileStatusType[];
}

interface ProfileStatusCheckboxProps {
  title: string;
  isActive: boolean;
  onChange: Function;
}

function profileStatusEditorFormat(profileStatus: profileStatusType[]) {
  return profileStatus.map((each) => {
    return {
      id: each.id,
      isActive: each.isActive,
      title: each.title,
      displayOrder: each.displayOrder,
    } as ProfileStatusEditorType;
  });
}

export default function ProfileStatusEditor({
  profileStatus,
}: ProfileStatusEditorProps) {
  const [initStatus, setInitStatus] = useState(true);
  const [status, setStatus] = useState(
    profileStatusEditorFormat(profileStatus),
  );

  function onChange(title: string, value: boolean) {
    const editedValue = {
      ...status.find((each) => each.title === title),
      isActive: value,
    } as ProfileStatusEditorType;
    const editedArray = [
      ...status.filter((each) => each.title !== title),
      editedValue,
    ].sort((a, b) => a.displayOrder - b.displayOrder);
    setInitStatus(false);
    setStatus(editedArray);
  }

  async function onSave() {
    await axiosInstance.put('/users/profile/statuses', {
      profileStatus: status,
    });
    setInitStatus(true);
  }

  return (
    <>
      <EditorHeader
        title={'สถานะการทำงาน'}
        onSubmit={() => onSave()}
        disabled={initStatus}
      />
      {status.map((each, index) => (
        <ProfileStatusCheckbox
          key={`ProfileStatusCheckbox-${index + 1}`}
          title={each.title}
          isActive={each.isActive}
          onChange={onChange}
        />
      ))}
      <ChangePreview>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          {status.map(
            (status, index) =>
              status.isActive && (
                <ProfileStatus
                  key={`status-${index + 1}`}
                  text={status.title}
                />
              ),
          )}
        </Box>
      </ChangePreview>
      <Divider sx={{ marginY: '10px' }} />
    </>
  );
}

function ProfileStatusCheckbox({
  title,
  isActive,
  onChange,
}: ProfileStatusCheckboxProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isActive}
          onChange={() => onChange(title, !isActive)}
        />
      }
      label={title}
    />
  );
}
