import React from 'react';
import { useParams, NavLink as RouterLink } from 'react-router-dom';
import { Typography, Box, Button, useTheme } from '@mui/material';
import { LessonIconStyle } from './style';
import Iconify from '../../../components/Iconify';
import { sectionType, lessonType } from '../../../@types/courseLesson';
import { useSelector } from '../../../redux/store'
import { alpha } from '@mui/material/styles';

export default function Exercises({lessons}: {lessons: sectionType[]}) {
  return (
    <Box>
      {lessons.map((section) => 
        section.lessons.map((item) => (
          (item.isEndQuestion || item.type === 'QUIZ' || item.type === 'DEVLAB') && <LessonItem key={item.title} lesson={item} />
        ))
      )}
    </Box>
  )
}

const LESSON_ICONS: {[key: string]: string} = {
  VIDEO: 'ci:play-circle-outline',
  CHECKED: 'akar-icons:check',
  CAUTION: 'eva:alert-triangle-outline',
  DEVLAB: 'fluent:window-dev-edit-16-filled',
  TEXT: 'gg:loadbar-doc',
  QUIZ: 'ic:round-quiz',
  LOCKED: 'material-symbols:lock'
}

function LessonItem({ lesson }:{ lesson: lessonType}) {
  const { courseLessons } = useSelector((state) => state.lesson)
  const theme = useTheme()
  const { courseSlug, lessonId } = useParams();
  const duration = (duration: number) => {
    const secondLesson = '' + Math.floor(duration%60)
    return duration ? `(${Math.floor(duration/60)}:${'00'.substring(0, 2 - secondLesson.length) + secondLesson} นาที)` : ''
  }
  const isCurrentLesson = lessonId === lesson.id

  function isLocked() {
    return (!courseLessons.enrolled || courseLessons.status === "PREREGISTER") && !lesson.isPreview
  }

  return (
      <Button
        component={RouterLink}
        to={`/course/${courseSlug}/${lesson.id}`}
        color='primary'
        disabled={isLocked()}
        sx={{
          color: isCurrentLesson ? 'primary.main' : 'text.secondary',
          backgroundColor: alpha(theme.palette.primary.main, isCurrentLesson ? 0.1: 0),
          display: 'flex',
          marginLeft: '14px',
          marginRight: '14px',
          padding: '16px',
          textDecoration: 'none',
          borderRadius: '8px'
        }}
      >
        <LessonIconStyle>
          {
            isLocked() ?
            <Iconify
              sx={{ width: '20px', height: '20px' }}
              icon={LESSON_ICONS.LOCKED}/>
            :
            (lesson.completed || lesson.quizPassed === false) &&
            <Iconify
              sx={{ color: isCurrentLesson ? 'primary.main' : '', width: '20px', height: '20px' }}
              icon={lesson.completed ? LESSON_ICONS.CHECKED : LESSON_ICONS.CAUTION}/>
          }
        </LessonIconStyle>
        <LessonIconStyle>
          <Iconify
            sx={{ color: isCurrentLesson ? 'primary.main' : '', width: '20px', height: '20px' }}
            icon={LESSON_ICONS[lesson.type]}/>
        </LessonIconStyle>
        <Typography variant="subtitle2" sx={{width:'100%', wordBreak: 'break-word'}}>{lesson.title} {duration(lesson.duration)}</Typography>
      </Button>
  )
}

