import React from 'react'
import { Typography, TypographyTypeMap } from '@mui/material'
import { Link } from 'react-router-dom'

export default function TextLink({link, text, typographyVariant, sx}: {link?: string, text: string, typographyVariant?: TypographyTypeMap['props']['variant'], sx?: Object}) {
  return (
    <>
      {
        link ?
        <Link to={link} style={{textDecoration: 'none'}}>
          <Typography
            variant={typographyVariant}
            sx={{
              wordBreak: 'break-word',
              textDecoration: 'none',
              color: 'text.primary',
              ':hover': { color: 'primary.main' },
              ...sx
            }}
          >
            {text}
          </Typography>
        </Link>
        :
        <Typography variant={typographyVariant} sx={{wordBreak: 'break-word', ...sx}}>{text}</Typography>
      }
    </>
  )
}
