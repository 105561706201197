import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Stack,
  Grid,
  Avatar,
  LinearProgress,
  Divider,
  Modal,
  Button,
  Pagination,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import {
  ReviewCourseType,
  ReviewDetailType,
  OverviewReviewType,
} from '../../../@types/reviewCourse';
import axiosInstance from '../../../utils/axios';
import Icon from '../../../components/Iconify';
import ReviewRateStar from '../../../components/ReviewRateStar';
import TextLink from '../../../components/link/Text';
import GeneralLink from '../../../components/link/General';
import {
  ProfileDetail,
  ProfileDetailTooltip,
} from '../../profile/ProfileDetailTooltip';
import { fTSeparator } from '../../../utils/formatNumber';

interface OverviewReviewProps {
  overviewData?: OverviewReviewType;
}

interface ReviewCommentProps {
  reviewData: ReviewDetailType;
}

interface ReviewComponentprops {
  courseId?: string;
  overviewData: OverviewReviewType;
}

interface RateBarProps {
  barColor: string;
}

interface ModalReviewProps {
  openModal: boolean;
  setOpenModal: Function;
  reviewList?: ReviewDetailType[];
}

const RateBar = styled(LinearProgress, {
  shouldForwardProp: (props) => props !== 'barColor',
})<RateBarProps>(({ barColor, theme }) => ({
  '.MuiLinearProgress-bar': {
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    backgroundColor: barColor,
  },
  height: '8px',
  borderTopRightRadius: '2px',
  borderBottomRightRadius: '2px',
  backgroundColor: theme.palette.grey[500_24],
}));

function OverviewReview({ overviewData }: OverviewReviewProps) {
  return (
    <Box>
      <Box sx={{ display: 'flex', marginTop: '35px', gap: '5px' }}>
        <Typography variant="h4" sx={{ marginY: '5px' }}>
          รีวิวจากผู้เรียน
        </Typography>
        <Box>
          <Typography
            variant="caption"
            sx={{ color: 'grey.500', top: 0, left: 0 }}
          >{`(${fTSeparator(overviewData?.count || 0)} รีวิว)`}</Typography>
        </Box>
      </Box>
      <Grid
        container
        sx={{
          width: '100%',
          gap: '20px',
          marginLeft: '30px',
          marginTop: '15px',
        }}
      >
        <Typography variant="h2" sx={{ color: 'warning.main' }}>
          {overviewData?.avg}
        </Typography>
        <Box>
          <Stack direction="row" sx={{ marginTop: '5px' }}>
            {Array(5)
              .fill(0)
              .map((_, index: number) => (
                <ReviewRateStar
                  key={`review-rate-star-${index}`}
                  width="24px"
                  height="24px"
                  display={overviewData?.avg ? overviewData?.avg - index : 0}
                />
              ))}
          </Stack>
          <Typography
            variant="caption"
            sx={{ color: 'grey.500', marginLeft: '10px' }}
          >{`จาก ${fTSeparator(overviewData?.count || 0)} รีวิว`}</Typography>
        </Box>
      </Grid>
    </Box>
  );
}

function ReviewComment({ reviewData }: ReviewCommentProps) {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [heightComment, setHeightComment] = useState<number>();

  const [commentTextSyle, setCommentTextSyle] = useState({
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    lineHeight: '20px',
    overflow: 'hidden',
    height: 'auto',
    maxHeight: '60px',
    transition: 'max-height .1s ease-out',
    textOverflow: 'ellipsis',
  });

  useEffect(() => {
    setHeightComment(
      document.getElementById(`review-${reviewData.id}`)?.scrollHeight,
    );
  }, [reviewData]);

  const getDate = (value?: Date): string => {
    const date = new Date(value || '');
    const month: string[] = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];
    return `${date.getDate()} ${month[date.getMonth()]} ${
      date.getFullYear() + 543
    }`;
  };

  return (
    <Box sx={{ marginTop: '25px' }}>
      <Stack direction="row">
        {reviewData?.userId ? (
          <>
            {reviewData?.user.role === 'STUDENT' ? (
              <GeneralLink
                link={
                  reviewData?.userId ? `/profile/${reviewData?.userId}` : ''
                }
              >
                <ProfileDetailTooltip
                  title={ProfileDetail(reviewData?.user)}
                  placement="top-start"
                >
                  <Avatar
                    alt={reviewData?.user.publicName}
                    src={reviewData?.user.imageUrl}
                    sx={{ width: '48px', height: '48px', marginRight: '16px' }}
                  />
                </ProfileDetailTooltip>
              </GeneralLink>
            ) : (
              <Avatar
                alt={reviewData?.user.publicName}
                src={reviewData?.user.imageUrl}
                sx={{ width: '48px', height: '48px', marginRight: '16px' }}
              />
            )}
          </>
        ) : (
          <Avatar
            alt={reviewData?.user.publicName}
            sx={{ width: '48px', height: '48px', marginRight: '16px' }}
          />
        )}
        <Stack direction="column">
          {reviewData?.userId ? (
            <>
              {reviewData?.user.role === 'STUDENT' ? (
                <ProfileDetailTooltip
                  title={ProfileDetail(reviewData?.user)}
                  placement="top-start"
                >
                  <Box>
                    <TextLink
                      link={
                        reviewData?.userId
                          ? `/profile/${reviewData?.userId}`
                          : ''
                      }
                      text={reviewData?.user.publicName}
                      typographyVariant="subtitle1"
                    />
                  </Box>
                </ProfileDetailTooltip>
              ) : (
                <TextLink
                  link={''}
                  text={reviewData?.user.publicName}
                  typographyVariant="subtitle1"
                />
              )}
            </>
          ) : (
            <TextLink
              link={reviewData?.userId ? `/profile/${reviewData?.userId}` : ''}
              text={reviewData?.user.publicName}
              typographyVariant="subtitle1"
            />
          )}

          <Stack direction="row" sx={{ margin: '2px 0px' }}>
            {Array(5)
              .fill(true, 0, reviewData.rate)
              .fill(false, reviewData.rate, 5)
              .map((rate: boolean, index: number) => (
                <Icon
                  key={`rate-reveiw-comment-${index}`}
                  icon="ant-design:star-filled"
                  sx={{
                    width: '18px',
                    height: '18px',
                    color: rate ? 'warning.main' : 'grey.500',
                  }}
                />
              ))}
          </Stack>

          <Typography
            variant="caption"
            sx={{ marginBottom: '8px', color: 'text.secondary' }}
          >
            {getDate(reviewData.createdAt)}
          </Typography>

          <Typography
            id={`review-${reviewData.id}`}
            variant="body2"
            sx={{ ...commentTextSyle }}
          >
            {reviewData.review}
          </Typography>

          {heightComment && heightComment - 2 > 60 ? (
            <Button
              variant="text"
              disableRipple={true}
              sx={{
                width: '90px',
                textAlign: 'left',
                color: 'grey.500',
                padding: '0px',
                marginLeft: '5px',
                ':hover': {
                  backgroundColor: 'transparent',
                  color: 'grey.600',
                },
              }}
              onClick={() => {
                setShowMore((prevState) => !prevState);
                !showMore
                  ? setCommentTextSyle({
                      ...commentTextSyle,
                      maxHeight: '100%',
                    })
                  : setCommentTextSyle({
                      ...commentTextSyle,
                      maxHeight: '60px',
                    });
              }}
            >
              {!showMore ? '...อ่านเพิ่มเติม' : '...เเสดงน้อยลง'}
            </Button>
          ) : null}
        </Stack>
      </Stack>

      <Divider sx={{ marginTop: '20px' }} />
    </Box>
  );
}

export default function Review({
  courseId,
  overviewData,
}: ReviewComponentprops) {
  const [loading, setLoading] = useState<boolean>(false);
  const [reviewData, setReviewData] = useState<ReviewCourseType>();
  const [page, setPage] = useState(1);
  const take = 5;

  const fetchReviewData = async (nextPage: number) => {
    try {
      setLoading(true);
      const response = await axiosInstance(
        `/courses/${courseId}/review?page=${nextPage}&take=${take}`,
      );
      const data = await response.data;
      setReviewData(data);
      setLoading(false);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchReviewData(1);
  }, []);

  if (reviewData?.data && reviewData?.data.length > 0) {
    return (
      <>
        <OverviewReview overviewData={overviewData} />
        <Typography variant="h4" sx={{ marginTop: '36px' }}>
          ความคิดเห็น
        </Typography>
        {reviewData?.data.map((reviewItem: ReviewDetailType, index: number) => (
          <ReviewComment
            key={`review-comment-${index}`}
            reviewData={reviewItem}
          />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <Pagination
            disabled={loading}
            count={reviewData.meta.pageCount}
            onChange={(e, value) => {
              setPage(value);
              fetchReviewData(value);
            }}
            page={page}
          />
        </Box>
      </>
    );
  }

  return null;
}

function OverviewFullReview({ overviewData }: OverviewReviewProps) {
  const theme = useTheme();
  const colorStyleRateBar: string[] = [
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.warning.light,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];

  const convertPercentage = (value: number, allCount: number): number => {
    return (value / allCount) * 100;
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ marginTop: '40px', marginBottom: '12px' }}>
        รีวิวจากผู้เรียน
      </Typography>

      <Grid
        container
        justifyContent="center"
        sx={{ width: '100%', gap: '20px' }}
      >
        <Grid item>
          <Stack direction="column" alignItems="center">
            <Typography variant="h2" sx={{ color: 'warning.main' }}>
              {overviewData?.avg}
            </Typography>

            <Stack direction="row">
              {Array(5)
                .fill(0)
                .map((_, index: number) => (
                  <ReviewRateStar
                    key={`review-rate-star-${index}`}
                    width="24px"
                    height="24px"
                    display={overviewData?.avg ? overviewData?.avg - index : 0}
                  />
                ))}
            </Stack>

            <Typography
              variant="caption"
              sx={{ color: 'grey.500' }}
            >{`จาก ${fTSeparator(overviewData?.count || 0)} รีวิว`}</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} lg={9} sx={{ width: '100%' }}>
          {overviewData?.rate.map(
            (item: { count: number; rate: number }, index: number) => (
              <Stack
                key={`overviewData-${index}`}
                direction="row"
                alignItems="center"
                sx={{ height: '19px' }}
              >
                <Box
                  sx={{
                    width: '10px',
                    textAlign: 'right',
                    marginRight: '11px',
                  }}
                >
                  <Typography
                    key={`review-rate-bar-${index}`}
                    variant="caption"
                    sx={{ color: 'grey.500' }}
                  >
                    {item.rate}
                  </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <RateBar
                    variant="determinate"
                    value={convertPercentage(item.count, overviewData.max)}
                    barColor={colorStyleRateBar[index]}
                  />
                </Box>

                <Box
                  sx={{
                    width: '45px',
                    textAlign: 'left',
                    marginTop: '-5px',
                    marginLeft: '11px',
                  }}
                >
                  <Typography variant="caption" sx={{ color: 'grey.500' }}>
                    {item.count}
                  </Typography>
                </Box>
              </Stack>
            ),
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

function ModalReviewComment({
  openModal,
  setOpenModal,
  reviewList,
}: ModalReviewProps) {
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      disableAutoFocus={true}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          height: '500px',
          color: 'text.primary',
          padding: '30px 40px',
          backgroundColor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 25,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Grid item xs={12} sm>
            <Typography variant="h4">ความคิดเห็นเพิ่มเติม</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm
            justifyContent={window.innerWidth < 600 ? 'start' : 'end'}
          >
            <Typography
              variant="body2"
              sx={{
                color: 'error.main',
                cursor: 'pointer',
                ':hover': { textDecoration: 'underline', fontWeight: 'bold' },
              }}
              onClick={() => setOpenModal(false)}
            >
              ปิดหน้าต่าง
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            height: '90%',
            boxShadow: '-10px 5px 10px #00000010 inset',
            marginTop: '10px',
            paddingRight: '30px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.4em',
              height: '0.5em',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'grey.400',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.600',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'grey.700',
              },
            },
          }}
        >
          {reviewList?.map((reviewItem: ReviewDetailType, index: number) => (
            <ReviewComment
              key={`ReviewComment-${index}`}
              reviewData={reviewItem}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
}
