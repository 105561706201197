import { Drawer, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch, RootState, useSelector } from '../../redux/store';
import { setBornbitOpen } from '../../redux/slices/config';
import CheckinLog from './CheckinLog/CheckinLog';
import CheckinStat from './CheckinStat';
import Iconify from '../Iconify';

export default function BornbitSidebar() {
  const { bornbitOpen } = useSelector((state: RootState) => state.config)
  const dispatch = useDispatch()

  function closeAll() {
    dispatch(setBornbitOpen(false))
  }

  return (
    <SideNavStyle
      anchor="right"
      open={bornbitOpen}
      onClose={() => dispatch(setBornbitOpen(false))}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          padding: '30px 20px 0px 20px'
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant='h3' sx={{color: 'primary.main'}}>
            <Iconify sx={{marginTop: '10px'}} icon='mdi:alpha-b-box-outline'/>
          </Typography>
          <Typography variant='h3' color='primary.main'>
            Bornbits
          </Typography>
        </Stack>
        
        <IconButton onClick={() => dispatch(setBornbitOpen(false))}>
          <Iconify icon='mingcute:close-fill'/>
        </IconButton>

      </Stack>
      <CheckinStat closeAll={() => closeAll()}/>
      <CheckinLog />
    </SideNavStyle>
  )
}

const SideNavStyle = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    maxWidth: '380px',
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'none',
    width: '100%',
    borderLeft: '0px',
  },
  '*::-webkit-scrollbar': {
    width: '0.5em',
  },
  '*::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: 'slategrey',
    borderRadius: '8px',
  },
}));