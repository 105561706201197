import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import ProfileSkeleton from '../../components/skeleton/Profile';
import { RootState } from '../../redux/store';

import ProfileDetail from './profileDetail';
import ProfileEditor from './profileEditor';
import ProfileProjects from './profileProject';

interface Props {
  profileId: string;
  isSelf: boolean;
  editMode: boolean;
  setEditMode: Function;
}

export default function ProfileController({
  profileId,
  isSelf,
  editMode,
  setEditMode,
}: Props) {
  const { profileData, loading, profileLoading } = useSelector(
    (state: RootState) => state.profile,
  );

  return (
    <Box
      sx={{
        borderRadius: '16px',
        marginBottom: '10px',
        paddingBottom: '10px',
        boxShadow: '0px 16px 32px -4px #0000003D',
        backgroundColor: 'background.paper',
      }}
    >
      {!loading && !profileLoading && profileData !== null ? (
        editMode ? (
          <ProfileEditor
            profileData={profileData}
            swapToDetail={() => setEditMode(false)}
          />
        ) : (
          <ProfileDetail
            profileData={profileData}
            isSelf={isSelf}
            swapToEdit={() => setEditMode(true)}
          />
        )
      ) : (
        <ProfileSkeleton />
      )}
      <ProfileProjects
        profileId={profileId}
        editMode={editMode}
        name={profileData?.user?.publicName || ''}
      />
    </Box>
  );
}
