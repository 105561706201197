import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Iconify from '../../../components/Iconify';
import { ProfileDevlabStatType } from '../../../@types/profile';
import { fTSeparator } from '../../../utils/formatNumber';
import { RootState } from '../../../redux/store';

export default function DevlabStat() {
  const { devlabStatistic, loading } = useSelector(
    (state: RootState) => state.profile,
  );

  function isNotShow() {
    const isAnyNull = _.values(devlabStatistic).some((each) => each === null);
    return isAnyNull;
  }

  if (!devlabStatistic || isNotShow()) return null;

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Typography variant="h4">สถิติการใช้งาน DevLab</Typography>
      <DLIconLabel
        icon={'healthicons:award-trophy'}
        label={
          <DLLabel
            text={'คะแนนรวม'}
            value={fTSeparator(devlabStatistic.score || 0)}
          />
        }
      />
      <DLIconLabel
        icon={'fluent:ribbon-12-filled'}
        label={
          <DLLabel
            text={'อันดับ'}
            value={fTSeparator(devlabStatistic.rank || 0)}
          />
        }
      />
      <DLIconLabel
        icon={'material-symbols:check-circle-rounded'}
        label={
          <DLLabel
            text={'ข้อที่ทำผ่าน'}
            value={fTSeparator(devlabStatistic.passed || 0)}
            postFix={'ข้อ'}
          />
        }
      />
    </Box>
  );
}

function DLIconLabel({ icon, label }: { icon: string; label: JSX.Element }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginBottom: '5px',
      }}
    >
      <Iconify
        icon={icon}
        sx={{ width: '25px', height: '25px', color: 'primary.light' }}
      />
      {label}
    </Box>
  );
}

function DLLabel({
  text,
  value,
  postFix,
}: {
  text: string;
  value: string;
  postFix?: string;
}) {
  return (
    <Typography
      variant={'subtitle2'}
      sx={{
        wordBreak: 'break-all',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      {text}
      <Typography sx={{ color: 'primary.light' }}>{value}</Typography>
      {postFix}
    </Typography>
  );
}
