import React from 'react';
import { styled } from '@mui/material/styles';
import { HEADER } from '../../../config';
import { Box, Container } from '@mui/material';
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import { Link as RouterLink } from 'react-router-dom';

export default function HeadNavMobile({setOpenNav} : {setOpenNav : Function}) {
  return (
    <HeadNavStyle>
      <Container sx={{height: '100%'}}>
        <Box sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Box component={RouterLink} to={'/'}>
            <Image visibleByDefault alt='logo' src="/logos/school-full-225x72.png" sx={{width: '120px'}}/>
          </Box>
          <Iconify
            icon='dashicons:menu-alt3'
            sx={{width: '24px', height: '24px', marginRight: '10px', cursor: 'pointer', ':hover' : {color: 'primary.main'}}}
            onClick={() => setOpenNav(true)}
          />
        </Box>
      </Container>
    </HeadNavStyle>
  )
}

const HeadNavStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: HEADER.MOBILE_HEIGHT,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 2px 20px -5px rgba(0,0,0,0.30)',
  zIndex: 10,
  '@media (min-width: 1200px)': {
    display: 'none'
  },
}))
