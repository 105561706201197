
import { Divider, Typography } from '@mui/material';
import TopRank from './toprank';
import Userranks from './userrank';
import React, {useState} from 'react';
import { Container } from '@mui/material';
import axios from '../../utils/axios';
import { LeaderboardUserType } from './type';
import { LeaderboardFilter } from './filter';
import { TopRankSectionSkeleton, UserRankSectionSkeleton } from './Skeleton';
import { ContentCardStyle } from './utils';

export default function Leaderboard() {
  const [toprankData, setToprankData] = useState<LeaderboardUserType[]>([] as LeaderboardUserType[])
  const [userrankData, setUserrankData] = useState<LeaderboardUserType[]>([] as LeaderboardUserType[])
  const [loading, setLoading] = useState(true)

  const getLeaderboardData = async (params: string) => {
    try {
      setLoading(true)
      const response = await axios.get(`/bornbits/leaderboard`+params)
      const data = await response.data
      setToprankData(data.leader || [])
      setUserrankData(data.aroundUser || [])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  function applyFilter(category: string, duration: string) {
    const durationParam = `?groupBy=${duration}`
    const categoryParams = category === 'ALL' ? '' : `&categories=${category}`
    getLeaderboardData(durationParam + categoryParams)
  }

  const selfTop = toprankData.some(data => data.self)

  return (
    <Container
      sx={{
        padding: '0px',
        marginTop: '30px',
        maxWidth: '1062.38px',
        '@media (min-width: 2000px)': {
          maxWidth:'1280px'
        },
      }}>
        <Typography variant='h3' sx={{textAlign: 'center', marginTop: '40px'}}> 
          อันดับสูงสุดของระบบ
        </Typography>
        <Typography variant='body1' sx={{textAlign: 'center', marginBottom: '20px'}}> 
          รวมผู้มีความสามารถไว้ให้โลกได้จารึก
        </Typography>
        <LeaderboardFilter applyFilter={applyFilter}/>
        {
          loading ?
          <>
            <TopRankSectionSkeleton />
            <ContentCardStyle sx={{margin: 'auto', padding: '0px 20px', maxWidth: '900px'}}>
              <UserRankSectionSkeleton />
            </ContentCardStyle>
          </>
          :
          <>
            <TopRank toprankData={toprankData}/>
            <Divider sx={{margin: '40px auto', width: '70%'}}/>
            <ContentCardStyle sx={{margin: 'auto', padding: '0px', maxWidth: '900px'}}>
              {
                selfTop ?
                <></> :
                userrankData.length ? 
                  <Userranks userrankData={userrankData}/> : 
                  <Typography sx={{textAlign: 'center', marginY: '20px'}}>- ยังไม่มีข้อมูลของคุณ -</Typography>
              }
              
            </ContentCardStyle>
          </>
        }
    </Container>
  )
}

