import React from 'react';
import { Box, Stack, Typography, LinearProgress } from '@mui/material';

import Iconify from '../Iconify';
import { RatingInCard } from '../Rating';
import {
  courseCatalogCardType,
  WcProductDataType,
} from '../../@types/courseCatalog';
import { CourseStatusType } from '../../@types/courseData';
import { MIN_STU_SHOWN } from '../../config';

type CourseCardContentProps = {
  courseData: courseCatalogCardType;
};

type CourseSaleDetailDataType = {
  rating: number;
  durationText: string;
  plan?: string;
  planLink?: string;
  wcProduct?: WcProductDataType;
  courseStatus: CourseStatusType;
  studentCount: number;
};

const ellipsisTwo = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
};

function CourseCardContent({ courseData }: CourseCardContentProps) {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        height: 'fit-content',
        minHeight: '160px',
      }}
    >
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            color="primary"
            variant="caption"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {courseData?.instructors.length
              ? courseData?.instructors[0].publicName
              : ''}
          </Typography>

          <Stack direction="row">
            {!courseData.completable && (
              <Typography
                variant="caption"
                sx={{
                  background:
                    'linear-gradient(90deg, rgb(9 70 121) 0%, rgb(11 60 227) 50%, rgb(76 145 223) 100%);',
                  paddingX: '3px',
                  marginLeft: '2px',
                  color: 'common.white',
                  borderRadius: '5px',
                }}
              >
                EARLY
              </Typography>
            )}

            {!courseData.recommendedPlan &&
              courseData.status !== CourseStatusType.EXCLUSIVE && (
                <Typography
                  variant="caption"
                  sx={{
                    background:
                      'linear-gradient(90deg, rgba(121,9,72,1) 0%, rgba(227,11,71,1) 50%, rgba(223,76,137,1) 100%);',
                    paddingX: '3px',
                    marginLeft: '2px',
                    color: 'common.white',
                    borderRadius: '5px',
                  }}
                >
                  PREMIUM
                </Typography>
              )}
          </Stack>
        </Stack>

        <Typography
          variant="subtitle2"
          sx={{
            height: '44px',
            color: 'text.primary',
            ...ellipsisTwo,
            textDecoration: 'none',
            ':hover': {
              color: 'primary.main',
            },
          }}
        >
          {courseData.title}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            height: '36px',
            color: 'text.secondary',
            ...ellipsisTwo,
          }}
        >
          {courseData.shortDescription}
        </Typography>
      </Box>

      <Box>
        {courseData.courseProgress !== null &&
        courseData.status !== CourseStatusType.SOON &&
        courseData.status !== CourseStatusType.PREREGISTER ? (
          <CourseProgress
            progress={courseData.courseProgress.percentComplete}
            completable={courseData.completable}
            lessonAmount={courseData.courseProgress.lessonAmount}
            lessonCompletedAmount={
              courseData.courseProgress.lessonCompletedAmount
            }
          />
        ) : (
          <CourseSaleDetail
            CourseSaleDetailData={{
              rating: courseData.rating,
              durationText: courseData.durationText,
              plan: courseData.recommendedPlan?.title,
              planLink: '/',
              wcProduct: courseData.wcProduct,
              courseStatus: courseData.status,
              studentCount: courseData.studentCount,
            }}
          />
        )}
      </Box>
    </Stack>
  );
}

interface CourseProgressProps {
  progress: number;
  completable: boolean;
  lessonAmount: number;
  lessonCompletedAmount: number;
}

function CourseProgress({
  progress,
  completable,
  lessonAmount,
  lessonCompletedAmount,
}: CourseProgressProps) {
  return (
    <Stack sx={{ marginBottom: '8px' }}>
      {completable && (
        <LinearProgress
          value={progress}
          variant="determinate"
          color={progress < 100 ? 'warning' : 'success'}
          sx={{ borderRadius: '8px', marginTop: '5px' }}
        />
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: '20px' }}
      >
        {completable ? (
          <Typography
            variant="body1"
            sx={{ color: progress < 100 ? 'warning.main' : 'success.main' }}
          >
            {`${progress}% complete`}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              color:
                lessonAmount !== lessonCompletedAmount
                  ? 'warning.main'
                  : 'success.main',
            }}
          >
            {`${lessonCompletedAmount} / ${lessonAmount} บทเรียน`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

interface CourseSaleDetailProps {
  CourseSaleDetailData: CourseSaleDetailDataType;
}

function CourseSaleDetail({ CourseSaleDetailData }: CourseSaleDetailProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box sx={{ marginTop: '7px' }}>
        {CourseSaleDetailData.courseStatus === CourseStatusType.PREREGISTER ? (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                color: 'text.primary',
                gap: '5px',
              }}
            >
              {CourseSaleDetailData.studentCount >= MIN_STU_SHOWN ? (
                <>
                  <Iconify
                    icon="eva:people-fill"
                    sx={{
                      width: '15px',
                      height: '15px',
                      color: 'text.primary',
                    }}
                  />
                  {` ${CourseSaleDetailData.studentCount} ท่าน`}
                </>
              ) : (
                <></>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ gap: '4px' }}>
              <Typography variant="body2" sx={{ color: 'grey.600' }}>
                {'Pre Register'}
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            {CourseSaleDetailData.courseStatus ===
            CourseStatusType.PUBLISHED ? (
              <RatingInCard
                rate={CourseSaleDetailData.rating}
                studentCount={CourseSaleDetailData.studentCount}
              />
            ) : (
              <Typography sx={{ color: 'text.secondary' }}>
                {'(เร็วๆ นี้)'}
              </Typography>
            )}

            <Stack direction="row" alignItems="center" sx={{ gap: '4px' }}>
              <Iconify
                icon="akar-icons:clock"
                width="20"
                height="20"
                sx={{ color: 'grey.600' }}
              />
              <Typography variant="body2" sx={{ color: 'grey.600' }}>
                {CourseSaleDetailData.durationText}
              </Typography>
            </Stack>
          </>
        )}
      </Box>
    </Stack>
  );
}

export default CourseCardContent;
