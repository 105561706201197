import React from 'react'
import { Grid, Stack, Skeleton } from '@mui/material'

export default function projectGallerySkeleton({ index }: { index: number }) {
  return (
    <Grid item xs={11.91} sm={5.8} md={3.8} lg={2.91} key={`loading-project-card-${index}`} justifyContent="center" sx={{ display: 'grid' }}>
      <Skeleton variant="rectangular" width="250px" height="250px" sx={{ borderRadius: '10px' }} />
      <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '10px' }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Skeleton variant="circular" width="24px" height="24px" sx={{ marginRight: '15px' }} />
          <Skeleton variant="text" width="100px" />
        </Stack>
        <Skeleton variant="text" width="50px" />
      </Stack>
    </Grid>
  )
}