import React from 'react';
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';

interface CircleScoreChartType {passed: boolean, score: number, maxScore: number, label?: string}

export default function CircleScoreChart({passed = true, score, maxScore, label = "คะแนนที่ได้"}: CircleScoreChartType) {
  const theme = useTheme()
  const chartColor = passed ? theme.palette.success: theme.palette.error
  const chartOption: ApexOptions = {
      chart: {
            fontFamily: theme.typography.fontFamily
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "75%",
            },
            track: {
              margin: 0,
            },

            dataLabels: {
              name: {
                offsetY: -20,
                show: true,
                color: theme.palette.text.secondary,
                fontSize: "13px"
              },
              value: {
                formatter: function (val: number) {
                  return ``;
                },
                offsetY: 0,
                color: theme.palette.text.primary,
                fontSize: "30px",
                fontWeight: "bold",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            colorStops: [
              [
                {
                  offset: 0,
                  color: chartColor.light,
                  opacity: 1
                },
                {
                  offset: 50,
                  color: chartColor.main,
                  opacity: 1
                },
                {
                  offset: 100,
                  color: chartColor.light,
                  opacity: 1
                },
              ],
            ]
          }
        },
        stroke: {
          lineCap: "round" as 'butt' | 'square' | 'round'
        },
        labels: [label]
      }
  const chartSerie = [score*100/maxScore]

  return (
    <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Typography variant='h3' sx={{position: 'absolute', zIndex: 1}}>{score}/{maxScore}</Typography>
      <Chart
        options={chartOption}
        series={chartSerie}
        type="radialBar"
        width="200"
        height="200"
      />
    </Box>
  )
}
