import React from 'react';
// routes
import Router from './routes';
import Theme from './theme';
import { CssBaseline } from '@mui/material';
import Consent from './components/Consent';
import EventSocket from './socket';

import NotificationToast from './components/notificationToast';
import EventBornBit from './components/notificationToast/EventBornBit';
import { BB_OPEN } from './config';
import SessionTimeoutNoti from './components/notification/SessionTimeout';

function App() {
  return (
    <Theme>
      <SessionTimeoutNoti />
      {BB_OPEN && <NotificationToast />}
      <CssBaseline />
      <Router />
      <EventSocket />
      {BB_OPEN && <EventBornBit />}
      <Consent />
    </Theme>
  );
}

export default App;
