import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from '../../components/Iconify';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios';
import { courseDataType } from '../../@types/courseData';
import StartCourse from '../../components/course/StartCourse';

export default function CourseNoPermission({ title }: { title: string }) {
  const { courseSlug } = useParams();
  const [courseData, setCourseData] = useState<courseDataType>();
  useEffect(() => {
    init();
  }, []);
  async function init() {
    const response = await axios.get(`/courses/${courseSlug}`);
    setCourseData(response.data);
  }

  return (
    <Box sx={{ textAlign: 'center', marginY: '100px' }}>
      <Box>
        <Iconify
          icon={'material-symbols:lock'}
          sx={{ width: '40px', height: '40px' }}
        />
      </Box>
      <Typography variant="h3">{title}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <Typography variant="subtitle1">หากเคยลงทะเบียนเรียนแล้ว, </Typography>
        <Typography
          component={Link}
          to="/login"
          variant="subtitle1"
          sx={{ color: 'primary.main' }}
        >
          กรุณาเข้าสู่ระบบ
        </Typography>
      </Box>

      {courseData && (
        <Box
          sx={{
            maxWidth: '475px',
            margin: '0 auto',
            padding: '20px',
            borderRadius: '16px',
          }}
        >
          <StartCourse
            userLearningStatus={courseData.userLearningStatus}
            slug={courseSlug || ''}
            courseStatus={courseData.status}
            wcProduct={courseData.wcProduct}
            hideLabel
          />
        </Box>
      )}
    </Box>
  );
}
