import { useEffect } from 'react';
import io, { Socket } from 'socket.io-client';

import axiosInstance from '../../utils/axios';
import { dispatch } from '../../redux/store';
import {
  setNotifications,
  setAnnouncements,
  setCount,
  setAnnounceCount,
  setNotiCount,
  updateNotificationCount,
  addNewNotifications,
  addNewAnnouncements,
  setNotiCursor,
  setAnnounceCursor,
} from '../../redux/slices/notification';

import useAuth from '../../hooks/useAuth';
import { updateCommentQuestionQueqe } from '../../redux/slices/comment';

function NotiController() {
  const { isAuthenticated } = useAuth();

  const notificationSocket: Socket = io(
    (window.__RUNTIME_CONFIG__.REACT_APP_HOST_SOCKET_KEY || '') + 'event',
    {
      path: window.__RUNTIME_CONFIG__.REACT_APP_HOST_SOCKET_PATH_PREFIX || '',
      withCredentials: true,
    },
  );

  async function initNotification() {
    try {
      const noticeResponse = await axiosInstance.get(
        '/notifications/user-notifications?take=10',
      );
      dispatch(setNotiCursor(noticeResponse.data.cursor || null));
      dispatch(setNotifications(noticeResponse.data.data));
    } catch (err) {
      return;
    }
  }

  async function initAnnouncement() {
    try {
      const annouceResponse = await axiosInstance.get(
        '/notifications/announcements?take=10',
      );
      dispatch(setAnnounceCursor(annouceResponse.data.cursor || null));
      dispatch(setAnnouncements(annouceResponse.data.data));
    } catch {
      return;
    }
  }

  const disconnectSocket = () => {
    if (notificationSocket) {
      notificationSocket.disconnect();
    }
  };

  useEffect(() => {
    if (notificationSocket) {
      initNotification();
      initAnnouncement();

      // @ts-ignore redux type error
      dispatch(updateNotificationCount());
      notificationSocket.on('notification', (data: any) => {
        dispatch(addNewNotifications(data));
        // @ts-ignore redux type error
        dispatch(updateNotificationCount());
      });

      notificationSocket.on('announcement', (data: any) => {
        dispatch(addNewAnnouncements(data));
        // @ts-ignore redux type error
        dispatch(updateNotificationCount());
      });

      notificationSocket.on(
        'comment-question-bot-reply',
        (data: { commentId: string }) => {
          dispatch(
            updateCommentQuestionQueqe({ id: data.commentId, status: 'READY' }),
          );
        },
      );
    } else {
      dispatch(setNotiCursor(null));
      dispatch(setAnnounceCursor(null));
      dispatch(setNotifications([]));
      dispatch(setAnnouncements([]));
      dispatch(setCount(0));
      dispatch(setAnnounceCount(0));
      dispatch(setNotiCount(0));
    }
  }, [notificationSocket, dispatch]);

  useEffect(() => {
    !isAuthenticated && disconnectSocket();
    return () => {
      disconnectSocket();
    };
  }, [isAuthenticated]);

  return null;
}

export default NotiController;
