import React from 'react';
import { Box, Avatar, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import { instructorType } from '../../..//@types/courseData';
import Iconify from '../../../components/Iconify';
import { fTSeparator } from '../../../utils/formatNumber';

interface InstructorsProps {
  instructors: instructorType[];
}

export default function Instructors({ instructors }: InstructorsProps) {
  return (
    <>
      <Typography variant="h4" sx={{ marginTop: '30px' }}>
        ผู้สอน
      </Typography>
      {instructors.map((instructor) => (
        <Instructor key={instructor.publicName} instructor={instructor} />
      ))}
    </>
  );
}

function Instructor({ instructor }: { instructor: instructorType }) {
  return (
    <Box sx={{ marginY: '15px' }}>
      <Box
        component={Link}
        to={`/search?search=${instructor.publicName}`}
        sx={{
          all: 'unset',
          userSelect: 'none',
          display: 'flex',
          gap: '25px',
          cursor: 'pointer',
        }}
      >
        <Avatar
          alt={instructor.publicName}
          src={instructor.imageUrl}
          sx={{ width: '79px', height: '79px' }}
        />
        <Box>
          <Typography variant="h5" sx={{ ':hover': { color: 'primary.main' } }}>
            {instructor.publicName}
          </Typography>
          <Typography
            variant="body1"
            sx={{ ...ellipsisTwo, color: 'text.secondary' }}
          >
            {instructor.bio}
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        gap={'1%'}
        rowGap={'5px'}
        sx={{ color: 'text.secondary', marginTop: '20px' }}
      >
        <Grid
          item
          sm={5}
          sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Iconify icon="ant-design:star-filled" width="18px" height="18px" />
          <Typography variant="body1">
            {instructor.rating
              ? `${instructor.rating} star ratings`
              : 'ยังไม่มีรีวิว '}
          </Typography>
        </Grid>
        <Grid
          item
          sm={5}
          sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Iconify icon="eva:person-fill" width="18px" height="18px" />
          <Typography variant="body1">
            {`${fTSeparator(instructor.studentCount)} ผู้เรียน`}
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Iconify icon="bx:play-circle" width="18px" height="18px" />
          <Typography variant="body1">
            {`${instructor.courseCount} คอร์ส`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const ellipsisTwo = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
};
