export default function getObjParams(qParams: string) {
  const queryString = qParams.replace(/.*?\?/, '');
  const queryList = queryString.split('&');
  return queryList.reduce(
    (prev, curr) => {
      const queryPair = curr.split('=');
      return { [queryPair[0]]: queryPair[1], ...prev };
    },
    {} as Record<string, string>,
  );
}
