import React, { useEffect } from 'react';
import { useRoutes, useParams, BrowserRouter } from 'react-router-dom';

import LessonLayout from '../layouts/lessons';
import MainLayout from '../layouts/main';

import ProjectGallery from '../pages/projectGallery';
import Lesson from '../pages/lesson';
import CourseFinal from '../pages/courseFinal';
import CourseDetail from '../pages/courseDetail';
import BundleDetail from '../pages/bundleDetail';
import Main from '../pages/main';
import Login from '../pages/login';
import NotFound from '../pages/404';
import ProjectDetail from '../pages/projectDetail';
import Profile from '../pages/profile';
import Certificate from '../pages/certification';
import CertificateEvent from '../pages/certficateEvent';
import CourseTransfer from '../pages/event/CourseTransfer';
import CodingQuestion from '../pages/lesson/Devlab/DevlabQuestion';
import SettingDevice from '../pages/settings/devices';
import ErrorPage from '../pages/error';
import SubscriptionPlanPage from '../pages/subscriptionPlan';
import WcProducts from '../pages/event/WcProduct';
import NotificationPage from '../pages/notification';
import Leaderboard from '../pages/leaderboard';

import LoggingPage from '../pages/logging/courseLog';
import BornbitSidebar from '../components/bornbits/Sidebar';
import { BB_OPEN } from '../config';
import SearchPage from '../pages/main/SearchPage';

function Route() {
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <Main /> },
        { path: '/profile', element: <Profile /> },
        { path: '/profile/:userId', element: <Profile /> },
        { path: '/search', element: <SearchPage /> },
        { path: '/search/bundle', element: <SearchPage /> },
        { path: '/course/:courseSlug', element: <CourseDetail /> },
        { path: '/course/:courseSlug/changelog', element: <LoggingPage /> },
        { path: '/bundle/:bundleSlug', element: <BundleDetail /> },
        { path: '/devproject', element: <ProjectGallery /> },
        { path: '/devproject/:projectId', element: <ProjectDetail /> },
        { path: '/certificate/:certificateId', element: <Certificate /> },
        {
          path: '/certificate/event/:certificateEventId',
          element: <CertificateEvent />,
        },
        { path: '/event/transfer', element: <CourseTransfer /> },
        { path: '/event/wc-product/:wcProductSlug', element: <WcProducts /> },
        { path: 'setting/devices', element: <SettingDevice /> },
        { path: '/subscription', element: <SubscriptionPlanPage /> },
        { path: '/notification/:noticeId', element: <NotificationPage /> },
        { path: '/leaderboard', element: <Leaderboard /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'course',
      element: <LessonLayout />,
      children: [
        { path: ':courseSlug/summary', element: <CourseFinal /> },
        { path: ':courseSlug/:lessonId', element: <Lesson /> },
        {
          path: ':courseSlug/:lessonId/devlab/:devlabId',
          element: <CodingQuestion />,
        },
      ],
    },
    { path: '/error', element: <ErrorPage /> },
    { path: '*', element: <NotFound /> },
  ]);
}

const Router = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Route />
      {BB_OPEN ? <BornbitSidebar /> : <></>}
    </BrowserRouter>
  );
};

export default Router;
