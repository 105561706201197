import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import axiosInstance from '../../utils/axios';
import { CommentDetailType } from '../../@types/comments';
import CommentLoading from '../skeleton/CommentLoading';
import CommentCard from './CommentCard';
import { useSelector } from '../../redux/store';

const StyleBox = styled(Card)(({ theme }) => ({
  padding: '10px',
  borderRadius: '16px',
  boxShadow: theme.shadows[5],
  backgroundColor: theme.palette.background.default,
}));

interface SelectedCommentProps {
  id: string;
  type: 'ANSWER' | 'COMMENT';
  courseId: string;
  lessonId: string;
}

export default function SelectedComment({
  id,
  type,
  courseId,
  lessonId,
}: SelectedCommentProps) {
  const selected = useRef<HTMLElement | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [triggerFetchComment, setTriggerFetchComment] = useState<boolean>(true);
  const [selectedCommentData, setSelectedCommentData] =
    useState<CommentDetailType>();

  const { lastCommentUpdated } = useSelector((state) => state.comment);

  function getApiPath() {
    if (type === 'ANSWER') {
      return `/comments/answer/${id}?courseId=${courseId}&lessonId=${lessonId}`;
    } else {
      return `/comments/${id}?courseId=${courseId}&lessonId=${lessonId}`;
    }
  }

  async function getSelectedComment() {
    try {
      setLoading(true);
      const response = await axiosInstance(getApiPath());
      setSelectedCommentData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function init() {
    await getSelectedComment();
    selected.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (lastCommentUpdated?.id === selectedCommentData?.id) {
      setSelectedCommentData(selectedCommentData);
    }
  }, [lastCommentUpdated]);

  return (
    <Box ref={selected} sx={{ marginY: '10px' }}>
      {loading ? (
        <Box>
          <CommentLoading number={1} />
        </Box>
      ) : (
        <>
          {selectedCommentData ? (
            <StyleBox>
              <Typography
                variant="h4"
                sx={{ color: 'primary.main', marginBottom: '10px' }}
              >
                ความคิดเห็นที่เลือก
              </Typography>
              <CommentCard
                key={`comment-card-${selectedCommentData.id}`}
                courseId={courseId}
                lessonId={lessonId}
                commentData={selectedCommentData}
                childCommentCount={selectedCommentData.child}
                triggerstate={triggerFetchComment}
                triggerFetchFunction={setTriggerFetchComment}
                selected={true}
              />
            </StyleBox>
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  );
}
