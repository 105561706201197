import React, { useState, useEffect } from 'react'
import { Box, Stack } from '@mui/material'

import Image from '../../../../components/Image'

import DevlabDescription from './DevlabDescription'
import DevlabProgress from './DevlabProgress'
import DevlabTask from './DevlabTask'

import TaskLogin from '../DevlabQuestion/TaskLogin'
import { lessonType } from '../../../../@types/lesson'
import axiosInstance from '../../../../utils/axios'

interface DevlabDetailProps {
  detail: lessonType
}

export default function DevlabDetail({ detail }: DevlabDetailProps) {
  const [devlabAccount, setDevlabAccount] = useState<boolean>(false)

  const getCheckDevlab = async () => {
    try {
      const response = await axiosInstance.get('/devlab/account')
      
      if (response.data) setDevlabAccount(true)
      else setDevlabAccount(false)

    } catch {
      setDevlabAccount(false)
    }
  }

  useEffect(() => {
    getCheckDevlab()
  }, [])

  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        <Stack direction="row" justifyContent="center" sx={{ marginTop: '50px', marginBottom: '35px' }}>
          <Image 
            visibleByDefault 
            src="/logos/school-full-824x624.png" 
            sx={{ width: '400px' }} 
          />
        </Stack>

        <DevlabDescription
          completed={detail.completed}
          nextLesson={detail.nextLesson && detail.nextLesson.id}
          lessonTitle={detail.title}
          description={detail?.devlab?.description}
          taskCount={detail?.devlab?.tasks?.length}
          time={detail.duration}
          passCondition={String(detail?.devlab?.percentPassing)}
          languageList={detail?.devlab?.allowLanguages}
        />
      </Box>

      <DevlabProgress
        allScore={String(detail?.devlab?.maxScore)}
        progress={detail?.devlab?.percentProgress}
        completed={detail.completed}
      />

      {devlabAccount ? (
        <DevlabTask taskList={detail?.devlab?.tasks} />
      )
      : (
        <TaskLogin />
      )}
    </>
  )
}
