import React from 'react'
import { Box, Typography, Skeleton, Grid } from '@mui/material'

export default function NoteSkeleton() {
  return (
    <Grid container spacing={2} sx={{marginTop: '10px'}}>
        <Grid item xs={1.6}>
          <Skeleton/>
        </Grid>
        <Grid item xs={10.4}>
          <Grid container spacing={2} sx={{marginBottom: '10px'}}>
            <Grid item xs={10}>
              <Box>
                <Skeleton/>
              </Box>
            </Grid>
            <Grid item xs={2} sx={{display: 'flex', justifyContent: 'space-around'}}>
              <Skeleton/>
              <Skeleton/>
            </Grid>
          </Grid>
          <Typography variant='caption'>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
          </Typography>
        </Grid>
      </Grid>
  )
}
