import React, { useState } from 'react'
import { Box, Badge, IconButton } from '@mui/material'
import Iconify from '../Iconify'
import NotiDrawer from './NotiDrawer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export default function Notification() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const count = useSelector((state: RootState) => state.notification.count);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={count} color="error">
          <Iconify icon='bxs:bell' sx={{width: '20px', height: '20px', cursor: 'pointer'}}/>
        </Badge>
      </IconButton>
      <NotiDrawer open={open} setAnchorEl={setAnchorEl}/>
    </Box>
  )
}