import { sectionType } from './courseLesson';
import { WcProductDataType } from './courseCatalog';
import { DeltaStatic } from 'quill';
import { SubscriptionPlanType } from './plan';

export enum CourseStatusType {
  PUBLISHED = 'PUBLISHED',
  SOON = 'SOON',
  EXCLUSIVE = 'EXCLUSIVE',
  PREREGISTER = 'PREREGISTER',
}

export type courseDataType = {
  categories: categoryType[];
  courseProgress: courseProgressType;
  images: courseImagesType;
  description: string | DeltaStatic;
  features: featureType[];
  id: string;
  instructors: instructorType[];
  previewVideo?: string;
  recommendedSoftwares: recommendedSoftwareType[];
  sections: sectionType[];
  shortDescription?: string;
  slug: string;
  tags: tagType[];
  title?: string;
  userLearningStatus: userLearningStatusType;
  durationText: string;
  createdAt: string;
  updatedAt: string;
  rating?: number;
  wcProduct: WcProductDataType;
  status: CourseStatusType;
};

export type courseImagesType = {
  card: string;
  cover: string;
  square: string;
};

export type CoursePlanType = {
  id: string;
  title: string;
  description: string;
  isBusinessPlan: boolean;
  basicPlan: any;
  variations: VariationsPlan[];
};

export type VariationsPlan = {
  id: string;
  billingInterval: number;
  billingPeriod: string;
  defaultVariation: boolean;
  price: number;
  pricePerMonth: number;
  regularPrice: number;
  url: string;
};

export type categoryType = {
  description: string | null;
  id: string;
  title: string;
};

export enum ProjectStatusType {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  INCOMPLETE = 'INCOMPLETE',
  NONE = 'NONE',
}

export type courseProgressType = {
  certificateId: string;
  completed: boolean;
  completedAt: string;
  courseId: string;
  id: string;
  learningDuration: number;
  lessonAmount: number;
  lessonCompletedAmount: number;
  percentComplete: number;
  projectStatus: ProjectStatusType;
  quizAmount: number;
  quizCompletedAmount: number;
  quizMaxScore: number;
  quizSumScore: number;
  totalDuration: number;
  userId: string;
};

export type featureType = {
  description: string;
  icon: string;
  text: string;
};

export type instructorType = {
  bio: string;
  publicName: string;
  id: string;
  imageUrl: string;
  lastName: string;
  studentCount: number;
  rating: number;
  courseCount: number;
};

export type recommendedSoftwareType = {
  description: string;
  icon: string;
  title: string;
  link: string;
};

export type tagType = {
  description: string | null;
  id: string;
  title: string;
};

export type groupType = {
  id: string;
  name: string;
};

export type userLearningStatusType = {
  enrollable: boolean;
  enrolled: boolean;
  recommendedPlan: SubscriptionPlanType;
};

export type priceplan = {
  id: string;
  price: number;
  regularPrice: number;
  url: string;
};

export type BundleSuggestType = {
  bundle: BundleType;
  meta: metaBundleSuggestType;
};

export enum BundleStatusType {
  PUBLISHED = 'PUBLISHED',
  SOON = 'SOON',
  EXCLUSIVE = 'EXCLUSIVE',
  PREREGISTER = 'PREREGISTER',
}

export type BundleType = {
  additionalPrice: number;
  cardImage: string;
  coverImage: string;
  createdAt: string;
  description: string;
  id: string;
  owned: boolean;
  previewVideo: string;
  publishedAt: string;
  shortDescription: string;
  slug: string;
  squareImage: string;
  status: BundleStatusType;
  title: string;
  updatedAt: string;
  images: courseImagesType;
  wcProduct: wcProductBundleType;
};

export type wcProductBundleType = {
  data: WcProductDataType;
  text: string;
  value: number;
};

export type metaBundleSuggestType = {
  hasProject: boolean;
  studentCount: number;
  review: reviewBundleType;
};

export type reviewBundleType = {
  average: number;
  count: number;
};
