import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function HelmetMetaData(
  {
    quote = 'borntoDev School | เริ่มต้นเรียน เขียนโปรแกรม ขั้นเทพ ! จากพื้นฐานสู่ยอดมนุษย์',
    title,
    image = 'https://www.borntodev.com/wp-content/uploads/2020/04/borntodev-80x80-1.png',
    description = 'คอร์สเรียน เขียนโปรแกรม รูปแบบออนไลน์ที่เน้นให้ผู้เรียนได้รู้ทั้งทฤษฎี และ การปฏิบัติจริงในรูปแบบออนไลน์ สามารถเรียนรู้ได้ทุกที่ ทุกเวลา ชำระเงินครั้งเดียว เรียนได้ตลอดชีพ !',
    currentUrl = ''
  }: {quote?: string, title?: string, image?: string, description?: string, currentUrl?: string}) {

    return (
        <Helmet>
            <title>{title ? title+" | borntoDev School" : quote}</title>
            <link rel="icon" href={`${window.__RUNTIME_CONFIG__.PUBLIC_URL}/logos/school-sqr-80x80.png`} />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="csrf_token" content={title ? title+" | borntoDev School" : quote} />
            <meta property="type" content="website" />
            <meta property="url" content={`https://school.borntodev.com${currentUrl}`} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="msapplication-TileColor" content="#ffc000" />
            <meta name="msapplication-TileImage" content={`${window.__RUNTIME_CONFIG__.PUBLIC_URL}/logos/school-sqr-80x80.png`} />
            <meta name="theme-color" content="#ffc000" />
            <meta name="_token" content="" />
            {/* <meta name="robots" content="noodp" /> */}
            <meta property="title" content={title ? title+" | borntoDev School" : quote} />
            <meta property="quote" content={quote+" | borntoDev School"} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title+" | borntoDev School"} />
            <meta property="og:quote" content={quote+" | borntoDev School"} />
            <meta property="og:image" content={image} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="borntoDev-School" />
            <meta property="og:description" content={description} />
        </Helmet>
    );
}
