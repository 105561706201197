import { styled, alpha } from '@mui/material/styles';
import { Button, ButtonProps, Palette, PaletteColor } from '@mui/material';

const ShadowButton = styled(Button)<ButtonProps>(({ color = 'primary' , theme }) =>
  {
    type PaletteKey = keyof {
      [Key in keyof Palette as Palette[Key] extends PaletteColor ? Key : never]: true;
    }

    return ({
      borderRadius: '8px',
      boxShadow: `0px 4px 10px 0px ${alpha(theme.palette[color as PaletteKey].main, 0.5)}`,
      ':hover': {
        boxShadow: `0px 4px 10px 0px ${alpha(theme.palette[color as PaletteKey].dark, 0.5)}`
      }
    })
  }
);

export default ShadowButton
