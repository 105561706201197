import React, { useEffect, useState } from 'react';
import { Box, Stack, Button, Typography, Divider } from '@mui/material';
import dayjs from 'dayjs';

import axiosInstance from '../../utils/axios';

import Icon from '../../components/Iconify';
import { fDateThai } from '../../utils/formatTime';

import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router';

interface DeviceStatusProps {
  active: boolean;
  online: boolean;
  date: string;
}

interface DeviceCardProps {
  deviceData: DeviceDataType;
  active: boolean | null;
  logoutOtherDevice: Function;
}

interface DeviceDataType {
  agent: string;
  isCurrent: boolean;
  isOnline: boolean;
  isActive: boolean;
  lastConnect: string;
  os: string;
  sessionId: string;
}

function DeviceStatus({ active, online, date }: DeviceStatusProps) {
  const statusConfig: any = {
    ACTIVE: {
      text: 'active',
      color: 'success.main',
    },
    INACTIVE: {
      text: 'inactive',
      color: 'error.main',
    },
    OFFLINE: {
      text: 'offline',
      color: 'text.disabled',
    },
  };

  const handleStatus = (): string => {
    if (online) {
      if (active) {
        return 'ACTIVE';
      } else {
        return 'INACTIVE';
      }
    } else {
      return 'OFFLINE';
    }
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" sx={{ gap: '5px' }}>
        <Box
          sx={{
            width: '10px',
            height: '10px',
            borderRadius: '100%',
            backgroundColor: statusConfig[handleStatus()].color,
          }}
        />
        <Typography sx={{ color: statusConfig[handleStatus()].color }}>
          {statusConfig[handleStatus()].text}
        </Typography>
      </Stack>
      <Typography variant="caption" sx={{ color: 'text.disabled' }}>
        {fDateThai(date) + ' ' + dayjs(date).format('hh:mm:ss A')}
      </Typography>
    </Box>
  );
}

function DeviceCard({
  deviceData,
  active,
  logoutOtherDevice,
}: DeviceCardProps) {
  const isCurrentActive =
    (deviceData.isCurrent && active) || deviceData.isActive;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: 'background.paper',
        padding: '20px',
        borderRadius: '8px',
        gap: '20px',
        '@media (max-width: 500px)': {
          flexDirection: 'column',
        },
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ gap: '10px' }}>
        <Icon
          icon="akar-icons:desktop-device"
          sx={{ width: '55px', height: '55px' }}
        />
        <Box>
          <Typography variant="subtitle1">{deviceData.agent}</Typography>
          <Typography variant="body1">{deviceData.os}</Typography>
          <DeviceStatus
            active={isCurrentActive}
            online={deviceData.isCurrent || deviceData.isOnline}
            date={deviceData.lastConnect}
          />
        </Box>
      </Stack>
      <Box>
        {deviceData.isCurrent ? (
          <Box>
            <Typography
              sx={{
                color: 'text.disabled',
                width: '126px',
                textAlign: 'right',
              }}
            >
              อุปกรณ์ปัจจุบัน
            </Typography>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="error"
            onClick={() => logoutOtherDevice(deviceData.sessionId)}
            sx={{ width: '126px' }}
          >
            <Icon
              icon="ic:round-logout"
              sx={{ width: '20px', height: '20px', marginRight: '5px' }}
            />
            <Typography>ลงชื่อออก</Typography>
          </Button>
        )}
      </Box>
    </Stack>
  );
}

export default function ManageDevice() {
  const { authSocketConnected } = useSelector(
    (state: RootState) => state.socket,
  );
  const [devices, setDevices] = useState<DeviceDataType[]>([]);
  const { isInitialized, isAuthenticated } = useAuth();
  const { active } = useSelector((state: RootState) => state.device);
  const navigate = useNavigate();

  async function fetchDevices() {
    try {
      const response = await axiosInstance.get('/users/setting/devices');
      setDevices(response.data);
    } catch (err) {
      return;
    }
  }

  async function logoutOtherDevice(sessionId: string) {
    try {
      await axiosInstance.post(`/users/setting/devices/${sessionId}/logout`);
      fetchDevices();
    } catch (err) {
      return;
    }
  }

  useEffect(() => {
    authSocketConnected && fetchDevices();
  }, [authSocketConnected, active]);

  useEffect(() => {
    isInitialized && !isAuthenticated && navigate('/', { replace: true });
  }, [isInitialized, isAuthenticated]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ gap: '10px', padding: '40px', paddingTop: '60px' }}
    >
      <Typography variant="h3">การเข้าใช้งานทั้งหมดด้วยบัญชีนี้</Typography>
      <Typography>
        ( หากมีการใช้งานบัญชีเกิน 2 อุปกรณ์จะทำให้ไม่สามารถเข้าเรียนได้ )
      </Typography>

      <Divider sx={{ width: '50%', marginTop: '20px', marginBottom: '40px' }} />
      {devices.map((device, index: number) => (
        <DeviceCard
          key={`devices-${index}`}
          deviceData={device}
          active={active}
          logoutOtherDevice={logoutOtherDevice}
        />
      ))}
    </Stack>
  );
}
