import React, { useState } from 'react';
import { Typography, Box, Stack, Grid, Avatar, Collapse } from '@mui/material';
import { BoxProps } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';

import { instructorType } from '../../../@types/courseData';
import { OverviewReviewType } from '../../../@types/reviewCourse';
import Icon from '../../../components/Iconify';
import Image from '../../../components/Image';
import { RatingInCover } from '../../../components/Rating';

interface CoverProps {
  courseImage: string;
  previewVideo?: string | null;
  coverImage?: string;
  title?: string;
  shortDescription?: string;
  dateCourse: string;
  instructors: instructorType[];
  overviewData: OverviewReviewType;
}

interface InstructorCardProps {
  instructor: instructorType;
}

const MAX_INSTRUCTOR_SHOW = 4;

export default function Cover({
  courseImage,
  previewVideo,
  coverImage,
  title,
  shortDescription,
  dateCourse,
  instructors,
  overviewData,
}: CoverProps) {
  const [showVideo, setShowVideo] = useState<boolean>(previewVideo !== null);
  const [showInstructor, setShowInstructor] = useState<boolean>(false);

  return (
    <CoverWrapStyle coverImage={coverImage}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '@media (max-width: 899px)': {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h3" color="text.primary">
          {title}
        </Typography>
        <Typography variant="subtitle1" color="text.primary">
          {shortDescription}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`อัพเดทเมื่อ ${dayjs(dateCourse).format('DD MMM YYYY')}`}
        </Typography>

        <Box
          sx={{ marginTop: '10px', textAlign: 'left', color: 'text.primary' }}
        >
          <RatingInCover
            rate={overviewData.avg}
            studentCount={overviewData.student}
            reviewCount={overviewData.count}
          />
        </Box>

        <Grid container rowGap="10px" sx={{ marginTop: '20px' }}>
          {instructors
            .slice(0, MAX_INSTRUCTOR_SHOW)
            .map((item: instructorType, index: number) => (
              <InstructorCard instructor={item} key={`instructor-${index}`} />
            ))}
        </Grid>

        <Collapse in={showInstructor}>
          <Grid container rowGap="10px" sx={{ marginTop: '10px' }}>
            {instructors
              .slice(MAX_INSTRUCTOR_SHOW)
              .map((item: instructorType, index: number) => (
                <InstructorCard
                  instructor={item}
                  key={`instructor-addition-${index}`}
                />
              ))}
          </Grid>
        </Collapse>

        {instructors.length > MAX_INSTRUCTOR_SHOW && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{ gap: '3px', marginTop: '10px', cursor: 'pointer' }}
            onClick={() => setShowInstructor((prev: boolean) => !prev)}
          >
            <Icon
              icon={
                showInstructor
                  ? 'solar:alt-arrow-up-outline'
                  : 'solar:alt-arrow-down-outline'
              }
              sx={{ fontSize: '18px' }}
            />
            <Typography sx={{ cursor: 'pointer', width: 'fit-content' }}>
              {showInstructor ? 'ดูน้อยลง' : 'แสดงทั้งหมด'}
            </Typography>
          </Stack>
        )}
      </Box>
      <Box sx={{ width: '100%' }}>
        {showVideo ? (
          <ReactPlayer
            url={previewVideo as string}
            controls={true}
            width="100%"
            playing={true}
            onError={() => setShowVideo(false)}
          />
        ) : (
          <Box sx={{ paddingTop: '56.25%', position: 'relative' }}>
            <Image
              src={courseImage}
              alt="Course-Image"
              sx={{
                borderRadius: '10px',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        )}
      </Box>
    </CoverWrapStyle>
  );
}

function InstructorCard({ instructor }: InstructorCardProps) {
  return (
    <Grid item xs={12} md={6}>
      <Stack
        to={`/search?search=${instructor.publicName}`}
        component={Link}
        direction="row"
        alignItems="center"
        sx={{ gap: '10px', textDecoration: 'none', cursor: 'pointer' }}
      >
        <Avatar alt="instructor" src={instructor.imageUrl} />
        <Box sx={{ maxWidth: '163px' }}>
          <Typography
            variant="body1"
            sx={{
              color: 'text.primary',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ':hover': {
                color: 'primary.main',
              },
            }}
          >
            {instructor.publicName}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
}

interface CoverWrapProps extends BoxProps {
  coverImage?: String;
}

const CoverWrapStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'coverImage',
})<CoverWrapProps>(({ coverImage, theme }) => ({
  backgroundImage: `linear-gradient(${alpha(
    theme.palette.background.default,
    0.97,
  )}, ${alpha(theme.palette.background.default, 0.95)}), url("${coverImage}")`,
  padding: '85px 40px 135px 40px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  '@media (max-width: 899px)': {
    flexDirection: 'column-reverse',
    padding: '40px',
  },
}));
