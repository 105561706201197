import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { HEADER, NAVBAR } from '../../config';
import { Box, Container, Drawer } from '@mui/material';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { Link as RouterLink, useParams } from 'react-router-dom';
import UserCard from '../../components/auth/UserCard'
import RedirectMenu from './RedirectMenu';
import useAuth from '../../hooks/useAuth';
import LogoutButton from '../../components/auth/LogoutButton';

export default function NavMobile() {
  const params = useParams()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(false)
  }, [params])
  return (
    <HeadNavStyle>
      <Container sx={{height: '100%'}}>
        <Box sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Box component={RouterLink} to={'/'}>
            <Image visibleByDefault alt='logo' src="/logos/school-full-225x72.png" sx={{width: '120px'}}/>
          </Box>
          <Iconify
            icon='dashicons:menu-alt3'
            sx={{width: '24px', height: '24px', cursor: 'pointer', ':hover' : {color: 'primary.main'}}}
            onClick={() => setOpen(true)}
          />
        </Box>
        <SideNav open={open} setOpen={setOpen}/>
      </Container>
    </HeadNavStyle>
  )
}

function SideNav({open, setOpen}: {open: boolean, setOpen: Function}) {
  const { isAuthenticated } = useAuth();
  return (
    <SideNavStyle
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Box component={RouterLink} to={'/'}>
            <Image visibleByDefault alt='logo' src="/logos/school-full-225x72.png" sx={{width: '120px'}}/>
          </Box>
          <Iconify
            icon='dashicons:menu-alt3'
            sx={{width: '24px', height: '24px', marginRight: '10px', cursor: 'pointer', ':hover' : {color: 'primary.main'}}}
            onClick={() => setOpen(false)}
          />
        </Box>
        <Box sx={{backgroundColor: 'background.neutral', padding: isAuthenticated ? '18px 20px': '', borderRadius: '8px', marginTop: '40px'}}>
          <UserCard/>
        </Box>
        <RedirectMenu sx={{marginTop: '40px'}}/>
        { isAuthenticated && <LogoutButton/> }
    </SideNavStyle>
  )
}


const HeadNavStyle = styled(Box)(({ theme }) => ({
    position: 'fixed',
    height: HEADER.MOBILE_HEIGHT,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 10
  }))

  const SideNavStyle = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper' : {
      maxWidth: NAVBAR.DASHBOARD_WIDTH,
      backgroundColor: theme.palette.background.default,
      backgroundImage: 'none',
      width: '100%',
      borderLeft: '0px',
      padding: '40px'
    },
    '*::-webkit-scrollbar': {
        width: '0.5em'
      },
      '*::-webkit-scrollbar-track': {
        WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'slategrey',
        borderRadius: '8px'
      }
  }));
