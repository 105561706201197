import React from 'react'
import { Typography, Box, Chip } from '@mui/material'

import { PropsHeaderSearch } from '../../../@types/projectGallery'

function GalleryHeaderSearch({ searchMode, galleryParams, projectCount }: PropsHeaderSearch) {
  if (searchMode === 'filter') {
    return (
      <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
        <Typography variant="h3">Filter by</Typography>
        <Box sx={{ marginTop: '30px' }}>
          {galleryParams?.categories?.map((item: string, index: Number) => (
            <Chip
              key={`consule-filter-${index}`}
              sx={{ padding: '0px 5px', margin: '4px 5px 0px 5px', cursor: 'default' }}
              label={item}
              variant="outlined"
              color="default"
            />
          ))}
          {galleryParams?.tags?.map((item: string, index: Number) => (
            <Chip
              key={`consule-filter-${index}`}
              sx={{ padding: '0px 5px', margin: '4px 5px 0px 5px', cursor: 'default' }}
              label={item}
              variant="outlined"
              color="primary"
            />
          ))}
        </Box>
      </Box>
    )
  } else if (searchMode === 'search') {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h2" sx={{ marginTop: '60px' }}>{galleryParams.search}</Typography>
        <Typography sx={{ fontSize: '24px' }}>{`${projectCount} search result`}</Typography>
      </Box>
    )
  }

  return null
}

export default GalleryHeaderSearch