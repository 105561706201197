import React from 'react'
import { Box, styled } from '@mui/material'

interface EditorProps {
  read?: boolean
  showText?: boolean
  noBackgroundColor?: boolean
}

const EditorStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'read' && prop !== 'showText' && prop !== 'noBackgroundColor',
})<EditorProps>(({ theme, read, showText = true, noBackgroundColor }) => ({
  borderRadius: '10px',
  borderStyle: 'solid',
  borderWidth: read ? '0px' : '1px',
  borderColor: theme.palette.grey[500_32],
  padding: '10px',
  backgroundColor: noBackgroundColor ? 'transparent': theme.palette.background.neutral,


  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-toolbar.ql-snow': {
    borderColor: 'transparent',
    borderBottom: `solid 1px ${theme.palette.grey[500_32]}`
  },
  '& .ql-snow.ql-toolbar .ql-formats': {
    margin: 0
  },
  '& .ql-snow.ql-toolbar .ql-stroke': {
    fill: 'none',
    stroke: theme.palette.text.primary,
  },
  '& .ql-snow.ql-toolbar .ql-fill': {
    fill: theme.palette.text.primary,
    stroke: 'none',
  },
  '& .ql-snow.ql-toolbar .ql-picker': {
    color: theme.palette.text.primary,
  },
  '& .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar button:hover .ql-fill': {
    fill: theme.palette.primary.main
  },
  '& .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke': {
    stroke: theme.palette.primary.main
  },
  '& .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke': {
    stroke: theme.palette.primary.main
  },
  '& .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover': {
    color: theme.palette.primary.main,
  },
  '& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label': {
    borderColor: 'transparent',
  },
  '& .ql-snow.ql-toolbar .ql-picker-item:hover': {
    color: theme.palette.primary.main
  },
  '& .ql-toolbar .ql-picker-options': {
    color: theme.palette.text.primary,
    backgroundColor:  theme.palette.background.neutral,
  },
  '& .ql-editor': {
    display: '-webkit-box',
    WebkitLineClamp: !showText && read ? 3 : 0,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    
    minHeight: read ? 0 : 200,
    overflow: read ? 'hidden' : 'auto',
    padding: read ? 0 : '12px 15px',
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.secondary,
    },
    '& pre.ql-syntax': {
      padding: theme.spacing(2),
      borderRadius: '10px',
    },
    '& code': {
      color: theme.palette.grey[700]
    }
  },
}));

export default EditorStyle