import React from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { Link as RouteLink } from 'react-router-dom'

import Image from '../components/Image';

export default function NotFound() {
  const headerText = "Page Not Found"
  const descriptionText = "We're sorry, the page you requested could not be found"
  const subDescriptionText = "Please go back the homepage"

  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Stack direction="column" justifyContent="center" alignItems="center" sx={{ textAlign: 'center', height: '100%' }}>
        <Box sx={{ marginBottom: '40px' }}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h4">{headerText}</Typography>
        </Box>

        <Divider sx={{ width: '100%' }} />
        <Image visibleByDefault alt='logo' src={`${window.__RUNTIME_CONFIG__.PUBLIC_URL}logos/school-full-225x72.png`} sx={{ width: '300px', margin: '15px 0px' }} />
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>{descriptionText}</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>{subDescriptionText}</Typography>

        <Button component={RouteLink} to="/" variant="contained" sx={{ marginY: '30px', width: 'fit-content', borderRadius: '16px', padding: '5px 30px' }}>
          <Typography variant="button">GO HOME</Typography>
        </Button>
      </Stack>
    </Box>
  )
}
