import { createSlice, current } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import {
  NotificationType,
  AnnouncementType,
  PlusAnnounceType,
} from '../../@types/notification';

type NotificationState = {
  notifications: NotificationType[];
  announcements: AnnouncementType[];
  notiCursor: string | null;
  announceCursor: string | null;
  count: number;
  notiCount: number;
  announceCount: number;
  plusAnnounce: PlusAnnounceType | null;
  sessionTimeoutNoti: boolean;
};

const initialState: NotificationState = {
  notifications: [],
  announcements: [],
  notiCursor: null,
  announceCursor: null,
  count: 0,
  notiCount: 0,
  announceCount: 0,
  plusAnnounce: null,
  sessionTimeoutNoti: false,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setAnnouncements(state, action) {
      state.announcements = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setNotiCount(state, action) {
      state.notiCount = action.payload;
    },
    setAnnounceCount(state, action) {
      state.announceCount = action.payload;
    },
    setNotiCursor(state, action) {
      state.notiCursor = action.payload;
    },
    setAnnounceCursor(state, action) {
      state.announceCursor = action.payload;
    },
    setPlusAnnounce(state, action) {
      state.plusAnnounce = action.payload;
    },
    addNewNotifications(state, action) {
      state.notifications = [action.payload, ...current(state).notifications];
    },
    addOldNotifications(state, action) {
      state.notifications = [
        ...current(state).notifications,
        ...action.payload,
      ];
    },
    addNewAnnouncements(state, action) {
      if (
        current(state).announcements.some(
          (item: AnnouncementType) => item.id === action.payload.id,
        )
      ) {
        state.announcements = checkAndFillItem(
          action.payload,
          current(state).announcements,
        );
      } else {
        state.announcements = [action.payload, ...current(state).announcements];
      }
    },
    addOldAnnouncements(state, action) {
      state.announcements = [
        ...current(state).announcements,
        ...action.payload,
      ];
    },
    setSessionTimeoutNoti(state, action) {
      state.sessionTimeoutNoti = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setNotifications,
  setAnnouncements,
  setCount,
  setNotiCount,
  setAnnounceCount,
  setNotiCursor,
  setAnnounceCursor,
  setPlusAnnounce,
  addNewNotifications,
  addOldNotifications,
  addNewAnnouncements,
  addOldAnnouncements,
  setSessionTimeoutNoti,
} = slice.actions;

export function updateNotificationCount() {
  return async () => {
    try {
      const response = await axios.get('/notifications/count');
      const data = await response.data;
      const { all, announcement, notification } = await data;
      dispatch(setCount(all));
      dispatch(setNotiCount(notification));
      dispatch(setAnnounceCount(announcement));
    } catch {
      return;
    }
  };
}

function checkAndFillItem(data: AnnouncementType, list: AnnouncementType[]) {
  return list.map((item: AnnouncementType) => {
    if (item.id === data.id) return data;
    else return item;
  });
}
